export const getResponsePlansInfoTable = (responsePlans: IEvaluationResponsePlan[] | string[]): string => {
    let responsePlanInfo: string = ''
    responsePlans.forEach((responsePlan) => {
        if (responsePlan && typeof responsePlan === 'object') {
            responsePlanInfo = responsePlanInfo === ''
                ? responsePlanInfo + responsePlan.responsePlanId
                : responsePlanInfo + ' - ' + responsePlan.responsePlanId
        } else {
            responsePlanInfo = responsePlanInfo === '' ? responsePlanInfo + responsePlan : responsePlanInfo + ' - ' + responsePlan
        }
    })
    return responsePlanInfo
}

export const getFirsResponsePlanId = (responsePlans: any): number => {
    return responsePlans.every((i: any) => typeof i === "string") ? Number(responsePlans[0]) : responsePlans[0].responsePlanId
}

export const getNumberResponsePlanId = (responsePlans: any): number[] => {
    return responsePlans.every((i: any) => typeof i === "string") ?
        responsePlans.map((rp: string) => Number(rp)) :
        responsePlans.map((rp: any) => rp.responsePlanId)
}

