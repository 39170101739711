import { TGetRequestAPI, TPostRequestAPI, TUpdateRequestAPI } from './apiClientTypes'
import { client } from './apiClient'
import { AxiosRequestConfig } from 'axios'

export const getRequestAPI: TGetRequestAPI = async (requestUrl: string, config?: AxiosRequestConfig<any>) => {
    return await client.get(requestUrl, config).then(res => {
        return res
    })
}

export const postRequestAPI: TPostRequestAPI = async (requestUrl: string, payload: any) => {
    return await client.post(requestUrl, payload).then(res => res)
}

export const updateRequestAPIById: TUpdateRequestAPI = async (requestUrl: string, payload: any) => {
    const { id, ...data } = payload
    const config = {
        headers: {
            'X-HTTP-Method-Override': 'PUT',
            Accept: 'application/json',
        },
        params: { id },
    }

    await client.post(requestUrl, data, config).then(res => res.data)
}

export const deleteRequestAPIById: TUpdateRequestAPI = async (requestUrl: string, payload: any) => {
    const { id, ...data } = payload
    const config = {
        headers: {
            'X-HTTP-Method-Override': 'DELETE',
            Accept: 'application/json',
        },
        params: { id },
    }

    await client.post(requestUrl, data, config).then(res => res.data)
}
