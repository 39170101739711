import styled from '@emotion/styled'
import {
    Box,
    Button,
    Checkbox,
    DialogContent,
    DialogTitle,
    Slider,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import { LANES_CLOSE, LANES_OPEN, NAVY_BLUE, NO_DATA, SMOKE, WHITE } from '../../theme'

export const EventsDialogTitleStyled = styled(DialogTitle)`
  padding: 16px 24px 0 24px;
`

export const EventsDialogTitleInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const EventsDialogTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`

export const EventsDialogIconTitleWrapper = styled.div`
  display: flex;
  height: 30px;
`

export const EventsDialogTitle = styled(Typography)`
  margin: auto 0 auto 8px;
  line-height: 8px;
`


export const EventsDialogSectionIdText = styled(Typography)`
  color: ${SMOKE};
  opacity: 0.8;
  font-size: 14px;
  font-family: AimsunMedium,serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0;
`

export const EventsDialogSeparator = styled(Box)`
  height: 1px;
  background-color: ${NO_DATA};
  box-shadow: 0 0 1px ${NO_DATA};
  margin-bottom: 20px;
  margin-top: 20px;
  opacity: 0.5;
`

export const EventsDialogContentStyled = styled(DialogContent)`
  padding-top: 0;
  padding-bottom: 26px;
  margin-top: -6px;
`

export const EventsDialogRadioGroupWrapper = styled.div`
  display: flex;
  column-gap: 48px;
`

export const EventsDialogSlider = styled(Slider)`
  height: 8px;
  margin-top: 32px;
  margin-left: 4%;
  width: 90%;
  
  .MuiSlider-track {
    height: 8px;
    border-radius: 4px;
  }
    
  .MuiSlider-rail {
    height: 8px;
    border-radius: 4px;
  }
    
  .MuiSlider-valueLabel {
    background-color: ${NO_DATA};
    & * {
      background: transparent;
      color: ${NAVY_BLUE};
    }
  }
    
  .MuiSlider-thumb {
    height: 20px;
    width: 20px;
    background-color: ${WHITE};
    border: 2px solid;
    &:focus,
    &:hover,
    &:active {
      box-shadow: inherit;
    }
  }
    
  .MuiSlider-mark {
    display: none;
  }
  
  .MuiSlider-markLabel {
    font-size: 14px;
    color: ${SMOKE};
  }
`

export const EventsDialogLanesWrapper = styled.div`
  padding: 20px;
  min-width: 400px;
  display: flex;
  column-gap: 32px;
  justify-content: space-evenly;
`

export const LaneInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  column-gap: 16px;
  justify-content: center;
`

export const EventsDialogCheckboxStyle = styled(Checkbox)<{ laneopen: number }>`
  transform: ${props => (props.laneopen === 0 ? 'rotate(-90deg)' : '')};
  background-color: ${props => (props.laneopen === 0? LANES_OPEN : LANES_CLOSE)};
  width: 20px;
  height: 20px;
  margin: 8px;
  padding: 0;
  border-radius: 4px;
  &:hover {
    background-color: ${props => (props.laneopen === 0? LANES_OPEN : LANES_CLOSE)};

  }
`

export const LaneEditInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 8px;
`

export const EventsDialogTimesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`

export const EventsDialogEditTimesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`

export const EventsDialogEditTimesTitle = styled(Typography)`
  font-family: AimsunBold, serif;
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: 0.00938em
`

export const DurationText = styled(Typography)`
  margin: 12px 0;
  font-size: 14px;
`

export const RenderInputStyled = styled(TextField)`
  input {
    text-align: center;
  }
`

export const EventsDialogButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 32px;
`
export const EventsDialogButton = styled(Button)`
  padding: 9px 25px 6px;
  border-radius: 10px;
  margin-left: 20px;
  min-width: 110px;
  
  &:hover {
    opacity: 0.85;
  }
`
export const EventsDialogSnackbarStyled = styled(Snackbar)`
  position: relative;
  @media (min-width: 600px){
    bottom: 0;
    margin-top: 24px;
  }
`
