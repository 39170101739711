import { Box, Typography } from '@mui/material'
import { NAVY_BLUE, NO_DATA, WHITE } from '../../theme'
import styled from '@emotion/styled'
import { ExpandCircleDownRounded as ExpandCircleDownRoundedIcon } from '@mui/icons-material'
import { TableCellContentStyled, TableCellHeaderStyled, TableCellHeaderTypography } from '../../components/tableStyles/tabletyles'

export const CentroidModifiedInfoHeaderBox = styled(Box)`
  flex-direction: row;
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
`

export const CentroidModifiedInfoHeaderText = styled(Typography)`
  padding: 3px 0 0 5px;
`

export const CentroidModifiedInfoContentBox = styled(Box)`
  max-width: 300px;
  padding: 10px;
  width: 100%;
`

export const CentroidModifiedInfoTooltipText = styled(Typography)`
  font-size: 18px;
  font-family: 'AimsunMedium', sans-serif, serif;
  margin-bottom: 4px;
`

export const CentroidModifiedInfoWrapper = styled(Box)`
  position: absolute;    
  top: 82px;
  left: 20px;
  background-color: ${WHITE};
  z-index: 1051;
`

export const ExpandedIcon = styled(ExpandCircleDownRoundedIcon)`
  z-index: inherit;
  cursor: pointer;
  color: ${NAVY_BLUE};
  margin-left: 32px;
  font-size: 24px;

  &.expand_less--collapsed {
    transform: rotate(180deg);
  }
`

export const CentroidModifiedHeaderTypographyBold = styled(TableCellHeaderTypography)`
  font-size: 16px;
  font-weight: bold;
`

export const CentroidModifiedTypographyBodyBold = styled(CentroidModifiedHeaderTypographyBold)`
  font-size: 14px;
  font-weight: bold;
`

export const CentroidModifiedTypography = styled(Typography)`
  font-size: 14px;
`

export const CentroidModifiedTableCellHeader = styled(TableCellHeaderStyled)`
  padding: 10px 12px 10px 12px;
  border-top: 1px solid ${NO_DATA};
`

export const CentroidModifiedTableCellContent = styled(TableCellContentStyled)`
  padding: 6px 12px 6px 12px;
`
