import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

const initialEvaluationsLandUseState = {
    creating: false,
}

const evaluationsLandUseSlice = createSlice({
    name: 'evaluationsLandUse',
    initialState: initialEvaluationsLandUseState,
    reducers: {
        setCreatingEvaluationsLandUse: (state, action: PayloadAction<boolean>): void => {
            state.creating = action.payload
        },
        createNewEvaluationLandUse: (state, action: PayloadAction<any>): void => {
            state.creating = true
        },
        loadPastEvaluationData: (state, action: PayloadAction<{ epoch: number; variable: string }>): void => {},
        loadEvaluationDataByView: (
            state,
            action: PayloadAction<{ allViewMode: IViewMode[]; viewModeId: number; mapId: number; option: string }>
        ): void => {},
        loadEvaluationDataByViewMode: (
            state,
            action: PayloadAction<{
                epoch: number
                allViewMode: IViewMode[]
                viewModeId: number
                mapId: number
                option: string
            }>
        ): void => {},
        loadBaseLineData: (
            state,
            action: PayloadAction<{ allViewMode: IViewMode[]; viewModeId: number; mapId: number; option: string }>
        ): void => {},
    },
})

export const {
    setCreatingEvaluationsLandUse,
    createNewEvaluationLandUse,
    loadPastEvaluationData,
    loadEvaluationDataByViewMode,
    loadEvaluationDataByView,
    loadBaseLineData,
} = evaluationsLandUseSlice.actions

export const selectCreatingEvaluationsLandUse = (state: RootState) => state.evaluationsLandUse.creating

export default evaluationsLandUseSlice.reducer
