import React from 'react'
import QualityManagerDialogTableMissingData from './qualityManagerDialogTableMissingData'
import QualityManagerDialogTablePatternsAvailableData from './qualityManagerDialogTablePatternsAvailableData'
import QualityManagerDialogTablePatternsCleanData from './qualityManagerDialogTablePatternsCleanData'
import QualityManagerDialogTablePatternsSelectedData from './qualityManagerDialogTablePatternsSelectedData'
import QualityManagerDialogTablePatternsVariabilityData from './qualityManagerDialogTablePatternsVariabilityData'
import QualityManagerDialogTableStandard from './qualityManagerDialogTableStandard'
import QualityManagerDialogTableDelayTimeQmSubpathData from './qualityManagerDialogTableDelayTimeQmSubpathData'

const QualityManagerDialogTableSwitch: React.FC<IQualityManagerDialogTableSwitchProps> = ({
    visualisation,
    intervalSelected,
    qmData,
    horizons,
    predictionHorizon,
    outputSelected,
    delayTimeSubpaths,
}: IQualityManagerDialogTableSwitchProps) => {
    if (visualisation === 'percentageMissingData' && intervalSelected === 'station') {
        return <QualityManagerDialogTableMissingData qmData={qmData} />
    }
    if (visualisation === 'patternsSelectedVsRealData') {
        return <QualityManagerDialogTablePatternsSelectedData qmData={qmData} />
    }
    if (visualisation === 'patternsAvailableVsRealData') {
        return <QualityManagerDialogTablePatternsAvailableData qmData={qmData} />
    }
    if (visualisation === 'patternsVsCleanData') {
        return <QualityManagerDialogTablePatternsCleanData qmData={qmData} />
    }
    if (visualisation === 'patternsVariability') {
        return <QualityManagerDialogTablePatternsVariabilityData qmData={qmData} />
    }
    if (visualisation === 'simulatedVsReal' && outputSelected === 'delayTimeQmSubpath') {
        return <QualityManagerDialogTableDelayTimeQmSubpathData qmData={qmData} delayTimeSubpaths={delayTimeSubpaths} />
    }
    return (
        <QualityManagerDialogTableStandard horizons={horizons} predictionHorizon={predictionHorizon} qmData={qmData} />
    )
}

export default QualityManagerDialogTableSwitch
