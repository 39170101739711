import React from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import {
    QmDialogFormControlStyled,
    QmDialogInputLabelStyled,
    QmDialogSelectorWrapper,
} from './qualityManagerDialogStyles'
import { useTranslation } from 'react-i18next'

const QualityManagerDialogSelector: React.FC<IQualityManagerDialogSelectorProps> = ({
    inputLabel,
    valueSelected,
    valueSelectorOptions,
    changeValueSelected,
    isLoading,
    nValues,
    renderValue,
}: IQualityManagerDialogSelectorProps) => {
    const { t } = useTranslation()

    return (
        <QmDialogSelectorWrapper item>
            <QmDialogFormControlStyled>
                <QmDialogInputLabelStyled>{inputLabel}</QmDialogInputLabelStyled>
                {nValues && renderValue ? (
                    <Select
                        value={valueSelected}
                        label={inputLabel}
                        renderValue={renderValue}
                        onChange={changeValueSelected}
                        disabled={isLoading}
                        variant='standard'
                        autoWidth>
                        {Array.from(Array(nValues / 5).keys())
                            .reverse()
                            .map(number => {
                                return (
                                    <MenuItem value={(number + 1) * 5} key={(number + 1) * 5}>
                                        {(number + 1) * 5}
                                    </MenuItem>
                                )
                            })}
                    </Select>
                ) : (
                    <Select
                        value={valueSelected}
                        label={inputLabel}
                        onChange={(event: SelectChangeEvent) => changeValueSelected(event.target)}
                        disabled={isLoading}
                        variant='standard'
                        autoWidth>
                        {valueSelectorOptions.map(option => {
                            return (
                                <MenuItem key={option} value={option} selected={option === valueSelected}>
                                    {t(`qualityManager.${option}`)}
                                </MenuItem>
                            )

                        })}
                    </Select>
                )}
            </QmDialogFormControlStyled>
        </QmDialogSelectorWrapper>
    )
}

export default QualityManagerDialogSelector
