import styled from '@emotion/styled'
import { IconButton, Slider, Typography } from '@mui/material'
import { GREY, LIGHT_GREY, NAVY_BLUE, SMOKE } from '../../theme'

export const SelectedTimeTypography = styled(Typography)<{ startonmark: number }>`
    width: fit-content;
    height: fit-content;
    border: 1px solid #9f9f9f;
    font-size: 14px;
    padding: 3px 10px 0 10px;
    border-radius: 20px;
    transform: ${props => (props.startonmark === 1 ? 'translate(96px, 7px)' : 'translate(116px, 7px)')};
`

export const HorizonSliderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 4px;
    margin: auto;
`

export const SliderWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const SliderIconButton = styled(IconButton)`
    margin-right: 10px;
    width: 44px;
    height: 44px;
    color: ${NAVY_BLUE};
`

export const SliderStyled = styled(Slider)<{ startonmark: number }>`
    height: 40px;
    width: 200px;
    padding: 0;
    margin: ${props => (props.startonmark === 1 ? '0 10px 0 0' : '0 10px 0 20px')};

    .MuiSlider-root,
    .MuiSlider-rail,
    .MuiSlider-track {
        height: 4px;
        border-radius: 2px;
    }
    .MuiSlider-rail {
        width: ${props => (props.startonmark === 1 ? 'calc(100% + 4px)' : 'calc(100% + 24px)')};
        color: ${SMOKE};
        transform: ${props => (props.startonmark === 1 ? 'translateY(0)' : 'translate(-20px, 0)')};
    }
    .MuiSlider-track {
        color: ${NAVY_BLUE};
        transform: translateX(2px);

        &:before {
            content: close-quote;
            height: ${props => (props.startonmark === 1 ? '0' : '4px')};
            width: ${props => (props.startonmark ? '0' : '22px')};
            border-radius: 2px;
            background: ${NAVY_BLUE};
            position: absolute;
            transform: ${props => (props.startonmark === 1 ? 'translateX(0)' : 'translateX(-22px)')};
        }
    }
    .MuiSlider-mark {
        height: 8px;
        width: 8px;
        border-radius: 4px;
        transform: translateY(-2px);
        color: ${NAVY_BLUE};
        &.MuiSlider-markActive {
            background: ${NAVY_BLUE};
        }
    }

    &.Mui-disabled {
        .MuiSlider-mark {
            color: ${GREY};
            &.MuiSlider-markActive {
                background: ${GREY};
            }
        }
    }
    .MuiSlider-markLabel {
        font-size: 12px;
        transform: translateX(-2px);
    }
    .MuiSlider-thumb {
        height: 8px;
        width: 8px;
        transform: translateY(-2px);
        color: ${NAVY_BLUE};
        &:hover {
            box-shadow: none;
        }
        &.MuiSlider-thumbColorPrimary {
            box-shadow: none;
        }
    }

    .MuiSlider-thumb.MuiSlider-thumbColorPrimary.Mui-disabled {
        display: none;
        color: ${LIGHT_GREY};
    }
`
