import * as React from 'react'
import {
    CardContent,
    Typography,
    IconButton,
    InputAdornment,
    FormControl,
    Select,
    SelectChangeEvent,
    MenuItem,
    Slide,
} from '@mui/material'
import {
    CardCentroidInfoModalStyled,
    CardHeaderInfoModalStyled,
    CentroidInfoTitle,
    ZoneCentroidInfoTitle,
    CentroidInfoWrapper,
    ZoneCentroidInfoText,
    CentroidInfoTextField,
    RunEvaluationButton,
    CentroidButtonsWrapper,
    NameNewEvaluationTextField,
    CentroidButtonsGroupWrapper,
    CentroidInfoModalTitle,
    EvaluationInputLabel,
    EvaluationInputDropDownForm,
    CentroidInfoTitleSpan,
    CentroidInfoLabel,
} from './centroidInfoModalStyles'
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { PulseLoader } from 'react-spinners'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
    createNewEvaluationLandUse,
    selectCreatingEvaluationsLandUse,
} from '../evaluationsSelector/store/evaluationsLandUseSlice'
import { DateTime } from 'luxon'
import { selectMapHorizonSelected } from '../mapHorizonSelector/store/mapHorizonSelectedSlice'
import { useTranslation } from 'react-i18next'

export const CentroidInfoModal: React.FC<CentroidInfoModalProps> = ({ centroidData, onClose, edit, optionLandUseView }) => {
    const { t } = useTranslation()
    const _cratingLandUseEvaluation: boolean = useAppSelector(selectCreatingEvaluationsLandUse)
    const _mapHorizonSelected: IMapHorizon = useAppSelector(selectMapHorizonSelected)
    const [creatingEvaluation, setCreatingEvaluation] = useState<boolean>(false)
    const [nameNewEvaluation, setNameNewEvaluation] = useState<string>('')
    const [newResidential, setNewResidential] = useState<number>(0)
    const [newOffice, setNewOffice] = useState<number>(0)
    const [newRetail, setNewRetail] = useState<number>(0)
    const [newIndustrial, setNewIndustrial] = useState<number>(0)
    const [newSchool, setNewSchool] = useState<number>(0)
    const [newMedical, setNewMedical] = useState<number>(0)
    const [newHotelrooms, setNewHotelrooms] = useState<number>(0)
    const [newAmGeneration, setNewAmGeneration] = useState<number>(0)
    const [newAmAttraction, setNewAmAttraction] = useState<number>(0)
    const [newPmGeneration, setNewPmGeneration] = useState<number>(0)
    const [newPmAttraction, setNewPmAttraction] = useState<number>(0)
    const [errorResidential, setErrorResidential] = useState<boolean>(false)
    const [errorOffice, setErrorOffice] = useState<boolean>(false)
    const [errorRetail, setErrorRetail] = useState<boolean>(false)
    const [errorIndustrial, setErrorIndustrial] = useState<boolean>(false)
    const [errorSchool, setErrorSchool] = useState<boolean>(false)
    const [errorMedical, setErrorMedical] = useState<boolean>(false)
    const [errorHotelrooms, setErrorHotelrooms] = useState<boolean>(false)
    const [errorAmGeneration, setErrorAmGeneration] = useState<boolean>(false)
    const [errorAmAttraction, setErrorAmAttraction] = useState<boolean>(false)
    const [errorPmGeneration, setErrorPmGeneration] = useState<boolean>(false)
    const [errorPmAttraction, setErrorPmAttraction] = useState<boolean>(false)
    const [changeSomeItem, setChangeSomeItem] = useState<boolean>(false)
    const [optionsSelected, setOptionsSelected] = useState<string>('landUseData')
    const [running, setRunning] = useState<boolean>(false)

    const dispatch = useAppDispatch()

    useEffect(() => {
        setRunning(_cratingLandUseEvaluation)
        if (!_cratingLandUseEvaluation && creatingEvaluation) {
            onClose()
            setCreatingEvaluation(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_cratingLandUseEvaluation])

    useEffect(() => {
        if (optionsSelected === 'landUseData') {
            setNewAmGeneration(0)
            setErrorAmGeneration(false)
            setNewAmAttraction(0)
            setErrorAmAttraction(false)
            setNewPmGeneration(0)
            setErrorPmGeneration(false)
            setNewPmAttraction(0)
            setErrorPmAttraction(false)
        } else {
            setNewResidential(0)
            setErrorResidential(false)
            setNewOffice(0)
            setErrorOffice(false)
            setNewRetail(0)
            setErrorRetail(false)
            setNewIndustrial(0)
            setErrorIndustrial(false)
            setNewSchool(0)
            setErrorSchool(false)
            setNewMedical(0)
            setErrorMedical(false)
            setNewHotelrooms(0)
            setErrorHotelrooms(false)
        }
        setChangeSomeItem(false)
    }, [optionsSelected])

    const {
        residential,
        office,
        retail,
        industrial,
        school,
        medical,
        hotelrooms,
        from_sections,
        to_sections,
        am_generation,
        am_attraction,
        pm_generation,
        pm_attraction,
    } = centroidData.data
    //TODO: amData and pmData must come from evaluation

    const handleChange = (event: any) => {
        const regex = /^[0-9\b]+$/
        const { value, id } = event.target
        const error  = value === '' || !regex.test(value) || value < 0
        setChangeSomeItem(!error)

        switch (id) {
            case 'new-value-residential':
                setNewResidential(value)
                setErrorResidential(error)
                break
            case 'new-value-office':
                setNewOffice(value)
                setErrorOffice(error)
                break
            case 'new-value-retail':
                setNewRetail(value)
                setErrorRetail(error)
                break
            case 'new-value-industrial':
                setNewIndustrial(value)
                setErrorIndustrial(error)
                break
            case 'new-value-school':
                setNewSchool(value)
                setErrorSchool(error)
                break
            case 'new-value-medical':
                setNewMedical(value)
                setErrorMedical(error)
                break
            case 'new-value-hotelrooms':
                setNewHotelrooms(value)
                setErrorHotelrooms(error)
                break
            case 'new-value-am_generation':
                setNewAmGeneration(value)
                setErrorAmGeneration(error)
                break
            case 'new-value-am_attraction':
                setNewAmAttraction(value)
                setErrorAmAttraction(error)
                break
            case 'new-value-pm_generation':
                setNewPmGeneration(value)
                setErrorPmGeneration(error)
                break
            case 'new-value-pm_attraction':
                setNewPmAttraction(value)
                setErrorPmAttraction(error)
                break
            default:
                break
        }
    }

    const handleNewEvaluationClick = () => {
        let fields
        if (optionsSelected === 'landUseData') {
            fields = {
                residential: Number(newResidential),
                office: Number(newOffice),
                retail: Number(newRetail),
                industrial: Number(newIndustrial),
                school: Number(newSchool),
                hospital: Number(newMedical),
                hotel_rooms: Number(newHotelrooms),
            }
        } else {
            fields = {
                am_generation: Number(newAmGeneration),
                am_attraction: Number(newAmAttraction),
                pm_generation: Number(newPmGeneration),
                pm_attraction: Number(newPmAttraction),
            }
        }
        fields = {
            ...fields,
            original_residential: validEditableValue(residential) ? Math.ceil(residential) : 0,
            original_office: validEditableValue(office) ? Math.ceil(office) : 0,
            original_retail: validEditableValue(retail) ? Math.ceil(retail) : 0,
            original_industrial: validEditableValue(industrial) ? Math.ceil(industrial) : 0,
            original_school: validEditableValue(school) ? Math.ceil(school) : 0,
            original_hospital: validEditableValue(medical) ? Math.ceil(medical) : 0,
            original_hotel_rooms: validEditableValue(hotelrooms) ? Math.ceil(hotelrooms) : 0,
            original_am_generation: validEditableValue(am_generation) ? Math.ceil(am_generation) : 0,
            original_am_attraction: validEditableValue(am_attraction) ? Math.ceil(am_attraction) : 0,
            original_pm_generation: validEditableValue(pm_generation) ? Math.ceil(pm_generation) : 0,
            original_pm_attraction: validEditableValue(pm_attraction) ? Math.ceil(pm_attraction) : 0,
        }

        const newEvaluationLandUseData = {
            centroid_id: centroidData.id,
            fields: fields,
            from_connections: from_sections.map((section: { id: number; position: number[] }) => section.id),
            to_connections: to_sections.map((section: { id: number; position: number[] }) => section.id),
            name: nameNewEvaluation,
            when: DateTime.now().toMillis(),
            type_of_day: 'typical_day',
            map: _mapHorizonSelected.id,
            evaluations: ['am', 'pm'],
        }

        dispatch(createNewEvaluationLandUse(newEvaluationLandUseData))
        setCreatingEvaluation(true)
    }

    const handleClose = () => {
        onClose()
    }

    const handleOptionsEditClick = (event: SelectChangeEvent) => {
        setOptionsSelected(event.target.value)
    }

    const validEditableValue = (value: any) => value === 0 || value || value === ''

    const cardTitle = <CentroidInfoModalTitle>{centroidData.eid}</CentroidInfoModalTitle>

    const allItemsZero = Number(newMedical) === 0 &&
        Number(newSchool) === 0 &&
        Number(newIndustrial) === 0 &&
        Number(newRetail) === 0 &&
        Number(newOffice) === 0 &&
        Number(newResidential) === 0 &&
        Number(newHotelrooms) === 0 &&
        Number(newAmGeneration) === 0 &&
        Number(newPmGeneration) === 0 &&
        Number(newAmAttraction) === 0 &&
        Number(newPmAttraction) === 0

    const disabledRunEvaluation =
        !changeSomeItem ||
        errorMedical ||
        errorSchool ||
        errorIndustrial ||
        errorRetail ||
        errorOffice ||
        errorResidential ||
        errorHotelrooms ||
        errorAmGeneration ||
        errorPmGeneration ||
        errorAmAttraction ||
        errorPmAttraction ||
        allItemsZero ||
        nameNewEvaluation === ''

    const centroidInfoModalTextField = (textFieldId: string, data: any, newData: any, error: boolean, unit: string) => {
        return (
            <CentroidInfoTextField
                error={error}
                onChange={handleChange}
                id={`new-value-${textFieldId}`}
                type='number'
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: <InputAdornment position='end'>{t(`units.${unit}`)}</InputAdornment>,
                }}
                disabled={!validEditableValue(newData)}
                variant='standard'
                value={validEditableValue(newData) ? newData : t('common.noData')}
            />
        )
    }

    const showAmBaseData = !edit && (optionLandUseView === 'am' || optionLandUseView === 'am_baseline'|| optionLandUseView === '' )
    const showPmBaseData = !edit && (optionLandUseView === 'pm' || optionLandUseView === 'pm_baseline'|| optionLandUseView === '' )

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <CardCentroidInfoModalStyled>
                <CardHeaderInfoModalStyled
                    action={
                        <IconButton aria-label='settings' onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                    title={cardTitle}
                />
                <CardContent>
                    <FormControl key='response-plan-form' variant='standard'>
                        <ZoneCentroidInfoTitle>
                            {`${t('mapbox.zone')}: `}
                            <ZoneCentroidInfoText>{centroidData?.eid}</ZoneCentroidInfoText>
                        </ZoneCentroidInfoTitle>
                        {edit && (
                            <>
                                <CentroidInfoWrapper>
                                    <NameNewEvaluationTextField
                                        error={nameNewEvaluation === ''}
                                        onChange={event => setNameNewEvaluation(event.target.value)}
                                        id={'new-evaluation-name'}
                                        type='text'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <EditIcon color={'primary'} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant='standard'
                                        value={nameNewEvaluation}
                                        label={`${t('mapbox.evaluationName')}: `}
                                    />
                                </CentroidInfoWrapper>
                                <CentroidButtonsGroupWrapper>
                                    <EvaluationInputDropDownForm key='evaluation-input-form' variant='standard'>
                                        <EvaluationInputLabel>{t('mapbox.evaluationInput')}</EvaluationInputLabel>
                                        <Select
                                            id='clock-drop-down-selector'
                                            value={optionsSelected}
                                            className='land-use-clock-selector'
                                            onChange={(event: SelectChangeEvent) => {
                                                handleOptionsEditClick(event)
                                            }}>
                                            <MenuItem value={'landUseData'}>{t('mapbox.basedLandUse')}</MenuItem>
                                            <MenuItem value={'numberOfTrips'}>{t('mapbox.basedNumberTrips')}</MenuItem>
                                        </Select>
                                    </EvaluationInputDropDownForm>
                                </CentroidButtonsGroupWrapper>

                                <CentroidInfoWrapper>
                                    <CentroidInfoLabel>{t('mapbox.current')}</CentroidInfoLabel>
                                    <CentroidInfoLabel>{t('mapbox.additional')}</CentroidInfoLabel>
                                </CentroidInfoWrapper>
                            </>
                        )}
                        {optionsSelected === 'landUseData' && (
                            <>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.residential')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(residential) ? 
                                                Math.ceil(residential).toLocaleString('en-US') : t('common.noData')} ${t('units.m2')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {edit &&
                                        validEditableValue(residential) &&
                                        centroidInfoModalTextField(
                                            'residential',
                                            residential,
                                            newResidential,
                                            errorResidential,
                                            'm2'
                                        )}
                                </CentroidInfoWrapper>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.office')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(office) ? 
                                                Math.ceil(office).toLocaleString('en-US') : t('common.noData')} ${t('units.m2')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {edit &&
                                        validEditableValue(office) &&
                                        centroidInfoModalTextField('office', office, newOffice, errorOffice, 'm2')}
                                </CentroidInfoWrapper>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.retail')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(retail) ? 
                                                Math.ceil(retail).toLocaleString('en-US') : t('common.noData')} ${t('units.m2')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {edit &&
                                        validEditableValue(retail) &&
                                        centroidInfoModalTextField('retail', retail, newRetail, errorRetail, 'm2')}
                                </CentroidInfoWrapper>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.industrial')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(industrial) ? 
                                                Math.ceil(industrial).toLocaleString('en-US') : t('common.noData')} ${t('units.m2')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {edit &&
                                        validEditableValue(industrial) &&
                                        centroidInfoModalTextField(
                                            'industrial',
                                            industrial,
                                            newIndustrial,
                                            errorIndustrial,
                                            'm2'
                                        )}
                                </CentroidInfoWrapper>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.school')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(school) ? 
                                                Math.ceil(school).toLocaleString('en-US') : t('common.noData')} ${t('units.m2')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {edit &&
                                        validEditableValue(school) &&
                                        centroidInfoModalTextField('school', school, newSchool, errorSchool, 'm2')}
                                </CentroidInfoWrapper>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.medical')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(medical) ? 
                                                Math.ceil(medical).toLocaleString('en-US') : t('common.noData')} ${t('units.m2')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {edit &&
                                        validEditableValue(medical) &&
                                        centroidInfoModalTextField('medical', medical, newMedical, errorMedical, 'm2')}
                                </CentroidInfoWrapper>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.hotelRooms')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(hotelrooms) ? 
                                                Math.ceil(hotelrooms).toLocaleString('en-US') : t('common.noData')} ${t('units.rooms')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {edit &&
                                        validEditableValue(hotelrooms) &&
                                        centroidInfoModalTextField(
                                            'hotelrooms',
                                            hotelrooms,
                                            newHotelrooms,
                                            errorHotelrooms,
                                            'rooms'
                                        )}
                                </CentroidInfoWrapper>
                                {showAmBaseData && (
                                    <>
                                        <CentroidInfoWrapper>
                                            <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                                {`${t('mapbox.generatedAm')}: `}
                                                <CentroidInfoTitleSpan>
                                                    {`${
                                                        validEditableValue(am_generation) ? 
                                                            Math.ceil(am_generation).toLocaleString('en-US') : t('common.noData')
                                                    } ${t('units.trips')}`}
                                                </CentroidInfoTitleSpan>
                                            </CentroidInfoTitle>
                                        </CentroidInfoWrapper>
                                        <CentroidInfoWrapper>
                                            <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                                {`${t('mapbox.attractedAm')}: `}
                                                <CentroidInfoTitleSpan>
                                                    {`${
                                                        validEditableValue(am_attraction) ? 
                                                            Math.ceil(am_attraction).toLocaleString('en-US') : t('common.noData')
                                                    } ${t('units.trips')}`}
                                                </CentroidInfoTitleSpan>
                                            </CentroidInfoTitle>
                                        </CentroidInfoWrapper>
                                    </>
                                )}
                                {showPmBaseData && (
                                    <>
                                        <CentroidInfoWrapper>
                                            <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                                {`${t('mapbox.generatedPm')}: `}
                                                <CentroidInfoTitleSpan>
                                                    {`${validEditableValue(pm_generation) ? 
                                                        Math.ceil(pm_generation).toLocaleString('en-US') : t('common.noData')
                                                    } ${t('units.trips')}`}
                                                </CentroidInfoTitleSpan>
                                            </CentroidInfoTitle>
                                        </CentroidInfoWrapper>
                                        <CentroidInfoWrapper>
                                            <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                                {`${t('mapbox.attractedPm')}: `}
                                                <CentroidInfoTitleSpan>
                                                    {`${validEditableValue(pm_attraction) ? 
                                                        Math.ceil(pm_attraction).toLocaleString('en-US') : t('common.noData')
                                                    } ${t('units.trips')}`}
                                                </CentroidInfoTitleSpan>
                                            </CentroidInfoTitle>
                                        </CentroidInfoWrapper>
                                    </>
                                )}
                            </>
                        )}
                        {optionsSelected === 'numberOfTrips' && edit && (
                            <>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.generatedAm')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(am_generation) ? 
                                                Math.ceil(am_generation).toLocaleString('en-US') : t('common.noData')
                                            } ${t('units.trips')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {validEditableValue(am_generation) &&
                                        centroidInfoModalTextField(
                                            'am_generation',
                                            am_generation,
                                            newAmGeneration,
                                            errorAmGeneration,
                                            'trips'
                                        )}
                                </CentroidInfoWrapper>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.attractedAm')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(am_attraction) ? 
                                                Math.ceil(am_attraction).toLocaleString('en-US') : t('common.noData')
                                            } ${t('units.trips')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {validEditableValue(am_attraction) &&
                                        centroidInfoModalTextField(
                                            'am_attraction',
                                            am_attraction,
                                            newAmAttraction,
                                            errorAmAttraction,
                                            'trips'
                                        )}
                                </CentroidInfoWrapper>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.generatedPm')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(pm_generation) ? 
                                                Math.ceil(pm_generation).toLocaleString('en-US') : t('common.noData')
                                            } ${t('units.trips')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {validEditableValue(pm_generation) &&
                                        centroidInfoModalTextField(
                                            'pm_generation',
                                            pm_generation,
                                            newPmGeneration,
                                            errorPmGeneration,
                                            'trips'
                                        )}
                                </CentroidInfoWrapper>
                                <CentroidInfoWrapper>
                                    <CentroidInfoTitle className={edit ? 'edit' : ''}>
                                        {`${t('mapbox.attractedPm')}: `}
                                        <CentroidInfoTitleSpan>
                                            {`${validEditableValue(pm_attraction) ? 
                                                Math.ceil(pm_attraction).toLocaleString('en-US') : t('common.noData')
                                            } ${t('units.trips')}`}
                                        </CentroidInfoTitleSpan>
                                    </CentroidInfoTitle>
                                    {validEditableValue(pm_attraction) &&
                                        centroidInfoModalTextField(
                                            'pm_attraction',
                                            pm_attraction,
                                            newPmAttraction,
                                            errorPmAttraction,
                                            'trips'
                                        )}
                                </CentroidInfoWrapper>
                            </>
                        )}
                        {edit && (
                            <>
                                <CentroidButtonsWrapper>
                                    <RunEvaluationButton onClick={handleClose} variant='text'>
                                        <Typography variant='button' color='primary'>
                                            {t('buttons.cancel')}
                                        </Typography>
                                    </RunEvaluationButton>
                                    <RunEvaluationButton
                                        onClick={handleNewEvaluationClick}
                                        variant='contained'
                                        disabled={disabledRunEvaluation}
                                        type={'submit'}>
                                        {running ? (
                                            <Typography variant='button'>
                                                <PulseLoader size={8} margin={2} color='#FFF'></PulseLoader>
                                            </Typography>
                                        ) : (
                                            <Typography variant='button'>{t('buttons.runEvaluation')}</Typography>
                                        )}
                                    </RunEvaluationButton>
                                </CentroidButtonsWrapper>
                            </>
                        )}
                    </FormControl>
                </CardContent>
            </CardCentroidInfoModalStyled>
        </Slide>
    )
}
