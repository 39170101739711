import styled from '@emotion/styled'

export const SearchTermWrapper = styled.div`
  padding: 0;
  margin: 20px 0;
  >.MuiContainer-root {
    padding: 0;
  }
  
  .MuiFormControl-root {
    width: 50%;
  }
`

