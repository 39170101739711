import styled from '@emotion/styled'
import { TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { TABLE_ROW } from '../../theme'

export const TableHeadStyled = styled(TableHead)`
  font-family: 'AimsunMedium', sans-serif, serif;
  font-size: 30px;
`

export const TableCellHeaderStyled = styled(TableCell)`
  padding-top: 8px;
  padding-bottom: 4px;
  border: 0;
`

export const TableCellHeaderTypography = styled(Typography)`
  font-size: 20px;
  font-family: 'AimsunMedium', sans-serif, serif;
`

export const TableRowContent = styled(TableRow)`
  &.table__row--even {
    background-color: ${TABLE_ROW};
    
    td:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  
  &.table__row--odd {
    
  }
`

export const TableCellContentStyled = styled(TableCell)`
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0;
`
