import React, { useEffect, useState } from 'react'
import CustomIcon from '../../components/customIcon/CustomIcon'
import { EvaluationsToolbarIcon, EvaluationsToolbarLabel, EvaluationsToolbarWrapper } from './evaluationsToolbarStyles'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import NewManageLiveEvaluationDialog from '../evaluationsSelector/newManageLiveEvaluationDialog/newManageLiveEvaluationDialog'
import {
    setOpenEvaluationLiveCreateDialog,
    setEvaluationLiveSelected,
    selectEvaluationLiveSelected,
    selectOpenEvaluationLiveCreateDialog,
} from './store/evaluationLiveSlice'
import {
    loadManageLiveResponsePlan
} from '../evaluationsSelector/newManageLiveEvaluationDialog/store/responsePlanManageLiveSlice'
import { selectEpoch } from '../core/coreSlice'

const EvaluationsToolbar = ({allViewModes}: IEvaluationsToolbarProps) => {
    const { t } = useTranslation()
    const _evaluationsIsSelected: boolean = useAppSelector(selectEvaluationLiveSelected)
    const _openEvaluationsCreateDialog: boolean = useAppSelector(selectOpenEvaluationLiveCreateDialog)
    const _epoch = useAppSelector(selectEpoch)
    const [evaluationsToolbarIsSelected, setEvaluationsToolbarIsSelected] = useState<boolean>(_evaluationsIsSelected)
    const [openEvaluationsDialog, setOpenEvaluationsDialog] = useState<boolean>(_openEvaluationsCreateDialog)

    const dispatch = useAppDispatch()

    useEffect(() => {
        setEvaluationsToolbarIsSelected(_evaluationsIsSelected)
        if (_evaluationsIsSelected) {
            dispatch(loadManageLiveResponsePlan({ epoch: _epoch }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_evaluationsIsSelected])

    useEffect(() => {
        setOpenEvaluationsDialog(_openEvaluationsCreateDialog)
    }, [_openEvaluationsCreateDialog])


    const handleEvaluationsToolbarOnClick = () => {
        setEvaluationsToolbarIsSelected(!evaluationsToolbarIsSelected)
        dispatch(setEvaluationLiveSelected(!evaluationsToolbarIsSelected))
        dispatch(setOpenEvaluationLiveCreateDialog(!openEvaluationsDialog))
    }

    return (
        <>
            <EvaluationsToolbarWrapper onClick={handleEvaluationsToolbarOnClick}>
                <EvaluationsToolbarIcon className={`${evaluationsToolbarIsSelected ? 'open-evaluationsToolbar-dialog' : ''}`}>
                    <CustomIcon name={'evaluationStatic'} />
                </EvaluationsToolbarIcon>
                <EvaluationsToolbarLabel className={`${evaluationsToolbarIsSelected ? 'open-evaluationsToolbar-dialog' : ''}`}>
                    {t('manageEvaluation.evaluation')}
                </EvaluationsToolbarLabel>
            </EvaluationsToolbarWrapper>
            {openEvaluationsDialog && <NewManageLiveEvaluationDialog allViewModes={allViewModes}/>}
        </>
    )
}

export default EvaluationsToolbar
