import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface IEvaluationSimulationDataState {
    evaluationLandUseSimulationData: { [key: string]: IEvaluationSimulationByHorizonData[] }
    evaluationManageSimulationData: { [key: string]: { [key: string]: IEvaluationSimulationByHorizonData[] } }
    fetching: boolean
}

const initialSimulatedDataState: IEvaluationSimulationDataState = {
    evaluationLandUseSimulationData: {},
    evaluationManageSimulationData: {},
    fetching: false,
}

export const evaluationSimulationDataSlice = createSlice({
    name: 'evaluationSimulationData',
    initialState: initialSimulatedDataState,
    reducers: {
        storeEvaluationLandUseSimulationData: (state, action: PayloadAction<{ data: IPredictionByHorizonData[], variable: string }>): void => {
            state.evaluationLandUseSimulationData = { ...state.evaluationLandUseSimulationData,
                [action.payload.variable]: action.payload.data
            }
        },
        storeEvaluationManageSimulationData: (state, action: PayloadAction<{ data: { rp: number | string, simulation : IPredictionByHorizonData[] }, variable: string }>): void => {
            state.evaluationManageSimulationData = { ...state.evaluationManageSimulationData,
                [action.payload.variable]: {
                    ...state.evaluationManageSimulationData[action.payload.variable],
                    [action.payload.data.rp]: action.payload.data.simulation
                }
            }
        },
        restoreEvaluationSimulationData: state => {
            state.evaluationLandUseSimulationData = initialSimulatedDataState.evaluationLandUseSimulationData
            state.evaluationManageSimulationData = initialSimulatedDataState.evaluationManageSimulationData
            state.fetching = false
        },
        setFetchingEvaluationSimulationData: (state, action: PayloadAction<boolean>): void => {
            state.fetching = action.payload
        },
        loadEvaluationSimulationData: (state, action: PayloadAction<{ epoch: number, variable: string }>): void => {
            state.fetching = true
        }
    },
})

export const {
    storeEvaluationLandUseSimulationData,
    storeEvaluationManageSimulationData,
    restoreEvaluationSimulationData,
    setFetchingEvaluationSimulationData,
    loadEvaluationSimulationData
} = evaluationSimulationDataSlice.actions

export const selectEvaluationLandUseSimulationData = (state: RootState): { [key: string]: IEvaluationSimulationByHorizonData[] } => state.evaluationSimulationData.evaluationLandUseSimulationData
export const selectEvaluationManageSimulationData = (state: RootState): { [key: string]: { [key: string]: IEvaluationSimulationByHorizonData[] } } => state.evaluationSimulationData.evaluationManageSimulationData
export const selectFetchingEvaluationSimulationData = (state: RootState): boolean => state.evaluationSimulationData.fetching

export default evaluationSimulationDataSlice.reducer
