import { all, AllEffect, call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects'
import { getPatternQuality, getPattern, getAvailablePatterns } from '../../../services/pattern/patternService'
import { selectEpoch } from '../../core/coreSlice'
import { loadPattern, storePattern, storePatternQuality, setFetching, storeAvailablePatterns } from './patternSlice'

function* fetchPattern(): Generator<StrictEffect, any, any> {
    const epoch: number = yield select(selectEpoch)
    let pattern: IPattern = yield call(getPattern, epoch)
    yield put(storePattern(pattern))
}

function* fetchPatternQuality(): Generator<unknown, any, IPatternQuality> {
    const patternQuality: IPatternQuality = yield call(getPatternQuality)
    yield put(storePatternQuality(patternQuality.value))
}

function* fetchAvailablePatterns(): Generator<unknown, any, IPatternJson[]> {
    const availablePatterns: IPatternJson[] = yield call(getAvailablePatterns)
    yield put(storeAvailablePatterns(availablePatterns))
}

function* initPattern(action: any) {
    yield call(fetchPattern)
    yield call(fetchPatternQuality)
    yield call(fetchAvailablePatterns)
    yield put(setFetching(false))
}
//TODO improve types
function* patternSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(loadPattern, initPattern)])
}

export default patternSaga
