import { PATTERNS_PALETTE } from '../features/qualityManagerDialog/qualityManagerDialogOptions'

export const generateDistinctPalette = (size: number) => {
    const primary = 215
    const step = Math.floor(360 / size)
    return Array.apply(null, Array(size)).map((_: any, i: number) => {
        let hue = primary + step * i
        if (hue > 360) {
            hue -= 360
        }
        return `hsl(${hue}, 80%, 60%)`
    })
}

export const generateExtendedPalette = (size: number) => {
    return Array.apply(null, Array(size)).map((_: any, i: number) => {
        let extension = 0
        let patternIndex = i
        while (patternIndex > PATTERNS_PALETTE.length - 1) {
            patternIndex -= PATTERNS_PALETTE.length
            extension += 1
        }
        if (extension === 0) {
            return PATTERNS_PALETTE[patternIndex]
        } else {
            const hsl = hexToHSLComponents(PATTERNS_PALETTE[patternIndex])!
            const step = 20
            let lightness = hsl.lightness + step
            if (lightness > 100) {
                lightness -= 100
            }
            let hue = hsl.hue + step
            if (hue > 360) {
                hue -= 360
            }
            return 'hsl(' + hue + ', ' + hsl.saturation + '%, ' + lightness + '%)'
        }
    })
}

export const hexToHSLComponents = (hex: string) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (!result) {
        return {
            hue: 0,
            saturation: 0,
            lightness: 0,
        }
    }

    var r = parseInt(result[1], 16)
    var g = parseInt(result[2], 16)
    var b = parseInt(result[3], 16)

    r /= 255
    g /= 255
    b /= 255
    var max = Math.max(r, g, b),
        min = Math.min(r, g, b)
    var h,
        s,
        l = (max + min) / 2

    if (max === min) {
        h = s = 0 // achromatic
    } else {
        var d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            default:
                h = (r - g) / d + 4
        }
        h /= 6
    }

    s = s * 100
    s = Math.round(s)
    l = l * 100
    l = Math.round(l)
    h = Math.round(360 * h)

    return {
        hue: h,
        saturation: s,
        lightness: l,
    }
}

export const hexToHSL = (hex: string) => {
    const hsl = hexToHSLComponents(hex)!
    return 'hsl(' + hsl.hue + ', ' + hsl.saturation + '%, ' + hsl.lightness + '%)'
}
