import React from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import { Container, FormControl, InputAdornment } from '@mui/material'
import { InputSearch } from './searchTermStyles'
import { useTranslation } from 'react-i18next'

const SearchTermComponent: React.FC<ISearchTermProps> = ({
    searchTermChange,
    handleKey,
    placeholder,
    iconColor,
    id,
    value,
    startAdornment,
    disableUnderline = false,
}: ISearchTermProps) => {
    const { t } = useTranslation()
    return (
        <Container maxWidth='xl'>
            <FormControl>
                <InputSearch
                    id={id || 'searchField'}
                    autoFocus
                    startAdornment={
                        startAdornment && (
                            <InputAdornment position='start'>
                                <SearchIcon color={iconColor || 'primary'} />
                            </InputAdornment>
                        )
                    }
                    placeholder={placeholder || t('search.search')}
                    onChange={(ev: any) => {
                        const text = ev.target as HTMLInputElement
                        if (searchTermChange) {
                            searchTermChange(text.value)
                        }
                    }}
                    disableUnderline={disableUnderline}
                    onKeyUp={(ev: any) => {
                        if (ev.key === 'Enter') {
                            const text = ev.target as HTMLInputElement
                            if (handleKey) {
                                handleKey(text.value)
                            }
                        }
                    }}
                    inputProps={{
                        autoComplete: 'off',
                    }}
                    value={value}
                />
            </FormControl>
        </Container>
    )
}

export default SearchTermComponent
