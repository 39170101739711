import { NAVY_BLUE, OLIVE_ISH, RED_LIVE, WHITE } from '../../theme'

export const getSeriesData = (
    realDataSeries: lineDataChart[],
    patternMaxDataSeries: lineDataChart[],
    patternMinDataSeries: lineDataChart[],
    predictionDataSeries: lineDataChart[],
    predictionDataHideSeries: lineDataChart[]
): ApexAxisChartSeries | ApexNonAxisChartSeries => [
    {
        type: 'area',
        name: 'Pattern Max',
        data: patternMaxDataSeries,
        color: RED_LIVE,
    },
    {
        type: 'area',
        name: 'Pattern Min',
        data: patternMinDataSeries,
        color: WHITE,
    },
    {
        type: 'line',
        name: 'Prediction',
        data: predictionDataSeries,
        color: OLIVE_ISH,
    },
    {
        type: 'line',
        name: 'Real',
        data: realDataSeries,
        color: NAVY_BLUE,
    },
    {
        type: 'line',
        name: 'Prediction',
        data: predictionDataHideSeries,
    },
]
