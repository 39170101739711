import { UNITS_BY_SYSTEM, UNIT_TYPE } from '../constants/unitsConstants'

export const converterToMetricSystem = (type: string, value: number): number => {
    if (value === -1) {
        return value
    }
    switch (type) {
        case UNIT_TYPE.speed:
            return Number.parseFloat((value * 1.609344001).toFixed(2))

        case UNIT_TYPE.distance:
            return Number.parseFloat((value * 0.3048).toFixed(2))

        case UNIT_TYPE.fluidity:
        case UNIT_TYPE.density:
            return Number.parseFloat((value * 0.621371192).toFixed(2))

        default:
            return value
    }
}

export const converterToImperialSystem = (type: string, value: number): number => {
    if (value === -1) {
        return value
    }
    switch (type) {
        case UNIT_TYPE.speed:
            return Number.parseFloat((value * 0.621371192).toFixed(2))

        case UNIT_TYPE.distance:
            return Number.parseFloat((value * 3.280839895).toFixed(2))

        case UNIT_TYPE.fluidity:
        case UNIT_TYPE.density:
            return Number.parseFloat((value * 1.609344001).toFixed(2))

        default:
            return value
    }
}

export const getUnitTypeFromUnit = (unit: string): string | undefined => {
    switch (unit) {
        case UNITS_BY_SYSTEM.metric.speed:
            return UNIT_TYPE.speed
        case UNITS_BY_SYSTEM.metric.fluidity:
            return UNIT_TYPE.fluidity
        case UNITS_BY_SYSTEM.metric.distance:
            return UNIT_TYPE.distance
        case UNITS_BY_SYSTEM.metric.density:
            return UNIT_TYPE.density
        default:
            return undefined
    }
}

export const getUnitFromType = (type: string | undefined) => {
    switch (type) {
        case UNIT_TYPE.speed:
            return UNITS_BY_SYSTEM.imperial.speed

        case UNIT_TYPE.distance:
            return UNITS_BY_SYSTEM.imperial.distance

        case UNIT_TYPE.fluidity:
            return UNITS_BY_SYSTEM.imperial.fluidity
        case UNIT_TYPE.density:
            return UNITS_BY_SYSTEM.imperial.density
        default:
            return undefined
    }
}

export const convertUnitsFromMetricToImperial = (unit: string): string =>
    getUnitFromType(getUnitTypeFromUnit(unit)) || unit
