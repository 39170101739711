import React, { useState } from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { ClockDropDownForm, ClockDropDownInputLabel, ClockDropDownWrapper } from './clockDropDownStyles'

const ClockDropDown: React.FC<IClockDropDownProps> = () => {
    const selectOptions = [{ id: 'typical_day', name: 'Typical Day' }]
    const [value, setValue] = useState<string>(selectOptions[0].id)

    const handleDayChange = (event: SelectChangeEvent) => {
        setValue(event.target.value)
    }
    return (
        <React.Fragment>
            <ClockDropDownWrapper>
                <ClockDropDownForm key='landuse-day-form' variant='standard'>
                    <ClockDropDownInputLabel>Day</ClockDropDownInputLabel>
                    <Select
                        id='clock-drop-down-selector'
                        value={value}
                        className='land-use-clock-selector'
                        onChange={(event: SelectChangeEvent) => {
                            handleDayChange(event)
                        }}>
                        {selectOptions.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </ClockDropDownForm>
            </ClockDropDownWrapper>
        </React.Fragment>
    )
}

export default ClockDropDown
