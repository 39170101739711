import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import CustomDialog from '../../../../../../components/customDialog/customDialog'
import { KPIsComparisonDialogProps } from './KpiComparisonDialogTypes'
import { NEXT_BLUE, NO_DATA, OLIVE_ISH, RED_LIVE } from '../../../../../../theme'
import { KpiComparisonTable } from '../../kpiComparisonTable/KpiComparisonTable'
import { ComparisonRadarChart } from '../../kpiComparisonRadarChart/comparisonRadarChart'
import { ComparisonTimeSeriesChart } from '../../kpiComparisonTimeSeriesChart/comparisonTimeSeriesChart'
import { ActionsButtonsWrapper, NoDataMessage, StepDialogButton } from './KpiComparisonDialogStyles'
import { checkKpiDataAvailable, getKpiListWithoutCongestion, getKpisSelected } from './KpiComparisonDialogHelper'
import { IRawKpi } from '../../../../../analyzeContainer/components/kpi/store/kpisSlice'

const MAX_STEP = 3

const KpiComparisonDialog = (
    {
        open,
        onClose,
        selectedEvaluation,
        kpiList,
        unitsSystem,
        apmHorizonStep,
        apmHorizonCount,
        responsePlanEvaluations
    }: KPIsComparisonDialogProps
) => {
    const [step, setStep] = useState<number>(0)
    const [responsePlansSelected, setResponsePlansSelected] = useState<string[]>(Object.keys(kpiList))
    const [kpiListSelected, setKpiListSelected] = useState< {[p: string]: IRawKpi[]}>({})
    const [kpiListWithoutCongestion, setKpiListWithoutCongestion] = useState<{[p: string]: IRawKpi[]}>({})
    const [kpiDataAvailable, setKpiDataAvailable] = useState<boolean>(checkKpiDataAvailable(kpiList))

    useEffect(() => {
        const newResponsePlans = Object.keys(kpiList)
        const responsePlanSelected = newResponsePlans.filter((rpID) => responsePlanEvaluations.includes(Number(rpID)))

        setKpiListSelected(getKpisSelected(newResponsePlans, kpiList))
        setKpiListWithoutCongestion(getKpiListWithoutCongestion(newResponsePlans, kpiList))
        setResponsePlansSelected(responsePlanSelected)
        setKpiDataAvailable(checkKpiDataAvailable(kpiList))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kpiList])

    useEffect(() => {
        const newResponsePlans = Object.keys(kpiList)
        const responsePlanSelected = newResponsePlans.filter((rpID) => responsePlanEvaluations.includes(Number(rpID)))

        setResponsePlansSelected(responsePlanSelected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responsePlanEvaluations])

    const handleClose = () => {
        setStep(0)
        onClose(responsePlansSelected)
    }

    const setSelectedResponsePlans = (responsePlans: string[]) => {
        setResponsePlansSelected(responsePlans)
    }

    const renderStepComponent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <KpiComparisonTable
                        setResponsePlansSelected={setSelectedResponsePlans}
                        responsePlansSelected={responsePlansSelected}
                        kpiList={kpiList}
                        unitsSystem={unitsSystem}
                    />
                )
            case 1:
                return (
                    <ComparisonRadarChart
                        kpiList={kpiListSelected}
                        setResponsePlansSelected={setSelectedResponsePlans}
                        responsePlansSelected={responsePlansSelected}
                    />
                )

            case 2:
                return (
                    <ComparisonTimeSeriesChart
                        responsePlansSelected={responsePlansSelected}
                        kpiList={kpiListWithoutCongestion}
                        apmHorizonStep={apmHorizonStep}
                        apmHorizonCount={apmHorizonCount}
                        setResponsePlansSelected={setSelectedResponsePlans}
                    />
                )
            default:
                return null
        }
    }

    return (
        <CustomDialog
            handleClose={handleClose}
            title={`Evaluation ${selectedEvaluation} - KPIs`}
            open={open}
            fullScreen={kpiDataAvailable}
        >
            { kpiDataAvailable ? (
                <>
                    {renderStepComponent(step)}
                    <ActionsButtonsWrapper>
                        {step > 0 && (
                            <StepDialogButton
                                onClick={() => setStep(step - 1)}
                                variant='contained'
                                style={{ background: RED_LIVE }}
                            >
                                <Typography variant='button'>Prev</Typography>
                            </StepDialogButton>
                        )}
                        {step === MAX_STEP - 1 ? (
                            <StepDialogButton
                                onClick={() => handleClose()}
                                variant='contained'
                                style={responsePlansSelected.length === 0 ? { background: NO_DATA } : { background: OLIVE_ISH }}
                                disabled={responsePlansSelected.length === 0}
                            >
                                <Typography variant='button'>Done</Typography>
                            </StepDialogButton>
                        ) : (
                            <StepDialogButton
                                onClick={() => setStep(step + 1)}
                                variant='contained'
                                style={{ background: NEXT_BLUE }}
                            >
                                <Typography variant='button'>Next</Typography>
                            </StepDialogButton>
                        )}
                    </ActionsButtonsWrapper>
                </>
            ) : (
                <NoDataMessage align='center'>
                    {'No kpi data available'}
                </NoDataMessage>

            )}
        </CustomDialog>
    )
}

export default KpiComparisonDialog
