import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

const initialPatternState: IPatternState = {
    id: -1,
    name: 'No set',
    quality: -1,
    fetching: false,
    availablePatterns: [],
}

const patternSlice = createSlice({
    name: 'pattern',
    initialState: initialPatternState,
    reducers: {
        storePattern: (state, action: PayloadAction<any>): void => {
            state.id = action.payload.pattern_id
            state.name = action.payload.pattern_name
        },
        storePatternQuality: (state, action: PayloadAction<number>): void => {
            state.quality = action.payload
        },
        setFetching: (state, action: PayloadAction<boolean>): void => {
            state.fetching = action.payload
        },
        loadPattern: (state, action: PayloadAction<number>): void => {
            state.fetching = true
        },
        storeAvailablePatterns: (state, action: PayloadAction<IPatternJson[]>): void => {
            state.availablePatterns = action.payload
        },
    },
})

export const { storePattern, storePatternQuality, setFetching, loadPattern, storeAvailablePatterns } =
    patternSlice.actions

export const selectPattern = (state: RootState) => state.pattern
export const selectFetchingPattern = (state: RootState) => state.pattern.fetching
export const selectAvailablePatterns = (state: RootState) => state.pattern.availablePatterns

export default patternSlice.reducer
