import { all, AllEffect, call, delay, ForkEffect, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import {
    storeEvaluations,
    storeEvaluationCreatedMessage,
    setSelectedEvaluation,
    selectSelectedEvaluation,
} from './evaluationsSlice'
import {
    createManageDemoEvaluation,
    deleteManageDemoEvaluation,
    getEvaluations,
} from '../../../services/evaluations/evaluationsService'
import {
    initialEmptyFeatureCollectionState,
    loadIncidents,
    selectResponsePlanActions,
    storeFetchingDataByMap,
    storeFinishFetchingDataByMap,
    storeMapboxData,
} from '../../mapbox/mapboxSlice'
import { selectConfig } from '../../core/coreSlice'
import { selectHorizon } from '../../horizonSlider/horizonSilderSlice'
import { selectEvaluationManageSimulationData } from '../../core/simulationEvaluationData/evaluationSimulationDataSlice'
import {
    createNewLiveEvaluationManage,
    createNewStaticEvaluationManage,
    deleteEvaluation,
    loadManageEvaluationDataByView,
    loadManageEvaluationDataByViewMode,
    loadPastManageEvaluationData,
    setStaticEvaluationManageRemoved,
    storeTaskEvaluationManageProgress,
    taskEvaluationManageProgress,
} from './evaluationsManageSlice'
import { selectPattern } from '../../pattern/store/patternSlice'
import { getNumberResponsePlanId } from '../newManageDemoEvaluationDialog/newManageEvaluationDialogHelper'
import { createLiveSimulation, createStaticSimulation } from '../../../services/simulations/simulationsService'
import { initManageEvaluationSimulationData } from '../../core/simulationEvaluationData/evaluationSimulationDataSaga'
import { getAllViewMode } from '../../../helpers/ContainerHelper'
import { getTaskProgressByEpoch } from '../../../services/tasks/tasksService'
import { TASK_STATUS_TYPE } from './evaluationsLandUseSaga'
import { getActionByIdService } from '../../../services/actions/actionsService'
import { getEventByIdService } from '../../../services/events/eventsService'
import { limitResponsePlanIds } from '../../../containers/manageContainer/ManageContainerHelper'

function* getAllRpEvaluationData(action: any) {
    const { epoch, responsePlans, id } = action.payload.evaluation
    const variable = action.payload.variable
    const responsePlansId = getNumberResponsePlanId(responsePlans)

    yield all(
        responsePlansId.map(rpId =>
            call(initManageEvaluationSimulationData, {
                ...action,
                payload: {
                    epoch: epoch,
                    rp: rpId,
                    variable,
                    evaluationId: id,
                },
            })
        )
    )
}

function* getEvaluationData(action: any) {
    const { epoch, id } = action.payload.evaluation
    const { variable, rpId } = action.payload
    yield call(initManageEvaluationSimulationData, {
        ...action,
        payload: {
            epoch: epoch,
            rp: rpId,
            variable,
            evaluationId: id,
        },
    })
}

function* storeEvaluationMapData(
    mapId: number,
    viewModeId: number,
    allData: any,
    sectionStationData: any,
    rpActions: any
): any {
    yield put(
        storeMapboxData({
            stationData: sectionStationData,
            sectionData: sectionStationData,
            mapId,
            viewModeId,
            stationNowData: [],
            stationFutureData: allData,
            sectionNowData: [],
            sectionFutureData: allData,
            riskPrediction: [],
            riskPredictionData: [],
            rpActions: rpActions,
            speedRecommendation: [],
            speedRecommendationData: [],
            qm: false,
        })
    )
}

function* evaluationManageCreation(action: any): any {
    try {
        const _evaluation = action.payload.evaluation
        const pattern = yield select(selectPattern)
        let order = { strategies: [], pattern: pattern.id, mode: 'on_demand' }
        order.strategies = _evaluation.responsePlans.map((rpId: any) => {
            const rpIdBundle = `${_evaluation.eventId}-${rpId}`
            return {
                strategy_id: rpIdBundle,
                strategy_name: rpIdBundle,
            }
        })

        const { simulation } = yield call(createStaticSimulation, order)
        const packId = simulation.pack_id
        const nextEvaluation = { ..._evaluation, epoch: packId }

        yield call(createManageDemoEvaluation, nextEvaluation)

        const newEvaluations = yield call(getEvaluations)
        const newEvaluationCreated = newEvaluations.find(
            (evaluation: IEvaluationStatic) => evaluation.epoch === nextEvaluation.epoch
        )
        if (newEvaluationCreated) {
            yield put(storeEvaluations(newEvaluations))
            const newAction = {
                ...action,
                payload: {
                    ...action.payload,
                    evaluation: newEvaluationCreated,
                    allViewModes: action.payload.allViewModes,
                    variable: action.payload.allViewModes[0].variable,
                },
            }

            yield put(storeEvaluationCreatedMessage('manageEvaluation.evaluationStrategyCreatedMessage'))
            yield call(goToEvaluationCreated, newAction)
        } else {
            yield put(storeEvaluationCreatedMessage('manageEvaluation.errorMessage'))
            yield put(storeEvaluations(newEvaluations))
        }
    } catch (error) {
        console.error(error)
    }
}

function* evaluationManageLiveCreation(action: any): any {
    try {
        const _evaluation = action.payload.evaluation
        const orderEvents = []
        let orderResponsePlans = []
        const listEvents: number[] = []
        const listActions: number[] = []

        _evaluation.responsePlans.forEach((responsePlan: IResponsePlan) => {
            responsePlan.events &&
                responsePlan.events.forEach((eventId: number) => {
                    const existEvent = listEvents.find((id: number) => id === eventId)
                    if (!existEvent) {
                        listEvents.push(eventId)
                    }
                })
            responsePlan.actions &&
                responsePlan.actions.forEach((actionId: number) => {
                    const existAction = listActions.find((id: number) => id === actionId)
                    if (!existAction) {
                        listActions.push(actionId)
                    }
                })
        })

        const actionsResponse = yield all(
            listActions.map((actionId: number) => {
                return call(getActionByIdService, actionId)
            })
        )
        const eventsResponse = yield all(
            listEvents.map((eventId: number) => {
                return call(getEventByIdService, eventId)
            })
        )

        for (let eventId in eventsResponse) {
            const currentEvent = eventsResponse[eventId]
            const speedReductions = currentEvent.lanes
                .filter((lane: any) => lane.open)
                .map((lane: any) => {
                    return {
                        lane_index: lane.id,
                        speed: lane.speed,
                    }
                })

            orderEvents.push({
                id: currentEvent.id,
                name: currentEvent.name,
                section_id: currentEvent.section_id,
                start_time: currentEvent.start_time,
                end_time: currentEvent.end_time,
                closed_lanes: currentEvent.closed_lanes,
                speed_reductions: speedReductions,
            })
        }

        for (let responsePlanId in _evaluation.responsePlans) {
            const rp = _evaluation.responsePlans[responsePlanId]
            let currentResponsePlan: {
                id: any
                name: any
                diversions?: any;
                control_changes?: any
                speed_changes?: any
                lane_closures?: any
            } = {
                id: rp.id,
                name: rp.name,
            }

            const actionList = rp.actions
            if (actionList && actionList.length > 0) {
                let control_changes = []
                let diversions = []
                let speed_changes = []
                let lane_closures = []

                for (let action in actionList) {
                    const currentAction = actionsResponse.find(
                        (actionResponse: any) => actionResponse.id === actionList[action]
                    )
                    const actionType = currentAction.actions_type
                    const actionId = currentAction.id
                    if (actionType.includes('diversion')) {
                        //set_a_diversion_route
                        const subpaths = currentAction.data.subpaths
                        for (let subpath in subpaths) {
                            const subpathId = Number.parseInt(subpath)
                            diversions.push({
                                section_id: currentAction.section_id,
                                use_percentage: subpaths[subpath],
                                subpath_id: subpathId,
                                action_id: actionId,
                            })
                        }
                    } else if (actionType.includes('control')) {
                        //change_control_plan
                        control_changes.push({
                            node_id: currentAction.data.node_id,
                            control_plan_id: currentAction.data.control_plan_id,
                            action_id: actionId,
                        })
                    } else if (actionType.includes('speed_change')) {
                        //change_speed_changes
                        speed_changes.push({
                            action_id: actionId,
                            name: currentAction.name,
                            section_id: currentAction.section_id,
                            lanes: currentAction.data.lanes,
                            speed: currentAction.data.speed
                        })
                    } else if (actionType.includes('lane_closure')) {
                        //change_lane_closure
                        lane_closures.push({
                            action_id: actionId,
                            name: currentAction.name,
                            section_id: currentAction.section_id,
                            lanes: currentAction.data.lanes
                        })
                    }
                }

                currentResponsePlan = {
                    ...currentResponsePlan,
                    diversions: diversions,
                    control_changes: control_changes,
                    speed_changes: speed_changes,
                    lane_closures: lane_closures
                }
            }
            orderResponsePlans.push(currentResponsePlan)
        }

        const simulationData = {
            when: _evaluation.epoch,
            events: orderEvents,
            response_plans: orderResponsePlans,
        }

        const { simulation } = yield call(createLiveSimulation, simulationData)

        const packId = simulation.pack_id
        const nextEvaluationRp = _evaluation.responsePlans.map((rp: any) => {
            return {
                responsePlanId: rp.id,
                description: rp.name,
            }
        })
        const nextEvaluation = {
            ..._evaluation,
            responsePlans: nextEvaluationRp,
            epoch: packId,
        }

        yield call(createManageDemoEvaluation, nextEvaluation)

        const newEvaluations = yield call(getEvaluations)
        const newEvaluationCreated = newEvaluations.find(
            (evaluation: IEvaluationStatic) => evaluation.epoch === nextEvaluation.epoch
        )
        if (newEvaluationCreated) {
            yield put(storeEvaluations(newEvaluations))
            const newAction = {
                ...action,
                payload: {
                    ...action.payload,
                    evaluation: newEvaluationCreated,
                    allViewModes: action.payload.allViewModes,
                    variable: action.payload.allViewModes[0].variable,
                },
            }

            yield put(storeEvaluationCreatedMessage('manageEvaluation.evaluationStrategyCreatedMessage'))
            yield call(goToEvaluationCreated, newAction)
        } else {
            yield put(storeEvaluationCreatedMessage('manageEvaluation.errorMessage'))
            yield put(storeEvaluations(newEvaluations))
        }
    } catch (error) {
        console.error(error)
    }
}

function* goToEvaluationCreated(action: any): any {
    yield put(setSelectedEvaluation(action.payload.evaluation))
    yield call(getPastManageEvaluationData, action)
    yield call(getTaskManageEvaluationProgress, action)
}

function* getManageEvaluationDataByViewMode(action: any): any {
    const { allViewMode, viewModeId, mapId, option, evaluation } = action.payload
    const selectedViewMode = allViewMode.find(({ id }: IViewMode) => id === viewModeId)
    const horizon: number = yield select(selectHorizon)
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const step: number = _moduleConfig['horizon-step']

    let actionToCall = action
    actionToCall = {
        ...actionToCall,
        payload: {
            epoch: evaluation.epoch,
            variable: selectedViewMode.variable,
            rpId: option,
            evaluation,
        },
    }

    yield put(storeFetchingDataByMap(mapId))
    yield call(getEvaluationData, actionToCall)
    const simulationData = yield select(selectEvaluationManageSimulationData)
    const allSimulationData = simulationData[selectedViewMode.variable]
    const rpActions = yield select(selectResponsePlanActions)
    const actions =
        rpActions[option].actions.length === 0 ? initialEmptyFeatureCollectionState : rpActions[option].actions

    yield call(
        storeEvaluationMapData,
        mapId,
        viewModeId,
        allSimulationData[option],
        allSimulationData[option][horizon / step],
        actions
    )
}

function* getManageEvaluationDataByView(action: any): any {
    const { allViewMode, viewModeId, mapId, option } = action.payload
    const selectedViewMode = allViewMode.find(({ id }: IViewMode) => id === viewModeId)
    const horizon: number = yield select(selectHorizon)
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const step: number = _moduleConfig['horizon-step']
    const simulationData = yield select(selectEvaluationManageSimulationData)
    const allSimulationData = simulationData[selectedViewMode.variable]
    const rpActions = yield select(selectResponsePlanActions)
    const actions =
        rpActions[option].actions.length === 0 ? initialEmptyFeatureCollectionState : rpActions[option].actions

    yield call(
        storeEvaluationMapData,
        mapId,
        viewModeId,
        allSimulationData[option],
        allSimulationData[option][horizon / step],
        actions
    )
}

function* getPastManageEvaluationData(action: any): any {
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const allViewMode = getAllViewMode('manage', _moduleConfig.view_mode, _moduleConfig.modules)

    const viewMode = allViewMode[0]

    yield put(storeFetchingDataByMap(0))
    yield put(storeFetchingDataByMap(1))
    yield put(storeFetchingDataByMap(2))
    yield put(storeFetchingDataByMap(3))

    yield call(getAllRpEvaluationData, action)
    const evaluationData = yield select(selectEvaluationManageSimulationData)
    const rpActions = yield select(selectResponsePlanActions)

    const selectedEvaluation = yield select(selectSelectedEvaluation)

    const rpEvaluationDataKeys = limitResponsePlanIds(getNumberResponsePlanId(selectedEvaluation.responsePlans))

    yield all(
        rpEvaluationDataKeys.map((rp: any, index: number) => {
            const actions =
                rpActions[rp].actions.length === 0 ? initialEmptyFeatureCollectionState : rpActions[rp].actions

            return call(
                storeEvaluationMapData,
                index,
                viewMode.id,
                evaluationData[viewMode.variable][rp],
                evaluationData[viewMode.variable][rp][1],
                actions
            )
        })
    )

    yield put(storeFinishFetchingDataByMap(0))
    yield put(storeFinishFetchingDataByMap(1))
    yield put(storeFinishFetchingDataByMap(2))
    yield put(storeFinishFetchingDataByMap(3))
}

function* getTaskManageEvaluationProgress(action: any): any {
    if (action.payload.evaluation.id !== -2) {
        let finish = false
        let callTimes = 0
        while (!finish) {
            yield delay(1000)
            const selectedEvaluation: IEvaluationStatic = yield select(selectSelectedEvaluation)
            const storeProgress = selectedEvaluation.epoch === action.payload.evaluation.epoch
            const task: any = yield call(getTaskProgressByEpoch, action.payload.evaluation.epoch)
            const tasksStatus: any[] = []
            task.forEach((taskRp: any) => {
                tasksStatus.push({
                    rp: taskRp.rp_id,
                    status:
                        taskRp.status === TASK_STATUS_TYPE.complete ||
                        taskRp.status === TASK_STATUS_TYPE.cancelled ||
                        taskRp.status === TASK_STATUS_TYPE.failed
                            ? 'ready'
                            : 'running',
                })
            })
            finish = task.length > 0 ? tasksStatus.every(task => task.status === 'ready') : false
            callTimes = callTimes + 1

            if (storeProgress) {
                yield put(storeTaskEvaluationManageProgress(task))
            }
            yield delay(4000)
        }
        if (callTimes > 1) {
            yield call(getPastManageEvaluationData, action)

            yield put(loadIncidents({ epoch: action.payload.evaluation.epoch, type: 'all' }))
        }
    } else {
        yield call(getTaskProgressByEpoch, action.payload.evaluation.epoch)
    }
}

function* deleteEvaluationByEpoch(action: any): any {
    const { epoch } = action.payload

    yield call(deleteManageDemoEvaluation, epoch)
    yield put(setStaticEvaluationManageRemoved(true))
}

function* evaluationsManageSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(createNewStaticEvaluationManage, evaluationManageCreation),
        yield takeLatest(createNewLiveEvaluationManage, evaluationManageLiveCreation),
        yield takeEvery(loadPastManageEvaluationData, getPastManageEvaluationData),
        yield takeEvery(loadManageEvaluationDataByViewMode, getManageEvaluationDataByViewMode),
        yield takeEvery(loadManageEvaluationDataByView, getManageEvaluationDataByView),
        yield takeLatest(deleteEvaluation, deleteEvaluationByEpoch),
        yield takeLatest(taskEvaluationManageProgress, getTaskManageEvaluationProgress),
    ])
}

export default evaluationsManageSaga
