import * as React from 'react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Skeleton } from '@mui/material'
import { selectConfig, selectTimeZone } from '../../core/coreSlice'
import QualityManagerDialogTableHead from './qualityManagerDialogTableHead'
import { TABLE_ROW } from '../../../theme'
import { useTranslation } from 'react-i18next'
import { QmDelayTimeBlufaxPrediction, QmDelayTimeDate } from '../qualityManagerDialogStyles'

export const QualityManagerDialogTableDelayTimeQmSubpathData: React.FC<IQualityManagerDialogTableSwitchProps> = ({
    qmData,
    delayTimeSubpaths
}) => {
    const { t } = useTranslation()
    const _moduleConfig: IModuleConfig = useSelector(selectConfig)
    const _timeZone: string = useSelector(selectTimeZone)

    const [order, setOrder] = useState<'desc' | 'asc' | undefined>('desc')
    const [orderBy, setOrderBy] = useState('time')
    const [loader, setLoader] = useState<boolean>(true)
    const [maxViewCount, setMaxViewCount] = useState<number>(50)
    const [qmDataToShow, setQmDataToShow] = useState<any[]>([])
    const [delayTimeSubpathsToShow, setDelayTimeSubpathsToShow] = useState<IQualityManagerHorizon[]>([])
    const { time: timeFormat, date: dateFormat } = _moduleConfig.date_format
    const tableContentHeight = window && window.innerHeight - 225


    useEffect(() => {
        if (qmData) {
            setQmDataToShow(qmData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, qmData])

    useEffect(() => {
        if (delayTimeSubpaths) {
            const subpathsToShow = delayTimeSubpaths.map(((subpath: string, index) => {
                return {
                    id: index,
                    value: subpath
                }
            }))

            setDelayTimeSubpathsToShow(subpathsToShow)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delayTimeSubpaths])


    const handleActionsScroll = ({ currentTarget }: { currentTarget: any }) => {
        if ((currentTarget.offsetHeight * 3) / 2 + currentTarget.scrollTop >= currentTarget.scrollHeight) {
            loadMoreActions()
        }
    }

    const loadMoreActions = () => {
        const nextMax = maxViewCount + 30
        if (nextMax <= qmDataToShow.length) {
            setMaxViewCount(nextMax)
        } else {
            setMaxViewCount(qmDataToShow.length)
            setLoader(false)
        }
    }

    const handleRequestSort = (event: any, property: React.SetStateAction<string>) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <Grid item>
            {delayTimeSubpaths && (
                <TableContainer sx={{ height: `${tableContentHeight}px` }} onScroll={handleActionsScroll}>
                    <Table
                        stickyHeader={true}
                        aria-label='quality manager table view'
                        size='small'
                        sx={{
                            height: 'max-content',
                        }}>
                        <QualityManagerDialogTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            horizons={delayTimeSubpathsToShow}
                            isDelayTimeSubPath={true}
                        />
                        <TableBody>
                            {qmDataToShow && qmDataToShow.length > 0 && qmDataToShow[0] &&
                                qmDataToShow[0][delayTimeSubpaths[0]] &&
                                (qmDataToShow[0][delayTimeSubpaths[0]].blufax || qmDataToShow[0][delayTimeSubpaths[0]].blufax === 0) &&
                                qmDataToShow
                                    .filter((data: any, index: number) => {
                                        return index < maxViewCount
                                    })
                                    .map((data: any, index: number) => {
                                    const dateTime = DateTime.fromMillis(Number(data.from), { zone: _timeZone })
                                    const fromTime = dateTime.toFormat(timeFormat)
                                    const fromDate = dateTime.toFormat(dateFormat)

                                    return (
                                        <TableRow
                                            sx={index % 2 === 0 ? { backgroundColor: TABLE_ROW } : {}}
                                            key={data.from + index}>
                                            <TableCell align='left'>
                                                <QmDelayTimeDate variant='caption'>{`${fromTime} ${fromDate}`}</QmDelayTimeDate>
                                            </TableCell>
                                            {delayTimeSubpaths.map((subpath, index) => {
                                                return (
                                                    <React.Fragment key={`${data[subpath]}_${index}_blufax_prediction`}>
                                                        <TableCell
                                                            component='th'
                                                            scope='row'
                                                            align='center'>
                                                            <QmDelayTimeBlufaxPrediction variant='caption'>
                                                                {data[subpath].blufax === -1 ||
                                                                isNaN(data[subpath].blufax)
                                                                    ? t('common.noData')
                                                                    : Number(data[subpath].blufax)}
                                                            </QmDelayTimeBlufaxPrediction>
                                                        </TableCell>
                                                        <TableCell
                                                            component='th'
                                                            scope='row'
                                                            align='center'>
                                                            <QmDelayTimeBlufaxPrediction variant='caption'>
                                                                {data[subpath].prediction === -1 ||
                                                                isNaN(data[subpath].prediction)
                                                                    ? t('common.noData')
                                                                    : Number(data[subpath].prediction)}
                                                            </QmDelayTimeBlufaxPrediction>
                                                        </TableCell>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })
                            }
                            {loader &&
                                [...Array(20)].map((row, index) => {
                                    return (
                                        <TableRow
                                            sx={index % 2 === 0 ? { backgroundColor: TABLE_ROW } : {}}
                                            key={'loader_row' + index}>
                                            {[...Array((delayTimeSubpaths.length * 2) + 1)].map((row, index) => {
                                                return (
                                                    <TableCell key={'loader_cell' + index} component='th' scope='row'>
                                                        <Skeleton variant='text' />
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Grid>
    )
}

export default QualityManagerDialogTableDelayTimeQmSubpathData
