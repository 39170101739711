import React, { useState, useEffect } from 'react'
import {
    selectConfig,
    selectEpoch,
    selectMapHorizonSelector,
    selectStatus,
    selectTimeZone,
    setStatus,
    storeEpoch,
    storeModuleName,
} from '../../features/core/coreSlice'
import { useDispatch } from 'react-redux'
import { StyledDivider } from '../../components'
import Navigator from '../../features/navigator/Navigator'
import { ToolbarContainer } from '../../components/toolbarComponent/toolbarComponentStyles'
import {
    enableNetworkAssets,
    selectVisibility,
    setSelectedMapbox,
    storeMapboxData,
    toggleCentroid,
    toggleOrthophoto,
} from '../../features/mapbox/mapboxSlice'
import { ContainerWrapper } from '../../components/containerWrapper/containerWrapper'
import { getAllViewMode, getAllViewModeBaseline } from '../../helpers/ContainerHelper'
import { useAppSelector } from '../../app/hooks'
import { changeHorizon } from '../../features/horizonSlider/horizonSilderSlice'
import { DateTime } from 'luxon'
import { addTimeToDate } from '../../helpers/DateTimeHelper'
import ClockDropDown from '../../features/clockDropDown/clockDropDown'
import MapboxComponent from '../../features/mapbox/mapboxComponent'
import { LanUseTypeMap, MapboxLanUseWrapper } from './LandUseContainerStyles'
import EvaluationSelector from '../../features/evaluationsSelector/evaluationsSelector'
import {
    loadLandUseEvaluations,
    selectEvaluations,
    selectFetchingEvaluations,
    selectSelectedEvaluation,
    newEvaluationSelectedState,
    setSelectedEvaluation,
    initialSetSelectedEvaluationState,
} from '../../features/evaluationsSelector/store/evaluationsSlice'
import LandUseViewsSelector from '../../features/landUseViewsSelector/landUseViewsSelector'
import { loadBaseLineLandUse } from '../../features/baselineLandUse/baselineLandUseSlice'
import {
    loadBaseLineData,
    loadEvaluationDataByView,
    loadEvaluationDataByViewMode,
    loadPastEvaluationData,
} from '../../features/evaluationsSelector/store/evaluationsLandUseSlice'
import MapHorizonSelector from '../../features/mapHorizonSelector/mapHorizonSelector'
import {
    selectMapHorizonSelected,
    setMapHorizonSelected,
} from '../../features/mapHorizonSelector/store/mapHorizonSelectedSlice'
import { restoreEvaluationSimulationData } from '../../features/core/simulationEvaluationData/evaluationSimulationDataSlice'
import { restoreInitialFeatureList } from '../../features/search/store/searchFeatureSlice'

const LAND_USE_INITIAL_OPTIONS = [
    { id: 0, name: 'Baseline AM', key: 'am_baseline' },
    { id: 1, name: 'Baseline PM', key: 'pm_baseline' },
]

const emptyLandUseMapboxData = (mapId: number) => {
    return {
        stationData: [],
        sectionData: [],
        mapId: mapId,
        viewModeId: 1,
        stationNowData: [],
        stationFutureData: [],
        sectionNowData: [],
        sectionFutureData: [],
        riskPrediction: [],
        riskPredictionData: [],
        speedRecommendation: [],
        speedRecommendationData: [],
        qm: false,
    }
}

const LandUseContainer: React.FC<IContainerProps> = () => {
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const _epoch: number = useAppSelector(selectEpoch)
    const _timeZone: string = useAppSelector(selectTimeZone)
    const _selectedEvaluation: IEvaluationStatic = useAppSelector(selectSelectedEvaluation)
    const _evaluations: IEvaluationStatic[] = useAppSelector(selectEvaluations)
    const _fetchingEvaluations: boolean = useAppSelector(selectFetchingEvaluations)
    const _appStatus: string = useAppSelector(selectStatus)
    const _mapHorizonSelected: IMapHorizon = useAppSelector(selectMapHorizonSelected)
    const _mapHorizonSelectorOptions = useAppSelector(selectMapHorizonSelector)
    const _layersVisibility = useAppSelector(selectVisibility)
    const [landUseViews, setLandUseViews] = useState<number[]>([])
    const [newEvaluationIsSelected, setNewEvaluationIsSelected] = useState<boolean>(false)
    const [newMapHorizonIsSelected, setNewMapHorizonIsSelected] = useState<boolean>(true)
    const [dimensions, setDimensions] = React.useState<{ height: number; width: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const [landUseViewsOptions, setLandUseViewsOptions] = useState(LAND_USE_INITIAL_OPTIONS)

    const dispatch = useDispatch()
    const containerName = 'Land use'
    const allViewModes = getAllViewMode(containerName, _moduleConfig.view_mode, _moduleConfig.modules)
    const allViewModesBaseline = getAllViewModeBaseline(
        containerName,
        _moduleConfig.baseline_view_mode,
        _moduleConfig.modules
    )

    const baselineVariables = _moduleConfig.baseline_view_mode.map(blViewMode => blViewMode.variable)

    //TODO: Define date to use
    const provisionalDate = addTimeToDate(DateTime.now().startOf('day'), 'hours', 10).toMillis()

    useEffect(() => {
        window.addEventListener('resize', handleResize, false)
        dispatch(storeModuleName(containerName))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (_appStatus === 'ready') {
            dispatch(storeMapboxData(emptyLandUseMapboxData(0)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_appStatus])

    useEffect(() => {
        if (_selectedEvaluation.evaluations) {
            setNewEvaluationIsSelected(false)
            const nextLandUseViewsOptions = _selectedEvaluation.evaluations.map((evaluation, index) => {
                return {
                    id: LAND_USE_INITIAL_OPTIONS.length + index,
                    name: evaluation.toUpperCase(),
                    key: evaluation,
                }
            })
            const newLandUseViewsOptions = LAND_USE_INITIAL_OPTIONS.concat(nextLandUseViewsOptions)
            setLandUseViewsOptions(newLandUseViewsOptions)
            setLandUseViews([0, 2])
        } else {
            if (_selectedEvaluation.id === -1) {
                setNewEvaluationIsSelected(false)
                setLandUseViews([])
                dispatch(storeMapboxData(emptyLandUseMapboxData(0)))
                dispatch(storeMapboxData(emptyLandUseMapboxData(1)))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedEvaluation])

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    useEffect(() => {
        dispatch(setStatus('loading'))
        dispatch(enableNetworkAssets({ networks: 'centroids', mapHorizon: _mapHorizonSelected.id }))
        dispatch(storeEpoch(provisionalDate))
        dispatch(changeHorizon(0))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getBaselineLandUse = () => {
        if (_timeZone !== 'local') {
            baselineVariables.forEach(blViewMode => {
                dispatch(loadBaseLineLandUse(blViewMode))
            })
        }
    }

    useEffect(() => {
        dispatch(setStatus('loading'))
        dispatch(enableNetworkAssets({ networks: 'centroids', mapHorizon: _mapHorizonSelected.id }))
        if (_epoch !== 0) {
            dispatch(setSelectedEvaluation(initialSetSelectedEvaluationState))
            dispatch(loadLandUseEvaluations())
            getBaselineLandUse()
        }
        regenerateMap()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_mapHorizonSelected])

    useEffect(() => {
        getBaselineLandUse()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_timeZone])

    useEffect(() => {
        dispatch(storeEpoch(provisionalDate))
        if (_epoch !== 0) {
            dispatch(setMapHorizonSelected(_mapHorizonSelectorOptions[0]))
            if (!_layersVisibility[0].orthophoto) {
                dispatch(toggleOrthophoto(0))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_epoch])

    const handleMapboxSelected = (id: number) => {
        dispatch(setSelectedMapbox(id))
    }

    const handleEvaluationsChange = (value: number | string) => {
        if (value === -2) {
            if (_selectedEvaluation.id !== -1) regenerateMap()
            setNewEvaluationIsSelected(true)
            setLandUseViews([])
            dispatch(setSelectedEvaluation(newEvaluationSelectedState))
            dispatch(storeMapboxData(emptyLandUseMapboxData(0)))
            dispatch(storeMapboxData(emptyLandUseMapboxData(1)))
            dispatch(restoreEvaluationSimulationData())
            if (!_layersVisibility[0].centroid) {
                dispatch(toggleCentroid(0))
            }
        } else {
            regenerateMap()
            const currentEval = _evaluations.filter(evaluation => evaluation.id === value)[0]
            dispatch(setSelectedEvaluation(currentEval))
            setNewEvaluationIsSelected(false)
            loadLanduseViews(landUseViews)
            dispatch(loadPastEvaluationData({ epoch: currentEval.epoch, variable: allViewModes[0].variable }))
        }
        dispatch(restoreInitialFeatureList({ mapId: 0 }))
        dispatch(restoreInitialFeatureList({ mapId: 1 }))
        if (!_layersVisibility[0].orthophoto) {
            dispatch(toggleOrthophoto(1))
        } else {
            if (!_layersVisibility[1].orthophoto) {
                dispatch(toggleOrthophoto(1))
            }
        }
    }

    const regenerateMap = () => {
        setNewMapHorizonIsSelected(false)
        setTimeout(() => {
            setNewMapHorizonIsSelected(true)
        }, 100)
    }

    const loadLanduseViews = (views: number[]) => {
        views.forEach((optionView: number, index) => {
            if (landUseViewsOptions[optionView].key.includes('baseline')) {
                dispatch(
                    loadBaseLineData({
                        allViewMode: allViewModesBaseline,
                        viewModeId: allViewModesBaseline[0].id,
                        mapId: index,
                        option: landUseViewsOptions[optionView].key,
                    })
                )
            } else {
                dispatch(
                    loadEvaluationDataByView({
                        allViewMode: allViewModes,
                        viewModeId: allViewModes[0].id,
                        mapId: index,
                        option: landUseViewsOptions[optionView].key,
                    })
                )
            }
        })
    }

    const handleLandUseViewsChange = (value: number[]) => {
        let nextValue = value
        if (value.length > 2) {
            nextValue.shift()
        } else if (value.length === 0) {
            nextValue = landUseViews
        }
        nextValue = nextValue.sort((a, b) => a - b)
        setLandUseViews(nextValue)
        loadLanduseViews(nextValue)
    }

    const handleChangeViewMode = (
        epoch: number,
        viewModeId: number,
        mapId: number,
        isBaseline: boolean,
        landUseViewOption: string
    ) => {
        if (!isBaseline) {
            dispatch(
                loadEvaluationDataByViewMode({
                    epoch: _selectedEvaluation.epoch,
                    allViewMode: allViewModes,
                    viewModeId: viewModeId,
                    mapId: mapId,
                    option: landUseViewOption,
                })
            )
        } else {
            dispatch(
                loadBaseLineData({
                    allViewMode: allViewModesBaseline,
                    viewModeId: viewModeId,
                    mapId: mapId,
                    option: landUseViewOption,
                })
            )
        }
    }

    const layers = {
        network: ['motorways', 'suburban', 'urban', 'local', 'centroid', 'orthophoto'],
        incidents: [],
        actions: [],
    }
    const mapHeight: number = dimensions.height - 70
    const windowWidth: number = dimensions.width
    const mapWidth: number = landUseViews.length > 1 ? windowWidth / 2 : windowWidth

    const viewport = { height: mapHeight, width: mapWidth }

    return (
        <ContainerWrapper>
            <ToolbarContainer>
                <Navigator config={_moduleConfig} />
                <StyledDivider orientation='vertical' />
                <MapHorizonSelector epoch={provisionalDate} />
                <StyledDivider orientation='vertical' />
                <ClockDropDown epoch={provisionalDate} />
                <StyledDivider orientation='vertical' />
                <EvaluationSelector
                    key='evaluations-selector-container'
                    setEvaluations={handleEvaluationsChange}
                    evaluations={_evaluations}
                    dateFormat={_moduleConfig.date_format.dateTimeLong}
                    disabled={_fetchingEvaluations}
                    timeZone={_timeZone}
                />
                <StyledDivider orientation='vertical' />
                <LandUseViewsSelector
                    landUseViewsSelected={landUseViews}
                    handleLandUseViewsChange={handleLandUseViewsChange}
                    landUseViews={landUseViewsOptions}
                    disabled={_selectedEvaluation.id === -1 || newEvaluationIsSelected}
                />
            </ToolbarContainer>
            <MapboxLanUseWrapper marginLeft={0}>
                {newMapHorizonIsSelected && (
                    <MapboxComponent
                        networkLayers={layers.network}
                        actionLayers={layers.actions}
                        incidentLayers={layers.incidents}
                        mapId={0}
                        viewport={viewport}
                        setSelected={handleMapboxSelected}
                        containerName={containerName}
                        showLegend={_selectedEvaluation.id >= 0}
                        editCentroid={newEvaluationIsSelected}
                        changeViewMode={handleChangeViewMode}
                        isBaseline={
                            landUseViews && landUseViews.length > 0
                                ? landUseViewsOptions[landUseViews[0]].key.includes('baseline')
                                : false
                        }
                        optionLandUseView={
                            landUseViews && landUseViews.length > 0 ? landUseViewsOptions[landUseViews[0]].key : ''
                        }>
                        {landUseViews && landUseViews.length > 0 && (
                            <LanUseTypeMap>{landUseViewsOptions[landUseViews[0]].name}</LanUseTypeMap>
                        )}
                    </MapboxComponent>
                )}
                {newMapHorizonIsSelected && landUseViews.length > 1 && !newEvaluationIsSelected && (
                    <MapboxComponent
                        networkLayers={layers.network}
                        actionLayers={layers.actions}
                        incidentLayers={layers.incidents}
                        mapId={1}
                        viewport={viewport}
                        setSelected={handleMapboxSelected}
                        containerName={containerName}
                        showLegend={_selectedEvaluation.id >= 0}
                        changeViewMode={handleChangeViewMode}
                        isBaseline={landUseViewsOptions[landUseViews[1]].key.includes('baseline')}
                        optionLandUseView={landUseViewsOptions[landUseViews[1]].key}>
                        <LanUseTypeMap>{landUseViewsOptions[landUseViews[1]].name}</LanUseTypeMap>
                    </MapboxComponent>
                )}
            </MapboxLanUseWrapper>
        </ContainerWrapper>
    )
}

export default LandUseContainer
