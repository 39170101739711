import { getRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'

export function getPattern(time: number): Promise<AxiosResponse<number>> {
    return getRequestAPI(`/api_pm/pattern?time=${time}`)
}

export function getPatternQuality(): Promise<AxiosResponse<number>> {
    return getRequestAPI('/reporter/pattern_match_geh')
}

export function getAvailablePatterns(): Promise<AxiosResponse<IPatternJson[]>> {
    return getRequestAPI('/api_pm/all_patterns')
}
