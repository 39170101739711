export const initialViewMode: IViewMode = {
    id: 1,
    name: 'Flow',
    variable: 'flow',
    label: 'Absolute Flow',
    webSocketMessage: true,
    sources: {
        stations: {
            now: 'na',
            future: 'na',
        },
        sections: {
            now: 'simulated',
            future: 'simulated',
        },
    },
    units: {
        main: 'veh/h',
    },
    styles: {
        ranges: {
            main: [
                {
                    min: 1,
                    max: 100,
                    color: '#6ce172',
                },
                {
                    min: 100,
                    max: 200,
                    color: '#b6f039',
                },
                {
                    min: 200,
                    max: 400,
                    color: '#DBF81D',
                },
                {
                    min: 400,
                    max: 600,
                    color: '#ffff00',
                },
                {
                    min: 600,
                    max: 800,
                    color: '#ffd500',
                },
                {
                    min: 800,
                    max: 1000,
                    color: '#ffab00',
                },
                {
                    min: 1000,
                    max: 1250,
                    color: '#f78402',
                },
                {
                    min: 1250,
                    max: 1500,
                    color: '#ee5d04',
                },
                {
                    min: 1500,
                    color: '#dd2e04',
                },
            ],
            reliability: [
                {
                    min: 0,
                    max: 0.7,
                    color: '#44628C',
                },
                {
                    min: 0.71,
                    max: 0.95,
                    color: '#172D4A',
                },
                {
                    min: 0.95,
                    color: '#09254C',
                },
            ],
        },
    },
}

export const getAllViewModeFromNames = (viewModeNames: string[], viewModes: IViewMode[]) => {
    const moduleViewMode: IViewMode[] = []
    viewModeNames?.forEach((name: string) => {
        const foundVieMode = viewModes.find(vMode => vMode.name === name)
        if (foundVieMode) moduleViewMode.push(foundVieMode)
    })
    return moduleViewMode
}

export const getAllViewMode = (moduleName: string, viewMode: IViewMode[], modules: IModule[]) => {
    if (moduleName === 'QM') {
        const module: IModule | undefined = modules.find(module => module.name === 'monitor')
        return getAllViewModeFromNames(module?.options?.quality_manager?.visualisation.viewModes, viewMode)
    }
    const module: IModule | undefined = modules.find(module => module.name === moduleName)
    const moduleViewMode: IViewMode[] = []
    module?.viewModes?.forEach((name: string) => {
        const foundVieMode = viewMode.find(vMode => vMode.name === name)
        if (foundVieMode) moduleViewMode.push(foundVieMode)
    })
    return moduleViewMode
}

export const getAllViewModeBaseline = (moduleName: string, viewMode: IViewMode[], modules: IModule[]) => {
    const module: IModule | undefined = modules.find(module => module.name === moduleName)
    const moduleViewMode: IViewMode[] = []
    module &&
        module.viewModes_baseline?.forEach((name: string) => {
            const foundVieMode = viewMode.find(vMode => vMode.name === name)
            if (foundVieMode) moduleViewMode.push(foundVieMode)
        })
    return moduleViewMode
}

export const getViewMode = ($id: any, viewModes: IViewMode[]): IViewMode => {
    const foundViewMode: IViewMode | undefined = viewModes.find(({ id }: IViewMode) => id === $id)
    return foundViewMode ? foundViewMode : initialViewMode
}
