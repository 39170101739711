export const statusTypes = new Map<number, string>([
    [0, 'Pending'],
    [1, 'Running'],
    [2, 'Complete'],
    [3, 'Cancelled'],
    [4, 'Failed'],
])

export const getDateFrom = (name: string) => {
    const dateFrom = name.length > 0 ? name.split(' ').pop() : name
    return dateFrom ? dateFrom : name
}

export const getResponsePlanInfo = (task: ITask) => {
    if (task.route_id !== '') {
        return `${task.rp_id}_${task.pack_id} ${task.route_id}`
    } else {
        return `${task.rp_id}_${task.pack_id}`
    }
}
