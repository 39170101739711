import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../app/store'

export interface IKpisState {
    kpis: IRawKpi[]
    fetching: boolean
}

export interface IRawKpi {
    horizon: number
    kpi: string
    name: string
    time: number
    units: string
    value: number
}

const initialKpisState: IKpisState = {
    kpis: [],
    fetching: false,
}

const kpiSlice = createSlice({
    name: 'kpis',
    initialState: initialKpisState,
    reducers: {
        storeKpis: (state, action: PayloadAction<any>): void => {
            state.kpis = action.payload
        },
        setFetchingKpis: (state, action: PayloadAction<boolean>): void => {
            state.fetching = action.payload
        },
        loadKpi: (state, action: PayloadAction<number>): void => {
            state.fetching = true
        },
    },
})

export const { storeKpis, setFetchingKpis, loadKpi } = kpiSlice.actions

export const selectKpis = (state: RootState) => state.analyzeContainer.kpis.kpis
export const selectFetchingKpis = (state: RootState) => state.analyzeContainer.kpis.fetching

export default kpiSlice.reducer
