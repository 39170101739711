import { AxiosResponse } from 'axios'
import { client } from '../apiClient/apiClient'


export async function createStaticSimulation(simulationData: any): Promise<AxiosResponse<any>> {
    return await client.post(`/api_controller/simulation`, simulationData).then(res => res)
}

export async function createLiveSimulation(simulationData: any): Promise<AxiosResponse<any>> {
    return await client.post(`/api_controller/simulation`, simulationData).then(res => res)
}
