import * as React from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useState } from 'react'
import EvaluationSelectorDialog from './evaluationSelectorDialog/evaluationSelctorDialog'
import { EvaluationForm, EvaluationInputLabel, EvaluationSelectorWrapper } from './evaluationsSelectorStyles'
import { useAppSelector } from '../../app/hooks'
import { selectSelectedEvaluation } from './store/evaluationsSlice'

const EvaluationSelector: React.FC<EvaluationSelectorProps> = ({
    setEvaluations,
    evaluations,
    disabled,
    dateFormat,
    timeZone,
}: EvaluationSelectorProps) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const _selectedEvaluation: IEvaluationStatic = useAppSelector(selectSelectedEvaluation)

    const handleEvaluationsChange = (event: SelectChangeEvent<number | string>) => {
        switch (event.target.value) {
            case 'all':
                return setOpenDialog(true)
            default:
                return setEvaluations(Number(event.target.value))
        }
    }

    const handleEvaluationsDialogClose = () => {
        setOpenDialog(false)
    }

    return (
        <React.Fragment>
            <EvaluationSelectorWrapper>
                <EvaluationForm key='evaluation-form' variant='standard'>
                    <EvaluationInputLabel>Evaluation</EvaluationInputLabel>
                    <Select
                        id='evaluation-selector'
                        value={_selectedEvaluation.id === -1 ? '' : _selectedEvaluation.id}
                        className='evaluation-selector'
                        disabled={disabled}
                        onChange={(event: SelectChangeEvent<number>) => {
                            handleEvaluationsChange(event)
                        }}
                    >
                        <MenuItem key='new-evaluation' value={-2}>
                            New evaluation
                        </MenuItem>
                        {evaluations
                            .filter(evaluation => evaluation.epoch !== null)
                            .slice()
                            .sort((curr, next) => next.epoch - curr.epoch)
                            .map(evaluation => (
                                <MenuItem key={evaluation.id} value={evaluation.id}>
                                    {`${evaluation.id} - ${evaluation.name}`}
                                </MenuItem>
                            ))}
                        <MenuItem key='response-plan-not-set' value={'all'}>
                            View all...
                        </MenuItem>
                    </Select>
                </EvaluationForm>
            </EvaluationSelectorWrapper>
            <EvaluationSelectorDialog
                open={openDialog}
                onClose={handleEvaluationsDialogClose}
                dateFormat={dateFormat}
                evaluations={evaluations}
                setSelectedEvaluation={setEvaluations}
                selectedEvaluation={_selectedEvaluation.id}
                timeZone={timeZone}
            />
        </React.Fragment>
    )
}

export default EvaluationSelector
