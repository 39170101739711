import React, { useEffect, useState } from 'react'
import LayersIcon from '@mui/icons-material/Layers'
import LayersIconOutlined from '@mui/icons-material/LayersOutlined'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import CrisisAlertIconOutLined from '@mui/icons-material/CrisisAlertOutlined'
import ShapeLineIcon from '@mui/icons-material/ShapeLine'
import ShapeLineIconOutLined from '@mui/icons-material/ShapeLineOutlined'
import { SpeedDialIcon, SpeedDialAction } from '@mui/material'
import { CustomSpeedDialStyled, sxActionNoSelected, sxActionSelected } from './customSpeedDialStyles'

const CustomSpeedDial: React.FC<ICustomSpeedDialProps> = ({
    actions,
    direction = 'up',
    type,
}: ICustomSpeedDialProps) => {
    const [open, setOpen] = useState<boolean>(false)
    const [icon, setIcon] = useState<any>(<LayersIcon />)
    const [openIcon, setOpenIcon] = useState<any>(<LayersIconOutlined />)

    useEffect(() => {
        if (type === 'incidents') {
            setIcon(<CrisisAlertIcon />)
            setOpenIcon(<CrisisAlertIconOutLined />)
        }

        if (type === 'actions') {
            setIcon(<ShapeLineIcon />)
            setOpenIcon(<ShapeLineIconOutLined />)
        }
    }, [type])

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    return (
        <CustomSpeedDialStyled
            ariaLabel={type}
            open={open}
            icon={<SpeedDialIcon icon={icon} openIcon={openIcon} />}
            onClose={handleClose}
            onOpen={handleOpen}
            direction={direction}>
            {actions.map(action => (
                <SpeedDialAction sx={action.selected ? sxActionSelected : sxActionNoSelected} {...action} />
            ))}
        </CustomSpeedDialStyled>
    )
}

export default CustomSpeedDial
