import {
    all,
    AllEffect,
    call,
    CallEffect,
    ForkEffect,
    put,
    PutEffect,
    select,
    SelectEffect,
    takeEvery,
} from 'redux-saga/effects'
import {
    loadStationSelected,
    setFetchingStationData,
    storePatternDataStation,
    storePredictionDataStation,
    storeRealDataStation,
    storeReliabilityDataStation,
} from './stationSelectedSlice'
import {
    getStationPatternData,
    getStationPredictionData,
    getStationRealData,
    getStationSensorReliabilityData,
} from '../../../services/station/stationService'
import { AxiosResponse } from 'axios'
import { selectPattern } from '../../pattern/store/patternSlice'
import {
    selectHideFutureCleanData,
    selectConfig,
    selectModuleName,
    selectNowTime,
} from '../../core/coreSlice'
import { getMarks } from '../stationDialogHelper'
import { addTimeToDate } from '../../../helpers/DateTimeHelper'
import { DateTime } from 'luxon'

function* fetchStationRealData(params: {
    mapId: number
    feature_id: number
    t: number
    viewMode: IViewMode
}): Generator<
    | SelectEffect
    | CallEffect<AxiosResponse<any, any>>
    | PutEffect<{ payload: any; type: 'stationsSelected/storeRealDataStation' }>,
    void,
    boolean & []
> {
    const hideFutureCleanData: boolean = yield select(selectHideFutureCleanData)
    const moduleName: any = yield select(selectModuleName)
    const nowTime: any = yield select(selectNowTime)
    const time: number = hideFutureCleanData && moduleName === 'monitor'
        ? params.t
        : addTimeToDate(DateTime.fromMillis(params.t), 'hour', 1).toMillis()

    const hours: number = hideFutureCleanData && moduleName === 'monitor' ? 6 : 7

    let data: any[] = yield call(getStationRealData, {
        ...params,
        t: time,
        n_hours: hours,
    })

    let realData: any[] = data
    if (hideFutureCleanData && moduleName === 'analyze') {
        realData = data.reduce((acc: any , curr: any) => {
            let data = [...acc]
            if (curr.time <= nowTime) {
                data.push(curr)
            }
            return data
        }, [])
    }

    yield put(storeRealDataStation({ realData, mapId: params.mapId }))
}

function* fetchStationPredictionData(params: { mapId: number; feature_id: number; t: number; viewMode: IViewMode }) {
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const step: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const marks = getMarks(step, numberOfHorizon)

    const predictionDataFetch: IPredictionByHorizonData[] = yield all(
        marks.map(horizon =>
            call(getStationPredictionData, {
                ...params,
                horizon,
            })
        )
    )
    let predictionData: { horizon: number; value: any }[] = []

    predictionDataFetch.forEach((prediction, index) => {
        return predictionData.push({
            horizon: marks[index],
            value: prediction[params.feature_id]?.value || null,
        })
    })

    yield put(storePredictionDataStation({ predictionData, mapId: params.mapId }))
}

function* fetchStationPatternData(params: {
    mapId: number
    feature_id: string
    t: number
    viewMode: IViewMode
    pattern_id: number
}): Generator<
    | CallEffect<AxiosResponse<any, any>>
    | SelectEffect
    | PutEffect<{ payload: any; type: 'stationsSelected/storePatternDataStation' }>,
    void,
    IPattern & []
> {
    let patternData: any[] = yield call(getStationPatternData, { ...params, n_hours: 6 })
    yield put(
        storePatternDataStation({
            patternData: patternData.filter(pattern => pattern.min_value != null && pattern.max_value != null),
            mapId: params.mapId,
        })
    )
}

function* fetchStationSensorReliabilityData(params: {
    mapId: number
    feature_id: number
    t: number
    viewMode: IViewMode
}): Generator<
    | CallEffect<AxiosResponse<any, any>>
    | PutEffect<{ payload: any; type: 'stationsSelected/storeReliabilityDataStation' }>,
    void,
    []
> {
    const reliabilityData: [] = yield call(getStationSensorReliabilityData, { ...params, n_hours: 6 })
    yield put(storeReliabilityDataStation({ reliabilityData, mapId: params.mapId }))
}

function* initStation(action: any) {
    const pattern: IPattern = yield select(selectPattern)

    yield call(fetchStationRealData, action.payload)
    yield call(fetchStationPredictionData, action.payload)
    yield call(fetchStationPatternData, { ...action.payload, pattern_id: pattern.id })
    yield call(fetchStationSensorReliabilityData, action.payload)
    yield put(setFetchingStationData({ mapId: action.payload.mapId, fetching: false }))
}

function* stationSelectedSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeEvery(loadStationSelected, initStation)])
}

export default stationSelectedSaga
