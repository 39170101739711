import { Typography, Box, Select, MenuItem, Grid, Card } from '@mui/material'
import { Cancel as CancelIcon } from '@mui/icons-material'
import styled from '@emotion/styled'
import { NAVY_BLUE, WHITE } from '../../theme'

export const LegendCardStyled = styled(Card)`
    position: absolute;
    left: 20px;
    bottom: 20px;
`

export const LegendMenuItemHeader = styled(MenuItem)`
    pointer-events: none;
    cursor: default;
    border-bottom: solid 1px;
    margin-bottom: 20px;
    font-size: 20px;
`

export const LegendHeaderText = styled(Typography)`
    font-family: 'AimsunBold, Roboto, Sans-serif';
`

export const LegendCloseIcon = styled(CancelIcon)`
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: inherit;
    cursor: pointer;
    color: ${WHITE};

    &.legend__close--collapsed {
        transform: rotate(45deg);
    }
`

export const LegendUnits = styled(Typography)`
    transform: translate(20px, 5px);
    font-size: 14px;
    margin-bottom: 4px;
    color: ${WHITE};
    opacity: 70%;
`

export const LegendRangeWrapper = styled(Grid)`
    &.MuiGrid-item {
        padding-top: 4px;
        &:first-of-type {
            padding-top: 16px;
        }
    }
`

export const LegendHeader = styled(Box)`
    background-color: ${NAVY_BLUE};
    position: relative;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;

    .MuiCardHeader-content {
        font-family: 'AimsunMedium', serif, sans-serif;
    }

    .MuiCardHeader-title {
        font-size: 16px;
        color: ${WHITE};
        font-family: 'AimsunLight', sans-serif;
    }

    .MuiCardHeader-subheader {
        color: ${WHITE};
        opacity: 0.7;
        font-size: 14px;
    }
`

export const LegendViewModeSelect = styled(Select)`
    color: ${WHITE};
    -webkit-text-fill-color: inherit;
    transform: translate(20px, 5px);
    border: none;
    #variableSelector {
        color: ${WHITE};
        -webkit-text-fill-color: inherit;
    }
    &.MuiInput-underline:before,
    &.MuiInput-underline:hover,
    &:after,
    &.MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
    }

    .MuiSelect-icon {
        display: none;
    }
`
