import { all, AllEffect, call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'
import { storeKpis, loadKpi, setFetchingKpis } from './kpisSlice'
import { getKpis } from '../../../../../services/kpis/kpisService'
import { AxiosResponse } from 'axios'

function* fetchKpis(
    action: any
): Generator<
    | CallEffect<AxiosResponse<number, any>>
    | PutEffect<{ payload: any; type: 'kpis/storeKpis' }>
    | PutEffect<{ payload: boolean; type: 'kpis/setFetchingKpis' }>,
    void
> {
    let kpis = yield call(getKpis, action.payload)
    yield put(storeKpis(kpis))
    yield put(setFetchingKpis(false))
}

function* kpisSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(loadKpi, fetchKpis)])
}

export default kpisSaga
