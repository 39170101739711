import { DateTime } from 'luxon'
import { AnyLayout } from 'mapbox-gl'
import {
    colorScaleR2,
    colorScaleRealData,
    colorScaleSlope,
    colorScaleStatisticData,
    addTranslatableNoData,
    HEATMAP_DAYS,
    MAX_DETECTORS_TO_SHOW,
    PATTERNS_PALETTE,
    QmOutputTypes,
} from './qualityManagerDialogOptions'
import i18n, { t } from 'i18next'
import { SMOKE } from '../../theme'

export const generateColorScaleForPatterns = (availablePatterns: IPatternJson[]) => {
    let patScale: IQualityManagerPatternScale[] = []

    availablePatterns?.forEach((pattern: IPatternJson, index: number) => {
        const nextColor: string = PATTERNS_PALETTE[index]
        patScale.push({
            from: pattern.pattern_id,
            to: pattern.pattern_id,
            name: `${t('qualityManager.pattern')} ${pattern.pattern_id}`,
            color: nextColor,
        })
    })
    patScale.push({
        from: -1,
        to: 3,
        name: t('common.noData'),
        color: '#dbdbdb',
    })
    return patScale
}

export const deployHorizons = (visualisation: string, count: number = 4, step: number = 15) => {
    const globalString: 'Average' | 'Global' = visualisation === 'patternsVsCleanData' ? t('qualityManager.average') : t('qualityManager.global')
    let nextHorizons: IQualityManagerHorizon[] = [{ id: 0, value: globalString }]
    const horizonCount: number = (step * count) / step
    for (let i: number = 1; i <= horizonCount; i++) {
        const value: string = (i * step).toString()
        nextHorizons.push({ id: i, value })
    }

    return nextHorizons
}

export const isDateWithinRange = (date: DateTime, lastAvailableDay: DateTime): boolean => {
    return date.startOf('day') <= lastAvailableDay.startOf('day')
}

export const generateHeatmapSeries = async (
    qm: any,
    horizon: number,
    dataSize: number,
    actualPage: number,
    timeZone: string,
    dateFormat: string,
    timeFormat: string,
    outputSelected: string,
    intervalSelected: string,
    visualisation: string,
    clockInterval: number,
    setPage: (value: React.SetStateAction<number>) => void,
    setNumberPages: (value: React.SetStateAction<number>) => void,
    setHeatmapSeries: (value: React.SetStateAction<any[]>) => void
) => {
    let series = await qm.reduce((acc: any, qmData: IQualityManager) => {
        // Apply timezone adjustment on timestamps
        const dateTime = DateTime.fromMillis(qmData.from, { zone: timeZone })

        const seriesData = { ...acc }
        if (intervalSelected === 'date') {
            const day = dateTime.minus({ seconds: clockInterval }).toFormat(dateFormat)
            let nextSeries: IQMSeries

            if (visualisation === 'patterns') {
                nextSeries = getNextPatternHeatmapSeries(qmData, 'pattern', timeZone, timeFormat, outputSelected)
            } else if (visualisation === 'patternsSelectedVsRealData') {
                nextSeries = getNextPatternHeatmapSeries(qmData, 'global', timeZone, timeFormat, outputSelected)
            } else {
                nextSeries = getNextHeatmapSeries(qmData, horizon, timeZone, timeFormat, outputSelected)
            }
            if (nextSeries) {
                if (!seriesData[day]) {
                    seriesData[day] = {
                        name: day,
                        data: [nextSeries],
                    }
                } else if (seriesData[day].data.length < dataSize && seriesData[day].name === day) {
                    seriesData[day].data.push(nextSeries)
                }
            }
        } else {
            //Station
            const day = dateTime.toFormat(dateFormat)
            setPage(actualPage)
            setNumberPages(qmData.stations && Math.ceil(qmData.stations.length / MAX_DETECTORS_TO_SHOW))
            const nextSeries = getStationHeatmapSeries(qmData, actualPage)
            seriesData[day] = {
                name: day,
                data: nextSeries,
            }
        }
        return seriesData
    }, [])

    if (series) {
        const _heatmapSeries = Object.keys(series).map(day => {
            if (series[day].data && series[day].data.length < dataSize && intervalSelected !== 'station') {
                series[day].data.shift()
            }
            return series[day]
        })
        const visibleHeatmapDays: any[] =
            _heatmapSeries.length - HEATMAP_DAYS > 0
                ? _heatmapSeries.slice(_heatmapSeries.length - HEATMAP_DAYS)
                : _heatmapSeries
        setHeatmapSeries(visibleHeatmapDays)
    }
}

const getNextHeatmapSeries = (
    qmData: IQualityManager,
    horizon: number,
    timeZone: string,
    timeFormat: string,
    outputSelected: string
) => {
    const dateTime = DateTime.fromMillis(qmData.from, { zone: timeZone })
    const from = dateTime.toFormat(timeFormat)
    const value = horizon === 0 ? qmData.global : qmData.partial[horizon - 1] ? qmData.partial[horizon - 1] : -1
    return sanitizeVisibleValues(value, from, outputSelected)
}

export const getNextPatternHeatmapSeries = (
    qmData: IQualityManager,
    valueToShow: string,
    timeZone: string,
    timeFormat: string,
    outputSelected: string
) => {
    if (qmData.feature_id === '0') {
        const dateTime = DateTime.fromMillis(qmData.from, { zone: timeZone })
        const from = dateTime.toFormat(timeFormat)
        const value = valueToShow === 'global' ? qmData.global : qmData.pattern
        return sanitizeVisibleValues(value, from, outputSelected)
    }
    return undefined
}

const getStationHeatmapSeries = (qmData: IQualityManager, pageToLoadData: number) => {
    let dateSeries = []
    const initial = pageToLoadData * MAX_DETECTORS_TO_SHOW - MAX_DETECTORS_TO_SHOW
    const final =
        qmData.stations && pageToLoadData * MAX_DETECTORS_TO_SHOW > qmData.stations.length
            ? qmData.stations.length
            : pageToLoadData * MAX_DETECTORS_TO_SHOW

    if (qmData.stations) {
        for (let i = initial; i < final; i++) {
            dateSeries.push({ x: qmData.stations[i].station, y: qmData.stations[i].global })
        }
    }

    return dateSeries
}

const sanitizeVisibleValues = (value: number, from: string, outputSelected: string) => {
    if (value === -1) return { x: from, y: value }
    if (outputSelected.toLowerCase().includes('r2') || outputSelected.toLowerCase().includes('slope')) {
        const roundupValue: number = value && value * 100
        const output: string = roundupValue ? roundupValue.toFixed(0) : '0'
        return { x: from, y: output }
    }
    const output: string = value ? value.toFixed(0) : '0'
    return { x: from, y: output }
}

const nextLineSeriesFormat = (series: any) => {
    let nextLineChartSeries: any[] = []
    Object.keys(series)
        .sort((curr: any, next: any) => {
            if (next === 'Clean') {
                return 1
            }
            if (curr === 'Clean') {
                return -1
            }
            if (curr === 'PM pred') {
                return -1
            }
            const numberCurr: number = parseInt(curr.match(/\d+/g))
            const numberNext: number = parseInt(next.match(/\d+/g))
            if (numberCurr < numberNext) {
                return -1
            }
            if (numberCurr > numberNext) {
                return 1
            }
            return 0
        })
        .forEach(item => {
            const output = {
                name: i18n.exists(`qualityManager.${series[item].name}`) ? t(`qualityManager.${series[item].name}`) : series[item].name,
                data: series[item].data,
            }
            nextLineChartSeries.push(output)
        })
    return nextLineChartSeries
}

export const generateLineChartSeries = async (
    qm: any,
    epoch: number,
    horizon: number,
    horizonStep: number,
    outputSelected: string,
    visualisation: string,
    setLineChartSeries: (value: React.SetStateAction<any[]>) => void
) => {
    let qmSeries = await qm.reduce((acc: any, curr: IQualityManager) => {
        const nextSeries: any = getNextLineChartSeries(curr, horizon, outputSelected)
        let seriesData = { ...acc }
        if (curr.from <= epoch) {
            const nextSeries = getNextLineChartSeries(curr, horizon, outputSelected)
            const seriesName = getSeriesName(horizon, horizonStep, visualisation)
            if (!seriesData['series']) {
                seriesData['series'] = {
                    name: seriesName,
                    data: [],
                }
            }
            if (nextSeries) seriesData['series'].data.push(nextSeries)
        }
        if (nextSeries) seriesData['series'].data.push(nextSeries)

        seriesData['series']?.data.sort((value: any, nextValue: any) => {
            const hour: number = Number.parseInt(value.x.split(':')[0])
            const nextHour: number = Number.parseInt(nextValue.x.split(':')[0])
            return hour - nextHour
        })

        return seriesData
    }, [])

    const nextLineChartSeries = nextLineSeriesFormat(qmSeries)
    setLineChartSeries(nextLineChartSeries)
}

const sanitizeDMDataPatternsChartSeries = (qmSeries: any): IQMCleanRealSeries => {
    const keys = Object.keys(qmSeries)
    const values = keys.filter(str => str.toLowerCase().includes('pattern'))

    let output: IQMCleanRealSeries = qmSeries
    if (!output['Clean']) {
        output['Clean'] = {
            name: 'Clean',
            data: [],
        }
    }
    if (!output['PM pred']) {
        output['PM pred'] = {
            name: 'PM pred',
            data: [],
        }
    }

    const lastindex = values.pop()
    if (lastindex) {
        const qmData = output[lastindex]

        qmData.data.forEach(data => {
            if (!qmSeries['Clean'].data.some((d: IQMxy) => d.x === data.x)) {
                qmSeries['Clean'].data.push({
                    x: data.x,
                    y: null,
                })
            }
            if (!qmSeries['PM pred'].data.some((d: IQMxy) => d.x === data.x)) {
                qmSeries['PM pred'].data.push({
                    x: data.x,
                    y: null,
                })
            }
        })
    }
    return output
}

export const generateDMDataPatternsChartSeries = async (
    qm: any,
    epoch: number,
    setLineChartSeries: (value: React.SetStateAction<any[]>) => void
) => {
    let qmSeries: any = {}
    Object.keys(qm).forEach(key => {
        const from: string = key
        if (Number(from) <= epoch) {
            const qmData = qm[from]

            qmData.forEach((data: { key: string; avg: any; reliability: any }) => {
                const currName: string = data.key
                const currValue: string = data.avg || data.reliability
                const output = currValue && Number(currValue).toFixed(0)
                if (!qmSeries[currName]) {
                    qmSeries[currName] = {
                        name: currName,
                        data: [],
                    }
                }

                qmSeries[currName].data.push({
                    x: from,
                    y: output,
                })
            })
        }
    })

    const output: IQMCleanRealSeries = sanitizeDMDataPatternsChartSeries(qmSeries)
    const nextLineChartSeries = nextLineSeriesFormat(output)

    setLineChartSeries(nextLineChartSeries)
}

export const generatePatternsAvailableLineChartSeries = async (
    qm: any,
    epoch: number,
    availablePatterns: IPatternJson[],
    setLineChartSeries: (value: React.SetStateAction<any[]>) => void
) => {
    let qmSeries = await qm.reduce((acc: AnyLayout, curr: IQualityManager) => {
        let seriesData: any = { ...acc }
        if (curr.from <= epoch) {
            if (curr.feature_id !== '0') {
                const currValue = curr.global
                const output = currValue?.toFixed(2)
                if (!seriesData[curr.pattern]) {
                    seriesData[curr.pattern] = {
                        name: `${t('qualityManager.pattern')} ${curr.pattern}`,
                        data: [],
                    }
                }

                seriesData[curr.pattern].data.push({
                    x: curr.from,
                    y: output,
                })
            } else if (curr.global === -1) {
                availablePatterns.forEach((pattern: IPatternJson) => {
                    if (!seriesData[pattern.pattern_id]) {
                        seriesData[pattern.pattern_id] = {
                            name: 'Pattern ' + pattern.pattern_id,
                            data: [],
                        }
                    }

                    seriesData[pattern.pattern_id].data.push({
                        x: curr.from,
                        y: -1,
                    })
                })
            }
        }
        return seriesData
    }, [])
    const nextLineChartSeries = nextLineSeriesFormat(qmSeries)

    setLineChartSeries(nextLineChartSeries)
}

export const generatePatternsSelectedLineChartSeries = async (
    qm: any,
    epoch: number,
    setLineChartSeries: (value: React.SetStateAction<any[]>) => void
) => {
    let qmSeries = await qm.reduce((acc: any, curr: IQualityManager) => {
        let seriesData = { ...acc }
        if (curr.from <= epoch) {
            if (curr.feature_id === '0') {
                const currValue = curr.global
                const output = currValue && currValue.toFixed(0)
                if (!seriesData['series']) {
                    seriesData['series'] = {
                        name: t('qualityManager.patternSelectedQm'),
                        data: [],
                    }
                }

                seriesData['series'].data.push({
                    x: curr.from,
                    y: output,
                })
            }
        }
        return seriesData
    }, [])

    const nextLineChartSeries = nextLineSeriesFormat(qmSeries)

    setLineChartSeries(nextLineChartSeries)
}

const getNextLineChartSeries = (qmData: IQualityManager, horizon: number, outputSelected: string) => {
    const from = qmData.from.toString()
    let currValue = horizon ? qmData.partial[horizon - 1] : qmData.global
    return sanitizeVisibleValues(currValue, from, outputSelected)
}

export const getSeriesName = (horizon: number, horizonStep: number, visualisation: string): string => {
    const step: number = horizon * horizonStep
    const globalString = visualisation === 'patternsVsCleanData' ? 'Average' : 'Global'
    return !horizon ? globalString : `+${step}`
}

function capitalizeFirstLetter(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const refreshHeatmap = (
    visualisation: string,
    outputSelected: string,
    intervalSelected: string,
    patternScale: IQualityManagerPatternScale[],
    heatmapOptions: ApexCharts.ApexOptions,
    setHeatmapOptions: (value: React.SetStateAction<ApexCharts.ApexOptions>) => void
) => {
    const outputOptions: IQMOutputType | undefined = QmOutputTypes.get(outputSelected.toLowerCase())
    if (visualisation === 'patterns') {
        setHeatmapOptions({
            ...heatmapOptions,
            xaxis: {
                ...heatmapOptions.xaxis,
                title: {
                    ...heatmapOptions.xaxis?.title,
                    text: t(`qualityManager.${heatmapOptions.xaxis?.title?.text}`)
                },
            },
            yaxis: {
                ...heatmapOptions.yaxis,
                title: {
                    // @ts-ignore
                    text: t(`qualityManager.${heatmapOptions.yaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            noData: {
                text: t('common.noData'),
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: SMOKE,
                    fontSize: '2.5rem',
                },
            },
            tooltip: {
                followCursor: true,
                custom: options => {
                    let _tooltipValue = t('common.noData')

                    if (options) {
                        const dataPointIndex = options.dataPointIndex
                        const cellTime = options.w.globals.labels[dataPointIndex]
                        const dataRow = options.series[options.seriesIndex]
                        const day = options.w.globals.seriesNames[options.seriesIndex]
                        if (dataRow[options.dataPointIndex] !== -1) _tooltipValue = dataRow[options.dataPointIndex]

                        return `
                        <div class="qm__tooltip">
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${day}
                            </p>
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.time')}: </span> ${cellTime}
                            </p>
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.pattern')}: </span> ${_tooltipValue}
                            </p>
                        </div>
                        `
                    }
                },
            },
            plotOptions: {
                heatmap: {
                    enableShades: false,
                    colorScale: {
                        ranges: patternScale,
                    },
                    radius: 0,
                },
            },
        })
    } else if (visualisation === 'patternsSelectedVsRealData') {
        setHeatmapOptions({
            ...heatmapOptions,
            xaxis: {
                ...heatmapOptions.xaxis,
                title: {
                    ...heatmapOptions.xaxis?.title,
                    text: t(`qualityManager.${heatmapOptions.xaxis?.title?.text}`)
                },
            },
            yaxis: {
                ...heatmapOptions.yaxis,
                title: {
                    // @ts-ignore
                    text: t(`qualityManager.${heatmapOptions.yaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            tooltip: {
                followCursor: true,
                custom: options => {
                    let _tooltipValue = t('common.noData')
                    if (options) {
                        const dataPointIndex = options.dataPointIndex
                        const cellTime = options.w.globals.labels[dataPointIndex]
                        const dataRow = options.series[options.seriesIndex]
                        const day = options.w.globals.seriesNames[options.seriesIndex]
                        if (dataRow[options.dataPointIndex] !== -1) _tooltipValue = dataRow[options.dataPointIndex]

                        return `
                        <div class="qm__tooltip">
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${day}
                            </p>
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.time')}: </span> ${cellTime}
                            </p>
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.tooltipValuePercentage')}: </span> ${_tooltipValue}
                            </p>
                        </div>
                        `
                    }
                },
            },
            plotOptions: {
                heatmap: {
                    enableShades: false,
                    colorScale: {
                        ...colorScaleRealData,
                        ranges: [
                            ...colorScaleRealData.ranges,
                            addTranslatableNoData(t('common.noData'))
                        ]
                    },
                    radius: 0,
                },
            },
            noData: {
                text: t('common.noData'),
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: SMOKE,
                    fontSize: '2.5rem',
                },
            },
        })
    } else if (outputOptions) {
        setHeatmapOptions({
            ...heatmapOptions,
            xaxis: {
                ...heatmapOptions.xaxis,
                title: {
                    ...heatmapOptions.xaxis?.title,
                    text: t(`qualityManager.${heatmapOptions.xaxis?.title?.text}`)
                },
            },
            tooltip: {
                followCursor: true,
                custom: options => {
                    let _tooltipValue: string = t('common.noData')

                    if (options) {
                        const dataPointIndex = options.dataPointIndex
                        const cellTime = options.w.globals.labels[dataPointIndex]
                        const dataRow = options.series[options.seriesIndex]
                        const day = options.w.globals.seriesNames[options.seriesIndex]
                        if (dataRow[options.dataPointIndex] !== -1)
                            _tooltipValue =
                                outputOptions.variable === 'qm'
                                    ? dataRow[options.dataPointIndex]
                                    : `0.${dataRow[options.dataPointIndex]}`

                        return `
                        <div class="qm__tooltip">
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${day}
                            </p>
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.time')}: </span> ${cellTime}
                            </p>
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.tooltipValue')} ${
                                    outputOptions.variable === 'qm' ? '%' : ''
                                }: </span> ${_tooltipValue}
                            </p>
                        </div>
                        `
                    }
                },
            },
            plotOptions: {
                heatmap: {
                    enableShades: false,
                    colorScale:
                        outputOptions.variable === 'qm'
                            ? {
                                ...colorScaleRealData,
                                ranges: [
                                    ...colorScaleRealData.ranges,
                                    addTranslatableNoData(t('common.noData'))
                                ]
                            }
                            : outputOptions.variable === 'r2'
                            ? {
                                ...colorScaleR2,
                                ranges: [
                                    ...colorScaleR2.ranges,
                                    addTranslatableNoData(t('common.noData'))
                                ]
                            }
                            : {
                                ...colorScaleSlope,
                                    ranges: [
                                    ...colorScaleSlope.ranges,
                                    addTranslatableNoData(t('common.noData'))
                                ]
                            },
                    radius: 0,
                },
            },
            yaxis: {
                ...heatmapOptions.yaxis,
                title: {
                    text: capitalizeFirstLetter(t(`qualityManager.${outputOptions.statistic}`)),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            noData: {
                text: t('common.noData'),
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: SMOKE,
                    fontSize: '2.5rem',
                },
            },
        })
    } else if (outputSelected === 'flow' || outputSelected === 'speed' || outputSelected === 'occupancy') {
        setHeatmapOptions({
            ...heatmapOptions,
            tooltip: {
                followCursor: true,
                custom: options => {
                    let _tooltipValue = t('common.noData')

                    if (options) {
                        const dataPointIndex = options.dataPointIndex
                        let cellTimeText: string
                        const cellTime = options.w.globals.labels[dataPointIndex]
                        if (intervalSelected === 'date') {
                            cellTimeText = `<p class="qm__tooltip-text">
                                                <span class="qm__tooltip-text--grey">${t('qualityManager.time')}: </span> ${cellTime}
                                            </p>`
                        } else {
                            cellTimeText = `<p class="qm__tooltip-text">
                                                <span class="qm__tooltip-text--grey">${t('qualityManager.station')}: </span> ${cellTime}
                                            </p>`
                        }
                        const dataRow = options.series[options.seriesIndex]
                        const day = options.w.globals.seriesNames[options.seriesIndex]
                        if (dataRow[dataPointIndex] !== -1) _tooltipValue = dataRow[dataPointIndex]

                        return `
                        <div class="qm__tooltip">
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${day}
                            </p>
                            ${cellTimeText}
                            <p class="qm__tooltip-text">
                                <span class="qm__tooltip-text--grey">${t('qualityManager.tooltipValuePercentage')}: </span> ${_tooltipValue}
                            </p>
                        </div>
                        `
                    }
                },
            },
            stroke: {
                show: false,
            },
            plotOptions: {
                heatmap: {
                    enableShades: false,
                    colorScale: {
                        ...colorScaleStatisticData,
                        ranges: [
                            ...colorScaleStatisticData.ranges,
                            addTranslatableNoData(t('common.noData'))
                        ]
                    },
                    radius: 0,
                },
            },
            xaxis: {
                labels: {
                    hideOverlappingLabels: true,
                    rotate: intervalSelected === 'date' ? -45 : -80,
                    style: {
                        fontFamily: 'AimsunLight',
                    },
                },
                title: {
                    text: intervalSelected === 'date' ? t('qualityManager.time') : t('qualityManager.station'),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            yaxis: {
                ...heatmapOptions.yaxis,
                title: {
                    text: capitalizeFirstLetter(t(`qualityManager.${outputSelected}`)),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            noData: {
                text: t('common.noData'),
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: SMOKE,
                    fontSize: '2.5rem',
                },
            },
        })
    }
}

export const refreshLineChart = (
    visualisation: string,
    outputSelected: string,
    timeZone: string,
    dateFormat: string,
    timeFormat: string,
    lineChartOptions: ApexCharts.ApexOptions,
    setLineChartOptions: (value: React.SetStateAction<ApexCharts.ApexOptions>) => void
) => {
    const dateTimeFormat = `${dateFormat} | ${timeFormat}`
    const outputOptions: IQMOutputType | undefined = QmOutputTypes.get(outputSelected.toLowerCase())
    if (visualisation === 'patternsVariability') {
        setLineChartOptions({
            ...lineChartOptions,
            markers: {
                size: 3,
            },
            tooltip: {
                followCursor: true,
                custom: options => {
                    let tooltipValue = t('common.noData')

                    if (options) {
                        const epoch = +options.ctx.data.twoDSeriesX[options.dataPointIndex]
                        const dateTime = DateTime.fromMillis(epoch, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)

                        let tip: string = `<div class="qm__tooltip">
                                                <p class="qm__tooltip-text">
                                                    <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${date}
                                                </p>`

                        const initialSeries = options.w.globals.initialSeries
                        initialSeries.forEach((series: { name: any }, index: string | number) => {
                            let value = options.series[index][options.dataPointIndex]
                            if (value === null || value === undefined || value === -1) {
                                value = tooltipValue
                            }
                            const name = series.name
                            tip =
                                tip +
                                `<p class="qm__tooltip-text">
                                                        <span class="qm__tooltip-text--grey">${name}: </span> ${value}
                                                    </p>`
                        })
                        tip = tip + `</div>`

                        return tip
                    }
                },
            },
            xaxis: {
                ...lineChartOptions.xaxis,
                labels: {
                    show: false,
                    formatter: function (value) {
                        const dateTime = DateTime.fromMillis(+value, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)
                        return date
                    },
                },
                title: {
                    text: t(`qualityManager.${lineChartOptions.xaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            yaxis: {
                ...lineChartOptions.yaxis,
                title: {
                    // @ts-ignore
                    text: t(`qualityManager.${lineChartOptions.yaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            noData: {
                text: t('common.noData'),
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: SMOKE,
                    fontSize: '2.5rem',
                },
            },
        })
    } else if (visualisation === 'patternsSelectedVsRealData') {
        setLineChartOptions({
            ...lineChartOptions,
            xaxis: {
                ...lineChartOptions.xaxis,
                labels: {
                    show: false,
                    formatter: function (value) {
                        const dateTime = DateTime.fromMillis(+value, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)
                        return date
                    },
                },
                title: {
                    text: t(`qualityManager.${lineChartOptions.xaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            yaxis: {
                ...lineChartOptions.yaxis,
                title: {
                    // @ts-ignore
                    text: t(`qualityManager.${lineChartOptions.yaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            tooltip: {
                followCursor: true,
                custom: options => {
                    let tooltipValue = t('common.noData')

                    if (options) {
                        const epoch = +options.ctx.data.twoDSeriesX[options.dataPointIndex]
                        const dateTime = DateTime.fromMillis(epoch, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)

                        let tip: string = `<div class="qm__tooltip">
                                                <p class="qm__tooltip-text">
                                                    <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${date}
                                                </p>`

                        const initialSeries = options.w.globals.initialSeries
                        initialSeries.forEach((series: { name: any }, index: string | number) => {
                            let value = options.series[index][options.dataPointIndex]
                            if (value === null || value === undefined || value === -1) {
                                value = tooltipValue
                            }
                            const name = series.name
                            tip =
                                tip +
                                `<p class="qm__tooltip-text">
                                                        <span class="qm__tooltip-text--grey">${name} </span> ${value} %
                                                    </p>`
                        })
                        tip = tip + `</div>`

                        return tip
                    }
                },
            },
            noData: {
                text: t('common.noData'),
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: SMOKE,
                    fontSize: '2.5rem',
                },
            },
        })
    } else if (visualisation === 'patternsVsCleanData') {
        setLineChartOptions({
            ...lineChartOptions,
            xaxis: {
                ...lineChartOptions.xaxis,
                labels: {
                    show: true,
                    formatter: function (value) {
                        const dateTime = DateTime.fromMillis(+value, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)
                        return date
                    },
                },
                title: {
                    text: t(`qualityManager.${lineChartOptions.xaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            yaxis: {
                ...lineChartOptions.yaxis,
                title: {
                    // @ts-ignore
                    text: t(`qualityManager.${lineChartOptions.yaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            stroke: {
                width: [5, 5, 1, 1, 1],
            },
            tooltip: {
                followCursor: true,
                custom: options => {
                    let tooltipValue = t('common.noData')

                    if (options) {
                        const epoch = +options.ctx.data.twoDSeriesX[options.dataPointIndex]
                        const dateTime = DateTime.fromMillis(epoch, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)

                        let tip: string = `<div class="qm__tooltip">
                                                <p class="qm__tooltip-text">
                                                    <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${date}
                                                </p>`

                        const initialSeries = options.w.globals.initialSeries
                        initialSeries.forEach((series: { name: any }, index: string | number) => {
                            let value = options.series[index][options.dataPointIndex]
                            if (value === null || value === undefined || value === -1) {
                                value = tooltipValue
                            }
                            const name = series.name
                            tip =
                                tip +
                                `<p class="qm__tooltip-text">
                                                        <span class="qm__tooltip-text--grey">${name}: </span> ${value}
                                                    </p>`
                        })
                        tip = tip + `</div>`

                        return tip
                    }
                },
            },
            noData: {
                text: t('common.noData'),
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: SMOKE,
                    fontSize: '2.5rem',
                },
            },
        })
    } else if (visualisation === 'patternsAvailableVsRealData') {
        setLineChartOptions({
            ...lineChartOptions,
            xaxis: {
                ...lineChartOptions.xaxis,
                labels: {
                    show: false,
                    formatter: function (value) {
                        const dateTime = DateTime.fromMillis(+value, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)
                        return date
                    },
                },
                title: {
                    text: t(`qualityManager.${lineChartOptions.xaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            yaxis: {
                ...lineChartOptions.yaxis,
                title: {
                    // @ts-ignore
                    text: t(`qualityManager.${lineChartOptions.yaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            tooltip: {
                followCursor: true,
                custom: options => {
                    let tooltipValue = t('common.noData')

                    if (options) {
                        const epoch = +options.ctx.data.twoDSeriesX[options.dataPointIndex]
                        const dateTime = DateTime.fromMillis(epoch, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)

                        let tip: string = `<div class="qm__tooltip">
                                                <p class="qm__tooltip-text">
                                                    <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${date}
                                                </p>`

                        const initialSeries = options.w.globals.initialSeries
                        initialSeries.forEach((series: { name: any }, index: string | number) => {
                            let value = options.series[index][options.dataPointIndex]
                            if (value === null || value === undefined || value === -1) {
                                value = tooltipValue
                            }
                            const name = series.name
                            tip =
                                tip +
                                `<p class="qm__tooltip-text">
                                                        <span class="qm__tooltip-text--grey">${name} ${t('qualityManager.tooltipValuePercentage')}: </span> ${value}
                                                    </p>`
                        })
                        tip = tip + `</div>`

                        return tip
                    }
                },
            },
        })
    } else if (
        (outputOptions && outputOptions.variable === 'qm') ||
        outputSelected === 'flow' ||
        outputSelected === 'speed' ||
        outputSelected === 'occupancy'
    ) {
        setLineChartOptions({
            ...lineChartOptions,
            xaxis: {
                ...lineChartOptions.xaxis,
                labels: {
                    show: false,
                    formatter: function (value) {
                        const dateTime = DateTime.fromMillis(+value, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)
                        return date
                    },
                },
                title: {
                    text: t(`qualityManager.${lineChartOptions.xaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            tooltip: {
                followCursor: true,
                custom: options => {
                    let tooltipValue = t('common.noData')

                    if (options) {
                        const dataRow = options.series[options.seriesIndex]
                        if (dataRow[options.dataPointIndex] !== -1) tooltipValue = dataRow[options.dataPointIndex]

                        const epoch = +options.ctx.data.twoDSeriesX[options.dataPointIndex]
                        const dateTime = DateTime.fromMillis(epoch, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)

                        return `
                            <div class="qm__tooltip">
                                <p class="qm__tooltip-text">
                                    <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${date}
                                </p>
                                <p class="qm__tooltip-text">
                                    <span class="qm__tooltip-text--grey">${t('qualityManager.tooltipValuePercentage')}: </span> ${tooltipValue}
                                </p>
                            </div>
                        `
                    }
                },
            },
            yaxis: {
                ...lineChartOptions.yaxis,
                title: {
                    text: outputOptions
                        ? capitalizeFirstLetter(t(`qualityManager.${outputOptions.statistic}`))
                        : capitalizeFirstLetter(outputSelected),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            noData: {
                text: t('common.noData'),
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: SMOKE,
                    fontSize: '2.5rem',
                },
            },
        })
    } else if (outputOptions && (outputOptions.variable === 'r2' || outputOptions.variable === 'slope')) {
        setLineChartOptions({
            ...lineChartOptions,
            xaxis: {
                ...lineChartOptions.xaxis,
                labels: {
                    show: false,
                    formatter: function (value) {
                        const dateTime = DateTime.fromMillis(+value, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)
                        return date
                    },
                },
                title: {
                    text: t(`qualityManager.${lineChartOptions.xaxis?.title?.text}`),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            tooltip: {
                followCursor: true,
                custom: options => {
                    let tooltipValue = t('common.noData')

                    if (options) {
                        const dataRow = options.series[options.seriesIndex]
                        if (dataRow[options.dataPointIndex] !== -1) {
                            const value = dataRow[options.dataPointIndex] / 100
                            tooltipValue = value.toString()
                        }

                        const epoch = +options.ctx.data.twoDSeriesX[options.dataPointIndex]
                        const dateTimeFormat = `${dateFormat} | ${timeFormat}`
                        const dateTime = DateTime.fromMillis(epoch, { zone: timeZone })
                        const date: string = dateTime.toFormat(dateTimeFormat)
                        return `
                            <div class="qm__tooltip">
                                <p class="qm__tooltip-text">
                                    <span class="qm__tooltip-text--grey">${t('qualityManager.date')}: </span> ${date}
                                </p>
                                <p class="qm__tooltip-text">
                                    <span class="qm__tooltip-text--grey">${t('qualityManager.tooltipValue')}: </span> ${tooltipValue}
                                </p>
                            </div>
                        `
                    }
                },
            },
            yaxis: {
                ...lineChartOptions.yaxis,
                labels: {
                    show: true,
                    formatter: function (value) {
                        return (value / 100).toString()
                    },
                    style: {
                        fontSize: '16px',
                        fontFamily: 'AimsunLight',
                    },
                },
                title: {
                    text: capitalizeFirstLetter(t(`qualityManager.${outputOptions.statistic}`)),
                    style: {
                        fontSize: '18px',
                        fontFamily: 'AimsunLight',
                    },
                },
            },
            noData: {
                text: t('common.noData'),
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: SMOKE,
                    fontSize: '2.5rem',
                },
            },
        })
    }
}

export function stableSort(array: any, comparator: any) {
    const stabilicedThis = array.map((el: any, index: any) => [el, index])
    stabilicedThis.sort((a: any, b: any) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilicedThis.map((el: any) => el[0])
}

export function getComparator(order: 'desc' | 'asc' | undefined, orderBy: string) {
    return order === 'desc'
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy)
}

function descendingComparator(a: any, b: any, orderBy: string) {
    if (b[orderBy] < a[orderBy]) return -1
    if (b[orderBy] > a[orderBy]) return 1
    return 0
}
