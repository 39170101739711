import { all, AllEffect, call, ForkEffect, put, takeEvery } from 'redux-saga/effects'

import {
    loadIncidents,
    storeDetectedIncidents,
    storeExternalIncidents,
    storeRealIncidents,
    storeTestIncidents,
} from '../../mapbox/mapboxSlice'
import { getExternalIncidents, getIncidents } from '../../../services/incidents/incidentsService'

function* findIncidents(action: any): Generator<unknown, any, unknown> {
    const { type, epoch } = action.payload
    if (type === 'all') {
        yield call(getAllIncidents, epoch)
    } else {
        yield call(getIncidentsByType, epoch, type)
    }
}

export function* getAllIncidents(epoch: number) {
    const incidentsBundle: any[] = yield all([
        getExternalIncidents(epoch, 'external'),
        getIncidents(epoch, 'idm'),
        getIncidents(epoch, 'test'),
        getIncidents(epoch, 'real'),
    ])

    yield put(storeExternalIncidents(incidentsBundle[0]))
    yield put(storeDetectedIncidents(incidentsBundle[1]))
    yield put(storeTestIncidents(incidentsBundle[2]))
    yield put(storeRealIncidents(incidentsBundle[3]))
}

export function* getIncidentsByType(epoch: number, type: IIncidentType) {
    let nextIncidents: GeoJSON.FeatureCollection
    switch (type) {
        case 'external':
            nextIncidents = yield call(getExternalIncidents, epoch, 'external')
            yield put(storeExternalIncidents(nextIncidents))
            break
        case 'idm':
            nextIncidents = yield call(getIncidents, epoch, 'idm')
            yield put(storeDetectedIncidents(nextIncidents))
            break
        case 'real':
            nextIncidents = yield call(getIncidents, epoch, 'real')
            yield put(storeRealIncidents(nextIncidents))
            break
        case 'test':
            nextIncidents = yield call(getIncidents, epoch, 'test')
            yield put(storeTestIncidents(nextIncidents))
            break
        default:
            console.log('Incident type not found')
    }
}

function* incidentsSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeEvery(loadIncidents, findIncidents),
    ])
}

export default incidentsSaga
