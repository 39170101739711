import * as React from 'react'
import {
    CentroidModifiedInfoWrapper,
    CentroidModifiedInfoHeaderText,
    CentroidModifiedInfoHeaderBox,
    ExpandedIcon,
    CentroidModifiedTypographyBodyBold,
    CentroidModifiedHeaderTypographyBold,
    CentroidModifiedTypography,
    CentroidModifiedTableCellContent,
    CentroidModifiedTableCellHeader,
} from './centroidModifiedInfoStyles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEvaluationSimulationData } from '../../helpers/evaluationLandUseSimulationHelper'
import {
    Container,
    Table,
    TableBody,
    TableContainer,
    TableRow,
} from '@mui/material'
import { TableHeadStyled, TableRowContent, } from '../../components/tableStyles/tabletyles'
import { prepareTableModifiedCentroidToShow } from './centroidModifiedInfoHelper'

export const CentroidModifiedInfo: React.FC<CentroidModifiedInfoProps> = ({
    centroidEditedTooltipData,
    viewMode,
    optionLandUseView
}) => {

    const { amAttracted, amGenerated, pmAttracted, pmGenerated } = useEvaluationSimulationData(viewMode)

    const amAttraction = centroidEditedTooltipData?.modifiedData?.newAmAttraction ? centroidEditedTooltipData?.modifiedData?.newAmAttraction : amAttracted ? amAttracted : 0
    const pmAttraction = centroidEditedTooltipData?.modifiedData?.newPmAttraction ? centroidEditedTooltipData?.modifiedData?.newPmAttraction : pmAttracted ? pmAttracted : 0
    const amGeneration = centroidEditedTooltipData?.modifiedData?.newAmGeneration ? centroidEditedTooltipData?.modifiedData?.newAmGeneration : amGenerated ? amGenerated : 0
    const pmGeneration = centroidEditedTooltipData?.modifiedData?.newPmGeneration ? centroidEditedTooltipData?.modifiedData?.newPmGeneration : pmGenerated ? pmGenerated : 0
    const { t } = useTranslation()
    const [collapse, setCollapse] = useState<boolean>(false)

    const toggleCollapse = (): void => {
        setCollapse(!collapse)
    }

    const modifiedData = {amAttraction, amGeneration, pmAttraction, pmGeneration}

    const tableData = prepareTableModifiedCentroidToShow(centroidEditedTooltipData, modifiedData, optionLandUseView ? optionLandUseView : '')

    return (
        <CentroidModifiedInfoWrapper>
            <CentroidModifiedInfoHeaderBox>
                <CentroidModifiedInfoHeaderText variant='h1'>{`Modified: ${centroidEditedTooltipData?.eid}`}</CentroidModifiedInfoHeaderText>
                <ExpandedIcon className={collapse ? '' : 'expand_less--collapsed'} onClick={toggleCollapse} />
            </CentroidModifiedInfoHeaderBox>
            {!collapse && (
                <TableContainer component={Container} sx={{ marginBottom: '16px', maxHeight: '50vh' }}>
                    <Table aria-label='response plans table' stickyHeader key='response plans table'>
                        <TableHeadStyled>
                            <TableRow>
                                <CentroidModifiedTableCellHeader align='center'>
                                    <CentroidModifiedHeaderTypographyBold>
                                        {''}
                                    </CentroidModifiedHeaderTypographyBold>
                                </CentroidModifiedTableCellHeader>
                                <CentroidModifiedTableCellHeader align='center'>
                                    <CentroidModifiedHeaderTypographyBold>
                                        {t('mapbox.baseline')}
                                    </CentroidModifiedHeaderTypographyBold>
                                </CentroidModifiedTableCellHeader>
                                <CentroidModifiedTableCellHeader align='center'>
                                    <CentroidModifiedHeaderTypographyBold>
                                        {t('mapbox.additional')}
                                    </CentroidModifiedHeaderTypographyBold>
                                </CentroidModifiedTableCellHeader>
                                <CentroidModifiedTableCellHeader align='center'>
                                    <CentroidModifiedHeaderTypographyBold>
                                        {t('mapbox.modification')}
                                    </CentroidModifiedHeaderTypographyBold>
                                </CentroidModifiedTableCellHeader>
                            </TableRow>
                        </TableHeadStyled>
                        <TableBody className='table__body'>
                            {tableData?.map((item, index) => {
                                return (
                                    <TableRowContent
                                        key={item.name}
                                        className={index % 2 === 0 ? 'table__row--even' : 'table__row--odd'}>
                                        <CentroidModifiedTableCellContent align='left' key='name'>
                                            <CentroidModifiedTypographyBodyBold variant='body1'>
                                                {t(item.translatableLabel)}
                                            </CentroidModifiedTypographyBodyBold>
                                        </CentroidModifiedTableCellContent>
                                        <CentroidModifiedTableCellContent align='center' key='baseline'>
                                            <CentroidModifiedTypography variant='body1'>
                                                {`${item.baseline.toLocaleString('en-US')} ${t(item.units)}`}
                                            </CentroidModifiedTypography>
                                        </CentroidModifiedTableCellContent>
                                        <CentroidModifiedTableCellContent align='center' key='modification'>
                                            <CentroidModifiedTypography variant='body1'>
                                                {`${item.modification.toLocaleString('en-US')} ${t(item.units)}`}
                                            </CentroidModifiedTypography>
                                        </CentroidModifiedTableCellContent>
                                        <CentroidModifiedTableCellContent align='center' key='current'>
                                            <CentroidModifiedTypography variant='body1'>
                                                {`${(item.baseline + item.modification).toLocaleString('en-US')} ${t(item.units)}`}
                                            </CentroidModifiedTypography>
                                        </CentroidModifiedTableCellContent>
                                    </TableRowContent>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </CentroidModifiedInfoWrapper>
    )
}
