import { getRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'

export function getRealData(epoch: number, variable: string): Promise<AxiosResponse<any>> {
    return getRequestAPI(`/api_dm/rpc/real_data_at_time?start_time=${epoch}&variable=${variable}`)
}

export function getAllAnalyticalData(epoch: number, variable: string): Promise<AxiosResponse<any>> {
    return getRequestAPI(`/api_dm/rpc/get_last_analytical_forecast_var_all?t=${epoch}&variable=${variable}`)
}

export function getAllSimulatedData(epoch: number, variable: string): Promise<AxiosResponse<any>> {
    return getRequestAPI(`/api_dm/rpc/get_last_simulation_forecast_var_all?t=${epoch}&variable=${variable}`)
}

export function getDataByHorizon(epoch: number, variable: string, type: string, horizon: number): Promise<AxiosResponse<any>> {
    return getRequestAPI(`/api_dm/rpc/get_prediction_data_var_all?t=${epoch}&variable=${variable}&type=${type}&horizon=${horizon}`, {cache: false})
}

export function getRiskPredictionByHorizon(epoch: number, horizon: number): Promise<AxiosResponse<any>> {
    return getRequestAPI(
        `/api_dm/rpc/get_prediction_data_var_all?t=${epoch}&variable=accident_risk_prediction&type=analytical&horizon=${horizon}`
    )
}

export function getSpeedRecommendationByHorizon(epoch: number, horizon: number): Promise<AxiosResponse<any>> {
    return getRequestAPI(
        `/api_dm/rpc/get_prediction_data_var_all?t=${epoch}&variable=recommended_speed&type=analytical&horizon=${horizon}`, {cache: false}
    )
}
export function getAllResponsePlanData(
    epoch: number,
    rp: number,
    variable: string,
    eventId: number
): Promise<AxiosResponse<any>> {
    return getRequestAPI(
        `/api_dm/rpc/get_last_simulation_forecast_rp_all?t=${epoch}&rp=${rp}&variable=${variable}&event=${eventId}`
    )
}
