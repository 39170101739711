import styled from '@emotion/styled'
import { NAVY_BLUE_LOGO, WHITE } from '../../theme'
import { Box, DialogContent, Typography } from '@mui/material'

export const LogoContainer = styled.div<{ mode: string }>`
    background-color: ${NAVY_BLUE_LOGO};
    width: ${props => (props.mode === 'compact' ? '66px' : '144px')};
    height: 70px;
    padding-left: 12px;
    padding-right: 12px;
`

export const AimsunLogo = styled.img`
    height: 40px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
`

export const AboutDialogWrapper = styled.div`
    background-color: #09254c;
    padding: 70px 70px 50px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const DialogContentStyled = styled(DialogContent)`
    padding: 0;
    width: 445px;
`

export const AboutDialogTextWrapper = styled(Box)`
    width: fit-content;
    margin-top: 30px;
    margin-bottom: 6px;
    position: relative;
    left: 66px;
    color: ${WHITE};
    display: flex;
    flex-direction: column;
`

export const AboutText = styled(Typography)`
    color: ${WHITE};
    margin: 6px 0;
    font-size: 14px;
    letter-spacing: 0.03333em;

    &:first-of-type {
        margin-bottom: 12px;
    }
`
