import { DateTime, Duration, DurationObjectUnits } from 'luxon'
import i18n from 'i18next'

export const addTimeToDate = (date: DateTime, unit: string, duration: number): DateTime => {
    return date.plus({ [unit]: duration })
}

export const subtractTimeToDate = (date: DateTime, unit: string, duration: number): DateTime => {
    return date.minus({ [unit]: duration })
}

export const humanizeDuration = (
    duration: Duration,
    options: { short?: boolean; includeSeconds?: boolean } = { short: false, includeSeconds: false }
): string => {
    const units: (keyof DurationObjectUnits)[] = ['months', 'days', 'hours', 'minutes', 'seconds']
    if (!options.includeSeconds) {
        units.pop()
    }
    const durationUnits: DurationObjectUnits = duration.shiftTo(...units).toObject()
    const list: string[] = []
    for (const unit of units) {
        if (durationUnits[unit]) {
            const count = Math.round(durationUnits[unit]!)
            if (options.short) {
                list.push(`${count}${i18n.t('datetime.' + unit + '_short')}`)
            } else {
                list.push(`${count} ${i18n.t('datetime.' + unit, { count })}`)
            }
        }
    }
    if (list.length === 1) {
        return list[0]
    } else if (list.length > 1) {
        return `${list.slice(0, list.length - 1).join(', ')} ${i18n.t('datetime.and')} ${list[list.length - 1]}`
    }
    return ''
}
