import React from 'react'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/CloseRounded'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { OLIVE_ISH, RED_LIVE } from '../../theme'
import {
    analyticalQualityRequest,
    computerInfoRequest,
    missingDataRequest,
    patternQualityRequest,
    simulatedQualityRequest,
    simulatedRuntimeRequest,
    simulationHealthRequest,
    noApmRequest,
    getSystemStatusRequest,
} from '../../services/systemStatus/systemStatusService'

import {
    getAnalyticalQualityColor,
    getPatternQualityColor,
    getRAMissingColor,
    getSimRuntimeColor,
    getSimulatedQualityColor,
} from '../../helpers/SystemStatusHelper'

import {
    Typography,
    Dialog,
    DialogContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { selectConfig, selectSystemServices, selectTimeZone } from '../core/coreSlice'
import { DateTime } from 'luxon'
import { CloseButton, DatetimeText, PaperContent, PaperText, PaperTitle, Title } from './SystemStatusStyles'
import { useTranslation } from 'react-i18next'

export const SystemStatusDialog: React.FC<ISystemStatusDialogProps> = ({
    open,
    epoch,
    onClose,
}: ISystemStatusDialogProps) => {
    const { t } = useTranslation()
    const _config: IModuleConfig = useSelector(selectConfig)
    const _systemServices: string[] = useSelector(selectSystemServices)
    const _timeZone = useSelector(selectTimeZone)
    const [date, setDate] = useState<string>('00/00/00')
    const [time, setTime] = useState<string>('00:00')
    const [services, setServices] = useState<any>({})
    const [patternQuality, setPatternQuality] = useState<number>(0)
    const [analyticalQuality, setAnalyticalQuality] = useState<number>(0)
    const [simulatedQuality, setSimulatedQuality] = useState<number>(0)
    const [simRuntime, setSimRuntime] = useState<number>(0)
    const [realityAcquirer, setRA] = useState<number>(0)
    const [name, setName] = useState<string>('')
    const [versionAN, setVersionAN] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [cores, setCores] = useState<number>(0)
    const [os, setOS] = useState<string>('')
    const [ram, setRAM] = useState<number>(0)
    const [simRuntimeColor, setSimRuntimeColor] = useState<string>('')
    const [simulationHealth, setSimulationHealth] = useState<boolean>(false)
    const [noApm, setNoAPM] = useState<boolean>(false)

    const { time: timeFormat, date: dateFormat } = _config.date_format

    useEffect(() => {
        if (open) {
            computerInfoRequest()
                .then((payload: any) => {
                    const computer: IComputerInfo = payload
                    setName(computer.name)
                    setAddress(computer.address)
                    setOS(computer.os)
                    setVersionAN(computer.version)
                    setCores(computer.cores)
                    setRAM(computer.ram)
                })
                .catch(err => console.log(err))
        }
    }, [open])

    useEffect(() => {
        const dateFormatted: string = DateTime.fromMillis(epoch, { zone: _timeZone }).toFormat(dateFormat)
        setDate(dateFormatted)
        const timeFormatted: string = DateTime.fromMillis(epoch, { zone: _timeZone }).toFormat(timeFormat)
        setTime(timeFormatted)

        getSystemStatusRequest()
            .then(payload => {
                checkServicesStatus(payload)
            })
            .catch((error: any) => {
                console.log('System status fetch error => ', error)
            })

        simulatedQualityRequest().then((payload: any) => {
            const simQuality: ISimQuality = payload
            setSimulatedQuality(simQuality.value)
        })

        analyticalQualityRequest().then((payload: any) => {
            const anaQuality: IAnaQuality = payload
            setAnalyticalQuality(anaQuality.value)
        })

        patternQualityRequest().then((payload: any) => {
            const pQuality: IPatternQuality = payload
            setPatternQuality(pQuality.value)
        })

        missingDataRequest().then((payload: any) => {
            const missing: IMissingData = payload
            setRA(missing.flow)
        })

        simulatedRuntimeRequest().then((payload: any) => {
            const simRuntime: ISimRuntime = payload
            setSimRuntime(simRuntime.value)
            const simRunTimeColor: string = getSimRuntimeColor(simRuntime.value)
            setSimRuntimeColor(simRunTimeColor)
        })

        simulationHealthRequest().then((payload: any) => {
            const simHealth: ISimulationHealth = payload
            setSimulationHealth(simHealth.latest_sim_health)
        })

        noApmRequest().then((payload: any) => {
            const noApm: INoApm = payload
            setNoAPM(noApm.value)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [epoch])

    const checkServicesStatus = (servicesStatus: any) => {
        let nextServices: IService[] = []
        if (_systemServices.length > 0) {
            const asArray = Object.keys(servicesStatus)
            _systemServices.forEach((service: string) => {
                if (service !== '') {
                    const found = asArray.find(key => {
                        const currentService: IService = servicesStatus[key]
                        return currentService.Service === service
                    })
                    if (found) {
                        nextServices.push(servicesStatus[found])
                    } else {
                        const currentService: IService = {
                            Service: service,
                            Port: -1,
                            Address: '',
                            Weights: {
                                Passing: 0,
                                Warning: 0,
                            },
                        }
                        nextServices.push(currentService)
                    }
                }
            })
        } else {
            nextServices = servicesStatus
        }
        setServices(nextServices)
    }

    const handleClose = () => {
        onClose(open)
    }

    const getTimeString = (_time: number): string => {
        return `${_time?.toFixed()}`
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby='system-status-dialog-title'
            open={open}
            fullWidth={true}
            fullScreen={true}
            id='systemStatusDialog'>
            <Title variant='h2'>{t('systemStatus.systemStatus')}</Title>
            <CloseButton onClick={handleClose}>
                <CloseIcon />
            </CloseButton>

            <DialogContent id='systemStatusDialogContent' className='table__container'>
                <Grid container spacing={5}>
                    <Grid item xs={4}>
                        <PaperContent elevation={3}>
                            <PaperTitle variant='h2'>{t('systemStatus.date')}</PaperTitle>
                            <DatetimeText>{date}</DatetimeText>
                            <DatetimeText>{time}</DatetimeText>
                        </PaperContent>
                    </Grid>
                    <Grid item xs={8}>
                        <PaperContent elevation={3}>
                            <PaperTitle variant='h2'>{t('systemStatus.computerInfo')}</PaperTitle>
                            <Grid container>
                                <Grid item xs={6}>
                                    <PaperText>{t('systemStatus.name')}: {name}</PaperText>
                                </Grid>
                                <Grid item xs={6}>
                                    <PaperText>{t('systemStatus.version')}: {versionAN}</PaperText>
                                </Grid>
                                <Grid item xs={6}>
                                    <PaperText>{t('systemStatus.address')}: {address}</PaperText>
                                </Grid>
                                <Grid item xs={6}>
                                    <PaperText>{t('systemStatus.cores')}: {cores}</PaperText>
                                </Grid>
                                <Grid item xs={6}>
                                    <PaperText>{t('systemStatus.os')}: {os}</PaperText>
                                </Grid>
                                <Grid item xs={6}>
                                    <PaperText>{t('systemStatus.ram')}: {ram} {t('units.mb')}</PaperText>
                                </Grid>
                            </Grid>
                        </PaperContent>
                    </Grid>
                    <Grid item xs={12}>
                        <PaperContent elevation={3}>
                            <Typography variant='h2'>{t('systemStatus.modules')}</Typography>
                            <TableContainer
                                style={{ width: '80%', margin: '0 auto', paddingTop: '40px', paddingBottom: '40px' }}>
                                <Table aria-label='quality manager table view'>
                                    <TableHead>
                                        <TableRow key='headerTableRow'>
                                            <TableCell align='center' style={{ width: '40px' }}>
                                                <Typography>
                                                    <strong>{t('systemStatus.quality')}</strong>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography>
                                                    <strong>{t('systemStatus.name')}</strong>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography>
                                                    <strong>{t('systemStatus.value')}</strong>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography>
                                                    <strong>{t('systemStatus.description')}</strong>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='table__body'>
                                        <TableRow className='table__row--even' key='Reality Acquirer'>
                                            <TableCell align='center'>
                                                <FiberManualRecordIcon
                                                    style={{ color: getRAMissingColor(realityAcquirer) }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography variant='body1'>{t('systemStatus.realityAcquirer')}</Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography variant='body1'>{realityAcquirer?.toFixed()}%</Typography>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography>{t('systemStatus.missingData')}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className='table__row--odd' key='Pattern quality'>
                                            <TableCell align='center'>
                                                <FiberManualRecordIcon
                                                    style={{ color: getPatternQualityColor(patternQuality) }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography variant='body1'>{t('systemStatus.patternMatcher')}</Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography variant='body1'>{patternQuality?.toFixed()}%</Typography>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography>{t('systemStatus.gehLess5PatternReal')}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        {!noApm && (
                                            <TableRow className='table__row--even' key='Simulated Quality'>
                                                <TableCell align='center'>
                                                    <FiberManualRecordIcon
                                                        style={{ color: getSimulatedQualityColor(simulatedQuality) }}
                                                    />
                                                </TableCell>
                                                <TableCell align='left'>
                                                    <Typography variant='body1'>{t('systemStatus.simulatedPrediction')}</Typography>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Typography variant='body1'>
                                                        {simulatedQuality?.toFixed()}%
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align='left'>
                                                    <Typography>{t('systemStatus.gehLess5PredictionReal')}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow className='table__row--odd' key='Analytical Quality'>
                                            <TableCell align='center'>
                                                <FiberManualRecordIcon
                                                    style={{ color: getAnalyticalQualityColor(analyticalQuality) }}
                                                />
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography variant='body1'>{t('systemStatus.analyticalPrediction')}</Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Typography variant='body1'>{analyticalQuality?.toFixed()}%</Typography>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography>{t('systemStatus.gehLess5PredictionReal')}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        {!noApm && (
                                            <TableRow className='table__row--even' key='Simulated Runtime'>
                                                <TableCell align='center'>
                                                    <FiberManualRecordIcon
                                                        style={{
                                                            color: simulationHealth ? simRuntimeColor : RED_LIVE,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align='left'>
                                                    <Typography variant='body1'>{t('systemStatus.simulationRuntime')}</Typography>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Typography variant='body1'>
                                                        {getTimeString(simRuntime)} {t('units.secs')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align='left'>
                                                    <Typography>{t('systemStatus.lastSimulatedPrediction')}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </PaperContent>
                    </Grid>
                    <Grid item xs={12}>
                        <PaperContent elevation={3}>
                            <Typography variant='h2'>{t('systemStatus.services')}</Typography>
                            <TableContainer
                                style={{ width: '80%', margin: '0 auto', paddingTop: '40px', paddingBottom: '40px' }}>
                                <Table aria-label='quality manager table view'>
                                    <TableHead>
                                        <TableRow key='headerTableRow'>
                                            <TableCell align='center' style={{ width: '40px' }}>
                                                <Typography>
                                                    <strong>{t('systemStatus.status')}</strong>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography>
                                                    <strong>{t('systemStatus.name')}</strong>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography>
                                                    <strong>{t('systemStatus.address')}</strong>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='left'>
                                                <Typography>
                                                    <strong>{t('systemStatus.port')}</strong>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='table__body'>
                                        {Object.entries(services).map((service: any, index) => {
                                            const _service = service.pop()
                                            const status = _service['Weights'] && _service['Weights'].Passing
                                            return (
                                                <TableRow
                                                    className={index % 2 === 0 ? 'table__row--even' : 'table__row--odd'}
                                                    key={index}>
                                                    <TableCell align='center'>
                                                        <FiberManualRecordIcon
                                                            style={{ color: status ? OLIVE_ISH : RED_LIVE }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography variant='body1'>{_service.Service}</Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography variant='body1'>{_service.Address}</Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography variant='body1'>{_service.Port}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </PaperContent>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
