import { getRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'
import queryString from 'query-string'

export function getPredictionVsRealRequest(params: IPredVsRealRequest): Promise<AxiosResponse<IPredVsRealResponse>> {
    return getRequestAPI(`/qm/prediction_vs_real?${queryString.stringify(params)}`)
}

export function getPredictionVsRealRequestDelayTime(params: ISimulatedVsRealDelayTimeAction): Promise<AxiosResponse<IPredVsRealResponse>> {
    return getRequestAPI(`/qm/blufax_border?${queryString.stringify(params)}`, { cache: false })
}

export function getWeightedSensors(variable: string): Promise<AxiosResponse<IPredVsRealResponse>> {
    return getRequestAPI(`/api_dm/weighted_sensors?variable=${variable}`)
}

export function getQMDMDataRequest(params: any): Promise<AxiosResponse<IQMDMResponse>> {
    return getRequestAPI(`/qm/dm_data?${queryString.stringify(params)}`)
}

export function getAveragePatternDataRequest(params: any): Promise<AxiosResponse<IQMAverageResponse>> {
    return getRequestAPI(`/api_dm/rpc/get_pattern_last_n_hours_avg?${queryString.stringify(params)}`)
}

export function getAveragePattern24hours(pattern_id: any, variable: any): Promise<AxiosResponse<IQMAverageResponse>> {
    return getRequestAPI(`/api_dm/rpc/get_pattern_avg?pattern_id=${pattern_id}&variable=${variable}`)
}

export function getAverageCleanDataRequest(params: any): Promise<AxiosResponse<IQMAverageResponse>> {
    return getRequestAPI(`/api_dm/rpc/get_last_n_hours_avg?${queryString.stringify(params)}`)
}

export function getVariabilityPatternDataRequest(params: any): Promise<AxiosResponse<IQMVariabilityResponse>> {
    return getRequestAPI(`/api_dm/rpc/get_pattern_variability?${queryString.stringify(params)}`)
}
