import { all, AllEffect, call, ForkEffect, put, takeLatest } from 'redux-saga/effects'
import {
    createNewResponsePlan,
    setResponsePlanCreated,
    setResponsePlanCreating,
} from './responsePlanSlice'
import { createManageResponsePlan } from '../../../services/responsePlans/responsePlansService'

function* createNewManageResponsePlan(action: any): any {
    try {
        yield call(createManageResponsePlan, action.payload.responsePlan)
        yield put(setResponsePlanCreated(true))
        yield put(setResponsePlanCreating(false))
    } catch (error) {
        console.error(error)
    }
}



function* responsePlanSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(createNewResponsePlan, createNewManageResponsePlan)
    ])
}

export default responsePlanSaga
