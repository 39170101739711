import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

const loadPersistedState = () => {
    const storedAccessToken = localStorage.getItem('access_token')
    const storedRefreshToken = localStorage.getItem('refresh_token')
    let access_token, refresh_token
    let status: IAuthStatus = 'unauthenticated'
    if (storedAccessToken && storedAccessToken !== 'undefined') {
        access_token = JSON.parse(storedAccessToken)
        status = 'authenticated'
    }
    if (storedRefreshToken && storedRefreshToken !== 'undefined') {
        refresh_token = JSON.parse(storedRefreshToken)
    }
    return { access_token, refresh_token, status }
}

const initialState: IAuthState = loadPersistedState()

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        storeAuthTokens: (state, action: PayloadAction<IAuthPayload>) => {
            state.access_token = action.payload.access_token
            state.refresh_token = action.payload.refresh_token
        },
        storeAuthStatus: (state, action: PayloadAction<IAuthStatus>) => {
            state.status = action.payload
        },
    },
})

export const { storeAuthTokens, storeAuthStatus } = authSlice.actions

export const selectAccessToken = (state: RootState): string => state.auth.access_token
export const selectRefreshToken = (state: RootState): string => state.auth.refresh_token
export const selectAuthStatus = (state: RootState): string => state.auth.status

export default authSlice.reducer
