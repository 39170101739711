import { postRequestAPI, getRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'
import { client } from '../apiClient/apiClient'

export function createEvent(event: IEventNetwork): Promise<AxiosResponse<number>> {
    return postRequestAPI(`/api_dm/events`, event)
}

export async function updateEventService(event: IEventNetwork): Promise<AxiosResponse<any, any>> {
    const config = {
        headers: {
            'X-HTTP-Method-Override': 'PUT',
            Accept: 'application/json',
        },
    }
    return await client.post(`/api_dm/events?id=${event.id}`, event, config).then(res => res)
}

export async function deleteEventService(event: IEventNetwork): Promise<AxiosResponse<any, any>> {
    const config = {
        headers: {
            'X-HTTP-Method-Override': 'DELETE',
            Accept: 'application/json',
        },
    }
    return await client.post(`/api_dm/events?id=${event.id}`, '', config).then(res => res)
}

export async function getEventByIdService(id: number): Promise<AxiosResponse<any, any>> {
    return await client.get(`/api_dm/events?id=${id}`).then(res => res)
}

export function getActiveEvents(time: number, type: string): Promise<AxiosResponse<any, any>> | undefined {
    if (time !== 0) {
        return getRequestAPI(`/api_dm/events?epoch=${time}&type=${type}`, { cache: false })
    }
}

export function getEvents(): Promise<AxiosResponse<IEventNetwork[]>> {
    return getRequestAPI(`/api_dm/events`, { cache: false })
}
