import { eventChannel } from 'redux-saga'
import { all, apply, call, fork, select, take, takeLatest } from 'redux-saga/effects'
import {
    selectConfig,
    setAnalyticalDataUpdates,
    setRealDataUpdates,
    setSREDataUpdates,
    setSimulatedDataUpdates,
    storeConfig,
} from '../features/core/coreSlice'
import store from '../app/store'
import { hasTypeData } from '../features/core/mapData/mapDataSaga'
import { loadSpeedRecommendationData } from '../features/core/speedRecommendationData/speedRecommendationDataSlice'
import { loadIncidents } from '../features/mapbox/mapboxSlice'

interface ILiveData {
    sim: any
    ana: any
    real: any
    sre: any
}

let state: ILiveData = {
    sim: {},
    ana: {},
    real: {},
    sre: {},
}

function createWebSocketConnection(url: string, namespace: string, protocol?: string) {
    const _protocol = protocol || 'wss'
    try {
        return new WebSocket(`${_protocol}://${url}/${namespace}/`)
    } catch (err) {
        console.log('websocket connection error', err)
    }
}

function createSocketChannel(
    webSocket: any,
    variablesExpected: { real: string[]; analytical: string[]; simulated: string[] }
) {
    return eventChannel(emit => {
        webSocket.onerror = function (e: any) {
            console.error(e)
        }

        webSocket.onmessage = function (evt: any) {
            const { source, varType } = JSON.parse(evt.data)

            if (varType === 'recommended_speed' && window.location.pathname === '/monitor'){
                store.dispatch(loadSpeedRecommendationData({epoch: store.getState().core.nowTime}))
            }
            if (
                source === 'external incidents update' &&
                varType === 'external_incidents' &&
                window.location.pathname === '/monitor'
            ) {
                store.dispatch(loadIncidents({ epoch: store.getState().core.nowTime, type: 'external' }))
            }

            switch (source) {
                case 'real data update':
                    if (varType.includes('sensor_reliability')) {
                        updateProgress(evt.timeStamp, varType, 'sre', variablesExpected.real)
                    } else {
                        updateProgress(evt.timeStamp, varType, 'real', variablesExpected.real)
                    }
                    break
                case 'simulated data update':
                    updateProgress(evt.timeStamp, varType, 'sim', variablesExpected.simulated)
                    break
                case 'analytical data update':
                    updateProgress(evt.timeStamp, varType, 'ana', variablesExpected.analytical)
                    break
            }
        }

        return () => {
            webSocket.onmessage = null
        }
    })
}

function updateProgress(timestamp: number, varType: any, source: string, variablesExpected: string[]) {
    state = {
        ...state,
        [source]: {
            //@ts-ignore
            ...state[source],
            [varType]: {
                status: 'ready',
                when: timestamp,
            },
        },
    }
    //@ts-ignore
    let updateFromWS = [...Object.keys(state[source])]
    //@ts-ignore
    const finish = variablesExpected.every(
        (varType: string) => updateFromWS.find((ele: string) => ele === varType) === varType
    )
    //@ts-ignore
    if (finish) {
        dispatchToStore(source)
    }
}

function dispatchToStore(source: string) {
    switch (source) {
        case 'real':
            store.dispatch(setRealDataUpdates(true))
            break
        case 'sim':
            store.dispatch(setSimulatedDataUpdates(true))
            break
        case 'ana':
            store.dispatch(setAnalyticalDataUpdates(true))
            break
        case 'sre':
            store.dispatch(setSREDataUpdates(true))
            break
    }
    cleanStoredSources(source)
}

function cleanStoredSources(source: string) {
    state = {
        ...state,
        [source]: {},
    }
}

function* emitResponse(socket: any) {
    yield apply(socket, socket.emit, ['message received'])
}

function* watchSocketChannel() {
    let config: IModuleConfig = yield select(selectConfig)
    const requiresWebSocket = config.modules.some(module => module.usesWebsocket !== false)
    if (requiresWebSocket) {
        config = yield* monitorConfig(config)

        const viewModeModules = config.modules.map(module => module.viewModes)
        let variablesExpected: { real: string[]; analytical: string[]; simulated: string[] } = {
            real: [],
            analytical: [],
            simulated: [],
        }
        viewModeModules.forEach(viewModeName => {
            viewModeName.forEach(name => {
                const viewModeFound = config.view_mode.find(
                    viewMode => viewMode.name === name && viewMode.webSocketMessage
                )
                if (viewModeFound) {
                    if (
                        hasTypeData(viewModeFound, 'real') &&
                        !variablesExpected.real.includes(viewModeFound.variable)
                    ) {
                        variablesExpected.real.push(viewModeFound.variable)
                    }
                    if (
                        hasTypeData(viewModeFound, 'analytical') &&
                        !variablesExpected.analytical.includes(viewModeFound.variable)
                    ) {
                        variablesExpected.analytical.push(viewModeFound.variable)
                    }
                    if (
                        hasTypeData(viewModeFound, 'simulated') &&
                        !variablesExpected.simulated.includes(viewModeFound.variable)
                    ) {
                        variablesExpected.simulated.push(viewModeFound.variable)
                    }
                }
            })
        })
        const riskViewModeFound = config.view_mode.find(
            viewMode => viewMode.variable === 'accident_risk_prediction' && viewMode.webSocketMessage
        )
        if (riskViewModeFound) {
            if (
                hasTypeData(riskViewModeFound, 'real') &&
                !variablesExpected.real.includes(riskViewModeFound.variable)
            ) {
                variablesExpected.real.push(riskViewModeFound.variable)
            }
            if (
                hasTypeData(riskViewModeFound, 'analytical') &&
                !variablesExpected.analytical.includes(riskViewModeFound.variable)
            ) {
                variablesExpected.analytical.push(riskViewModeFound.variable)
            }
            if (
                hasTypeData(riskViewModeFound, 'simulated') &&
                !variablesExpected.simulated.includes(riskViewModeFound.variable)
            ) {
                variablesExpected.simulated.push(riskViewModeFound.variable)
            }
        }

        // @ts-ignore
        const webSocket = yield call(createWebSocketConnection, config.web_socket?.address, config.web_socket?.client)
        // @ts-ignore
        const webSocketChannel = yield call(createSocketChannel, webSocket, variablesExpected)

        while (true) {
            try {
                yield take(webSocketChannel)
                yield fork(emitResponse, webSocket)
            } catch (err) {
                console.log('socket error: ', err)
            }
        }
    } else {
        console.warn(`websocket not required for any module`)
    }
}

function* monitorConfig(previousValue: IModuleConfig, takePattern = '*') {
    while (true) {
        const nextValue: IModuleConfig = yield select(selectConfig)
        if (nextValue && nextValue.web_socket && nextValue.web_socket !== previousValue.web_socket) {
            return nextValue
        }
        yield take(takePattern)
    }
}

export function* listenToSocketChanges() {
    yield all([takeLatest(storeConfig, watchSocketChannel)])
}
