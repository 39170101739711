export const getResponsePlansInfoTable = (responsePlans: IEvaluationResponsePlan[] | string[] | IResponsePlan[]): string => {
    let responsePlanInfo: string = ''
    responsePlans.forEach((responsePlan) => {
        if (responsePlan && typeof responsePlan === 'object') {
            if ('responsePlanId' in responsePlan) {
                responsePlanInfo = responsePlanInfo === ''
                    ? responsePlanInfo + responsePlan.responsePlanId
                    : responsePlanInfo + ' - ' + responsePlan.responsePlanId
            }
        } else {
            responsePlanInfo = responsePlanInfo === '' ? responsePlanInfo + responsePlan : responsePlanInfo + ' - ' + responsePlan
        }
    })
    return responsePlanInfo
}