import styled from "@emotion/styled"
import { TableCellContentStyled } from "../../../../../components/tableStyles/tabletyles"

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &.no-selected {
    opacity: 0.5;
  }
`

export const TableCellContentComparison = styled(TableCellContentStyled)`
  &.no-selected {
    opacity: 0.5;
  }
`
