import React from 'react'
import { TextField } from '@mui/material'
import styled from '@emotion/styled'

export interface IClockInputRenderProps {
    timeParams: {}
    dateParams: {}
}

export const ClockInputRenderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90px;
    padding: 0 4px;
`

export const ClockTimeInput = styled(TextField)`
    input {
        text-align: center;
        padding: 0;
        font-size: 21px;
    }
`

export const ClockDateInput = styled(TextField)`
    input {
        text-align: center;
        padding: 0;
        margin-top: 2px;
        font-size: 12px;
    }
`

export const ClockInputRender = ({ timeParams, dateParams }: IClockInputRenderProps) => {
    return (
        <ClockInputRenderWrapper>
            <ClockTimeInput {...timeParams} variant='standard' InputProps={{ disableUnderline: true }} />
            <ClockDateInput {...dateParams} variant='standard' InputProps={{ disableUnderline: true }} />
        </ClockInputRenderWrapper>
    )
}
