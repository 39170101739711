import { all } from 'redux-saga/effects'
import incidentsSaga from '../features/incindents/store/incidentsSaga'

export default function* secondarySaga() {
    try {
        yield all([
            incidentsSaga(),
        ])
    } catch (error: any) {
        const mode: 'development' | 'production' | 'test' = process.env.NODE_ENV || 'production'
        if (mode === 'development') {
            console.log(error)
        }
    }
}
