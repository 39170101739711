import axios from 'axios'
import store, { RootState } from '../../app/store'
import { storeAuthStatus } from './authSlice'

const REACT_APP_AUTH_URL = 'https://login.aimsun.com'

export const parseJwt = (token: string): IJwtContent => {
    return JSON.parse(window.atob(token.split('.')[1]).toString())
}

export const signOut = (): void => {
    localStorage.clear()
    const url = new URL(REACT_APP_AUTH_URL)
    url.searchParams.append('redirect', window.location.href.split('?', 1)[0])
    window.location.href = url.href
}

export const handleRefreshToken = (): Promise<IAuthResponse> => {
    return new Promise((resolve, reject): void => {
        const state: RootState = store.getState()
        const { access_token, refresh_token } = state.auth
        store.dispatch(storeAuthStatus('refreshing'))
        if (access_token && refresh_token) {
            const jwtContent: IJwtContent = parseJwt(access_token)
            const params = new URLSearchParams()
            params.append('redirect', window.location.href)
            params.append('refresh_token', refresh_token)
            params.append('scope', jwtContent.scope)
            axios
                .post(`${REACT_APP_AUTH_URL}/api/token`, params)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.response?.data)
                })
        } else {
            reject({
                code: 'NotAuthorizedException',
            })
        }
    })
}

export const checkTokenExpiration = (): boolean => {
    const state: RootState = store.getState()
    const { access_token } = state.auth
    const data: IJwtContent = parseJwt(access_token)
    if (Date.now() >= data.exp * 1000) {
        return true
    }
    return false
}
