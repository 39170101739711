import { getRequestAPI, postRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'
import { client } from '../apiClient/apiClient'

export function getActionsService(): Promise<AxiosResponse<number>> {
    return getRequestAPI(`/api_dm/actions_geo`, { cache: false })
}

export async function getActionByIdService(id: number): Promise<AxiosResponse<number>> {
    return await client.get(`/api_dm/actions_geo?id=${id}`).then(res => res)
}
export function getListActionsService(): Promise<AxiosResponse<number>> {
    return getRequestAPI(`/api_dm/actions`, { cache: false })
}

export function getRPActionsService(epoch: number, rp: number | string): Promise<AxiosResponse<number>> {
    return getRequestAPI(`api_dm/active_actions?epoch=${epoch}&response_plan=${rp}`, { cache: false })
}

export async function createManageTrafficAction(action: ICreateTrafficAction): Promise<AxiosResponse<any>> {
    return await postRequestAPI(`api_dm/actions_geo`, action)
}


