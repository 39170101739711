import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const ToolbarContainer = styled(Box)`
    display: flex;
    height: 70px;
    width: 100%;
    background-color: #f3f3f3;
    -webkit-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.75);
`
