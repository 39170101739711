import styled from '@emotion/styled'
import { FormControlLabel } from '@mui/material'

export const TrafficActionsRPFormControlStyled = styled(FormControlLabel)`
  margin: 0;
  &.list__row--even {
    background-color: #f4f4f4;
  }

  &.list__row--even,
  &.list__row--odd {
    width: 100%;
    border-radius: 10px;
    padding-left: 10px;
  }
`

export const TrafficActionsRPWrapper = styled.div`
    max-width: 300px;
`