import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

const initialModuleConfig: IModuleConfig = {
    units: 'metric',
    searchIdentifier: 'all',
    sectionOnClick: false,
    hideFutureCleanData: false,
    showSpeedRecommendation: true,
    'horizon-count': 4,
    'horizon-step': 15,
    mode: 'demo',
    incidents: {
        IDM: true,
        external: true,
    },
    position: {
        latitude: -31.94949019914631,
        longitude: 115.85114303863156,
        zoom: 12,
    },
    date_format: {
        time: 'HH:mm',
        timeWSeconds: 'HH:mm:ss',
        date: 'dd-LL-yyyy',
        dateTime: 'dd-LL-yyyy HH:mm',
        dateTimeLong: 'DDDD HH:mm',
    },
    web_socket: {
        address: null,
        port: '8010',
        client: 'wsclient',
    },
    modules: [],
    view_mode: [],
    baseline_view_mode: [],
    mapHorizonSelector: [
        {
            id: 'default',
            name: '',
            pattern_id: 4,
        },
    ],
}

const initialMainCofing: IMainConfig = {
    defaults: {
        'clock-interval': '900',
        'yellow-risk-threshold': '0.039',
        'orange-risk-threshold': '0.052',
        'red-risk-threshold': '0.175',
        'hide-future': "false",
    },
    cleaner: {
        'cleaner-data-interval': '300',
    },
    ana: {
        'ana-type': 'nmf',
    },
    auth: {
        'use-login': undefined,
    },
    clock: {
        'clock-start': '',
        'clock-end': '',
    },
}

const initialState: ICoreState = {
    moduleName: '',
    module_config: initialModuleConfig,
    main_config: initialMainCofing,
    variableTypesWS: [],
    status: 'loading',
    services: [],
    strategy: [],
    policies: [],
    view_mode: [],
    ptLines: [],
    epoch: 0,
    nowTime: 0,
    tz: 'local',
    data_updates: {
        real: false,
        analytical: false,
        simulated: false,
        sre: false,
    },
    epochChange: false,
    currentError: {
        name: '',
        code: '',
        message: '',
    },
}

export const coreSlice = createSlice({
    name: 'core',
    initialState,
    reducers: {
        storeConfig: (state, action: PayloadAction<any>): void => {
            state.module_config = action.payload
        },
        storeModuleName: (state, action: PayloadAction<any>): void => {
            state.moduleName = action.payload
        },
        storeMainConfig: (state, action: PayloadAction<any>): void => {
            state.main_config = action.payload
        },
        storeVariableTypesWS: (state, action: PayloadAction<any>): void => {
            state.variableTypesWS = action.payload
        },
        storeViewMode: (state: ICoreState, action: PayloadAction<IViewMode[]>): void => {
            state.view_mode = action.payload
        },
        storeNowTime: (state: ICoreState, action: PayloadAction<number>): void => {
            state.nowTime = action.payload
        },
        storeEpoch: (state: ICoreState, action: PayloadAction<number>): void => {
            state.epoch = action.payload
        },
        storeTimeZone: (state: ICoreState, action: PayloadAction<string>): void => {
            state.tz = action.payload
        },
        storeEpochChange: (state: any, action: PayloadAction<boolean>): void => {
            state.epochChange = action.payload
        },
        storeSystemServices: (state: ICoreState, action: PayloadAction<string[]>): void => {
            state.services = action.payload
        },
        storeStrategy: (state: ICoreState, action: PayloadAction<string[]>): void => {
            state.strategy = action.payload
        },
        storePolicies: (state: ICoreState, action: PayloadAction<string[]>): void => {
            state.policies = action.payload
        },
        setStatus: (state, action: PayloadAction<IAppStatus>): void => {
            state.status = action.payload
        },
        setCurrentError: (state, action: PayloadAction<ICurrentError>): void => {
            state.currentError = action.payload
        },
        initialSetup: state => {
            state.status = 'idle'
        },
        updateNowTime: state => {},
        getPtLines: state => {},
        storePtLines: (state, action: PayloadAction<any>): void => {
            state.ptLines = action.payload
        },
        setRealDataUpdates: (state: ICoreState, action: PayloadAction<boolean>): void => {
            state.data_updates = {
                ...state.data_updates,
                real: action.payload,
            }
        },
        setSimulatedDataUpdates: (state: ICoreState, action: PayloadAction<boolean>): void => {
            state.data_updates = {
                ...state.data_updates,
                simulated: action.payload,
            }
        },
        setAnalyticalDataUpdates: (state: ICoreState, action: PayloadAction<boolean>): void => {
            state.data_updates = {
                ...state.data_updates,
                analytical: action.payload,
            }
        },
        setSREDataUpdates: (state: ICoreState, action: PayloadAction<boolean>): void => {
            state.data_updates = {
                ...state.data_updates,
                sre: action.payload,
            }
        },
        resetDataUpdates: (state: ICoreState): void => {
            state.data_updates = {
                real: false,
                analytical: false,
                simulated: false,
                sre: false,
            }
        },
    },
})

export const {
    storeModuleName,
    storeConfig,
    storeMainConfig,
    storeVariableTypesWS,
    storeViewMode,
    storeEpoch,
    storeNowTime,
    storeTimeZone,
    storeEpochChange,
    setStatus,
    storeSystemServices,
    storeStrategy,
    storePolicies,
    initialSetup,
    setRealDataUpdates,
    setAnalyticalDataUpdates,
    setSimulatedDataUpdates,
    setSREDataUpdates,
    resetDataUpdates,
    updateNowTime,
    getPtLines,
    storePtLines,
    setCurrentError,
} = coreSlice.actions

export const selectModuleName = (state: RootState): string => state.core.moduleName
export const selectConfig = (state: RootState): IModuleConfig => state.core.module_config
export const selectMainConfig = (state: RootState): IMainConfig => state.core.main_config
export const selectViewMode = (state: RootState): IViewMode[] => state.core.view_mode
export const selectNowTime = (state: RootState): number => state.core.nowTime
export const selectEpoch = (state: RootState): number => state.core.epoch
export const selectTimeZone = (state: RootState): string => state.core.tz
export const selectEpochChange = (state: RootState): boolean => state.core.epochChange
export const selectStatus = (state: RootState): IAppStatus => state.core.status
export const selectSystemServices = (state: RootState): string[] => state.core.services
export const selectRealDataUpdates = (state: RootState): boolean => state.core.data_updates.real
export const selectAnalyticalDataUpdates = (state: RootState): boolean => state.core.data_updates.analytical
export const selectSimulatedDataUpdates = (state: RootState): boolean => state.core.data_updates.simulated
export const selectCurrentError = (state: RootState): ICurrentError => state.core.currentError
export const selectHideFutureCleanData = (state: RootState): boolean => state.core.main_config.defaults['hide-future'] === "true"
export const selectMapHorizonSelector = (state: RootState): IMapHorizon[] => state.core.module_config.mapHorizonSelector
export const selectVariableTypesWS = (state: RootState): IVariablesTypeWS[] => state.core.variableTypesWS
export const selectPtLines = (state: RootState) => state.core.ptLines
export const selectStrategies = (state: RootState) => state.core.strategy
export const selectPolicies = (state: RootState) => state.core.policies

export default coreSlice.reducer
