import { all, AllEffect, call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { getMarks } from '../../../helpers/SliderMarksHelper'
import { selectConfig } from '../coreSlice'
import {
    getResponsePlanSimulation,
    getResponsePlanSimulationManage, getResponsePlanSimulationManageLive,
} from '../../../services/responseplanSimulation/responseplanSimulationService'
import {
    loadEvaluationSimulationData,
    setFetchingEvaluationSimulationData,
    storeEvaluationLandUseSimulationData,
    storeEvaluationManageSimulationData,
} from './evaluationSimulationDataSlice'
import { selectMapHorizonSelected } from '../../mapHorizonSelector/store/mapHorizonSelectedSlice'
import { getPattern } from '../../../services/pattern/patternService'
import { getEvaluation } from '../../../services/evaluations/evaluationsService'
import { getRPActionsService } from '../../../services/actions/actionsService'
import { FeatureCollection } from 'geojson'
import { storeRpActions } from '../../mapbox/mapboxSlice'

export function* initEvaluationSimulationData(action: any) {
    yield call(fetchEvaluationSimulationData, action.payload)
    yield put(setFetchingEvaluationSimulationData(false))
}
function* fetchEvaluationSimulationData({ epoch, variable }: { epoch: number; variable: string }) {
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const _mapHorizonSelected: IMapHorizon = yield select(selectMapHorizonSelected)
    const step: number = _moduleConfig['horizon-step']
    const marks = getMarks(step, 0)

    const amSimulationData: IPredictionByHorizonData[] = yield all(
        marks.map(horizon =>
            call(getResponsePlanSimulation, epoch, 'am', variable, horizon.value, _mapHorizonSelected.pattern_id)
        )
    )

    const pmSimulationData: IPredictionByHorizonData[] = yield all(
        marks.map(horizon =>
            call(getResponsePlanSimulation, epoch, 'pm', variable, horizon.value, _mapHorizonSelected.pattern_id)
        )
    )

    const amAttractedSimulationData: IPredictionByHorizonData[] = yield all(
        marks.map(horizon =>
            call(
                getResponsePlanSimulation,
                epoch,
                'am',
                'trips_attracted',
                horizon.value,
                _mapHorizonSelected.pattern_id
            )
        )
    )

    const pmAttractedSimulationData: IPredictionByHorizonData[] = yield all(
        marks.map(horizon =>
            call(
                getResponsePlanSimulation,
                epoch,
                'pm',
                'trips_attracted',
                horizon.value,
                _mapHorizonSelected.pattern_id
            )
        )
    )

    const amGeneratedSimulationData: IPredictionByHorizonData[] = yield all(
        marks.map(horizon =>
            call(
                getResponsePlanSimulation,
                epoch,
                'am',
                'trips_generated',
                horizon.value,
                _mapHorizonSelected.pattern_id
            )
        )
    )

    const pmGeneratedSimulationData: IPredictionByHorizonData[] = yield all(
        marks.map(horizon =>
            call(
                getResponsePlanSimulation,
                epoch,
                'pm',
                'trips_generated',
                horizon.value,
                _mapHorizonSelected.pattern_id
            )
        )
    )

    const amPmSimulationData = amSimulationData.map((amData, index) => {
        return {
            am: amData,
            pm: pmSimulationData[index],
            am_attracted: amAttractedSimulationData,
            pm_attracted: pmAttractedSimulationData,
            am_generated: amGeneratedSimulationData,
            pm_generated: pmGeneratedSimulationData,
        }
    })
    yield put(storeEvaluationLandUseSimulationData({ data: amPmSimulationData, variable }))
}

export function* initManageEvaluationSimulationData(action: any) {
    yield call(fetchManageEvaluationSimulationData, action.payload)
    yield put(setFetchingEvaluationSimulationData(false))
}

function* fetchManageEvaluationSimulationData({
    epoch,
    rp,
    variable,
    evaluationId,
}: {
    epoch: number
    rp: number
    variable: string
    evaluationId: number
}) {
    const pattern: IPatternJson = yield call(getPattern, epoch)
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const evaluationData: IEvaluationStatic = yield call(getEvaluation, evaluationId)
    const { eventId } = evaluationData
    const step: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const marks = getMarks(step, numberOfHorizon, false)

    const eventRp: string = eventId ? `${eventId}-${rp}` : `${rp}`
    const actions: FeatureCollection = yield call(getRPActionsService, epoch, eventRp)
    yield put(storeRpActions({ rp, actions }))

    const horizonSimulationData: IPredictionByHorizonData[] = eventId ?
        yield all(
            marks.map(horizon =>
                call(
                    getResponsePlanSimulationManage,
                    epoch,
                    rp,
                    variable,
                    horizon.value,
                    Number(pattern.pattern_id),
                    Number(eventId)
                )
            )
        ) :
        yield all(
            marks.map(horizon =>
                call(
                    getResponsePlanSimulationManageLive,
                    epoch,
                    rp,
                    variable,
                    horizon.value,
                    Number(pattern.pattern_id)
                )
            )
        )

    //Add horizon 0 as empty data
    horizonSimulationData.unshift([])

    yield put(storeEvaluationManageSimulationData({ data: { rp, simulation: horizonSimulationData }, variable }))
}

function* evaluationSimulationDataSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(loadEvaluationSimulationData, initEvaluationSimulationData)])
}

export default evaluationSimulationDataSaga
