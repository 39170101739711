import { t } from 'i18next'

export const getMarks = (
    step: number,
    numberOfHorizon: number,
    withNow: boolean = true,
    variable: string = ''
): ISliderMark[] => {
    const marks: ISliderMark[] = []
    let i = withNow ? 0 : 1
    if (variable === 'geh_partial') {
        for (; i <= numberOfHorizon; i++) {
            marks.push({
                value: i === 0 ? step : i * step,
                label: i === 0 ? t('common.now') : (i * step).toString(),
                variable: i === 0 ? 'geh_global' : variable,
            })
        }
    } else {
        for (; i <= numberOfHorizon; i++) {
            marks.push({
                value: i * step,
                label: i === 0 ? t('common.now') : (i * step).toString(),
                variable: variable,
            })
        }
    }

    return marks
}
