import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface ISimulatedDataState {
    simulatedData: { [key: number]: { [key: string]: IPredictionByHorizonData[] } }
    fetching: boolean
}

const initialSimulatedDataState: ISimulatedDataState = {
    simulatedData: {},
    fetching: false,
}

export const simulatedDataSlice = createSlice({
    name: 'simulatedData',
    initialState: initialSimulatedDataState,
    reducers: {
        storeSimulatedData: (
            state,
            action: PayloadAction<{ data: IPredictionByHorizonData[], variable: string }>
        ): void => {
            state.simulatedData = { ...state.simulatedData,
                [action.payload.variable]: action.payload.data
            }
        },
        setFetchingSimulatedData: (state, action: PayloadAction<boolean>): void => {
            state.fetching = action.payload
        },
        loadSimulatedData: (state, action: PayloadAction<{ epoch: number; variable: string }>): void => {
            state.fetching = true
        },
    },
})

export const { loadSimulatedData, setFetchingSimulatedData, storeSimulatedData } = simulatedDataSlice.actions

export const selectSimulatedData = (
    state: RootState
): { [key: number]: { [key: string]: IPredictionByHorizonData[] } } => state.simulatedData.simulatedData
export const selectFetchingSimulatedData = (state: RootState): boolean => state.simulatedData.fetching

export default simulatedDataSlice.reducer
