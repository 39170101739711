import { getRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'

export function getResponsePlanSimulation(
    time: number,
    responsePlan: string | number,
    variable: string,
    horizon: number,
    patternId: number,
): Promise<AxiosResponse<any>> {
    return getRequestAPI(`/api_dm/rpc/get_forecast_rp_all?t=${time}&rp=${responsePlan}&variable=${variable}&horizon=${horizon}&pattern_id=${patternId}`)
}

export function getResponsePlanSimulationManage(
    time: number,
    responsePlan: string | number,
    variable: string,
    horizon: number,
    patternId: number,
    eventId: number
): Promise<AxiosResponse<any>> {
    return getRequestAPI(`/api_dm/rpc/get_forecast_rp_all?t=${time}&rp=${responsePlan}&variable=${variable}&horizon=${horizon}&pattern_id=${patternId}&event=${eventId}`)
}

export function getResponsePlanSimulationManageLive(
    time: number,
    responsePlan: string | number,
    variable: string,
    horizon: number,
    patternId: number
): Promise<AxiosResponse<any>> {
    return getRequestAPI(`/api_dm/rpc/get_forecast_rp_all?t=${time}&rp=${responsePlan}&variable=${variable}&horizon=${horizon}&pattern_id=${patternId}`, {cache: false})
}
