import styled from '@emotion/styled'
import { TextField, Typography } from '@mui/material'
import { NAVY_BLUE } from '../../../../../theme'


export const TrafficActionsDiversionWrapper = styled.div`
  margin-bottom: 16px;
`

export const TrafficActionsDiversionContent = styled.div`
  margin-bottom: 16px;
`

export const TrafficActionsDiversionSet = styled(Typography)`
  margin-bottom: 16px;
  color: ${NAVY_BLUE};
  font-weight: bold;
`

export const DiversionRoutePercentageStyled = styled(TextField)`
  .MuiFormHelperText-root.Mui-error {
    font-size: 12px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`