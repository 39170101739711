import { all, AllEffect, call, ForkEffect, put, takeLatest } from 'redux-saga/effects'
import { getRealData } from '../../../services/mapData/mapDataService'
import { loadRealData, setFetchingRealData, storeRealData } from './realDataSlice'

export function* initRealData(action: any) {
    yield call(fetchRealData, action.payload)
    yield put(setFetchingRealData(false))
}

function* fetchRealData({ epoch, variable }: { epoch: number; variable: string }) {
    const realData: IRealData[] = yield call(getRealData, epoch, variable)
    const newRealData: IMapDataByHorizon = {}
    realData.forEach((data: IRealData) => {
        newRealData[data.feature_id] = {
            value: data.value,
            reliability: data.reliability,
        }
    })
    yield put(storeRealData({ data: newRealData, variable }))
}

function* realDataSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(loadRealData, initRealData)])
}

export default realDataSaga
