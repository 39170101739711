import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import styled from '@emotion/styled'
import { NO_DATA } from '../../../theme'

export const DoNothingScenario = styled(FormControlLabel)`
  justify-content: space-between;
  margin: 0;
  width: 100%;
`

export const NewManageLiveEvaluationsDialogTitleStyled = styled(DialogTitle)`
  padding: 16px 24px 0 24px;
`

export const NewManageLiveEvaluationsDialogTitleInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const NewManageLiveEvaluationsDialogTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`

export const NewManageLiveEvaluationsDialogIconTitleWrapper = styled.div`
  display: flex;
  height: 30px;
`

export const NewManageLiveEvaluationsDialogTitle = styled(Typography)`
  margin: auto 0 auto 8px;
  line-height: 8px;
`

export const NewManageLiveEvaluationsDialogSeparator = styled(Box)`
  height: 1px;
  background-color: ${NO_DATA};
  box-shadow: 0 0 1px ${NO_DATA};
  margin-bottom: 20px;
  margin-top: 20px;
  opacity: 0.5;
`

export const NewManageLiveEvaluationsDialogContentStyled = styled(DialogContent)`
  padding-top: 0;
  padding-bottom: 26px;
  margin-top: -6px;
  height: 60vh;
`

export const NewManageLiveEvaluationsSelectRpWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const NewManageLiveEvaluationsName = styled(Typography)`
 margin: 20px 0;
`

export const NewManageLiveEvaluationsSearchTermWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  >.MuiContainer-root {
    padding: 0;
    width: 100%;
    >.MuiFormControl-root {
      padding: 0;
      width: 100%;
    }
  }
`

export const NewManageLiveEvaluationsDialogListWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-height: 45vh;
`

export const NewManageLiveEvaluationsDialogName = styled(TextField)`
  .MuiFormHelperText-root {
    margin-top: 8px;
    font-size: 14px;
  }
`

export const NewManageLiveEvaluationsDialogButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 20px 0;
  padding: 0 24px;
`

export const NewManageLiveEvaluationsDialogButton = styled(Button)`
  padding: 9px 25px 6px;
  border-radius: 10px;
  margin-left: 20px;
  min-width: 110px;
  
  &:hover {
    opacity: 0.85;
  }
`

export const NewManageLiveEvaluationsDialogResetButton = styled(NewManageLiveEvaluationsDialogButton)`
  padding: 5px 15px 3px;
  border-radius: 10px;
`

export const NewManageLiveEvaluationsDialogSnackbarStyled = styled(Snackbar)`
  position: relative;
  margin: 24px auto;
  width: 70%;
  transform: translateX(0);
  bottom: initial;
  left: auto;
  right: auto;
  @media (min-width: 600px){
    margin: 24px auto;
    width: 70%;
    transform: translateX(0);
    bottom: initial;
    left: auto;
    right: auto;
  }
`

