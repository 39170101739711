import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface IAnalyticalDataState {
    analyticalData: { [key: string]: IPredictionByHorizonData[] }
    fetching: boolean
}

const initialAnalyticalDataState: IAnalyticalDataState = {
    analyticalData: {},
    fetching: false,
}

export const analyticalDataSlice = createSlice({
    name: 'analyticalData',
    initialState: initialAnalyticalDataState,
    reducers: {
        storeAnalyticalData: (state, action: PayloadAction<{ data: IPredictionByHorizonData[], variable: string }>): void => {
            state.analyticalData = { ...state.analyticalData,
                [action.payload.variable]: action.payload.data
            }
        },
        setFetchingAnalyticalData: (state, action: PayloadAction<boolean>): void => {
            state.fetching = action.payload
        },
        loadAnalyticalData: (state, action: PayloadAction<{ epoch: number, variable: string }>): void => {
            state.fetching = true
        }
    },
})

export const { loadAnalyticalData, setFetchingAnalyticalData, storeAnalyticalData } = analyticalDataSlice.actions

export const selectAnalyticalData = (state: RootState): { [key: string]: IPredictionByHorizonData[] } => state.analyticalData.analyticalData
export const selectFetchingAnalyticalData = (state: RootState): boolean => state.analyticalData.fetching

export default analyticalDataSlice.reducer
