import styled from '@emotion/styled'

export const RpName = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  background-color: #09254c;
  font-family: AimsunBold,sans-serif,serif;
  font-weight: bold;
  font-size: 1.5em;
  padding: 14px 12px 10px;
  border-radius: 3px;
  margin: 20px;
  box-shadow: 0 2px 1px -1px #000;
`

export const RpNameIntoProgress = styled.div`
  color: #fff;
  font-family: AimsunBold,sans-serif,serif;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  margin-bottom: 12px;
`
