import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface IRiskPredictionDataState {
    riskPredictionData: IPredictionByHorizonData[]
}

const initialRiskPredictionDataState: IRiskPredictionDataState = {
    riskPredictionData: [],
}

export const riskPredictionDataSlice = createSlice({
    name: 'riskPredictionData',
    initialState: initialRiskPredictionDataState,
    reducers: {
        storeRiskPredictionData: (state, action: PayloadAction<{ data: IPredictionByHorizonData[]}>): void => {
            state.riskPredictionData = action.payload.data
        },
        loadRiskPredictionData: (): void => {}
    },
})

export const { loadRiskPredictionData, storeRiskPredictionData } = riskPredictionDataSlice.actions

export const selectRiskPredictionData = (state: RootState): IPredictionByHorizonData[] => state.riskPredictionData.riskPredictionData

export default riskPredictionDataSlice.reducer
