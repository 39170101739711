import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'


export const initialMapHorizonSelected: IMapHorizon = {
    id: 'default',
    name: 'default',
    pattern_id: 4,
}

const mapHorizonSelectedSlice = createSlice({
    name: 'mapHorizonSelected',
    initialState: initialMapHorizonSelected,
    reducers: {
        setMapHorizonSelected: (state, action: PayloadAction<IMapHorizon>): IMapHorizon => {
            return action.payload
        },
    },
})

export const { setMapHorizonSelected } = mapHorizonSelectedSlice.actions

export const selectMapHorizonSelected = (state: RootState) => state.mapHorizonSelected

export default mapHorizonSelectedSlice.reducer
