import { all, AllEffect, call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { loadSpeedRecommendationData, storeSpeedRecommendationData } from './speedRecommendationDataSlice'
import { selectConfig } from '../coreSlice'
import { getMarks } from '../../../helpers/SliderMarksHelper'
import { getSpeedRecommendationByHorizon } from '../../../services/mapData/mapDataService'
import { initialEmptyFeatureCollectionState } from '../../mapbox/mapboxSlice'

export function* initSpeedRecommendationData(action: any) {
    yield call(fetchSpeedRecommendationData, action.payload)
}

function* fetchSpeedRecommendationData({ epoch }: { epoch: number; variable: string }): any {const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const step: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const marks = getMarks(step, numberOfHorizon)

    let speedRecommendation: any = yield all(
        marks.map(horizon => {
            if (horizon.value === 0) {
                return call(getSpeedRecommendationByHorizon, epoch, 0)
            } else {
                return []
            }
        })
    )

    if (speedRecommendation[0].length === 0) {
        speedRecommendation[0] =  initialEmptyFeatureCollectionState
    }


    yield put(storeSpeedRecommendationData({ data: speedRecommendation }))
}

function* speedRecommendationDataSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(loadSpeedRecommendationData, initSpeedRecommendationData)])
}

export default speedRecommendationDataSaga
