import React, { useEffect, useState } from 'react'
import {  useAppSelector } from '../../app/hooks'
import { RpName, RpNameIntoProgress } from './responsePlanEvaluationStyles'
import { selectTaskProgressEvaluationsManage } from '../evaluationsSelector/store/evaluationsManageSlice'
import { Box, CircularProgress, Typography } from '@mui/material'

const initialTask = {
    event_id: '',
    execution_time: 0,
    id: '',
    name: '--',
    pack_id: '',
    parent: '',
    percentage: 10,
    route_id: '',
    rp_id: '',
    sibling: '',
    status: 2
}

const ResponsePlanEvaluationName: React.FC<ResponsePlanEvaluationNameProps> = ({ rpName }: ResponsePlanEvaluationNameProps) => {
    const _tasksEvaluationProgress = useAppSelector(selectTaskProgressEvaluationsManage)

    const [taskRpProgress, setTaskRpProgress] = useState<any>(initialTask)

    useEffect(() => {
        const taskRpProgress = _tasksEvaluationProgress.filter((task: any) => task.rp_id === rpName.toString())
        if (taskRpProgress.length > 0) {
            setTaskRpProgress(taskRpProgress[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_tasksEvaluationProgress, rpName])

    if (taskRpProgress.status === 4) {
        return (
            <RpName>
                <RpNameIntoProgress>{rpName}</RpNameIntoProgress>
                <Typography variant='caption' component='div' color='primary'>
                    {`Failed`}
                </Typography>
            </RpName>
        )
    }else if (taskRpProgress.percentage < 100 && taskRpProgress.status !== 2) {
        return (
            <RpName>
                <RpNameIntoProgress>{rpName}</RpNameIntoProgress>
                <Box position='relative' display='inline-flex'>
                    <CircularProgress size='60px' variant='determinate' thickness={5} value={taskRpProgress.percentage} />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position='absolute'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'>
                        <Typography variant='caption' component='div' color='primary'>
                            {`${Math.round(taskRpProgress.percentage)}%`}
                        </Typography>
                    </Box>
                </Box>
            </RpName>
        )
    } else {
        return  <RpName>{rpName}</RpName>
    }
}

export default ResponsePlanEvaluationName
