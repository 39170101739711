import { Box, styled } from '@mui/material'

export const ViewsToolbarWrapper = styled(Box)`
    height: 70px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    cursor: pointer;
`
