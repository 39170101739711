import { Box, Icon } from '@mui/material'
import styled from '@emotion/styled'
import { BORDER_ZOOM, NAVY_BLUE, WHITE } from '../../theme'

export const ZoomContainer = styled(Box)`
    width: 35px;
    background-color: ${NAVY_BLUE};
    box-shadow: 0 0 12px 6px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    color: ${WHITE};
    z-index: 11;
    padding: 10px 10px 7px;
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    margin-right: 6px;
    pointer-events: auto;
`

export const ZoomIcon = styled(Icon)`
    position: relative;
    right: 5px;
    height: 30px;

    &:hover {
        cursor: pointer;
    }

    &:first-of-type {
        border-bottom: 1px solid ${BORDER_ZOOM};
    }

    &:last-of-type {
        padding-bottom: 5px;
    }
`
