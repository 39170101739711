import React, { useEffect, useState } from 'react'
import CustomDialog from '../../../components/customDialog/customDialog'
import {
    Alert,
    Container,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    IconButton,
    Radio,
    Slide, Snackbar,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import SearchTermComponent from '../../../components/searchTermComponent/SearchTermComponent'
import {
    TableCellContentStyled,
    TableCellHeaderStyled,
    TableCellHeaderTypography,
    TableHeadStyled,
    TableRowContent,
} from '../../../components/tableStyles/tabletyles'
import { DateTime } from 'luxon'
import { getResponsePlansInfoTable } from './evaluationSelctorDialogHelper'
import {
    EvaluationsDialogButton,
    EvaluationsDialogButtonsWrapper,
    SearchTermWrapperEvaluationDialog,
} from './evaluationSelctorDialogStyles'
import { TransitionProps } from '@mui/material/transitions'
import {
    deleteEvaluation,
    selectRemovedEvaluationsManage,
    setStaticEvaluationManageRemoved,
} from '../store/evaluationsManageSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { PulseLoader } from 'react-spinners'
import { loadEvaluations } from '../store/evaluationsSlice'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const EvaluationSelectorDialog = ({
    open,
    onClose,
    dateFormat,
    setSelectedEvaluation,
    evaluations,
    selectedEvaluation,
    timeZone,
}: EvaluationSelectorDialogProps) => {
    const _evaluationIsRemoved:boolean = useAppSelector(selectRemovedEvaluationsManage)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [evaluationSelected, setEvaluationSelected] = useState<number>(selectedEvaluation)
    const [disableLoadButton, setDisableLoadButton] = useState<boolean>(true)
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [evaluationToDelete, setEvaluationToDelete] = useState<IEvaluationStatic | null>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false)
    const [feedbackMessage, setFeedbackMessage] = useState<string>('')

    const dispatch = useAppDispatch()

    useEffect(() => {
        setEvaluationSelected(selectedEvaluation)
    }, [selectedEvaluation])

    useEffect(() => {
        if(_evaluationIsRemoved) {
            dispatch(loadEvaluations())
            setTimeout(() => {
                if (evaluationToDelete && evaluationToDelete.id === evaluationSelected){
                    setDisableLoadButton(true)
                    setEvaluationSelected(selectedEvaluation)
                }
                setDeleting(false)
                setOpenDeleteDialog(false)
                setEvaluationToDelete(null)
                setFeedbackOpen(true)
                setFeedbackMessage(`Evaluation: ${evaluationToDelete?.name} was successfully removed.`)
                dispatch(setStaticEvaluationManageRemoved(false))
            }, 3000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_evaluationIsRemoved])

    const handleSelectEvaluation = () => {
        setSearchTerm('')
        if (evaluationSelected !== -1) {
            setEvaluationSelected(evaluationSelected)
            setSelectedEvaluation(evaluationSelected)
        }
        onClose()
        setDisableLoadButton(true)
    }

    const handleClose = () => {
        setSearchTerm('')
        setEvaluationSelected(selectedEvaluation)
        onClose()
        setDisableLoadButton(true)
    }

    const handleDeleteEvaluationDialog = (evaluation: any) => {
        setOpenDeleteDialog(true)
        setEvaluationToDelete(evaluation)
    }

    const handleDeleteEvaluation = () => {
        if (evaluationToDelete) {
            setDeleting(true)
            dispatch(deleteEvaluation(evaluationToDelete))
        }
    }

    const handleDeleteDialogClose = () => {
        setEvaluationToDelete(null)
        setOpenDeleteDialog(false)
    }

    const searchTermChange = (searchTerm: string) => {
        setSearchTerm(searchTerm.toLowerCase())
    }

    const checkBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDisableLoadButton(false)
        setEvaluationSelected(Number(event.target.value))
    }

    const handleFeedBackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setFeedbackOpen(false)
        setFeedbackMessage('')
    }

    return (
        <CustomDialog
            handleClose={handleClose}
            title={`Select one evaluation`}
            open={open}
            fullScreen={false}
            maxWidth={'xl'}>
            <SearchTermWrapperEvaluationDialog>
                <SearchTermComponent
                    searchTermChange={searchTermChange}
                    iconColor='inherit'
                    startAdornment={true}
                    placeholder={'Search Kpi by name, horizon or unit'}
                    disableUnderline={false}
                />
            </SearchTermWrapperEvaluationDialog>
            <TableContainer component={Container} sx={{ marginBottom: '30px', maxHeight: '50vh' }}>
                <Table aria-label='response plans table' stickyHeader key='response plans table'>
                    <TableHeadStyled>
                        <TableRow>
                            <TableCellHeaderStyled align='center'>
                                <TableCellHeaderTypography>ID</TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                            <TableCellHeaderStyled align='left'>
                                <TableCellHeaderTypography>Name</TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                            <TableCellHeaderStyled align='left'>
                                <TableCellHeaderTypography>Response plans included</TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                            <TableCellHeaderStyled align='left'>
                                <TableCellHeaderTypography>Time</TableCellHeaderTypography>
                            </TableCellHeaderStyled>
                            <TableCellHeaderStyled align='left'>
                            </TableCellHeaderStyled>
                        </TableRow>
                    </TableHeadStyled>
                    <TableBody className='table__body'>
                        {evaluations?.filter(evaluation => evaluation.epoch !== null)
                        .filter(evaluation => {
                            const { id, name, epoch } = evaluation
                            const date = DateTime.fromMillis(epoch, { zone: timeZone }).toFormat(dateFormat)
                            const isoDate = DateTime.fromMillis(epoch, { zone: timeZone }).toISODate()

                            return (
                                date.includes(searchTerm) ||
                                (isoDate && isoDate.includes(searchTerm)) ||
                                id.toString().includes(searchTerm) ||
                                name.toLowerCase().includes(searchTerm)
                            )
                        })
                        .sort((curr, next) => next.epoch - curr.epoch)
                        .map((evaluation, index) => {
                            const { id, name, epoch, responsePlans } = evaluation

                            let responsePlanInfo: string = responsePlans ? getResponsePlansInfoTable(responsePlans) : ''
                            return (
                                <TableRowContent
                                    key={id}
                                    className={index % 2 === 0 ? 'table__row--even' : 'table__row--odd'}>
                                    <TableCellContentStyled align='left' key='id'>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={id === evaluationSelected}
                                                    onChange={checkBoxChange}
                                                    value={id}
                                                    color='primary'
                                                />
                                            }
                                            label={id}
                                        />
                                    </TableCellContentStyled>
                                    <TableCellContentStyled align='left' key='name'>
                                        <Typography variant='body1'>{name || 'not set'}</Typography>
                                    </TableCellContentStyled>
                                    <TableCellContentStyled align='left' key='response_plans'>
                                        <Typography variant='body1'>{responsePlanInfo}</Typography>
                                    </TableCellContentStyled>
                                    <TableCellContentStyled key='time'>
                                        <Typography variant='body1'>
                                            {DateTime.fromMillis(epoch, { zone: timeZone }).toFormat(
                                                dateFormat
                                            )}
                                        </Typography>
                                    </TableCellContentStyled>
                                    <TableCellContentStyled key='delete'>
                                        <IconButton aria-label="delete" disabled={id === selectedEvaluation} color="primary" onClick={() => handleDeleteEvaluationDialog(evaluation)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCellContentStyled>
                                </TableRowContent>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <EvaluationsDialogButtonsWrapper>
                <EvaluationsDialogButton onClick={handleClose} variant='text'>
                    <Typography variant='button' color='primary'>
                        Cancel
                    </Typography>
                </EvaluationsDialogButton>
                <EvaluationsDialogButton onClick={() => handleSelectEvaluation()} variant='contained' disabled={disableLoadButton}>
                    <Typography variant='button'>Load</Typography>
                </EvaluationsDialogButton>
            </EvaluationsDialogButtonsWrapper>
            <CustomDialog
                open={openDeleteDialog}
                title={`Delete evaluation: ${evaluationToDelete?.name}`}
                transition={Transition}
                handleClose={handleDeleteDialogClose}
                fullScreen={false}
                maxWidth={'sm'}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {`Are you sure to delete the evaluation: ${evaluationToDelete?.name}?`}
                        <br/>
                        {'This action has no turning back.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <EvaluationsDialogButtonsWrapper>
                        <EvaluationsDialogButton onClick={handleDeleteDialogClose} variant='text'>
                            <Typography variant='button' color='primary'>
                                {'Cancel'}
                            </Typography>
                        </EvaluationsDialogButton>
                        <EvaluationsDialogButton onClick={() => deleting ? {} : handleDeleteEvaluation()} variant='contained'>
                            {deleting ? (
                                <Typography variant='button'>
                                    <PulseLoader size={8} margin={2} color='#FFF'></PulseLoader>
                                </Typography>
                            ) : (
                                <Typography variant='button'>{'Delete'}</Typography>
                            )}
                        </EvaluationsDialogButton>
                    </EvaluationsDialogButtonsWrapper>
                </DialogActions>
            </CustomDialog>
            <Snackbar
                open={feedbackOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={handleFeedBackClose}>
                <Alert onClose={handleClose} severity={'info'} sx={{ width: '100%' }}>
                    {feedbackMessage}
                </Alert>
            </Snackbar>
        </CustomDialog>
    )
}

export default EvaluationSelectorDialog
