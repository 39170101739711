import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { getPatternQualityColor } from './patternHelper'
import { PatternTitle, PatternContainer, StyledBarLoader, PatterWrapper, StyledBox } from './patternStyles'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { loadPattern, selectFetchingPattern, selectPattern } from './store/patternSlice'
import { selectEpoch } from '../core/coreSlice'
import { useTranslation } from 'react-i18next'

const Pattern: React.FC<IPatternProps> = ({ showName }: IPatternProps) => {
    const { t } = useTranslation()
    const _pattern: IPatternState = useAppSelector(selectPattern)
    const _fetchingPattern: boolean = useAppSelector(selectFetchingPattern)
    const _epoch: number = useAppSelector(selectEpoch)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (_epoch !== 0) {
            dispatch(loadPattern(_epoch))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_epoch])
    const qualityColor = getPatternQualityColor(_pattern.quality)

    return (
        <PatterWrapper>
            {!_fetchingPattern && (
                <>
                    <StyledBox qualitycolor={qualityColor} title={`${_pattern.quality?.toFixed()}%`} />
                    <PatternContainer>
                        <PatternTitle>{t('pattern.pattern')}</PatternTitle>
                        <Typography variant={'body1'}>{showName ? `${_pattern.id} - ${_pattern.name}` : _pattern.id}</Typography>
                    </PatternContainer>
                </>
            )}
            {_fetchingPattern && <StyledBarLoader height={5} width={30} />}
        </PatterWrapper>
    )
}

export default Pattern
