import { getRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'

export function getTasks(): Promise<AxiosResponse<any>> {
    return getRequestAPI(`/api_tasks/rpem_tasks`, { cache: false })
}

export function getTaskProgressByEpoch(pack_id: number): Promise<AxiosResponse<any>> {
    return getRequestAPI(`/api_tasks/rpem_tasks?pack_id=${pack_id}`, { cache: false })
}
