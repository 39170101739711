import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'

const initialStateLayerVisibility: IMapContainerLayers = {
    0: {
        detectors: true,
        motorways: true,
        suburban: true,
        urban_1: true,
        urban_2: true,
        local: true,
        centroid: true,
        orthophoto: false,
        detected: true,
        reported: true,
        external: true,
        test: true,
        real: true,
        traffic_actions: true,
        subpaths: false,
        nodes: true,
    },
    1: {
        detectors: true,
        motorways: true,
        suburban: true,
        urban_1: true,
        urban_2: true,
        local: true,
        centroid: true,
        orthophoto: false,
        detected: true,
        reported: true,
        external: true,
        test: true,
        real: true,
        traffic_actions: true,
        subpaths: false,
        nodes: false,
    },
    2: {
        detectors: true,
        motorways: true,
        suburban: true,
        urban_1: true,
        urban_2: true,
        local: true,
        centroid: true,
        orthophoto: false,
        detected: true,
        reported: true,
        external: true,
        test: true,
        real: true,
        traffic_actions: true,
        subpaths: false,
        nodes: false,
    },
    3: {
        detectors: true,
        motorways: true,
        suburban: true,
        urban_1: true,
        urban_2: true,
        local: true,
        centroid: true,
        orthophoto: false,
        detected: true,
        reported: true,
        external: true,
        test: true,
        real: true,
        traffic_actions: true,
        subpaths: false,
        nodes: false,
    },
}

const initialStateNoSectionsLayerVisibility: IMapContainerLayers = {
    0: {
        detectors: true,
        motorways: false,
        suburban: false,
        urban_1: false,
        urban_2: false,
        local: false,
        centroid: false,
        orthophoto: false,
        detected: false,
        reported: false,
        external: false,
        test: false,
        real: false,
        traffic_actions: false,
        subpaths: false,
        nodes: true,
    },
    1: {
        detectors: true,
        motorways: false,
        suburban: false,
        urban_1: false,
        urban_2: false,
        local: false,
        centroid: false,
        orthophoto: false,
        detected: false,
        reported: false,
        external: false,
        test: false,
        real: false,
        traffic_actions: false,
        subpaths: false,
        nodes: false,
    },
    2: {
        detectors: true,
        motorways: false,
        suburban: false,
        urban_1: false,
        urban_2: false,
        local: false,
        centroid: false,
        orthophoto: false,
        detected: false,
        reported: false,
        external: false,
        test: false,
        real: false,
        traffic_actions: false,
        subpaths: false,
        nodes: false,
    },
    3: {
        detectors: true,
        motorways: false,
        suburban: false,
        urban_1: false,
        urban_2: false,
        local: false,
        centroid: false,
        orthophoto: false,
        detected: false,
        reported: false,
        external: false,
        test: false,
        real: false,
        traffic_actions: false,
        subpaths: false,
        nodes: false,
    },
}

const initialStateNetworkTile: INetworkTile = {
    available: false,
    maxzoom: -1,
    minzoom: -1,
    bounds: [-1, -1, -1, -1],
    mapHorizon: 'default',
}

const initialStateNetwork: INetwork = {
    centroid: initialStateNetworkTile,
    detectors: initialStateNetworkTile,
    motorways: initialStateNetworkTile,
    suburban: initialStateNetworkTile,
    urban_1: initialStateNetworkTile,
    urban_2: initialStateNetworkTile,
    local: initialStateNetworkTile,
    public_transport: initialStateNetworkTile,
    subpaths: initialStateNetworkTile,
    nodes: initialStateNetworkTile,
}

const initialDataByMap = {
    stationData: [],
    sectionData: [],
    mapId: 0,
    viewModeId: 1,
    stationNowData: [],
    stationFutureData: [],
    sectionNowData: [],
    sectionFutureData: [],
    riskPrediction: { type: 'FeatureCollection', features: [] },
    rpActions: { type: 'FeatureCollection', features: [] },
    riskPredictionData: [],
    speedRecommendation: { type: 'FeatureCollection', features: [] },
    speedRecommendationData: [],
    evaluation: undefined,
    fetchingData: true,
    qm: false,
}

export const initialEmptyFeatureCollectionState: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: [],
}

const initialIncidentsState: IMapboxState['incidents'] = {
    external: { type: 'FeatureCollection', features: [] },
    detected: { type: 'FeatureCollection', features: [] },
    test: { type: 'FeatureCollection', features: [] },
    real: { type: 'FeatureCollection', features: [] },
}
const initialEventsState: IMapboxState['events'] = {
    test: { type: 'FeatureCollection', features: [] },
    real: { type: 'FeatureCollection', features: [] },
}

const initialState: IMapboxState = {
    selected: 0,
    bundle: [],
    style: 'mapbox://styles/adrianraimsun/cllpatrpd005501pj35qm56l4',
    token: 'pk.eyJ1IjoiYWRyaWFucmFpbXN1biIsImEiOiJjand3NHExZmcwZXNqNDNyenQ0cWI2MWcwIn0.Li3ROjBKONh2qOxScL6Tfw',
    fetching: false,
    network: initialStateNetwork,
    incidents: initialIncidentsState,
    events: initialEventsState,
    actions: initialEmptyFeatureCollectionState,
    rpActions: {},
    viewState: null,
    layers_visibility: initialStateLayerVisibility,
    dataByMap: [initialDataByMap],
    showTrafficActionNetwork: false,
}

export const mapboxSlice = createSlice({
    name: 'mapbox',
    initialState,
    reducers: {
        enableNetworkAssets: (
            state: IMapboxState,
            action: PayloadAction<{ networks: string; mapHorizon: string }>
        ): void => {
            state.fetching = true
        },
        loadActions: (state: IMapboxState, action: PayloadAction): void => {
            //state.loading = true
        },
        storeActions: (state: IMapboxState, action: PayloadAction<GeoJSON.FeatureCollection>): void => {
            state.actions = action.payload
            const mapDataIndexByMapId = state.dataByMap.findIndex(dataMap => dataMap.mapId === 0)
            if (mapDataIndexByMapId !== -1) {
                state.dataByMap[mapDataIndexByMapId] = {
                    ...state.dataByMap[mapDataIndexByMapId],
                    rpActions: action.payload,
                }
            }
        },
        restoreActions: (state: IMapboxState): void => {
            state.actions = initialEmptyFeatureCollectionState
            const mapDataIndexByMapId = state.dataByMap.findIndex(dataMap => dataMap.mapId === 0)
            if (mapDataIndexByMapId !== -1) {
                state.dataByMap[mapDataIndexByMapId] = {
                    ...state.dataByMap[mapDataIndexByMapId],
                    rpActions: initialEmptyFeatureCollectionState,
                }
            }
        },
        storeRpActions: (
            state: IMapboxState,
            action: PayloadAction<{ rp: number; actions: GeoJSON.FeatureCollection }>
        ): void => {
            state.rpActions = {
                ...state.rpActions,
                [action.payload.rp]: {
                    actions: action.payload.actions,
                },
            }
        },
        restoreRpActions: (state: IMapboxState, action: PayloadAction): void => {
            const numberOfMaps = state.dataByMap.length
            for (let i = 0; i < numberOfMaps; i++) {
                state.dataByMap[i].rpActions = initialEmptyFeatureCollectionState
            }
        },
        loadIncidents: (state: IMapboxState, action: PayloadAction<{ epoch: number; type: string }>): void => {
            //state.loading = true
        },
        storeExternalIncidents: (state: IMapboxState, action: PayloadAction<GeoJSON.FeatureCollection>): void => {
            state.incidents.external = action.payload
        },
        storeDetectedIncidents: (state: IMapboxState, action: PayloadAction<GeoJSON.FeatureCollection>): void => {
            state.incidents.detected = action.payload
        },
        storeTestIncidents: (state: IMapboxState, action: PayloadAction<GeoJSON.FeatureCollection>): void => {
            state.incidents.test = action.payload
        },
        storeRealIncidents: (state: IMapboxState, action: PayloadAction<GeoJSON.FeatureCollection>): void => {
            state.incidents.real = action.payload
        },
        storeTestEvents: (state: IMapboxState, action: PayloadAction<GeoJSON.FeatureCollection>): void => {
            state.events.test = action.payload
        },
        storeRealEvents: (state: IMapboxState, action: PayloadAction<GeoJSON.FeatureCollection>): void => {
            state.events.real = action.payload
        },
        storeDetectors: (state: IMapboxState, action: PayloadAction<INetworkTile>): void => {
            state.network.detectors = action.payload
        },
        storeMotorways: (state: IMapboxState, action: PayloadAction<INetworkTile>): void => {
            state.network.motorways = action.payload
        },
        storeSuburban: (state: IMapboxState, action: PayloadAction<INetworkTile>): void => {
            state.network.suburban = action.payload
        },
        storeUrban1: (state: IMapboxState, action: PayloadAction<INetworkTile>): void => {
            state.network.urban_1 = action.payload
        },
        storeUrban2: (state: IMapboxState, action: PayloadAction<INetworkTile>): void => {
            state.network.urban_2 = action.payload
        },
        storeLocal: (state: IMapboxState, action: PayloadAction<INetworkTile>): void => {
            state.network.local = action.payload
        },
        storePublicTransport: (state: IMapboxState, action: PayloadAction<INetworkTile>): void => {
            state.network.public_transport = action.payload
        },
        storeCentroid: (state: IMapboxState, action: PayloadAction<INetworkTile>): void => {
            state.network.centroid = action.payload
        },
        storeSubpaths: (state: IMapboxState, action: PayloadAction<INetworkTile>): void => {
            state.network.subpaths = action.payload
        },
        storeNodes: (state: IMapboxState, action: PayloadAction<INetworkTile>): void => {
            state.network.nodes = action.payload
        },
        storeBundle: (state: IMapboxState, action: PayloadAction<any>): void => {
            state.bundle = action.payload
        },
        setSelectedMapbox: (state: IMapboxState, action: PayloadAction<number>): void => {
            state.selected = action.payload
        },
        updateViewState: (state: IMapboxState, action: PayloadAction<any>): void => {
            state.viewState = action.payload
        },
        toggleAnaLayerVisibility: (state: IMapboxState, action: PayloadAction<string>): void => {
            state.layers_visibility =
                action.payload === 'enabled' ? initialStateLayerVisibility : initialStateNoSectionsLayerVisibility
        },
        storeLayerVisibility: (state: IMapboxState, action: PayloadAction<IMapContainerLayers>): void => {
            state.layers_visibility = action.payload
        },
        restoreLayerVisibility: (state: IMapboxState): void => {
            state.layers_visibility = initialStateLayerVisibility
            state.network = initialStateNetwork
        },
        restoreIncidents: (state: IMapboxState): void => {
            state.incidents = initialIncidentsState
        },
        restoreEvents: (state: IMapboxState): void => {
            state.events = initialEventsState
        },
        toggleDetectors: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const detectors_visibility = state.layers_visibility[id].detectors
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    detectors: !detectors_visibility,
                },
            }
        },
        setVisibilityDetectors: (state: IMapboxState, action: PayloadAction<any>): void => {
            const id: number = action.payload.mapId
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    detectors: action.payload.detectors_visibility,
                },
            }
        },
        toggleMotorways: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const motorways_visibility = state.layers_visibility[id].motorways
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    motorways: !motorways_visibility,
                },
            }
        },
        toggleSuburban: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const suburban_visibility = state.layers_visibility[id].suburban
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    suburban: !suburban_visibility,
                },
            }
        },
        toggleUrban1: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const urban_1_visibility = state.layers_visibility[id].urban_1
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    urban_1: !urban_1_visibility,
                },
            }
        },
        toggleUrban2: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const urban_2_visibility = state.layers_visibility[id].urban_2
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    urban_2: !urban_2_visibility,
                },
            }
        },
        toggleLocal: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const local_visibility = state.layers_visibility[id].local
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    local: !local_visibility,
                },
            }
        },
        toggleOrthophoto: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const orthophoto_visibility = state.layers_visibility[id].orthophoto
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    orthophoto: !orthophoto_visibility,
                },
            }
        },
        toggleCreateTrafficActionNetwork: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const subpaths_visibility = state.layers_visibility[id].subpaths
            const motorways_visibility = state.layers_visibility[id].motorways
            const suburban_visibility = state.layers_visibility[id].suburban
            const urban_1_visibility = state.layers_visibility[id].urban_1
            const urban_2_visibility = state.layers_visibility[id].urban_2
            const local_visibility = state.layers_visibility[id].local
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    subpaths: !subpaths_visibility,
                    motorways: !motorways_visibility,
                    suburban: !suburban_visibility,
                    urban_1: !urban_1_visibility,
                    urban_2: !urban_2_visibility,
                    local: !local_visibility,
                },
            }
            state.showTrafficActionNetwork = !state.showTrafficActionNetwork
        },
        toggleCentroid: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const centroid_visibility = state.layers_visibility[id].centroid
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    centroid: !centroid_visibility,
                },
            }
        },
        setVisibilitySections: (state: IMapboxState, action: PayloadAction<any>): void => {
            const id: number = action.payload.mapId
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    local: action.payload.sections_visibility,
                    urban_2: action.payload.sections_visibility,
                    urban_1: action.payload.sections_visibility,
                    suburban: action.payload.sections_visibility,
                    motorways: action.payload.sections_visibility,
                },
            }
        },
        toggleDetectedIncidents: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const detected_visibility = state.layers_visibility[id].detected
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    detected: !detected_visibility,
                },
            }
        },
        toggleReportedIncidents: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const reported_visibility = state.layers_visibility[id].reported
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    reported: !reported_visibility,
                },
            }
        },
        toggleExternalIncidents: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const external_visibility = state.layers_visibility[id].external
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    external: !external_visibility,
                },
            }
        },
        toggleTestEvents: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const test_visibility = state.layers_visibility[id].test
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    test: !test_visibility,
                },
            }
        },
        toggleRealEvents: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const real_visibility = state.layers_visibility[id].real
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    real: !real_visibility,
                },
            }
        },
        toggleActions: (state: IMapboxState, action: PayloadAction<number>): void => {
            const id: number = action.payload
            const traffic_actions_visibility = state.layers_visibility[id].traffic_actions
            state.layers_visibility = {
                ...state.layers_visibility,
                [id]: {
                    ...state.layers_visibility[id],
                    traffic_actions: !traffic_actions_visibility,
                },
            }
        },
        storeDataByMap: (state: IMapboxState, action: PayloadAction<any>): void => {
            state.dataByMap = action.payload
        },
        storeFetchingDataByMap: (state: IMapboxState, action: PayloadAction<any>): void => {
            const mapDataIndexByMapId = state.dataByMap.findIndex(dataMap => dataMap.mapId === action.payload)
            if (mapDataIndexByMapId !== -1) {
                state.dataByMap[mapDataIndexByMapId].fetchingData = true
            }
        },
        storeFinishFetchingDataByMap: (state: IMapboxState, action: PayloadAction<any>): void => {
            const mapDataIndexByMapId = state.dataByMap.findIndex(dataMap => dataMap.mapId === action.payload)
            if (mapDataIndexByMapId !== -1) {
                state.dataByMap[mapDataIndexByMapId].fetchingData = false
            }
        },
        restoreDataByMap: (state: IMapboxState): void => {
            const numberOfMaps = state.dataByMap.length
            const nextDataByMap = []
            for (let i = 0; i < numberOfMaps; i++) {
                nextDataByMap.push({
                    ...initialDataByMap,
                    mapId: i,
                })
            }
            state.dataByMap = nextDataByMap
        },
        storeMapboxData: (state: IMapboxState, action: PayloadAction<IStoreMapboxData>): void => {
            const mapDataIndexByMapId = state.dataByMap.findIndex(dataMap => dataMap.mapId === action.payload.mapId)
            const resetMapboxData = {
                stationData: action.payload.stationData,
                sectionData: action.payload.sectionData,
                mapId: action.payload.mapId,
                viewModeId: action.payload.viewModeId,
                stationNowData: action.payload.stationNowData,
                stationFutureData: action.payload.stationFutureData,
                sectionNowData: action.payload.sectionNowData,
                sectionFutureData: action.payload.sectionFutureData,
                riskPrediction: action.payload.riskPrediction,
                riskPredictionData: action.payload.riskPredictionData,
                speedRecommendation: action.payload.speedRecommendation,
                speedRecommendationData: action.payload.speedRecommendationData,
                evaluation: action.payload.evaluation,
                fetchingData: false,
                rpActions: action.payload.rpActions ? action.payload.rpActions : [],
                qm: action.payload.qm,
            }
            if (mapDataIndexByMapId !== -1) {
                state.dataByMap[mapDataIndexByMapId] = resetMapboxData
            } else {
                state.dataByMap.push(resetMapboxData)
            }
        },
    },
})

export const {
    storeBundle,
    setSelectedMapbox,
    enableNetworkAssets,
    loadActions,
    storeActions,
    restoreActions,
    storeRpActions,
    restoreRpActions,
    loadIncidents,
    storeDetectors,
    storeMotorways,
    storeSuburban,
    storeUrban1,
    storeUrban2,
    storeLocal,
    storePublicTransport,
    storeCentroid,
    storeExternalIncidents,
    storeDetectedIncidents,
    storeRealIncidents,
    storeTestIncidents,
    storeRealEvents,
    storeTestEvents,
    setVisibilityDetectors,
    setVisibilitySections,
    toggleAnaLayerVisibility,
    toggleDetectors,
    toggleMotorways,
    toggleSuburban,
    toggleUrban1,
    toggleUrban2,
    toggleLocal,
    toggleCentroid,
    toggleOrthophoto,
    toggleDetectedIncidents,
    toggleReportedIncidents,
    toggleExternalIncidents,
    toggleTestEvents,
    toggleRealEvents,
    toggleActions,
    storeDataByMap,
    storeFetchingDataByMap,
    storeFinishFetchingDataByMap,
    restoreDataByMap,
    storeMapboxData,
    restoreLayerVisibility,
    restoreIncidents,
    restoreEvents,
    storeNodes,
    storeSubpaths,
    toggleCreateTrafficActionNetwork,
    storeLayerVisibility,
} = mapboxSlice.actions

export const selectMapboxToken = (state: RootState): string => state.mapbox.token
export const selectStyle = (state: RootState): string => state.mapbox.style
export const selectSelected = (state: RootState): number => state.mapbox.selected
export const selectNetwork = (state: RootState): INetwork => state.mapbox.network
export const selectVisibility = (state: RootState): IMapContainerLayers => state.mapbox.layers_visibility
export const selectViewState = (state: RootState): any => state.mapbox.viewState
export const selectTrafficActions = (state: RootState): any => state.mapbox.actions
export const selectTrafficActionsNetworkFlag = (state: RootState): boolean => state.mapbox.showTrafficActionNetwork
export const selectResponsePlanActions = (state: RootState): any => state.mapbox.rpActions
export const selectIncidents = (state: RootState): any => state.mapbox.incidents
export const selectEvents = (state: RootState): any => state.mapbox.events
export const selectAllMapData = (state: RootState): any => state.mapbox.dataByMap
export const selectMapboxData = (state: RootState, mapId: number) => {
    const dataByMapID = state.mapbox.dataByMap.find(mapData => mapData.mapId === mapId)
    return dataByMapID ? dataByMapID : state.mapbox.dataByMap[0]
}

export default mapboxSlice.reducer
