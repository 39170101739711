import React, { useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
    selectConfig,
    selectEpoch,
    setStatus,
    selectTimeZone,
    storeEpoch,
    selectStatus,
    updateNowTime,
    selectNowTime,
    storeModuleName,
} from '../../features/core/coreSlice'
import Navigator from '../../features/navigator/Navigator'
import { StyledDivider } from '../../components'
import Clock from '../../features/clock/clock'
import { ToolbarContainer } from '../../components/toolbarComponent/toolbarComponentStyles'
import Sidebar from '../../features/sidebar/sidebar'
import {
    loadIncidents,
    enableNetworkAssets,
    setSelectedMapbox,
    storeMapboxData,
    storeActions,
    initialEmptyFeatureCollectionState,
    restoreRpActions,
} from '../../features/mapbox/mapboxSlice'
import { ContainerWrapper } from '../../components/containerWrapper/containerWrapper'
import EvaluationSelector from '../../features/evaluationsSelector/evaluationsSelector'
import ResponsePlanSelector from '../manageContainer/components/responsePlanSelector/responsePlanSelector'
import KpisComparison from '../manageContainer/components/kpi/kpisComparison/kpiComparison'
import { useSelector } from 'react-redux'
import {
    initialSetSelectedEvaluationState,
    loadEvaluation,
    loadEvaluations,
    newEvaluationSelectedState,
    selectEvaluations,
    selectFetchingEvaluations,
    selectSelectedEvaluation,
    setSelectedEvaluation,
} from '../../features/evaluationsSelector/store/evaluationsSlice'
import { loadRPKpi } from './components/kpi/store/kpisResponsePlansSlice'
import { changeHorizon, storeHorizon } from '../../features/horizonSlider/horizonSilderSlice'
import { getAllViewMode } from '../../helpers/ContainerHelper'
import Logo from '../../features/logo/Logo'
import Pattern from '../../features/pattern/pattern'
import HorizonSlider from '../../features/horizonSlider/horizonSlider'
import { getMarks } from '../../helpers/SliderMarksHelper'
import { MapboxLanUseWrapper } from '../landuseContainer/LandUseContainerStyles'
import MapboxComponent from '../../features/mapbox/mapboxComponent'
import {
    loadManageEvaluationDataByView,
    loadManageEvaluationDataByViewMode,
    loadPastManageEvaluationData,
    taskEvaluationManageProgress,
} from '../../features/evaluationsSelector/store/evaluationsManageSlice'
import NewManageEvaluationDialog from '../../features/evaluationsSelector/newManageDemoEvaluationDialog/newManageEvaluationDialog'
import { getNumberResponsePlanId } from '../../features/evaluationsSelector/newManageDemoEvaluationDialog/newManageEvaluationDialogHelper'
import { restoreEvaluationSimulationData } from '../../features/core/simulationEvaluationData/evaluationSimulationDataSlice'
import ResponsePlanEvaluationName from '../../features/responsePlanEvaluationName/responsePlanEvaluationName'
import { limitResponsePlanIds } from './ManageContainerHelper'

const emptyMapboxData = (mapId: number) => {
    return {
        stationData: [],
        sectionData: [],
        mapId: mapId,
        viewModeId: 1,
        stationNowData: [],
        stationFutureData: [],
        sectionNowData: [],
        sectionFutureData: [],
        riskPrediction: [],
        riskPredictionData: [],
        speedRecommendationData: [],
        speedRecommendation: [],
        qm: false,
    }
}

const ManageDemoContainer: React.FC<IContainerProps> = ({ options }: IContainerProps) => {
    const _epoch: number = useAppSelector(selectEpoch)
    const _nowTime: number = useAppSelector(selectNowTime)
    const _timeZone: string = useSelector(selectTimeZone)
    const _moduleConfig = useAppSelector(selectConfig)
    const _selectedEvaluation: IEvaluationStatic = useSelector(selectSelectedEvaluation)
    const _evaluations: IEvaluationStatic[] = useSelector(selectEvaluations)
    const _fetchingEvaluations: boolean = useSelector(selectFetchingEvaluations)
    const _appStatus: string = useAppSelector(selectStatus)

    const [sidebarOpen, setSidebarOpen] = useState<boolean>(true)
    const [responsePlanEvaluations, setResponsePlanEvaluations] = useState<number[]>([])
    const [newEvaluationIsSelected, setNewEvaluationIsSelected] = useState<boolean>(false)
    const [openNewEvaluationDialog, setOpenNewEvaluationDialog] = useState<boolean>(false)
    const [pastEvaluationSelected, setPastEvaluationSelected] = useState<boolean>(true)
    const [dimensions, setDimensions] = React.useState<{ height: number; width: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const dispatch = useAppDispatch()

    const step: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const marks = getMarks(step, numberOfHorizon, false)
    const containerName = 'manage'
    const allViewModes = getAllViewMode(containerName, _moduleConfig.view_mode, _moduleConfig.modules)

    useEffect(() => {
        window.addEventListener('resize', handleResize, false)
        dispatch(storeModuleName(containerName))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (_appStatus === 'ready') {
            dispatch(storeMapboxData(emptyMapboxData(0)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_appStatus])

    useEffect(() => {
        if (_nowTime !== 0 && _selectedEvaluation.id < 0) {
            storeEpoch(_nowTime)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_nowTime])

    useEffect(() => {
        if (_selectedEvaluation.responsePlans) {
            dispatch(storeEpoch(_selectedEvaluation.epoch))
            setNewEvaluationIsSelected(false)
            setOpenNewEvaluationDialog(false)
            getMapDataByEpoch(_selectedEvaluation.epoch)
            const responsePlansIds = limitResponsePlanIds(getNumberResponsePlanId(_selectedEvaluation.responsePlans))
            setResponsePlanEvaluations(responsePlansIds)
            dispatch(storeActions(initialEmptyFeatureCollectionState))
            regenerateMap()
        } else {
            if (_selectedEvaluation.id === -1) {
                setNewEvaluationIsSelected(false)
                setResponsePlanEvaluations([])
                dispatch(storeMapboxData(emptyMapboxData(0)))
                dispatch(storeMapboxData(emptyMapboxData(1)))
                setOpenNewEvaluationDialog(false)
                dispatch(storeEpoch(_nowTime))
            } else if (_selectedEvaluation.id === -2) {
                dispatch(storeEpoch(_nowTime))
                regenerateMap()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedEvaluation])

    useEffect(() => {
        setStatus('loading')
        dispatch(enableNetworkAssets({ networks: 'sections', mapHorizon: 'default' }))
        dispatch(updateNowTime())
        dispatch(loadEvaluations())
        dispatch(storeHorizon(15))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRPKpis = useCallback(() => {
        dispatch(
            loadRPKpi({
                selectedEvaluation: _selectedEvaluation,
                isStaticMode: true, //demo ManageLiveContainer
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedEvaluation])

    const handleChangeHorizonAndDate = (horizon: number) => {
        dispatch(changeHorizon(horizon))
    }

    const toggleSidebar = (open: boolean): void => {
        setSidebarOpen(!open)
    }

    const handleMapboxSelected = (id: number) => {
        dispatch(setSelectedMapbox(id))
    }

    const handleChangeKPISResponsePlansSelected = (responsePlans: string[]) => {
        const numberResponsePlan = responsePlans.map(rp => Number(rp))
        let nextValue = numberResponsePlan
        if (numberResponsePlan.length > 4) {
            nextValue.shift()
        } else if (numberResponsePlan.length === 0) {
            nextValue = responsePlanEvaluations
        }

        nextValue = nextValue.sort((a, b) => a - b)
        setResponsePlanEvaluations(nextValue)
        loadResponsePlanEvaluations(nextValue)
    }

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    const getMapDataByEpoch = (epoch: number) => {
        dispatch(loadIncidents({ epoch, type: 'real' }))
        dispatch(loadIncidents({ epoch, type: 'test' }))
        dispatch(loadIncidents({ epoch, type: 'external' }))
    }

    const handleEvaluationsChange = (event: number | string) => {
        if (event === -2) {
            setNewEvaluationIsSelected(true)
            setResponsePlanEvaluations([])
            dispatch(setSelectedEvaluation(newEvaluationSelectedState))
            dispatch(storeMapboxData(emptyMapboxData(0)))
            dispatch(storeMapboxData(emptyMapboxData(1)))
            dispatch(taskEvaluationManageProgress({ evaluation: newEvaluationSelectedState, variable: '' }))
            setOpenNewEvaluationDialog(true)
        } else {
            const currentEval = _evaluations.filter(evaluation => evaluation.id === event)[0]
            dispatch(loadEvaluation(currentEval))
            setOpenNewEvaluationDialog(false)
            setNewEvaluationIsSelected(false)
            dispatch(storeHorizon(15))
            dispatch(loadPastManageEvaluationData({ evaluation: currentEval, variable: allViewModes[0].variable }))
            dispatch(restoreRpActions())
            dispatch(taskEvaluationManageProgress({ evaluation: currentEval, variable: allViewModes[0].variable }))
            if (event !== -1) {
                regenerateMap()
            }
        }
        dispatch(restoreEvaluationSimulationData())
    }

    const handleCreateEvaluationDialogCancel = (isCancel: boolean) => {
        if (isCancel) {
            dispatch(setSelectedEvaluation(initialSetSelectedEvaluationState))
        }
    }

    const regenerateMap = () => {
        setPastEvaluationSelected(false)
        setTimeout(() => {
            setPastEvaluationSelected(true)
        }, 100)
    }

    const loadResponsePlanEvaluations = (views: number[]) => {
        views.forEach((optionView: number, index) => {
            dispatch(
                loadManageEvaluationDataByView({
                    allViewMode: allViewModes,
                    viewModeId: allViewModes[0].id,
                    mapId: index,
                    option: optionView,
                })
            )
        })
    }

    const handleResponsePlanChange = (event: number[]) => {
        console.log('DEBUG Change Response Plan => ' + event)
        let nextValue = event
        if (event.length > 4) {
            nextValue.shift()
        } else if (event.length === 0) {
            nextValue = responsePlanEvaluations
        }

        nextValue = nextValue.sort((a, b) => a - b)
        setResponsePlanEvaluations(nextValue)
        loadResponsePlanEvaluations(nextValue)
    }

    const handleChangeViewMode = (
        epoch: number,
        viewModeId: number,
        mapId: number,
        isBaseline: boolean,
        landUseViewOption: string,
        manageViewOption: number
    ) => {
        dispatch(
            loadManageEvaluationDataByViewMode({
                epoch: _selectedEvaluation.epoch,
                allViewMode: allViewModes,
                viewModeId: viewModeId,
                mapId: mapId,
                option: manageViewOption,
                evaluation: _selectedEvaluation,
            })
        )
    }

    const handleNewEvaluationsDialogClose = () => {
        setOpenNewEvaluationDialog(false)
    }

    const layers = {
        network: ['motorways', 'suburban', 'urban', 'local'],
        incidents: ['external', 'incidents', 'test', 'real', 'detected'],
        actions: ['traffic_actions'],
    }
    const mapHeight: number = responsePlanEvaluations.length > 2 ? (dimensions.height - 70) / 2 : dimensions.height - 70
    const windowWidth: number = dimensions.width - (sidebarOpen ? 144 : 66)
    const mapWidth: number = responsePlanEvaluations.length > 1 ? windowWidth / 2 : windowWidth

    const viewport = {
        map1: { height: mapHeight, width: mapWidth },
        map2: { height: mapHeight, width: mapWidth },
        map3: { height: mapHeight, width: responsePlanEvaluations.length === 3 ? windowWidth : windowWidth / 2 },
        map4: { height: mapHeight, width: mapWidth },
    }

    const showArrowProgress = _selectedEvaluation.id === -1
    const isDisabled = _selectedEvaluation.id !== -1

    return (
        <ContainerWrapper>
            <ToolbarContainer>
                <Logo mode={sidebarOpen ? '' : 'compact'} />
                <Navigator config={_moduleConfig} />
                <StyledDivider orientation='vertical' />
                <Clock
                    minutesStep={15}
                    longTimeStep={{ number: 1, unit: 'hours' }}
                    updateDataWithNowTime={true}
                    showArrowProgress={showArrowProgress}
                    isDisabled={isDisabled}
                    allowFuture={true}
                    allowPast={false}
                />
                <StyledDivider orientation='vertical' />
                <EvaluationSelector
                    key='evaluations-selector-container'
                    setEvaluations={handleEvaluationsChange}
                    evaluations={_evaluations}
                    dateFormat={_moduleConfig.date_format.dateTimeLong}
                    disabled={_fetchingEvaluations}
                    timeZone={_timeZone}
                />
                <StyledDivider orientation='vertical' />
                {_selectedEvaluation.id > 0 && (
                    <>
                        <ResponsePlanSelector
                            responsePlanSelected={responsePlanEvaluations}
                            handleResponsePlansChange={handleResponsePlanChange}
                            evaluation={_selectedEvaluation}
                            disabled={_selectedEvaluation.id === -1 || newEvaluationIsSelected}
                        />
                        <StyledDivider orientation='vertical' />
                    </>
                )}
                {_selectedEvaluation.id > 0 && (
                    <>
                        <HorizonSlider
                            isDisabled={_selectedEvaluation.id === -1}
                            horizonValue={0}
                            marks={marks}
                            horizonStep={15}
                            changeHorizonAndDate={handleChangeHorizonAndDate}
                        />
                        <StyledDivider orientation='vertical' />
                    </>
                )}
                <Pattern showName={false} />
                <StyledDivider orientation='vertical' />
                {_selectedEvaluation.id > 0 && (
                    <KpisComparison
                        isDisabled={_selectedEvaluation.id === -1}
                        getRPKpis={getRPKpis}
                        selectedEvaluation={_selectedEvaluation.id.toString()}
                        apmHorizonCount={4}
                        apmHorizonStep={15}
                        responsePlanEvaluations={responsePlanEvaluations}
                        setSelectedResponsePlans={handleChangeKPISResponsePlansSelected}
                    />
                )}
            </ToolbarContainer>
            <Sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} epoch={_epoch} options={options} />
            <MapboxLanUseWrapper marginLeft={sidebarOpen ? 144 : 66}>
                {pastEvaluationSelected && (
                    <MapboxComponent
                        networkLayers={layers.network}
                        actionLayers={layers.actions}
                        incidentLayers={layers.incidents}
                        mapId={0}
                        viewport={viewport.map1}
                        setSelected={handleMapboxSelected}
                        containerName={containerName}
                        showLegend={_selectedEvaluation.id >= 0}
                        changeViewMode={handleChangeViewMode}
                        optionManageView={responsePlanEvaluations[0]}
                        isBaseline={false}>
                        {responsePlanEvaluations && responsePlanEvaluations.length > 0 && (
                            <ResponsePlanEvaluationName rpName={responsePlanEvaluations[0]} />
                        )}
                    </MapboxComponent>
                )}
                {pastEvaluationSelected && responsePlanEvaluations.length > 1 && !newEvaluationIsSelected && (
                    <MapboxComponent
                        networkLayers={layers.network}
                        actionLayers={layers.actions}
                        incidentLayers={layers.incidents}
                        mapId={1}
                        viewport={viewport.map2}
                        setSelected={handleMapboxSelected}
                        containerName={containerName}
                        showLegend={_selectedEvaluation.id >= 0}
                        changeViewMode={handleChangeViewMode}
                        optionManageView={responsePlanEvaluations[1]}
                        isBaseline={false}>
                        <ResponsePlanEvaluationName rpName={responsePlanEvaluations[1]} />
                    </MapboxComponent>
                )}
                {pastEvaluationSelected && responsePlanEvaluations.length > 2 && !newEvaluationIsSelected && (
                    <MapboxComponent
                        networkLayers={layers.network}
                        actionLayers={layers.actions}
                        incidentLayers={layers.incidents}
                        mapId={2}
                        viewport={viewport.map3}
                        setSelected={handleMapboxSelected}
                        containerName={containerName}
                        showLegend={_selectedEvaluation.id >= 0}
                        changeViewMode={handleChangeViewMode}
                        optionManageView={responsePlanEvaluations[2]}
                        isBaseline={false}>
                        <ResponsePlanEvaluationName rpName={responsePlanEvaluations[2]} />
                    </MapboxComponent>
                )}
                {pastEvaluationSelected && responsePlanEvaluations.length > 3 && !newEvaluationIsSelected && (
                    <MapboxComponent
                        networkLayers={layers.network}
                        actionLayers={layers.actions}
                        incidentLayers={layers.incidents}
                        mapId={3}
                        viewport={viewport.map4}
                        setSelected={handleMapboxSelected}
                        containerName={containerName}
                        showLegend={_selectedEvaluation.id >= 0}
                        changeViewMode={handleChangeViewMode}
                        optionManageView={responsePlanEvaluations[3]}
                        isBaseline={false}>
                        <ResponsePlanEvaluationName rpName={responsePlanEvaluations[3]} />
                    </MapboxComponent>
                )}
            </MapboxLanUseWrapper>
            {openNewEvaluationDialog && (
                <NewManageEvaluationDialog
                    open={openNewEvaluationDialog}
                    onClose={handleNewEvaluationsDialogClose}
                    allViewModes={allViewModes}
                    setCreateEvaluationDialogCancel={handleCreateEvaluationDialogCancel}
                />
            )}
        </ContainerWrapper>
    )
}

export default ManageDemoContainer
