import { Button, Card, CardHeader, FormControl, InputLabel, TextField, Typography } from '@mui/material'
import { GREY, NAVY_BLUE, NO_DATA, WHITE } from '../../theme'
import styled from '@emotion/styled'

export const CardHeaderInfoModalStyled = styled(CardHeader)`
  border-bottom: 1px solid ${NO_DATA};
  span {
    font-size: 24px;
    font-weight: bold;
  }
`
export const CentroidInfoModalTitle = styled(Typography)`
  font-family: AimsunMedium,Roboto,Sans-serif,serif;
  font-size: 24px;
  font-weight: bold;
  color: ${NAVY_BLUE};
`

export const CardCentroidInfoModalStyled = styled(Card)`
  padding: 8px 16px;
  position: absolute;
  z-index: 99;
  min-width: 100px;
  margin: auto 0;
  right: 8px;
  top: 8px;
`

export const CentroidInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const ZoneCentroidInfoTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
  font-family: AimsunMedium,Roboto,Sans-serif,serif;
  margin-top: 0;
  margin-bottom: 16px;
`

export const ZoneCentroidInfoText = styled.span`
  font-size: 20px;
  font-weight: normal;
  font-family: AimsunMedium,Roboto,Sans-serif,serif;

`

export const CentroidInfoTitle = styled(ZoneCentroidInfoTitle)`
  margin: 8px 0;
  min-width: 120px;
  text-align: left;
  font-size: 16px;
  width: 100%;
  &.edit {
    width: 50%;
    opacity: 0.5;
  }
`

export const CentroidInfoTitleSpan = styled.span`
  font-size: 16px;
  font-weight: 400;
  font-family: AimsunMedium,Roboto,Sans-serif,serif;
`

export const CentroidInfoTextField = styled(TextField)`
  width: 50%;
  margin: auto 0 auto 8px;
`

export const EvaluationInputDropDownForm = styled(FormControl)`
  width: 70%;
  &.Mui-focused{
    color: inherit;
  }
  .land-use-clock-selector {
    &.selected {
      background-color: ${NAVY_BLUE};
      opacity: 0.6;
    }
    &:after {
      border-bottom-color: inherit;
    }
  }
`

export const EvaluationInputLabel = styled(InputLabel)`
  font-size: 12px;
  color: ${GREY};
  &.Mui-focused{
    color: inherit;
  }
`

export const CentroidInfoLabel = styled(ZoneCentroidInfoTitle)`
  font-size: 12px;
  font-family: AimsunMedium,Roboto,Sans-serif,serif;
  color: ${GREY};
  width: 50%;
  margin: 30px 0 8px 0;
  &:last-of-type {
    margin: 30px 0 8px 8px;
  }
`

export const NameNewEvaluationTextField = styled(TextField)`
  margin: auto 0;
  width: 70%;
  
  .MuiInput-underline {
    &:after, &:before {
      width: 88%;
    }
  }
`

export const RunEvaluationButton = styled(Button)`
  padding: 9px 25px 6px;
  border-radius: 20px;
  margin-left: 20px;
  span {
    text-transform: none;
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    font-family: AimsunMedium,Roboto,Sans-serif,serif;
  }
  &.MuiButton-text {
    &:hover {
      background-color: ${WHITE};
    }
  }
  &:hover {
    opacity: 0.85;
  }
`
export const CentroidButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 32px;
`

export const CentroidButtonsGroupWrapper = styled.div`
  width: 100%;
  margin: 16px auto 0;
`
