import { all, AllEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects'
import { changeHorizon, storeHorizon } from './horizonSilderSlice'
import { initialEmptyFeatureCollectionState, selectAllMapData, storeDataByMap } from '../mapbox/mapboxSlice'
import { selectConfig } from '../core/coreSlice'

export function* changeDataByHorizon(
    action: any
): Generator<
    | SelectEffect
    | PutEffect<{ payload: any; type: 'mapbox/storeDataByMap' }>
    | PutEffect<{ payload: number; type: 'horizon/storeHorizon' }>,
    void,
    IDataByMap[] & IModuleConfig
> {
    const allMapboxData: IDataByMap[] = yield select(selectAllMapData)
    const _moduleConfig: IModuleConfig = yield select(selectConfig)

    const step: number = _moduleConfig['horizon-step']
    let nextDataByMap = new Array(allMapboxData.length)
    allMapboxData.forEach((dataByMap: IDataByMap, index: number) => {
        let stationData: any = dataByMap.stationNowData
        let sectionData: any = dataByMap.sectionNowData
        let riskPrediction: any = initialEmptyFeatureCollectionState
        let speedRecommendation: any = dataByMap.speedRecommendationData.length > 0 ?
            dataByMap.speedRecommendationData[0] : initialEmptyFeatureCollectionState

        if (action.payload > 0) {
            stationData = dataByMap.stationFutureData[action.payload / step] || []
            sectionData = dataByMap.sectionFutureData[action.payload / step] || []
            riskPrediction = dataByMap.riskPredictionData[action.payload / step] || {
                features: [],
                type: 'FeatureCollection',
            }
            speedRecommendation = { features: [], type: 'FeatureCollection' }
        }
        nextDataByMap[index] = {
            ...dataByMap,
            stationData: stationData,
            sectionData: sectionData,
            riskPrediction: riskPrediction,
            speedRecommendation: speedRecommendation
        }
    })
    yield put(storeDataByMap(nextDataByMap))
    yield put(storeHorizon(action.payload))
}

function* horizonSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(changeHorizon, changeDataByHorizon)])
}

export default horizonSaga
