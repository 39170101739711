import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { GREY, TITLE_TOOLBAR } from '../../theme'
import { BarLoader } from 'react-spinners'

export const StyledBarLoader = styled(BarLoader)`
    color: #e9a59d;
`

export const PatternTitle = styled(Typography)`
    color: ${TITLE_TOOLBAR};
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 4px;
`

export const PatternContainer = styled.div`
    text-align: center;
    padding: 4px 8px;
`

export const PatterWrapper = styled.div`
    padding: 0 15px;
    position: relative;
    margin: auto;
`

export const StyledBox = styled(Box)<{ qualitycolor: string }>`
    background-color: ${props => (props.qualitycolor ? props.qualitycolor : GREY)};
    width: 8px;
    height: 8px;
    cursor: inherit;
    border-radius: 5px;
    position: absolute;
    top: 12px;
    right: 8px;
`
