import {
    call,
    CallEffect,
    put,
    PutEffect,
    takeLatest,
} from 'redux-saga/effects'
import { loadPrecodedResponsePlan, setPrecodedResponsePlans } from './precodedResponsePlansSlice'
import { getPrecodedResponsePlan } from '../../../../services/responsePlans/precodedResponsePlansService'
import { AxiosResponse } from 'axios'

function* fetchPrecodedResponsePlans(): Generator<CallEffect<AxiosResponse<IResponsePlan, any>> |
    PutEffect<{payload: IResponsePlan[], type: "precodedResponsePlan/setPrecodedResponsePlans"}>, void, IResponsePlan[]> {
    const precodedResponsePlans: IResponsePlan[] = yield call(getPrecodedResponsePlan)
    yield put(setPrecodedResponsePlans(precodedResponsePlans))

}


function* precodedResponsePlansSaga(): any {
    yield takeLatest(loadPrecodedResponsePlan, fetchPrecodedResponsePlans)
}

export default precodedResponsePlansSaga
