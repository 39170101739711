import * as React from 'react'
import {
    ActionIcon,
    ActionIconActive,
    ActionIconLight,
    ActionIconWhite,
    ActionIconMarker,
    ActionIconMarkerRed,
    EventIcon,
    EventIconLight,
    TestEventIcon,
    EventIconBlue,
    EventIconWhite,
    EventIconNoFill,
    EventIconMarker,
    EventIconMarkerRed,
    EvaluationIcon,
    ResponsePlanIcon,
    SingleMapIcon,
    SingleMapIconActive,
    TwoMapIcon,
    TwoMapIconActive,
    ThreeMapIcon,
    ThreeMapIconActive,
    FourMapIcon,
    FourMapIconActive,
    DetectorsIcon,
    DetectorsIconActive,
    MotorwaysIcon,
    MotorwaysIconActive,
    SuburbanIcon,
    SuburbanIconActive,
    UrbanIcon,
    UrbanIconActive,
    LocalIcon,
    LocalIconActive,
    ExternalEventIconLight,
    ExternalEventIconWhite,
    DetectedEventIcon,
    DetectedEventAffectationIcon,
    DetectedEventIconWhite,
    DetectedEventIconLight,
    ZonesIcon,
    ZonesIconActive,
    aerial_imageryActiveIcon,
    aerial_imageryIcon,
} from '../../app/icons'
import { SvgIcon } from '@mui/material'

const CustomIcon: React.FC<{
    name: string
    color?: 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error'
    style?: object
}> = ({ name, color = 'inherit', style = { width: '20px', height: '20px' } }) => {
    const renderIcon = (name: string) => {
        switch (name) {
            case 'action':
                return ActionIcon
            case 'actionActive':
                return ActionIconActive
            case 'actionBlue':
                return ActionIconActive
            case 'actionLight':
                return ActionIconLight
            case 'actionWhite':
                return ActionIconWhite
            case 'actionMarker':
                return ActionIconMarker
            case 'actionMarkerRed':
                return ActionIconMarkerRed
            case 'detectors':
                return DetectorsIcon
            case 'detectorsActive':
                return DetectorsIconActive
            case 'externalEventWhite':
                return ExternalEventIconWhite
            case 'externalEventLight':
                return ExternalEventIconLight
            case 'detectedEvent':
                return DetectedEventIcon
            case 'detectedEventAffectation':
                return DetectedEventAffectationIcon
            case 'detectedEventWhite':
                return DetectedEventIconWhite
            case 'detectedEventLight':
                return DetectedEventIconLight
            case 'testEvent':
                return TestEventIcon
            case 'realEvent':
                return EventIcon
            case 'realEventActive':
                return EventIconBlue
            case 'eventWhite':
                return EventIconWhite
            case 'eventLight':
                return EventIconLight
            case 'eventNoFill':
                return EventIconNoFill
            case 'eventMarker':
                return EventIconMarker
            case 'eventMarkerRed':
                return EventIconMarkerRed
            case 'evaluation':
                return EvaluationIcon
            case 'evaluationStatic':
                return EvaluationIcon
            case 'responsePlan':
                return ResponsePlanIcon
            case 'singleMap':
                return SingleMapIcon
            case 'singleMapActive':
                return SingleMapIconActive
            case 'twoMap':
                return TwoMapIcon
            case 'twoMapActive':
                return TwoMapIconActive
            case 'threeMap':
                return ThreeMapIcon
            case 'threeMapActive':
                return ThreeMapIconActive
            case 'fourMap':
                return FourMapIcon
            case 'fourMapActive':
                return FourMapIconActive
            case 'motorways':
                return MotorwaysIcon
            case 'motorwaysActive':
                return MotorwaysIconActive
            case 'suburban':
                return SuburbanIcon
            case 'suburbanActive':
                return SuburbanIconActive
            case 'urban':
                return UrbanIcon
            case 'urbanActive':
                return UrbanIconActive
            case 'local':
                return LocalIcon
            case 'localActive':
                return LocalIconActive
            case 'centroid':
                return ZonesIcon
            case 'centroidActive':
                return ZonesIconActive
            case 'aerial_imagery':
                return aerial_imageryIcon
            case 'aerial_imageryActive':
                return aerial_imageryActiveIcon
            default:
                return ActionIcon
        }
    }

    return <SvgIcon viewBox='0 0 25 25' component={renderIcon(name)} color={color} style={style} />
}

export default CustomIcon
