import { Button, FormControl, FormControlLabel, InputLabel, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { GREY, NAVY_BLUE } from '../../../theme'

export const NewManageEvaluationDialogButton = styled(Button)`
  padding: 9px 25px 6px;
  border-radius: 10px;
  margin-left: 20px;
  
  &:hover {
    opacity: 0.85;
  }
`
export const NewManageEvaluationResetButton = styled(NewManageEvaluationDialogButton)`
  padding: 8px;
  border-radius: 16px;
  position: absolute;
  right: 0;
  span {
    font-size: 14px;
    text-transform: capitalize;
  }
  &:hover {
    opacity: 1;
  }
`

export const NewManageEvaluationDialogButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 32px;
`

export const DoNothingScenario = styled(FormControlLabel)`
  justify-content: space-between;
  margin: 0;
  width: 100%;
`
export const EventsDropDownForm = styled(FormControl)`
  width: 100%;
  margin: 16px 0;
  &.Mui-focused{
    color: inherit;
  }
  .land-use-clock-selector {
    &.selected {
      background-color: ${NAVY_BLUE};
      opacity: 0.6;
    }
    &:after {
      border-bottom-color: inherit;
    }
  }
`

export const SearchStrategiesForm = styled(FormControl)`
  width: 100%;
  margin: 16px 0;
`

export const EventsInputLabel = styled(InputLabel)`
  color: ${GREY};
  &.Mui-focused{
    color: inherit;
  }
`

export const PrecodedResponsePlanTypography = styled(Typography)`
  margin-top: 8px;
  font-family: AimsunBold,Roboto,Sans-serif,serif;
  color: #09254C;
  font-size: 18px;
  font-weight: 800;
  opacity: 1;
  line-height: 1.2;
  letter-spacing: -0.00833em;
`

export const ResponsePlanFormControlLabel = styled(FormControlLabel)`
  display: block;
  padding: 0 16px;
`

export const NewManageEvaluationContentWrapper = styled.div`
  padding: 0 16px;
`
