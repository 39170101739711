import * as React from 'react'
import { Checkbox, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { ResponsePlanForm, ResponsePlanInputLabel, ResponsePlanSelectorWrapper } from './responsePlanSelectorStyles'

const ResponsePlanSelector: React.FC<ResponsePlanSelectorProps> = ({
    responsePlanSelected,
    handleResponsePlansChange,
    evaluation,
    disabled,
}: ResponsePlanSelectorProps) => {
    const handleEvaluationsChange = (event: SelectChangeEvent<number[]>) => {
        handleResponsePlansChange(event.target.value)
    }

    const valuesToRender = (selected: number[]) => {
        return selected.join(',')
    }

    return (
        <React.Fragment>
            <ResponsePlanSelectorWrapper>
                <ResponsePlanForm key='response-plan-form' variant='standard'>
                    <ResponsePlanInputLabel>Response plan</ResponsePlanInputLabel>
                    <Select
                        id='response-plan-selector'
                        multiple={true}
                        value={responsePlanSelected}
                        className='response-plan-selector'
                        onChange={(event: SelectChangeEvent<number[]>) => {
                            handleEvaluationsChange(event)
                        }}
                        disabled={disabled}
                        renderValue={selected => valuesToRender(selected)}>
                        {evaluation?.responsePlans?.map((responsePlan: IEvaluationResponsePlan | string | IResponsePlan, index: number) => {
                            let rpId: any = ''
                            let rpInfo: string = ''
                            if (responsePlan && typeof responsePlan === 'object') {
                                if ('responsePlanId' in responsePlan) {
                                    rpId = responsePlan.responsePlanId
                                    rpInfo = `${responsePlan.responsePlanId} - ${responsePlan.description}`
                                }
                            } else {
                                rpId = Number(responsePlan)
                                rpInfo = responsePlan
                            }
                            return (
                                <MenuItem key={index.toString() + rpId.toString()} value={rpId}>
                                    <Checkbox checked={responsePlanSelected.indexOf(rpId) > -1} />
                                    {rpInfo}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </ResponsePlanForm>
            </ResponsePlanSelectorWrapper>
        </React.Fragment>
    )
}

export default ResponsePlanSelector
