import React, { JSXElementConstructor, ReactElement, ReactNode } from 'react'
import { Breakpoint, Dialog, Typography } from '@mui/material'
import { CloseRounded as CloseIcon } from '@mui/icons-material'
import { DialogContentStyled, DialogTitleIconButton, DialogTitleStyled } from './customDialogStyles'
import { TransitionProps } from '@mui/material/transitions'

interface ICustomDialogProps {
    className?: string;
    children: ReactNode;
    handleClose: () => void
    open: boolean,
    justButtonClose?: boolean
    title: string
    fullWidth?: boolean
    fullScreen?: boolean
    maxWidth?: false | Breakpoint | undefined
    transition?: JSXElementConstructor<TransitionProps & { children: ReactElement<any, any>; }>
}
const CustomDialog: React.FC<ICustomDialogProps> = (
    {
        className,
        children,
        handleClose,
        open,
        justButtonClose = true,
        title,
        fullWidth = true,
        maxWidth = 'md',
        fullScreen = false,
        transition
    }: ICustomDialogProps
) => {

    return (
        <Dialog
            fullScreen={fullScreen}
            className={className}
            aria-labelledby='charts-dialog__title'
            open={open}
            onClose={justButtonClose ? () => {} : handleClose}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            TransitionComponent={transition}
        >
            <DialogTitleStyled>
                <Typography variant='inherit'>{title}</Typography>
                <DialogTitleIconButton onClick={handleClose}>
                    <CloseIcon />
                </DialogTitleIconButton>
            </DialogTitleStyled>
            <DialogContentStyled id='dialog-content'>
                {children}
            </DialogContentStyled>
        </Dialog>
    )
}

export default CustomDialog
