import { TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { QmDialogTableTypographyHeader } from '../qualityManagerDialogStyles'
import { useTranslation } from 'react-i18next'

const QualityManagerDialogTableFixedHead: React.FC<IQualityManagerDialogTableHeadProps> = ({
    order,
    orderBy,
    generalTittle,
    fixedTittles,
    onRequestSort,
}: IQualityManagerDialogTableHeadProps) => {
    const { t } = useTranslation()
    const TypoValue = ({ sortValue }: { sortValue: string }) => {
        return <QmDialogTableTypographyHeader>{sortValue}</QmDialogTableTypographyHeader>
    }

    const HeaderCell = ({
        id,
        align,
        colSpan = 1,
    }: {
        id: string
        align: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined
        colSpan?: number
    }) => {
        return (
            <TableCell key={id} align={align} sortDirection={orderBy === id ? order : false} colSpan={colSpan}>
                <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : 'asc'}
                    onClick={createSortHandler(id)}>
                    <TypoValue sortValue={id} />
                    {orderBy === id ? (
                        <Box component='span' sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                    ) : null}
                </TableSortLabel>
            </TableCell>
        )
    }

    const createSortHandler = (property: string) => (event: any) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            {generalTittle && fixedTittles && (
                <TableRow>
                    <TableCell key={generalTittle} align='center' colSpan={fixedTittles.length + 1}>
                        <TypoValue sortValue={generalTittle} />
                    </TableCell>
                </TableRow>
            )}
            <TableRow>
                <HeaderCell id={t('qualityManager.time')} align='left' />
                {fixedTittles &&
                    fixedTittles.map((value: string, index: number) => {
                        return <HeaderCell key={index} id={value} align='center' />
                    })}
            </TableRow>
        </TableHead>
    )
}

export default QualityManagerDialogTableFixedHead
