import React, { useEffect, useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import {
    CardContentTrafficActionsStyled,
    CardContentWrapper,
    CardHeaderTrafficActionsStyled,
    CardTrafficActionsRendererStyled,
    HeaderWrapperTrafficActions,
    TrafficActionsButton,
    TrafficActionsButtonsWrapper,
    TrafficActionsNameStyled,
} from './tafficActionsRendererStyles'
import TrafficActionsDiversionRoute  from './trafficActionsDiversionRoute/trafficActionsDiversionRoute'
import TrafficActionsResponsePlan from './trafficActionsResponsePlan/trafficActionsResponsePlan'
import { Close as CloseIcon } from '@mui/icons-material'
import CustomIcon from '../../../../components/customIcon/CustomIcon'
import { PulseLoader } from 'react-spinners'
import { useDispatch } from 'react-redux'
import {
    createNewTrafficAction,
    selectTrafficActionCreated,
    selectTrafficActionCreating, setTrafficActionCreated, setTrafficActionCreating,
} from './store/trafficActionSlice'
import { useAppSelector } from '../../../../app/hooks'

interface TrafficActionsRendererProps {
    subPathNodeInfoModal: any
    onCloseNewActionModal: () => void
    highlightDiversionRoutes: (diversionRoute: any, color: string) => void
    clearDiversionRoutes: () => void
    checkedDiversionRoutes: (checked: boolean, routeToCheck: any, color: string) => void
}

const TrafficActionsRenderer: React.FC<TrafficActionsRendererProps> = ({
   subPathNodeInfoModal,
   onCloseNewActionModal,
   highlightDiversionRoutes,
   clearDiversionRoutes,
   checkedDiversionRoutes,
}: TrafficActionsRendererProps) => {

    const _newActionCreating: boolean = useAppSelector(selectTrafficActionCreating)
    const _newActionCreated: boolean = useAppSelector(selectTrafficActionCreated)
    const [actionName, setActionName] = useState<string>('')
    const [controlPlanIDSelected, setControlPlanIDSelected] = useState<number>(-1)
    const [diversionRoutesSelected, setDiversionRoutesSelected] = useState<any[]>([])
    const [diversionRoutesPercentageSelected, setDiversionRoutesPercentageSelected] = useState<any[]>([])
    const [controlPlanList, setControlPlanList] = useState<ControlPlan[]>([])
    const [diversionRouteList, setDiversionRouteList] = useState<any[]>([])
    const [postingData, setPostingData] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [errorDiversionRouteId, setErrorDiversionRouteId] = useState<number[]>([])
    const [maxValuePercentage, setMaxValuePercentage] = useState<number>(100)
    const dispatch = useDispatch()

    const handleClose = () => {
        onCloseNewActionModal()
        setDisabled(false)
        dispatch(setTrafficActionCreating(false))
        diversionRoutesSelected.map((diversionRoute: any) =>
            checkedDiversionRoutes(false, diversionRoute, '' )
        )
    }

    useEffect(() => {
        setControlPlanList(subPathNodeInfoModal.attributes['GKNode::controlPlans']?.length > 0 ?
            subPathNodeInfoModal.attributes['GKNode::controlPlans'] : [])
        setDiversionRouteList(subPathNodeInfoModal.attributes.subpaths?.length > 0 ?
            subPathNodeInfoModal.attributes.subpaths : [])
        diversionRoutesSelected.map((diversionRoute: any) =>
            checkedDiversionRoutes(false, diversionRoute, '' )
        )
        setDiversionRoutesSelected([])
        setDiversionRoutesPercentageSelected([])
        setControlPlanIDSelected(-1)
        setDisabled(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subPathNodeInfoModal])

    useEffect(() => {
        if (!_newActionCreating && _newActionCreated){
            dispatch(setTrafficActionCreated(false))
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_newActionCreating, _newActionCreated])

    const TitleHeader = () => {
        return (
        <HeaderWrapperTrafficActions>
            <CustomIcon name='event' style={{ marginTop: '0' }} />
            <Typography variant='h2'>{'New Action'}</Typography>
        </HeaderWrapperTrafficActions>
    )}

    const handleControlPlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id: number = Number.parseInt(event.target.value)
        setControlPlanIDSelected(id)
    }

    const highlightDiversionRoute = (diversionRoute: any, color: string) => {
        highlightDiversionRoutes(diversionRoute, color)

    }

    const clearDiversionRoute = () => {
        clearDiversionRoutes()
    }

    const handleDiversionRouteChecked = (checked: boolean, diversionRoute: any, routeToCheck: any, color: string, drPercentage: any[]) => {
        if (checked) {
            const diversionRoutesSelectedIds = [...diversionRoutesSelected]
            const diversionRoutesSelectedIdsFiltered = diversionRoutesSelectedIds.filter(dRSelected => {
                return dRSelected.id !== routeToCheck.id
            })
            const newDiversionRoutesSelected = diversionRoutesSelectedIdsFiltered.concat(routeToCheck)
            setDiversionRoutesSelected(newDiversionRoutesSelected)
        } else {
            const diversionRoutesSelectedIds = [...diversionRoutesSelected]
            const diversionRoutesSelectedIdsFiltered = diversionRoutesSelectedIds.filter(dRSelected => {
                return dRSelected.id !== routeToCheck.id
            })
            setDiversionRoutesSelected(diversionRoutesSelectedIdsFiltered)
        }

        if (drPercentage.length > 0) {
            let maxPercentage = 100
            let newErrorDiversionRouteId = [...errorDiversionRouteId]

            drPercentage.forEach((dr: any) => {
                if (maxPercentage - dr.value < 0) {
                    const errorIdExist = newErrorDiversionRouteId.find(id => id === routeToCheck.id)
                    if (!errorIdExist) {
                        newErrorDiversionRouteId = [...newErrorDiversionRouteId, routeToCheck.id]
                    }
                } else {
                    setMaxValuePercentage(maxPercentage - dr.value)
                    newErrorDiversionRouteId = newErrorDiversionRouteId.filter(dRSelected => {
                        return dRSelected !== dr.subpathId
                    })
                    maxPercentage = maxPercentage - dr.value
                }
            })
            setErrorDiversionRouteId(newErrorDiversionRouteId)
        } else {
            setMaxValuePercentage(100)
            setErrorDiversionRouteId([])
        }

        setDiversionRoutesPercentageSelected(drPercentage)
        checkedDiversionRoutes(checked,routeToCheck, color )
    }

    const newActionsHandler = () => {
        setPostingData(true)
        const sectionId = Number(subPathNodeInfoModal.id)
        let subpathSelected = {}
        diversionRoutesSelected.forEach((DRSelected: any) => {
            const hasData = diversionRoutesPercentageSelected.find((DRPerce: any) => {
                return DRPerce.subpathId === DRSelected.id
            })
            subpathSelected = {
                ...subpathSelected,
                [hasData.subpathId]: Number(hasData.value)
            }
        })

        let data = {}
        switch (subPathNodeInfoModal.type) {
            case 'GKSubPath':
            case 'GKSection':
                data = {
                    subpaths: subpathSelected
                }
                break
            case 'GKNode':
                data = {
                    control_plan_id: controlPlanIDSelected,
                    node_id: sectionId
                }
                break
            default:
                break
        }
        const type = (subPathNodeInfoModal.type === 'GKSubPath' || subPathNodeInfoModal.type === 'GKSection') ? 'set_a_diversion_route' : 'change_control_plan'
        const trafficAction = {
            name: actionName,
            type,
            sectionId,
            coords: {
                lng: subPathNodeInfoModal.longitude,
                lat: subPathNodeInfoModal.latitude
            },
            data
        }
        setDisabled(true)

        dispatch(createNewTrafficAction({trafficAction}))
    }


    const allDRSelectedHasPercentage = () => {
        let allDrWithPercentage: any = []
        diversionRoutesSelected.forEach((DRSelected: any) => {
            const hasPercentage = diversionRoutesPercentageSelected.find((DRPerce: any) => {
                return DRPerce.subpathId === DRSelected.id && (DRPerce.value !== '') && DRPerce.value > 0 && DRPerce.value <= 100
            })
            if (hasPercentage) {
                allDrWithPercentage.push(hasPercentage)
            }
        })
        return diversionRoutesSelected.length === allDrWithPercentage.length
    }
    const mandatoryControlPlan = subPathNodeInfoModal.type === 'GKNode' && controlPlanIDSelected !== -1
    const mandatoryDiversionRoutePercentage = diversionRoutesSelected.length > 0 && allDRSelectedHasPercentage()
    const mandatoryDiversionRoute = (subPathNodeInfoModal.type === 'GKSubPath' || subPathNodeInfoModal.type === 'GKSection')
        && diversionRoutesSelected.length > 0 && mandatoryDiversionRoutePercentage
    const enableRunButton = actionName !== '' && (mandatoryControlPlan || mandatoryDiversionRoute) && errorDiversionRouteId.length === 0
    const errorActionName = actionName === ''

    return (
            <CardTrafficActionsRendererStyled>
                <CardHeaderTrafficActionsStyled
                    action={
                        <IconButton aria-label='settings' onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                    title={<TitleHeader />}
                />
                <CardContentWrapper>
                    <CardContentTrafficActionsStyled>
                        <TrafficActionsNameStyled
                            id='eventName'
                            value={actionName || ''}
                            label={'Name'}
                            placeholder='Insert action name ...'
                            variant={'standard'}
                            autoFocus={true}
                            onChange={e => setActionName(e.target.value)}
                            style={{
                                width: '100%',
                                marginBottom: '20px',
                            }}
                            error={errorActionName}
                            helperText={errorActionName ? 'This field is required' : ''}
                        />
                        {(subPathNodeInfoModal.type === 'GKSubPath' || subPathNodeInfoModal.type === 'GKSection') &&
                            <TrafficActionsDiversionRoute
                                highlightDiversionRoute={highlightDiversionRoute}
                                clearDiversionRoute={clearDiversionRoute}
                                handleDiversionRouteChecked={handleDiversionRouteChecked}
                                diversionRouteList={diversionRouteList}
                                diversionRoutesSelected={diversionRoutesSelected}
                                maxValuePercentage={maxValuePercentage}
                                errorDiversionRouteId={errorDiversionRouteId}
                            />
                        }
                        {subPathNodeInfoModal.type === 'GKNode' &&
                            <TrafficActionsResponsePlan
                                handleControlPlanChange={handleControlPlanChange}
                                controlPlanList={controlPlanList}
                                controlPlanIDSelected={controlPlanIDSelected}
                            />
                        }
                    </CardContentTrafficActionsStyled>
                    <TrafficActionsButtonsWrapper>
                        <TrafficActionsButton onClick={handleClose} variant='text'>
                            <Typography variant='button' color='primary'>{'Cancel'}</Typography>
                        </TrafficActionsButton>
                        <TrafficActionsButton  disabled={!enableRunButton || disabled} onClick={newActionsHandler} variant='contained' >
                            {(postingData &&
                                    <PulseLoader size={8} margin={2} color='#FFF' />) ||
                                <Typography variant='button'>{'Create'}</Typography>
                            }
                        </TrafficActionsButton>
                    </TrafficActionsButtonsWrapper>
                </CardContentWrapper>
            </CardTrafficActionsRendererStyled>
    )
}

export default TrafficActionsRenderer
