import { DateTime } from 'luxon'
import store, { RootState } from '../../app/store'
import { t } from 'i18next'
import { converterToImperialSystem, getUnitTypeFromUnit } from '../../helpers/unitsHelper'

export const StationDialogTooltip = ({
    timeZone,
    hour,
    real,
    dataReliability,
    patterMax,
    patterMin,
    sensorReliability,
    prediction,
    viewMode,
}: StationDialogTooltipProps): string => {
    const _state: RootState = store.getState()
    const _config: IModuleConfig = _state.core.module_config
    const { units } = _config
    const unitType = getUnitTypeFromUnit(viewMode.units.main)
    const realMetric: string | undefined = real?.toFixed(0)
    const realImperial: string | undefined =
        real && !!unitType ? converterToImperialSystem(unitType, Number(real)).toFixed(0) : undefined
    const sensorTooltip =
        (sensorReliability === 0 || sensorReliability) &&
        `
       <p class='charts__tooltip-content'>
                <span class='charts__tooltip-content--highlight'>
                ${t('stationDialog.sensorReliability')}: 
            </span>
            ${sensorReliability.toFixed(2)}
        </p>
    `
    const dataReliabilityTooltip =
        (dataReliability === 0 || dataReliability) &&
        `
       <p class='charts__tooltip-content'>
                <span class='charts__tooltip-content--highlight'>
                ${t('stationDialog.dataReliability')}: 
            </span>
            ${dataReliability.toFixed(2)}
        </p>
    `
    const realTooltip =
        (real === 0 || real) &&
        `
        <div>
            <p class='charts__tooltip-content'>
                <span class='charts__tooltip-content--highlight'>
                    ${t('stationDialog.real')}: 
                </span>
                ${units === 'imperial' && !!unitType ? realImperial : realMetric}
            </p>
            ${dataReliability === 0 || dataReliability ? dataReliabilityTooltip : ''}
            ${sensorReliability ? sensorTooltip : ''}
        </div>
    `
    const patternTooltip =
        (patterMin === 0 || patterMin) &&
        (patterMax === 0 || patterMax) &&
        `
        <div>
            <p class='charts__tooltip-content'>
                <span class='charts__tooltip-content--highlight-red'>
                    ${t('stationDialog.patternMin')}: 
                </span>
                ${patterMin.toFixed(0)}
            </p>
            <p class='charts__tooltip-content'>
                <span class='charts__tooltip-content--highlight-red'>
                    ${t('stationDialog.patternMax')}: 
                </span>
                ${patterMax.toFixed(0)}
            </p>
        </div>
    `
    const predictionTooltip =
        (prediction === 0 || prediction) &&
        `
        <p class='charts__tooltip-content'>
            <span class='charts__tooltip-content--highlight-green'>
                ${t('stationDialog.prediction')}: 
            </span>
            ${prediction.toFixed(0)}
        </p>
    `

    const dateString = hour ? DateTime.fromMillis(hour, { zone: timeZone }).toFormat(_config.date_format.time) : ''

    return `
          <div class='charts__tooltip-container'>
            <CardContent>
                <Typography class='charts__tooltip-title'>${dateString}</Typography>
                ${real === 0 || real ? realTooltip : ''}
                ${patterMax !== null && patterMin !== null ? patternTooltip : ''}
                ${prediction ? predictionTooltip : ''}
            </CardContent>
        </div>
    `
}
