import React, { useEffect, useState } from 'react'
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material'
import SearchTermComponent from '../../components/searchTermComponent/SearchTermComponent'
import {
    SearchWrapper,
    SearchIconButton,
    SearchInputDiv,
    CloseIconButton,
    SearchTermInputWrapper,
    ListStyled,
    ListItemIconStyled,
    TypeNetwork,
    CloseListIconButton,
} from './searchStyles'
import { Alert, ListItemButton, ListItemText, ListSubheader, Snackbar } from '@mui/material'
import CustomIcon from '../../components/customIcon/CustomIcon'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import {
    restoreInitialFeatureList,
    selectFoundFeatureList,
    selectOpenFoundFeatureList,
    selectSearchCriteria,
} from './store/searchFeatureSlice'
import { WHITE } from '../../theme'
import { useTranslation } from 'react-i18next'

const Search: React.FC<ISearchProps> = ({ handleSearch, handleListClick, mapId }: ISearchProps) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const mapIdToHook = mapId ? mapId : 0
    const _foundFeatureList: IFoundFeature[] = useAppSelector((state: RootState) =>
        selectFoundFeatureList(state, mapIdToHook)
    )
    const _openFoundFeatureList: boolean = useAppSelector((state: RootState) =>
        selectOpenFoundFeatureList(state, mapIdToHook)
    )
    const _criteria: string = useAppSelector((state: RootState) => selectSearchCriteria(state, mapIdToHook))
    const [open, setOpen] = useState(false)
    const [term, setTerm] = useState<string>('')
    const [itemSelected, setItemSelected] = useState<number | null>(null)
    const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false)

    useEffect(() => {
        if (_foundFeatureList.length === 0 && _openFoundFeatureList) {
            setFeedbackOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_foundFeatureList])

    useEffect(() => {
        if (!_criteria.length) setTerm(_criteria)
    }, [_criteria])

    const handleClick = () => {
        if (open) {
            dispatch(restoreInitialFeatureList({ mapId: mapId }))
        }
        setOpen(!open)
    }

    const handleListItemClick = (id: number) => {
        handleListClick(id)
        setItemSelected(id)
    }

    const handleCloseListClick = () => {
        dispatch(restoreInitialFeatureList({ mapId: mapId }))
    }
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setFeedbackOpen(false)
    }

    const roadName = (type: string) => {
        switch (type) {
            case 'detector':
                return 'detectors'
            case 'urban_1':
            case 'urban_2':
                return 'urban'
            default:
                return type
        }
    }

    const openList = _openFoundFeatureList && _foundFeatureList.length > 0

    return (
        <SearchWrapper id='searchTool'>
            {openList && (
                <ListStyled
                    aria-labelledby='nested-list-subheader'
                    subheader={
                        <ListSubheader component='div' id='nested-list-subheader'>
                            {`${t('search.searchCriteria')} ${_criteria}`}
                            <CloseListIconButton onClick={handleCloseListClick}>
                                <CloseIcon sx={{ width: '16px', height: '16px' }} />
                            </CloseListIconButton>
                        </ListSubheader>
                    }>
                    {_foundFeatureList.map((foundFeature: any, index: number) => {
                        return (
                            <ListItemButton
                                selected={itemSelected === foundFeature.id}
                                onClick={() => handleListItemClick(foundFeature.id)}
                                id={foundFeature.id}
                                key={`${foundFeature.id}`}>
                                <ListItemIconStyled>
                                    <CustomIcon
                                        name={roadName(foundFeature.type)}
                                        style={{
                                            margin: 'auto',
                                            width: '16px',
                                            height: '16px',
                                            backgroundColor: WHITE,
                                        }}
                                    />
                                </ListItemIconStyled>
                                <TypeNetwork primary={t(`search.${roadName(foundFeature.type)}`)} />
                                <ListItemText primary={`${t('search.id')}: ${foundFeature.id}`} />
                            </ListItemButton>
                        )
                    })}
                </ListStyled>
            )}
            {!open && (
                <SearchIconButton onClick={handleClick}>
                    <SearchIcon />
                </SearchIconButton>
            )}
            {open && (
                <SearchInputDiv>
                    <SearchTermInputWrapper variant='outlined' square>
                        <SearchTermComponent
                            startAdornment={false}
                            value={term}
                            searchTermChange={searchTerm => setTerm(searchTerm)}
                            handleKey={(searchTerm: string) => {
                                return handleSearch(searchTerm)
                            }}
                            disableUnderline={true}
                        />
                    </SearchTermInputWrapper>
                    <CloseIconButton onClick={handleClick}>
                        <CloseIcon />
                    </CloseIconButton>
                </SearchInputDiv>
            )}
            <Snackbar
                open={feedbackOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity={'warning'} sx={{ width: '100%' }}>
                    {t('feedBackMessage.featureNotFound', { criteria: _criteria })}
                </Alert>
            </Snackbar>
        </SearchWrapper>
    )
}

export default Search
