import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/store'

interface ResponsePlanManageLiveState {
    responsePlanManageLive: IResponsePlan[]
    precodedResponsePlanManageLive: IResponsePlan[]
}
const initialResponsePlanManageLiveState: ResponsePlanManageLiveState = {
    responsePlanManageLive: [],
    precodedResponsePlanManageLive: [],
}

const responsePlanManageLiveSlice = createSlice({
    name: 'responsePlanManageLiveSlice',
    initialState: initialResponsePlanManageLiveState,
    reducers: {
        setResponsePlanLives: (state, action: PayloadAction<IResponsePlan[]>): void => {
            state.responsePlanManageLive = action.payload
        },
        setPrecodedResponsePlanManageLive: (state, action: PayloadAction<IResponsePlan[]>): void => {
            state.precodedResponsePlanManageLive = action.payload
        },
        loadManageLiveResponsePlan: (state, action: PayloadAction<any>): void => {},
    },
})

export const {
    loadManageLiveResponsePlan,
    setPrecodedResponsePlanManageLive,
    setResponsePlanLives,
} = responsePlanManageLiveSlice.actions

export const selectResponsePlanLives = (state: RootState) => state.responsePlanManageLive.responsePlanManageLive
export const selectPrecodedResponsePlanLive = (state: RootState) => state.responsePlanManageLive.precodedResponsePlanManageLive

export default responsePlanManageLiveSlice.reducer
