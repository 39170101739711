import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

const initialEvaluationsManageState = {
    creating: false,
    removed: false,
    taskProgress: []
}

const evaluationsManageSlice = createSlice({
    name: 'evaluationsManage',
    initialState: initialEvaluationsManageState,
    reducers: {
        setCreatingEvaluationsManage: (state, action: PayloadAction<boolean>): void => {
            state.creating = action.payload
        },
        createNewLiveEvaluationManage: (state, action: PayloadAction<any>): void => {
            state.creating = true
        },
        createNewStaticEvaluationManage: (state, action: PayloadAction<any>): void => {
            state.creating = true
        },
        setStaticEvaluationManageRemoved: (state, action: PayloadAction<any>): void => {
            state.removed = action.payload
        },
        storeTaskEvaluationManageProgress: (state, action: PayloadAction<any>): void => {
            state.taskProgress = action.payload
        },
        restoreTaskEvaluationManageProgress: (state, action: PayloadAction<any>): void => {
            state.taskProgress = []
        },
        loadPastManageEvaluationData: (state, action: PayloadAction<{ evaluation: IEvaluationStatic, variable: string }>): void => {},
        taskEvaluationManageProgress: (state, action: PayloadAction<{ evaluation: IEvaluationStatic, variable: string }>): void => {},
        deleteEvaluation: (state, action: PayloadAction<IEvaluationStatic>): void => {},
        loadManageEvaluationDataByView: (
            state,
            action: PayloadAction<{ allViewMode: IViewMode[]; viewModeId: number; mapId: number; option: number }>
        ): void => {},
        loadManageEvaluationDataByViewMode: (
            state,
            action: PayloadAction<{
                epoch: number
                allViewMode: IViewMode[]
                viewModeId: number
                mapId: number
                option: number
                evaluation: IEvaluationStatic
            }>
        ): void => {},
    },
})

export const {
    setCreatingEvaluationsManage,
    createNewLiveEvaluationManage,
    createNewStaticEvaluationManage,
    setStaticEvaluationManageRemoved,
    loadPastManageEvaluationData,
    taskEvaluationManageProgress,
    storeTaskEvaluationManageProgress,
    restoreTaskEvaluationManageProgress,
    deleteEvaluation,
    loadManageEvaluationDataByViewMode,
    loadManageEvaluationDataByView,
} = evaluationsManageSlice.actions

export const selectCreatingEvaluationsManage = (state: RootState) => state.evaluationsManage.creating
export const selectRemovedEvaluationsManage = (state: RootState) => state.evaluationsManage.removed
export const selectTaskProgressEvaluationsManage = (state: RootState) => state.evaluationsManage.taskProgress

export default evaluationsManageSlice.reducer
