import {
    all,
    AllEffect,
    call,
    CallEffect,
    ForkEffect,
    put,
    PutEffect,
    select,
    SelectEffect,
    takeLatest,
} from 'redux-saga/effects'
import {
    loadEvaluations,
    storeEvaluations,
    setFetchingEvaluations,
    loadEvaluation,
    setSelectedEvaluation,
    loadLandUseEvaluations,
} from './evaluationsSlice'
import { AxiosResponse } from 'axios'
import { getEvaluation, getEvaluations, getLandUseEvaluations } from '../../../services/evaluations/evaluationsService'
import { selectMapHorizonSelected } from '../../mapHorizonSelector/store/mapHorizonSelectedSlice'

function* fetchEvaluations(
    action: any
): Generator<
    | CallEffect<AxiosResponse<IEvaluationStatic[], any>>
    | PutEffect<{ payload: any; type: 'evaluations/storeEvaluations' }>
    | PutEffect<{ payload: boolean; type: 'evaluations/setFetchingEvaluations' }>,
    void,
    IEvaluationStatic[]
> {
    const evaluations: IEvaluationStatic[] = yield call(getEvaluations)
    yield put(storeEvaluations(evaluations))
    yield put(setFetchingEvaluations(false))
}

function* fetchEvaluation(
    action: any
): Generator<
    | CallEffect<AxiosResponse<IEvaluationStatic, any>>
    | PutEffect<{ payload: any; type: 'evaluations/setSelectedEvaluation' }>
    | PutEffect<{ payload: boolean; type: 'evaluations/setFetchingEvaluations' }>,
    void,
    IEvaluationStatic
> {
    const selectedEvaluation = action.payload
    const evaluation: IEvaluationStatic = yield call(getEvaluation, selectedEvaluation.id)
    yield put(setSelectedEvaluation(evaluation))
    yield put(setFetchingEvaluations(false))
}

function* fetchLandUseEvaluations(): Generator<
    | CallEffect<AxiosResponse<number, any>>
    | PutEffect<{ payload: any; type: 'evaluations/storeEvaluations' }>
    | PutEffect<{ payload: boolean; type: 'evaluations/setFetchingEvaluations' }>
    | SelectEffect,
    void,
    IMapHorizon
> {
    const mapHorizonSelected: IMapHorizon = yield select(selectMapHorizonSelected)
    let evaluations = yield call(getLandUseEvaluations, mapHorizonSelected.id)
    yield put(storeEvaluations(evaluations))
    yield put(setFetchingEvaluations(false))
}

function* evaluationsSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(loadEvaluations, fetchEvaluations),
        yield takeLatest(loadEvaluation, fetchEvaluation),
        yield takeLatest(loadLandUseEvaluations, fetchLandUseEvaluations),
    ])
}

export default evaluationsSaga
