import { AxiosResponse } from 'axios'
import queryString from 'query-string'
import { getRequestAPI } from '../../services/apiClient/apiClientHelpers'

export function getPatternDataRequest(params: any): Promise<AxiosResponse<IQMAverageResponse>> {
    return getRequestAPI(`/api_dm/rpc/get_pattern_last_n_hours?${queryString.stringify(params)}`)
}

export function getCleanDataRequest(params: any): Promise<AxiosResponse<IQMAverageResponse>> {
    return getRequestAPI(`/api_dm/rpc/get_last_n_hours?${queryString.stringify(params)}`)
}
