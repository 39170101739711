import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { DateTime } from 'luxon'

const initialDashboardState: any = {
    toolbar: {
        navbar: true,
    },
    image: {
        path: '',
    },
    heatmap: {},
    details: {},
    timeseries: {},
    clock: {},
    mapbox: {},
    calendar: {
        availableData: [],
        maxAndMinTimesWithData: {},
    },
    patterns: [],
    viewMode: {},
    network: {
        detectors: {
            available: false,
        },
        corridors: {
            available: false,
            network: undefined,
        },
    },
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialDashboardState,
    reducers: {
        loadPatternAverages: (
            state,
            action: PayloadAction<{ widgetName: string; date: DateTime; variable: string }>
        ) => {
            state.timeseries = {
                ...state.timeseries,
            }
        },
        loadPatternSelectedInTime: (
            state: any,
            action: PayloadAction<{ widgetName: string; date: DateTime }>
        ): void => {
            state.heatmap = {
                ...state.heatmap,
            }
        },
        storeClock: (state, action: PayloadAction<{ widgetName: string; date: DateTime; variable: any }>): void => {
            state.clock = {
                ...state.clock,
                [action.payload.widgetName]: {
                    date: action.payload.date,
                    open: false,
                },
            }
        },
        updateClock: (state, action: PayloadAction<{ connection: IWidgetConnection; data: any }>): void => {
            const { connection, data } = action.payload
            const currentWidgetState = state[connection.to.type]
            state[connection.to.type] = {
                [connection.to.name]: {
                    ...currentWidgetState[connection.to.name],
                    [connection.to.target]: data,
                },
            }
        },
        storeMapbox: (state, action: PayloadAction<{ widgetName: string; viewMode: any }>): void => {
            state.mapbox = {
                ...state.mapbox,
                [action.payload.widgetName]: {
                    viewMode: action.payload.viewMode,
                },
            }
        },
        updateMapbox: (state, action: PayloadAction<{ connection: IWidgetConnection; data: any }>): void => {
            const { connection, data } = action.payload
            const currentWidgetState = state[connection.to.type]

            state[connection.to.type] = {
                [connection.to.name]: {
                    ...currentWidgetState[connection.to.name],
                    [connection.to.target]: data,
                },
            }
        },
        changeMapboxViewMode: (
            state,
            action: PayloadAction<{ widgetName: string; viewMode: IViewMode; feature: GeoJSON.Feature }>
        ): void => {
            const currentWidgetState = { ...state.mapbox[action.payload.widgetName] }
            state.mapbox = {
                ...state.mapbox,
                [action.payload.widgetName]: {
                    ...currentWidgetState,
                    viewMode: action.payload.viewMode,
                    featureSelected: action.payload.feature,
                },
            }
        },
        changeFeatureSelected: (
            state,
            action: PayloadAction<{
                widgetName: string
                featureSelected: GeoJSON.Feature
                viewMode: IViewMode | undefined
            }>
        ): void => {
            const currentWidgetState = { ...state.mapbox[action.payload.widgetName] }
            state.mapbox = {
                ...state.mapbox,
                [action.payload.widgetName]: {
                    ...currentWidgetState,
                    featureSelected: action.payload.featureSelected,
                    viewMode: action.payload.viewMode,
                },
            }
        },
        storeMapboxWidgetData: (state, action: PayloadAction<{ widgetName: string; data: any[] }>): void => {
            const currentWidgetState = { ...state.mapbox[action.payload.widgetName] }
            state.mapbox = {
                ...state.mapbox,
                [action.payload.widgetName]: {
                    ...currentWidgetState,
                    data: action.payload.data,
                    loading: false,
                },
            }
        },
        storeHeatmap: (state, action: PayloadAction<{ widgetName: string; data: any; time: any }>): void => {
            state.heatmap = {
                ...state.heatmap,
                [action.payload.widgetName]: {
                    data: action.payload.data,
                    time: action.payload.time,
                    loading: false,
                },
            }
        },
        storeHeatmapWidgetSeries: (state, action: PayloadAction<{ widgetName: string; series: any }>) => {
            const currentWidgetState = { ...state.heatmap[action.payload.widgetName] }
            state.heatmap = {
                ...state.heatmap,
                [action.payload.widgetName]: {
                    ...currentWidgetState,
                    data: action.payload.series,
                    loading: false,
                },
            }
        },
        updateHeatmap: (state, action: PayloadAction<{ connection: IWidgetConnection; data: any }>): void => {
            const { connection, data } = action.payload
            const currentWidgetState = state[connection.to.type]

            state[connection.to.type] = {
                [connection.to.name]: {
                    ...currentWidgetState[connection.to.name],
                    [connection.to.target]: data,
                },
            }
        },
        storeTimeseries: (
            state,
            action: PayloadAction<{
                widgetName: string
                series: any
                date: any
                featureSelected?: any
                pattern?: any
            }>
        ): void => {
            const currentWidgetState = { ...state.timeseries[action.payload.widgetName] }
            state.timeseries = {
                ...state.timeseries,
                [action.payload.widgetName]: {
                    ...currentWidgetState,
                    series: action.payload.series,
                    loading: false,
                    featureSelected: action.payload.featureSelected,
                    pattern: action.payload.pattern,
                },
            }
        },
        updateTimeseries: (state, action: PayloadAction<{ connection: IWidgetConnection; data: any }>): void => {
            const { connection, data } = action.payload
            const currentWidgetState = state[connection.to.type]

            state[connection.to.type] = {
                [connection.to.name]: {
                    ...currentWidgetState[connection.to.name],
                    [connection.to.target]: data,
                },
            }
        },
        storeDetails: (
            state,
            action: PayloadAction<{ widgetName: string; data: any; time: any; featureSelected: any }>
        ): void => {
            state.details = {
                ...state.details,
                [action.payload.widgetName]: {
                    ...state.details[action.payload.widgetName],
                    ...action.payload,
                },
            }
        },
        updateDetails: (state, action: PayloadAction<{ connection: IWidgetConnection; data: any }>): void => {
            const { connection, data } = action.payload
            const currentWidgetState = state[connection.to.type]

            state[connection.to.type] = {
                [connection.to.name]: {
                    ...currentWidgetState[connection.to.name],
                    [connection.to.target]: data,
                },
            }
        },
        storeAvailablePatterns: (state: any, action: PayloadAction<{ patterns: IPatternJson[] }>): void => {
            state.patterns = action.payload.patterns
        },
        storeTimesWithData: (state: any, action: PayloadAction<{ timestamps: string[] }>): void => {
            state.calendar = {
                ...state.calendar,
                availableData: action.payload.timestamps,
            }
        },
        storeMaxAndMinTimesWithData: (state: any, action: PayloadAction<{ from: number; to: number }>): void => {
            state.calendar = {
                ...state.calendar,
                maxAndMinTimesWithData: action.payload,
            }
        },
        initCalendar: (state: any): void => {
            state.calendar = {
                ...state.calendar,
            }
        },
        loadMonthAvailableData: (state: any, action: PayloadAction<{ month: number; year: number }>): void => {
            state.calendar = {
                ...state.calendar,
                month: action.payload.month,
                year: action.payload.year,
            }
        },
        storeViewMode: (state: any, action: PayloadAction<{ viewMode: IViewMode }>): void => {
            state.viewMode = action.payload.viewMode
        },
        storeNetwork: (
            state: any,
            action: PayloadAction<{ type: string; network: { available: boolean; network: any } }>
        ): void => {
            state.network = {
                ...state.network,
                [action.payload.type]: action.payload.network,
            }
        },
        initNetwork: (state: any): void => {
            state.network = {
                ...state.network,
            }
        },
        fetchWidgetRealData: (
            state: any,
            action: PayloadAction<{ date: DateTime; widget: IWidget; viewMode: IViewMode }>
        ): void => {},
        updatePatternTimeseries: (state: any, action: PayloadAction<any>): void => {},
        initPatternsAvailable: (): void => {},
        reloadGlobalTimeseries: (state: any, action: PayloadAction<any>): void => {},
        loadDataRange: (): void => {},
    },
})

export const {
    changeFeatureSelected,
    changeMapboxViewMode,
    fetchWidgetRealData,
    initCalendar,
    initPatternsAvailable,
    initNetwork,
    loadPatternAverages,
    loadPatternSelectedInTime,
    loadMonthAvailableData,
    storeHeatmap,
    storeTimeseries,
    storeDetails,
    storeClock,
    storeMapbox,
    storeMapboxWidgetData,
    storeHeatmapWidgetSeries,
    storeAvailablePatterns,
    storeTimesWithData,
    storeViewMode,
    storeNetwork,
    updateDetails,
    updateMapbox,
    updateClock,
    updateHeatmap,
    updateTimeseries,
    updatePatternTimeseries,
    reloadGlobalTimeseries,
    storeMaxAndMinTimesWithData,
    loadDataRange,
} = dashboardSlice.actions

export const selectTimeseriesState = (state: RootState) => state.dashboard.timeseries
export const selectHeatmapState = (state: RootState) => state.dashboard.heatmap
export const selectDetailsState = (state: RootState) => state.dashboard.details
export const selectClockState = (state: RootState) => state.dashboard.clock
export const selectMapboxState = (state: RootState) => state.dashboard.mapbox
export const selectAvailablePatterns = (state: RootState) => state.dashboard.patterns
export const selectAvailableData = (state: RootState) => state.dashboard.calendar.availableData
export const selectMaxAndMinTimesWithData = (state: RootState) => state.dashboard.calendar.maxAndMinTimesWithData
export const selectMonthAvailableData = (state: RootState) => state.dashboard.calendar.monthAvailableData
export const selectViewMode = (state: RootState) => state.dashboard.viewMode
export const selectDashboardNetwork = (state: RootState) => state.dashboard.network
export const selectCorridors = (state: RootState) => state.dashboard.network.corridors

export default dashboardSlice.reducer
