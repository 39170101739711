import { all, AllEffect, call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { getDataByHorizon } from '../../../services/mapData/mapDataService'
import { loadSimulatedData, setFetchingSimulatedData, storeSimulatedData } from './simulatedDataSlice'
import { getMarks } from '../../../helpers/SliderMarksHelper'
import { DateTime } from 'luxon'
import { subtractTimeToDate } from '../../../helpers/DateTimeHelper'
import { selectConfig } from '../coreSlice'

export function* initSimulatedData(action: any) {
    yield call(fetchSimulatedData, action.payload)
    yield put(setFetchingSimulatedData(false))
}

function* fetchSimulatedData({ epoch, variable }: { epoch: number; variable: string }) {
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const step: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const marks = getMarks(step, numberOfHorizon)

    const simulatedData: IPredictionByHorizonData[] = yield all(
        marks.map(horizon => {
            if (horizon.value === 0) {
                const previousEpoch = subtractTimeToDate(DateTime.fromMillis(epoch), 'minutes', step).toMillis()
                return call(getDataByHorizon, previousEpoch, variable, 'simulated', step)
            } else {
                return call(getDataByHorizon, epoch, variable, 'simulated', horizon.value)
            }
        })
    )
    yield put(storeSimulatedData({ data: simulatedData, variable }))
}

function* simulatedDataSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(loadSimulatedData, initSimulatedData)])
}

export default simulatedDataSaga
