import { useState, useEffect } from 'react';
import { useAppSelector } from '../app/hooks'
import { selectEvaluationLandUseSimulationData } from '../features/core/simulationEvaluationData/evaluationSimulationDataSlice'

export function useEvaluationSimulationData(viewMode: IViewMode) {
    const _evaluationSimulationData:  { [key: string]: IEvaluationSimulationByHorizonData[] } =
        useAppSelector(selectEvaluationLandUseSimulationData)
    const [amAttracted, setAmAttracted] = useState(null)
    const [amGenerated, setAmGenerated] = useState(null)
    const [pmAttracted, setPmAttracted] = useState(null)
    const [pmGenerated, setPmGenerated] = useState(null)


    useEffect(() => {
        setAmAttracted(_evaluationSimulationData[viewMode.variable] ?
            // @ts-ignore
            _evaluationSimulationData[viewMode.variable][0]['am_attracted'][0][0]?.value : 0)
        setAmGenerated(_evaluationSimulationData[viewMode.variable] ?
            // @ts-ignore
            _evaluationSimulationData[viewMode.variable][0]['am_generated'][0][0]?.value : 0)
        setPmAttracted(_evaluationSimulationData[viewMode.variable] ?
            // @ts-ignore
            _evaluationSimulationData[viewMode.variable][0]['pm_attracted'][0][0]?.value : 0)
        setPmGenerated(_evaluationSimulationData[viewMode.variable] ?
            // @ts-ignore
            _evaluationSimulationData[viewMode.variable][0]['pm_generated'][0][0]?.value : 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_evaluationSimulationData])

    return { amAttracted, amGenerated, pmAttracted, pmGenerated }
}