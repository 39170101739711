export const limitResponsePlanIds = (responsePlanIds: number[]) => {
    let nextValue = []
    if (responsePlanIds.length > 4) {
        for (let i = 0; i < 4; i++) {
            nextValue[i] = responsePlanIds[i]
        }
    } else {
        nextValue = responsePlanIds
    }
    nextValue = nextValue.sort((a, b) => a - b)
    return nextValue
}
