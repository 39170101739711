import React, { useState, useEffect } from 'react'
import { Layouts, Responsive, WidthProvider } from 'react-grid-layout'

import {
    selectConfig,
    selectMainConfig,
    selectStatus,
    selectTimeZone,
    setStatus,
    storeModuleName,
} from '../../features/core/coreSlice'
import { enableNetworkAssets, selectMapboxToken, selectNetwork, selectStyle } from '../../features/mapbox/mapboxSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Card, CardContent, Grid, TextFieldProps, Typography } from '@mui/material'
import { getAllViewMode, getViewMode } from '../../helpers/ContainerHelper'
import { ToolbarContainer } from '../../components/toolbarComponent/toolbarComponentStyles'
import { LogoContainer } from '../../features/logo/logoStyles'
import { NAVY_BLUE, RED_LIVE, SMOKE } from '../../theme'
import { CircleLayer, Layer, Map, Source } from 'react-map-gl'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { ClockPickerView, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'
import { ClockInputRender } from '../../features/clock/clockInputRender'
import {
    changeMapboxViewMode,
    changeFeatureSelected,
    initCalendar,
    loadPatternSelectedInTime,
    selectClockState,
    selectDetailsState,
    selectHeatmapState,
    selectMapboxState,
    selectTimeseriesState,
    selectAvailablePatterns,
    storeClock,
    storeMapbox,
    initPatternsAvailable,
    fetchWidgetRealData,
    selectAvailableData,
    loadMonthAvailableData,
    storeViewMode,
    selectViewMode,
    reloadGlobalTimeseries,
    selectMaxAndMinTimesWithData,
    loadDataRange,
} from './DashboardContainerSlice'

import { DateTime } from 'luxon'
import { loadLineChartInitialStateWithTooltip } from '../../features/qualityManagerDialog/qualityManagerDialogOptions'
import {
    CorridorLine,
    DetailsContainer,
    DetectorCircle,
    HeatmapContainer,
    MapboxWidgetContainer,
    SidebarContainer,
    TimeseriesContainer,
    WidgetClockWrapper,
    WidgetTitle,
    WidgetTitleFlex,
} from './DashboardStyles'
import { addTimeToDate, subtractTimeToDate } from '../../helpers/DateTimeHelper'
import { ClockStyledIconButton } from '../../features/clock/clockStyles'
import { ChevronLeft, ChevronRight, SkipNext, SkipPrevious } from '@mui/icons-material'
import {
    getBorderDetectorStyles,
    getColorStyles,
    detectorsLayerInitialState,
    getFeature,
} from '../../helpers/MapboxHelper'
import {
    detailsChartOptionsInitialState,
    heatmapWidgetOptionsInitialState,
    lineChartWidgetOptionsInitialState,
} from './DashboardChartOptions'
import { StationDialogTooltip } from '../../features/stationDialog/stationDialogTooltip'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import GridLoader from 'react-spinners/GridLoader'
import Legend from '../../features/legend/legend'
import Chart from 'react-apexcharts'
import mapboxgl from 'mapbox-gl'
import { Feature, GeoJsonProperties, Geometry } from 'geojson'
import { BarLoader } from 'react-spinners'
import { LayerControlsContainer } from '../../features/mapbox/mapboxStyles'
import Search from '../../features/search/search'
import Zoom from '../../features/zoom/zoom'
import { useTranslation } from 'react-i18next'
import { getStationDetailsLegend } from '../../features/stationDialog/stationDialogLegend'
import { loadSearchFeature, selectFoundFeatureList } from '../../features/search/store/searchFeatureSlice'
import { RootState } from '../../app/store'
import { generateExtendedPalette } from '../../helpers/colorsHelper'
import { converterToImperialSystem, getUnitTypeFromUnit } from '../../helpers/unitsHelper'

const ResponsiveGridLayout = WidthProvider(Responsive)

const DashboardContainer: React.FC<IDashboardContainerProps> = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const _mainConfig: IMainConfig = useAppSelector(selectMainConfig)
    const _timeZone: string = useAppSelector(selectTimeZone)
    const _mapboxStyle: string = useAppSelector(selectStyle)
    const _mapboxToken: string = useAppSelector(selectMapboxToken)
    const _appStatus: string = useAppSelector(selectStatus)
    const _network: INetwork = useAppSelector(selectNetwork)
    const _clockState = useAppSelector(selectClockState)
    const _mapboxState = useAppSelector(selectMapboxState)
    const _timeseriesState = useAppSelector(selectTimeseriesState)
    const _heatmapState = useAppSelector(selectHeatmapState)
    const _detailsState = useAppSelector(selectDetailsState)
    const _availablePatterns = useAppSelector(selectAvailablePatterns)
    const _availableData = useAppSelector(selectAvailableData)
    const _availableMaxAndMinTimesWithData = useAppSelector(selectMaxAndMinTimesWithData)
    const _viewMode = useAppSelector(selectViewMode)
    const _range = useAppSelector(selectMaxAndMinTimesWithData)
    const _foundFeatureList: IFoundFeature[] = useAppSelector((state: RootState) => selectFoundFeatureList(state, 0))
    const [featureTooltip, setFeatureTooltip] = useState<any>(undefined)
    const [fetching, setFetching] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [layouts, setLayouts] = useState<Layouts>({ lg: [], md: [], sm: [] })
    const [viewModesAvailable, setViewModesAvailable] = useState<IViewMode[]>([])
    const [widgets, setWidgets] = useState<IWidget[]>([])
    const [lineChartOptions, setLineChartOptions] = useState<ApexCharts.ApexOptions>(lineChartWidgetOptionsInitialState)
    const [heatmapOptions, setHeatmapOptions] = useState<ApexCharts.ApexOptions>(heatmapWidgetOptionsInitialState)
    const [detectorsLayer, setDetectorsLayer] = useState<CircleLayer>(detectorsLayerInitialState)
    const [mapbox, setMapbox] = useState<any>(undefined)
    const [rowHeight, setRowHeight] = useState<number>(70)
    const [zoomed, setZoomed] = useState<boolean>(false)
    const [dragged, setDragged] = useState<boolean>(false)
    const [availableDatesAndTimes, setAvailableDatesAndTimes] = useState<AvailableDatesAndTimes>({})
    const [clockWidgetDate, setClockWidgetDate] = useState<DateTime>()
    const [idle, setIdle] = useState<boolean>(false)
    const { time: timeFormat, date: dateFormat } = _moduleConfig.date_format
    const { units } = _moduleConfig
    const clockInterval: number = Number.parseInt(_mainConfig.defaults['clock-interval']) / 60
    const containerName = 'dashboard'

    useEffect(() => {
        setLoading(true)
        setStatus('loading')

        dispatch(loadDataRange())
        dispatch(enableNetworkAssets({ networks: 'all', mapHorizon: 'default' }))
        dispatch(storeModuleName(containerName))
        calculateRowHeight()
        window.addEventListener('resize', calculateRowHeight)
        return () => window.removeEventListener('resize', calculateRowHeight)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (_availableData.length > 0) {
            const _availableDatesAndTimes: any = {}
            _availableData.forEach((t: number) => {
                const currentTime = DateTime.fromMillis(t, { zone: _timeZone })
                const date = currentTime.toISODate() || ''
                const hour = currentTime.get('hour')
                const minute = currentTime.get('minute')
                if (!_availableDatesAndTimes[date]) {
                    _availableDatesAndTimes[date] = {}
                }
                if (!_availableDatesAndTimes[date][hour]) {
                    _availableDatesAndTimes[date][hour] = []
                }
                _availableDatesAndTimes[date][hour].push(minute)
            })
            setAvailableDatesAndTimes(_availableDatesAndTimes)
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_availableData])

    useEffect(() => {
        if (_availablePatterns?.length > 0) {
            const PATTERNS_EXTENDED_PALETTE = generateExtendedPalette(_availablePatterns.length)
            const nextPatternRanges = _availablePatterns.map((pattern: IPatternJson, index: number) => {
                const nextColor: string = PATTERNS_EXTENDED_PALETTE[index]
                return {
                    from: pattern.pattern_id,
                    to: pattern.pattern_id,
                    name: `Pattern ${pattern.pattern_id}`,
                    color: nextColor,
                }
            })
            nextPatternRanges.push({
                from: -1,
                to: 3,
                name: 'No data',
                color: '#dbdbdb',
            })
            setHeatmapOptions({
                ...heatmapOptions,
                colors: PATTERNS_EXTENDED_PALETTE,
                plotOptions: {
                    heatmap: {
                        useFillColorAsStroke: true,
                        enableShades: false,
                        colorScale: {
                            ranges: nextPatternRanges,
                        },
                        radius: 0,
                    },
                },
            })

            setLineChartOptions({
                ...lineChartOptions,
                colors: PATTERNS_EXTENDED_PALETTE,
                tooltip: {
                    custom: options => {
                        let tooltipValue = 'no data'
                        if (options) {
                            const xAxisTimestamp = options.ctx.data.twoDSeriesX[options.dataPointIndex]
                            const dateTime = DateTime.fromMillis(xAxisTimestamp, { zone: _timeZone })
                            const date: string = dateTime.toFormat(dateFormat)
                            const time: string = dateTime.toFormat(timeFormat)
                            let dateString = `${date} | ${time}`
                            if (time === '00:00') {
                                const accurateDate = dateTime.minus({ day: 1 }).toFormat(dateFormat)
                                dateString = `${accurateDate} | 24:00`
                            }
                            let tip: string = `
                                <div class="qm__tooltip">
                                    <p class="qm__tooltip-text">
                                        <span class="qm__tooltip-text--grey">Date: </span> ${dateString}
                                    </p>
                            `
                            const initialSeries = options.w.globals.initialSeries
                            initialSeries.forEach((series: any, index: any) => {
                                let value = options.series[index][options.dataPointIndex]
                                if (!value || value === -1) {
                                    value = tooltipValue
                                }
                                const name = series.name
                                tip += `<p class="qm__tooltip-text">
                                    <span class="qm__tooltip-text--grey">${name}: </span> ${value}
                                    </p>`
                            })
                            tip += `</div>`
                            return tip
                        }
                    },
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_availablePatterns])

    useEffect(() => {
        if (_appStatus === 'ready' && !fetching) {
            const dashboardConfig: IModule | undefined = _moduleConfig.modules.find(
                (module: any) => module.name === 'dashboard'
            )
            const viewModes: IViewMode[] = _moduleConfig.view_mode
            const currentWidgets: IWidget[] = dashboardConfig?.options.widgets

            if (dashboardConfig) {
                const initialViewMode: IViewMode = getViewMode(1, viewModes)
                const dashboardViewModes: IViewMode[] = getAllViewMode('dashboard', viewModes, _moduleConfig.modules)
                currentWidgets.forEach(($widget: IWidget) => {
                    if ($widget.type === 'clock') {
                        dispatch(
                            storeClock({
                                widgetName: $widget.name,
                                date: DateTime.fromMillis(_range.to, { zone: _timeZone }),
                                variable: initialViewMode.variable,
                            })
                        )
                    }
                    if ($widget.type === 'mapbox') {
                        dispatch(storeMapbox({ widgetName: $widget.name, viewMode: initialViewMode }))
                    }
                })
                setViewModesAvailable(dashboardViewModes)
                dispatch(storeViewMode({ viewMode: initialViewMode }))
            }

            setWidgets(currentWidgets)
            generateLayouts(currentWidgets)
            loadLineChartInitialStateWithTooltip(_timeZone, dateFormat, timeFormat, setLineChartOptions)
            dispatch(initPatternsAvailable())
            dispatch(initCalendar())
            setFetching(true)
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_appStatus, _network, loading])

    useEffect(() => {
        widgets
            .filter(($widget: IWidget) => $widget.type === 'mapbox')
            .forEach(($widget: IWidget) => {
                const currentState = _mapboxState[$widget.name]
                const { data } = currentState
                refreshDetectors(data, _viewMode, 'geogkdetector')
                refreshDetectorLayer(_viewMode)
                return $widget
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_mapboxState])

    useEffect(() => {
        if (zoomed || dragged || idle) {
            widgets
                .filter(($widget: IWidget) => $widget.type === 'mapbox')
                .forEach(($widget: IWidget) => {
                    const currentState = _mapboxState[$widget.name]
                    const { data } = currentState
                    refreshDetectors(data, _viewMode, 'geogkdetector')
                    refreshDetectorLayer(_viewMode)
                    return $widget
                })
            setZoomed(false)
            setDragged(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoomed, dragged, idle])

    useEffect(() => {
        if (_clockState.mapClock) setClockWidgetDate(_clockState.mapClock.date)
        const filteredWidgets = widgets.map(($widget: IWidget) => {
            if ($widget.type === 'mapbox') {
                const mapboxState = _mapboxState[$widget.name]
                dispatch(fetchWidgetRealData({ date: mapboxState?.date, widget: $widget, viewMode: _viewMode }))
            }
            return $widget
        })
        if (filteredWidgets.length === 0) {
            console.warn('mapbox widgets not found', filteredWidgets)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_clockState])

    useEffect(() => {}, [_heatmapState, _timeseriesState])
    useEffect(() => {}, [_detailsState])

    useEffect(() => {
        if (featureTooltip) {
            featureTooltip.addTo(mapbox)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureTooltip])

    const calculateRowHeight = () => {
        setRowHeight(window.innerHeight / 18)
    }

    const generateLayouts = ($widgets: IWidget[]) => {
        const layout = $widgets.map((widget: IWidget) => {
            const { name, position, size, isStatic } = widget

            return {
                i: name,
                x: position.x,
                y: position.y,
                w: size.w,
                h: size.h,
                static: isStatic,
            }
        })
        setLayouts({
            lg: layout,
            md: layout,
            sm: layout,
            xs: layout,
            xxs: layout,
        })
    }

    const refreshDetectors = (data: any[], viewMode: IViewMode, sourceLayer: string) => {
        if (_network.detectors.available && mapbox) {
            try {
                const features: Feature<Geometry, GeoJsonProperties>[] = mapbox.querySourceFeatures(
                    'aimsunLive_detectors_source',
                    {
                        sourceLayer,
                    }
                )
                features.forEach(feature => {
                    const foundData: any = data?.find((item: any) => feature.properties?.eid === item.feature_id)
                    const _value = foundData?.value
                    const _variable = viewMode.variable
                    if (_value !== undefined || _value) {
                        const numberValue = Number.parseInt(_value)
                        const _reliability = foundData?.reliability
                        const featureState = {
                            [_variable]: numberValue,
                            reliability: _reliability,
                        }
                        mapbox.setFeatureState(
                            {
                                source: 'aimsunLive_detectors_source',
                                sourceLayer,
                                id: feature.properties?.id,
                            },
                            featureState
                        )
                    } else {
                        const featureState = {
                            [_variable]: -1,
                            reliability: -1,
                        }

                        mapbox.setFeatureState(
                            {
                                source: 'aimsunLive_detectors_source',
                                sourceLayer,
                                id: feature.properties?.id,
                            },
                            featureState
                        )
                    }
                })
            } catch (error) {
                console.log('refreshFeatureStyleData()', error)
            }
        }
    }

    const refreshDetectorLayer = (viewMode: IViewMode) => {
        try {
            setDetectorsLayer({
                id: 'aimsunLive_detectors',
                type: 'circle',
                'source-layer': 'geogkdetector',
                source: 'aimsunLive_detectors_source',
                paint: {
                    'circle-color': getColorStyles(viewMode),
                    'circle-stroke-width': 2,
                    'circle-stroke-color': getBorderDetectorStyles(viewMode),
                },
            })
        } catch (error) {
            console.log('refreshDetectorLayer()', error)
        }
    }

    const handleClockChange = (date: DateTime) => {
        if (date) {
            const maxAvailableDate = DateTime.fromMillis(Number(_availableMaxAndMinTimesWithData.to), {
                zone: _timeZone,
            })
            const minAvailableDate = DateTime.fromMillis(Number(_availableMaxAndMinTimesWithData.from), {
                zone: _timeZone,
            })
            if (date > maxAvailableDate) {
                setClockWidgetDate(maxAvailableDate)
            } else if (date < minAvailableDate) {
                setClockWidgetDate(minAvailableDate)
            } else {
                setClockWidgetDate(date)
            }
        }
    }

    const handleAccept = (widgetName: string, date: DateTime) => {
        saveDate(widgetName, date)
    }

    const saveDate = (widgetName: string, date: DateTime | null) => {
        if (date) {
            widgets
                .filter(({ type }) => type === 'mapbox')
                .forEach(() => {
                    dispatch(storeClock({ widgetName, date, variable: _viewMode.variable }))
                    dispatch(loadPatternSelectedInTime({ widgetName, date }))
                })
        }
    }

    const renderClockInput = (widgetName: string, params: TextFieldProps) => {
        const widgetState = _clockState[widgetName]
        const widgetDate = widgetState.date?.setZone(_timeZone)
        let timeParams = { ...params }
        let dateParams = { ...params }
        if (timeParams?.inputProps?.value) {
            timeParams.inputProps = {
                ...params.inputProps,
                value: widgetDate?.toFormat(_moduleConfig.date_format.time),
            }
        }
        if (dateParams?.inputProps?.value) {
            dateParams.inputProps = {
                ...params.inputProps,
                value: widgetDate?.toFormat(_moduleConfig.date_format.date),
            }
        }

        return <ClockInputRender timeParams={timeParams} dateParams={dateParams} />
    }

    const handleChangeViewMode = ({ widgetName, id }: any) => {
        const nextViewMode: IViewMode = getViewMode(id, _moduleConfig.view_mode)

        dispatch(storeViewMode({ viewMode: nextViewMode }))
        widgets
            .filter(({ type }) => type === 'mapbox')
            .forEach((widget: IWidget) => {
                const currentWidgetState = _mapboxState[widget.name]
                dispatch(
                    changeMapboxViewMode({
                        widgetName,
                        viewMode: nextViewMode,
                        feature: currentWidgetState.feature,
                    })
                )
            })
    }

    const goBack = (widgetName: string, date: DateTime, duration: number) => {
        if (date) {
            const nextDate: DateTime = subtractTimeToDate(date, 'minutes', duration)
            saveDate(widgetName, nextDate)
        }
    }

    const goForward = (widgetName: string, date: DateTime, duration: number) => {
        if (date) {
            const nextDate: DateTime = addTimeToDate(date, 'minutes', duration)
            saveDate(widgetName, nextDate)
        }
    }

    const handleMapboxClick = (widgetName: string, feature: any) => {
        widgets
            .filter(({ type }) => type === 'mapbox')
            .forEach(() => {
                dispatch(
                    changeFeatureSelected({
                        widgetName,
                        featureSelected: feature,
                        viewMode: _viewMode,
                    })
                )
            })
    }

    const isNextTimeAvailable = (date: DateTime, duration: number) => {
        if (date) {
            const nextDate: DateTime = addTimeToDate(date.setZone(_timeZone), 'minutes', duration)
            const nextEpoch: number = nextDate.toMillis()
            return _availableData.indexOf(nextEpoch) === -1
        }
    }

    const isPreviousTimeAvailable = (date: DateTime, duration: number) => {
        if (date) {
            const nextDate: DateTime = subtractTimeToDate(date.setZone(_timeZone), 'minutes', duration)
            const nextEpoch: number = nextDate.toMillis()
            return _availableData.indexOf(nextEpoch) === -1
        }
    }

    const handleZoomIn = (): void => {
        const currentZoom: number = mapbox.getZoom()
        const nextZoom: number = currentZoom + 1
        mapbox.easeTo({ zoom: nextZoom })
    }

    const handleZoomOut = (): void => {
        const currentZoom: number = mapbox.getZoom()
        const nextZoom: number = currentZoom - 1
        mapbox.easeTo({ zoom: nextZoom })
    }

    const handleSearch = (criteria: string): void => {
        if (criteria.length < 4) {
            // setFeedbackMessage(t('feedBackMessage.minimumCharacter', { charNumber: 4 }))
            // setFeedbackOpen(true)
        } else {
            dispatch(
                loadSearchFeature({
                    mapId: 0,
                    criteria: criteria,
                    searchIdentifier: _moduleConfig.searchIdentifier,
                })
            )
        }
    }

    const handleListSearchClick = (featureSelectedId: any): void => {
        const foundSelectedProperties = _foundFeatureList.find((feature: any) => feature.id === featureSelectedId)
        let flyTo = false
        const center = mapbox.getCenter()
        const decimals = 4
        if (
            foundSelectedProperties &&
            (mapbox.getZoom() !== 18 || mapbox.getZoom() !== 14) &&
            center.lng.toFixed(decimals) !== foundSelectedProperties.position[0].toFixed(decimals) &&
            center.lat.toFixed(decimals) !== foundSelectedProperties.position[1].toFixed(decimals)
        ) {
            flyTo = true
        }

        if (flyTo) {
            // @ts-ignore
            mapbox.flyTo({
                center: [foundSelectedProperties!.position[0], foundSelectedProperties!.position[1]],
                zoom: 18,
                duration: 2000,
                essential: true,
            })

            if (featureTooltip) featureTooltip.remove()

            setTimeout(() => {
                if (foundSelectedProperties) {
                    const found = getFeature(
                        featureSelectedId,
                        foundSelectedProperties,
                        mapbox,
                        _moduleConfig.searchIdentifier
                    )
                    if (found?.id) {
                        const coordinates = found.geometry.coordinates
                        const featureId = found.properties.eid
                        const tooltip = new mapboxgl.Popup({ closeButton: false })
                            .setLngLat(coordinates)
                            .setHTML(featureId)
                        setFeatureTooltip(tooltip)
                        goToFeature(found)
                    }
                }
            }, 2050)
        }
    }

    const goToFeature = (feature: any) => {
        const longitude = feature.geometry.coordinates[0]
        const latitude = feature.geometry.coordinates[1]
        mapbox.flyTo({ center: [longitude, latitude], zoom: 18 })
    }

    const renderDetailsIcon = (feature: any) => {
        const circleColor = feature.layer.paint['circle-color']
        const lineColor = feature.layer.paint['line-color']
        const defaultColor = {
            r: 1,
            g: 1,
            b: 1,
        }
        const color = circleColor || lineColor || defaultColor
        const style = {
            background: `rgb(${color.r * 255}, ${color.g * 255}, ${color.b * 255})`,
        }
        if (lineColor) {
            return <CorridorLine style={style} />
        } else {
            return <DetectorCircle style={style} />
        }
    }

    return (
        <ResponsiveGridLayout
            className='layout'
            layouts={layouts}
            rowHeight={rowHeight}
            breakpoints={{ lg: 1920, md: 1440, sm: 1024, xs: 768, xxs: 0 }}
            cols={{ lg: 12, md: 12, sm: 12, xs: 4, xxs: 2 }}
            margin={[0, 0]}
            onWidthChange={() => {
                if (mapbox) {
                    setTimeout(() => {
                        try {
                            mapbox.resize()
                        } catch {}
                    }, 500)
                }
            }}>
            {widgets.map(($widget: IWidget) => {
                if ($widget.type === 'image') {
                    return <LogoContainer mode='compact' key={$widget.name} />
                }

                if ($widget.type === 'toolbar') {
                    return <ToolbarContainer key={$widget.name} />
                }

                if ($widget.type === 'clock') {
                    const widgetName: string = $widget?.name
                    const widgetState = _clockState[widgetName]
                    const clockDate = widgetState?.date || 0

                    if (loading) {
                        return (
                            <Grid
                                container
                                direction='column'
                                justifyContent='center'
                                alignContent='center'
                                alignItems='center'
                                key={$widget.name}
                                style={{
                                    background: '#f3f3f3',
                                }}>
                                <Grid item>
                                    <BarLoader height={5} width={50} />
                                </Grid>
                            </Grid>
                        )
                    } else {
                        return (
                            <WidgetClockWrapper key={widgetName}>
                                <ClockStyledIconButton
                                    id='goBackDay'
                                    disabled={isPreviousTimeAvailable(clockDate, 1440)}
                                    onClick={() => goBack(widgetName, clockDate, 1440)}>
                                    <SkipPrevious />
                                </ClockStyledIconButton>
                                <ClockStyledIconButton
                                    id='goBackHour'
                                    disabled={isPreviousTimeAvailable(clockDate, 60)}
                                    onClick={() => goBack(widgetName, clockDate, 60)}>
                                    <KeyboardDoubleArrowLeftIcon />
                                </ClockStyledIconButton>
                                <ClockStyledIconButton
                                    id='goBackClockInterval'
                                    disabled={isPreviousTimeAvailable(clockDate, clockInterval)}
                                    onClick={() => goBack(widgetName, clockDate, clockInterval)}>
                                    <ChevronLeft />
                                </ClockStyledIconButton>
                                <LocalizationProvider
                                    localeText={{
                                        cancelButtonLabel: t('clockButtons.cancel'),
                                        okButtonLabel: t('clockButtons.ok'),
                                    }}
                                    dateAdapter={AdapterLuxon}
                                    adapterLocale={i18n.language}>
                                    <MobileDateTimePicker
                                        value={clockWidgetDate}
                                        ampm={false}
                                        showToolbar={false}
                                        onAccept={(date: any) => handleAccept(widgetName, date)}
                                        onChange={date => date && handleClockChange(date)}
                                        onClose={() => {
                                            if (clockWidgetDate) {
                                                dispatch(
                                                    loadMonthAvailableData({
                                                        month: clockWidgetDate.month,
                                                        year: clockWidgetDate.year,
                                                    })
                                                )
                                            }
                                        }}
                                        DialogProps={{ style: { color: 'magenta' } }}
                                        minutesStep={clockInterval}
                                        renderInput={(params: any) => renderClockInput(widgetName, params)}
                                        disableIgnoringDatePartForTimeValidation={false}
                                        onMonthChange={(date: DateTime) => {
                                            dispatch(loadMonthAvailableData({ month: date.month, year: date.year }))
                                        }}
                                        shouldDisableDate={(day: DateTime) => {
                                            const date: string = day.toISODate() || ''
                                            const result = availableDatesAndTimes[date]
                                            return !result
                                        }}
                                        shouldDisableTime={(timeValue: number, clockType: ClockPickerView) => {
                                            let result = true
                                            if (clockWidgetDate && Object.keys(availableDatesAndTimes).length) {
                                                const date = clockWidgetDate.toISODate() || ''
                                                const hour = clockWidgetDate.get('hour')
                                                const availableTimes = availableDatesAndTimes[date]

                                                if (clockType === 'hours') {
                                                    result = !!availableTimes[timeValue]
                                                } else if (clockType === 'minutes') {
                                                    if (availableTimes[hour]) {
                                                        result = availableTimes[hour].includes(timeValue)
                                                    }
                                                }
                                            }
                                            return !result
                                        }}
                                    />
                                </LocalizationProvider>
                                <ClockStyledIconButton
                                    id='goForwardClockInterval'
                                    disabled={isNextTimeAvailable(clockDate, clockInterval)}
                                    onClick={() => goForward(widgetName, clockDate, clockInterval)}>
                                    <ChevronRight />
                                </ClockStyledIconButton>
                                <ClockStyledIconButton
                                    id='goForwardHour'
                                    disabled={isNextTimeAvailable(clockDate, 60)}
                                    onClick={() => goForward(widgetName, clockDate, 60)}>
                                    <KeyboardDoubleArrowRightIcon />
                                </ClockStyledIconButton>
                                <ClockStyledIconButton
                                    id='goForwardDay'
                                    disabled={isNextTimeAvailable(clockDate, 1440)}
                                    onClick={() => goForward(widgetName, clockDate, 1440)}>
                                    <SkipNext />
                                </ClockStyledIconButton>
                            </WidgetClockWrapper>
                        )
                    }
                }

                if ($widget.type === 'sidebar') {
                    return <SidebarContainer style={{ background: NAVY_BLUE }} key={$widget.name} />
                }

                if ($widget.type === 'mapbox') {
                    return (
                        <MapboxWidgetContainer key={$widget.name}>
                            <Map
                                initialViewState={{
                                    latitude: _moduleConfig.position.latitude,
                                    longitude: _moduleConfig.position.longitude,
                                    zoom: _moduleConfig.position.zoom,
                                }}
                                mapStyle={_mapboxStyle}
                                mapboxAccessToken={_mapboxToken}
                                reuseMaps={false}
                                keyboard={false}
                                dragRotate={false}
                                touchZoomRotate={false}
                                logoPosition='top-right'
                                interactiveLayerIds={['aimsunLive_detectors']}
                                onLoad={mapboxEvent => {
                                    const nextMap = mapboxEvent.target
                                    nextMap.on('click', 'aimsunLive_detectors', (event: any) => {
                                        const coordinates = event.features[0].geometry.coordinates
                                        const featureId = event.features[0].properties.eid
                                        const currentPopup = new mapboxgl.Popup({ closeButton: false })
                                            .setLngLat(coordinates)
                                            .setHTML(featureId)
                                            .addTo(nextMap)

                                        currentPopup.on('close', function () {
                                            dispatch(reloadGlobalTimeseries($widget.name))
                                        })

                                        handleMapboxClick($widget.name, event.features[0])
                                    })
                                    setMapbox(nextMap)
                                }}
                                onZoomEnd={() => {
                                    setZoomed(true)
                                }}
                                onDragEnd={() => {
                                    setDragged(true)
                                }}
                                onIdle={() => {
                                    setIdle(true)
                                }}>
                                {_network.detectors.available && (
                                    <Source
                                        type='vector'
                                        tiles={[`${window.location.origin}/tiles/default/geogkdetector/{z}/{x}/{y}`]}
                                        maxzoom={_network.detectors.maxzoom}
                                        minzoom={_network.detectors.minzoom}
                                        bounds={_network.detectors.bounds}
                                        id='aimsunLive_detectors_source'>
                                        <Layer {...detectorsLayer} />
                                    </Source>
                                )}
                                <Legend
                                    showLegend={true}
                                    isLoading={false}
                                    fetchingData={false}
                                    initiallyOpen={true}
                                    viewModes={viewModesAvailable}
                                    selectedViewMode={_viewMode}
                                    changeViewMode={handleChangeViewMode}
                                    widgetName={$widget.name}
                                />
                                <LayerControlsContainer>
                                    <Search
                                        handleSearch={handleSearch}
                                        handleListClick={handleListSearchClick}
                                        mapId={0}
                                    />
                                    <Zoom zoomIn={handleZoomIn} zoomOut={handleZoomOut} />
                                </LayerControlsContainer>
                            </Map>
                        </MapboxWidgetContainer>
                    )
                }

                if ($widget.type === 'heatmap') {
                    const currentHeatmapState = _heatmapState[$widget.name]
                    if (currentHeatmapState?.data !== undefined) {
                        const series = currentHeatmapState.data
                        const heatmapHeight = rowHeight * $widget.size.h - 52
                        return (
                            <HeatmapContainer key={$widget.name}>
                                <WidgetTitle variant='h2'> {t(`dashboard.${$widget.title}`)} </WidgetTitle>
                                <Chart
                                    options={{
                                        ...heatmapOptions,
                                        chart: {
                                            ...heatmapOptions.chart,
                                            events: {
                                                dataPointSelection: (event, chartContext, config) => {
                                                    const dataPointIndex = config.dataPointIndex
                                                    const hour = config.w.globals.labels[dataPointIndex]
                                                    const day = config.w.globals.seriesNames[config.seriesIndex]
                                                    const time = `${day} ${hour}`
                                                    const nextDateTime = DateTime.fromFormat(
                                                        time,
                                                        _moduleConfig.date_format.dateTime,
                                                        {
                                                            zone: _timeZone,
                                                        }
                                                    )

                                                    const date = nextDateTime.toMillis()
                                                    if (_availableData.indexOf(date) >= 0) {
                                                        const dashboardConfig: IModule | undefined =
                                                            _moduleConfig.modules.find(
                                                                (module: any) => module.name === 'dashboard'
                                                            )
                                                        const connections = dashboardConfig?.options.connections
                                                        const heatmaps = connections.find(
                                                            (item: any) => item.to.type === 'heatmap'
                                                        )
                                                        for (let index in heatmaps) {
                                                            const current = heatmaps[index]
                                                            saveDate(current.name, nextDateTime)
                                                        }
                                                    }
                                                },
                                            },
                                        },
                                        xaxis: {
                                            labels: {
                                                style: {
                                                    fontFamily: 'AimsunLight',
                                                },
                                                formatter: (value, timestamp, opts) => {
                                                    if (value) {
                                                        if (opts?.i === 0) return '00:00'
                                                        if (opts?.i === series[0].data.length - 1) return '24:00'
                                                    }
                                                    return ''
                                                },
                                            },
                                            title: {
                                                text: 'Time',
                                                offsetY: -15,
                                                style: {
                                                    fontFamily: 'AimsunLight',
                                                    fontSize: '14px',
                                                },
                                            },
                                            tooltip: {
                                                enabled: false,
                                            },
                                        },
                                        yaxis: {
                                            labels: {
                                                style: {
                                                    fontSize: '12px',
                                                    fontFamily: 'AimsunLight',
                                                },
                                                formatter: (value: number): string => {
                                                    const dateTime = DateTime.fromFormat(value.toString(), 'dd-MM-yyyy')
                                                    const date: string = dateTime.toFormat(dateFormat)
                                                    return date !== 'Invalid DateTime' ? date : ''
                                                },
                                            },
                                            title: {
                                                text: 'Date',
                                                style: {
                                                    fontFamily: 'AimsunLight',
                                                    fontSize: '14px',
                                                },
                                                rotate: 0,
                                                offsetX: -10,
                                            },
                                        },
                                    }}
                                    type='heatmap'
                                    series={series}
                                    height={heatmapHeight}
                                />
                            </HeatmapContainer>
                        )
                    } else {
                        return (
                            <Grid
                                container
                                direction='column'
                                justifyContent='center'
                                alignContent='center'
                                alignItems='center'
                                key={$widget.name}
                                style={{
                                    background: '#f3f3f3',
                                }}>
                                <Grid item>
                                    <GridLoader size={26} margin={7} color={RED_LIVE} />
                                </Grid>
                            </Grid>
                        )
                    }
                }

                if ($widget.type === 'timeseries') {
                    const currentTimeSeriesState = _timeseriesState[$widget.name]
                    if (currentTimeSeriesState?.series !== undefined) {
                        const type = getUnitTypeFromUnit(_viewMode.units.main)
                        let series: any[] = []
                        if (units === 'metric') {
                            series = currentTimeSeriesState.series
                        } else if (type) {
                            series = currentTimeSeriesState.series.map((serie: any) => {
                                return serie.data.map((entry: any) => {
                                    return converterToImperialSystem(type, entry.y)
                                })
                            })
                        }
                        const timeseriesHeight = rowHeight * $widget.size.h - 52
                        const pattern = `Pattern ${currentTimeSeriesState.pattern?.pattern_id}`
                        const match = series.find((serie: any) => serie.name === pattern)
                        const currentPatternIndex = series.indexOf(match)
                        const strokesWidth = series.map((_: any, i: number) => (i === currentPatternIndex ? 4 : 1))
                        return (
                            <TimeseriesContainer key={$widget.name}>
                                <WidgetTitle variant='h2'>
                                    {t(`dashboard.${$widget.title}`)}{' '}
                                    <span>
                                        {currentTimeSeriesState.featureSelected
                                            ? currentTimeSeriesState.featureSelected.properties.eid
                                            : 'global'}
                                    </span>
                                </WidgetTitle>
                                <Chart
                                    options={{
                                        ...lineChartOptions,
                                        yaxis: {
                                            ...lineChartOptions.yaxis,
                                            title: {
                                                text:
                                                    _viewMode.variable.charAt(0).toUpperCase() +
                                                    _viewMode.variable.slice(1),
                                                style: {
                                                    fontFamily: 'AimsunLight',
                                                    fontSize: '14px',
                                                },
                                                rotate: 0,
                                                offsetX: -10,
                                            },
                                            labels: {
                                                style: {
                                                    fontFamily: 'AimsunLight',
                                                },
                                            },
                                        },
                                        xaxis: {
                                            labels: {
                                                style: {
                                                    fontFamily: 'AimsunLight',
                                                    fontSize: '14px',
                                                },
                                                formatter: (value, timestamp, opts) => {
                                                    if (value) {
                                                        if (opts?.i === 0) return '00:00'
                                                        if (opts?.i === series[0].data.length - 1) {
                                                            const date = DateTime.fromMillis(+value, {
                                                                zone: _timeZone,
                                                            })
                                                            if (date.hour === 0) {
                                                                return '24:00'
                                                            }
                                                            return date.toFormat(_moduleConfig.date_format.time)
                                                        }
                                                    }
                                                    return ''
                                                },
                                            },
                                            title: {
                                                text: 'Time',
                                                offsetY: -15,
                                                style: {
                                                    fontFamily: 'AimsunLight',
                                                    fontSize: '14px',
                                                },
                                            },
                                            tooltip: {
                                                enabled: false,
                                            },
                                        },
                                        noData: {
                                            text: 'No data',
                                            align: 'center',
                                            verticalAlign: 'middle',
                                            style: {
                                                color: SMOKE,
                                                fontSize: '2.5rem',
                                            },
                                        },
                                        legend: {
                                            position: 'right',
                                        },
                                        stroke: {
                                            width: strokesWidth,
                                        },
                                    }}
                                    type='line'
                                    series={series}
                                    height={timeseriesHeight}
                                />
                            </TimeseriesContainer>
                        )
                    } else {
                        return (
                            <Grid
                                container
                                direction='column'
                                justifyContent='center'
                                alignContent='center'
                                alignItems='center'
                                key={$widget.name}
                                style={{
                                    background: '#f3f3f3',
                                }}>
                                <Grid item>
                                    <GridLoader size={26} margin={7} color={RED_LIVE} />
                                </Grid>
                            </Grid>
                        )
                    }
                }

                if ($widget.type === 'details') {
                    const currentDetailsState = _detailsState[$widget.name]
                    const detailsHeight = rowHeight * $widget.size.h - 52
                    if (currentDetailsState) {
                        const type = getUnitTypeFromUnit(_viewMode.units.main)
                        let series: ApexAxisChartSeries = []
                        if (units === 'metric') {
                            series = currentDetailsState.data
                        } else if (type) {
                            series = currentDetailsState.data.map((serie: any) => {
                                return serie.data.map((entry: any) => {
                                    return converterToImperialSystem(type, entry.y)
                                })
                            })
                        }
                        let titleWidget
                        if (currentDetailsState.featureSelected) {
                            const isCorridor = currentDetailsState.featureSelected.layer.type === 'line'
                            const displayedId = isCorridor
                                ? currentDetailsState.featureSelected?.layer.source
                                : currentDetailsState.featureSelected?.properties.eid
                            titleWidget = (
                                <WidgetTitleFlex variant='h2'>
                                    {renderDetailsIcon(currentDetailsState.featureSelected)}
                                    {isCorridor ? `${t('dashboard.corridor')} ${displayedId}` : displayedId}
                                </WidgetTitleFlex>
                            )
                        } else {
                            titleWidget = <WidgetTitle variant='h2'>{t(`dashboard.${$widget.title}`)}</WidgetTitle>
                        }

                        return (
                            <DetailsContainer key={$widget.name}>
                                {titleWidget}

                                <Chart
                                    options={{
                                        ...detailsChartOptionsInitialState,
                                        annotations: {
                                            xaxis: [
                                                {
                                                    ...detailsChartOptionsInitialState.annotations?.xaxis![0],
                                                    x: currentDetailsState.date?.setZone(_timeZone)?.toMillis(),
                                                },
                                            ],
                                        },
                                        xaxis: {
                                            labels: {
                                                hideOverlappingLabels: true,
                                                formatter: function (val: string) {
                                                    const date = DateTime.fromMillis(Number(val), { zone: _timeZone })
                                                    if (date.minute === 0) {
                                                        return date.toFormat(_moduleConfig.date_format.time)
                                                    }
                                                    return ''
                                                },
                                                style: {
                                                    fontFamily: 'AimsunLight',
                                                },
                                            },
                                            tooltip: {
                                                enabled: false,
                                            },
                                        },
                                        yaxis: {
                                            ...detailsChartOptionsInitialState.yaxis,
                                            title: {
                                                rotate: 0,
                                                offsetX: -10,
                                                text: _viewMode.name,
                                                style: {
                                                    fontFamily: 'AimsunLight',
                                                    fontSize: '14px',
                                                },
                                            },
                                        },
                                        tooltip: {
                                            enabled: true,
                                            custom: options => {
                                                if (options) {
                                                    const patternMaxDataSeries: any[] = options.w.config.series[0].data
                                                    const patternMinDataSeries: any[] = options.w.config.series[1].data
                                                    const realDataSeries: any[] = options.w.config.series[2].data
                                                    const reliabilityDataSeries: any[] = options.w.config.series[3].data

                                                    const dataPointIndex = options.dataPointIndex

                                                    if (patternMaxDataSeries.length === 0) {
                                                        return null
                                                    }

                                                    return StationDialogTooltip({
                                                        timeZone: _timeZone,
                                                        hour: realDataSeries[dataPointIndex].x,
                                                        real: realDataSeries[dataPointIndex].y,
                                                        dataReliability: reliabilityDataSeries[dataPointIndex].y,
                                                        patterMin: patternMinDataSeries.length
                                                            ? patternMinDataSeries[dataPointIndex].y
                                                            : null,
                                                        patterMax: patternMaxDataSeries.length
                                                            ? patternMaxDataSeries[dataPointIndex].y
                                                            : null,
                                                        sensorReliability: null,
                                                        prediction: null,
                                                        viewMode: _viewMode,
                                                    })
                                                }
                                            },
                                        },
                                        legend: {
                                            show: true,
                                            onItemClick: {
                                                toggleDataSeries: false,
                                            },
                                            position: 'right',
                                            markers: {
                                                width: 0,
                                            },
                                            customLegendItems: getStationDetailsLegend(t),
                                        },
                                    }}
                                    series={series}
                                    type={'line'}
                                    height={detailsHeight}
                                />
                            </DetailsContainer>
                        )
                    } else {
                        return (
                            <Grid
                                container
                                direction='column'
                                justifyContent='center'
                                alignContent='center'
                                alignItems='center'
                                key={$widget.name}
                                style={{
                                    background: '#f3f5f0',
                                }}>
                                <Grid item>
                                    <GridLoader size={26} margin={7} color={RED_LIVE} />
                                </Grid>
                            </Grid>
                        )
                    }
                }

                return (
                    <Card key={$widget.name}>
                        <CardContent>
                            <Typography variant='body1'>Type not found for {JSON.stringify($widget)}</Typography>
                        </CardContent>
                    </Card>
                )
            })}
        </ResponsiveGridLayout>
    )
}

export default DashboardContainer
