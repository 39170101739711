import { DateTime } from 'luxon'
import { NAVY_BLUE, RED_LIVE, SMOKE, WHITE } from '../../theme'
import { PATTERNS_PALETTE } from '../../features/qualityManagerDialog/qualityManagerDialogOptions'

export const detailsChartOptionsInitialState: ApexCharts.ApexOptions = {
    annotations: {
        xaxis: [
            {
                strokeDashArray: 0,
                borderColor: RED_LIVE,
                borderWidth: 1,
            },
        ],
    },
    chart: {
        animations: {
            speed: 500,
        },
        type: 'rangeArea',
        toolbar: {
            show: false,
        },
        redrawOnParentResize: true,
        zoom: {
            enabled: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    fill: {
        opacity: [0.24, 1, 1],
    },
    stroke: {
        curve: 'straight',
        width: [1, 1, 2, 2],
        colors: [RED_LIVE, RED_LIVE, NAVY_BLUE],
    },
    markers: {
        size: [0.000001, 0.000001, 2],
        strokeColors: [RED_LIVE, RED_LIVE, NAVY_BLUE],
        colors: [WHITE, WHITE, NAVY_BLUE],
        hover: {
            sizeOffset: 0,
        },
    },
    grid: {
        show: true,
        xaxis: {
            lines: {
                show: true,
            },
        },
        yaxis: {
            lines: {
                show: true,
            },
        },
        position: 'front',
    },
    yaxis: {
        labels: {
            formatter: function (val) {
                return Number(val).toFixed(0)
            },
            show: true,
            style: {
                fontSize: '10px',
                fontFamily: 'AimsunLight',
            },
        },
    },
    xaxis: {
        axisTicks: {
            show: true,
        },
        labels: {
            hideOverlappingLabels: true,
            formatter: function (val: string, timestamp?: number | undefined, opts?: any) {
                const date = DateTime.fromMillis(Number(val))
                if (date.minute === 0) {
                    return date.toFormat('HH:mm')
                }
                return ''
            },
            style: {
                fontFamily: 'AimsunLight',
            },
        },
        title: {
            text: 'Time',
            offsetY: -15,
            style: {
                fontFamily: 'AimsunLight',
                fontSize: '14px',
            },
        },
        tooltip: {
            enabled: false,
        },
        tickAmount: 'dataPoints',
        type: 'numeric',
    },
    noData: {
        text: 'No data',
        align: 'center',
        verticalAlign: 'middle',
        style: {
            color: SMOKE,
            fontSize: '2.5rem',
        },
    },
}

export const heatmapWidgetOptionsInitialState: ApexCharts.ApexOptions = {
    tooltip: {
        custom: options => {
            let _tooltipValue = 'No data'
            if (options) {
                const dataPointIndex = options.dataPointIndex
                const cellTime = options.w.globals.labels[dataPointIndex]
                const dataRow = options.series[options.seriesIndex]
                const day = options.w.globals.seriesNames[options.seriesIndex]
                if (dataRow[options.dataPointIndex] !== -1) _tooltipValue = dataRow[options.dataPointIndex]

                return `
                <div class="qm__tooltip">
                    <p class="qm__tooltip-text">
                        <span class="qm__tooltip-text--grey">Date: </span> ${day}
                    </p>
                    <p class="qm__tooltip-text">
                        <span class="qm__tooltip-text--grey">Time: </span> ${cellTime}
                    </p>
                    <p class="qm__tooltip-text">
                        <span class="qm__tooltip-text--grey">Pattern: </span> ${_tooltipValue}
                    </p>
                </div>
                `
            }
        },
    },
    states: {
        normal: {
            filter: {
                type: 'none',
                value: 0,
            },
        },
        hover: {
            filter: {
                type: 'lighten',
                value: 0.15,
            },
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'darken',
                value: 0.7,
            },
        },
    },
    chart: {
        animations: {
            enabled: false,
            animateGradually: {
                enabled: false,
            },
            dynamicAnimation: {
                enabled: false,
            },
        },
        toolbar: {
            show: false,
        },
    },
    legend: {
        show: true,
        position: 'right',
    },
    dataLabels: {
        enabled: false,
    },
    xaxis: {
        labels: {
            hideOverlappingLabels: true,
            style: {
                fontFamily: 'AimsunLight',
            },
        },
        title: {
            text: 'Time',
            style: {
                fontSize: '18px',
                fontFamily: 'AimsunLight',
            },
        },
    },
    yaxis: {
        labels: {
            show: true,
            style: {
                fontSize: '16px',
                fontFamily: 'AimsunLight',
            },
        },
        title: {
            text: 'Date',
            style: {
                fontFamily: 'AimsunLight',
                fontSize: '14px',
            },
            rotate: 0,
            offsetX: -10,
        },
    },
    noData: {
        text: 'No data',
        align: 'center',
        verticalAlign: 'middle',
        style: {
            color: SMOKE,
            fontSize: '2.5rem',
        },
    },
}

export const lineChartWidgetOptionsInitialState: ApexCharts.ApexOptions = {
    colors: PATTERNS_PALETTE,
    chart: {
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false,
        },
    },
    stroke: {
        width: 2,
    },
    grid: {
        show: true,
        xaxis: {
            lines: {
                show: false,
            },
        },
        yaxis: {
            lines: {
                show: true,
            },
        },
        position: 'front',
    },
}
