import React, { useEffect, useMemo, useState } from 'react'
import { Typography, MenuItem, CardContent, Grid, SelectChangeEvent } from '@mui/material'
import { Label as LabelIcon } from '@mui/icons-material'
import { EXISTING_CONGESTION, NO_DATA } from '../../theme'
import {
    LegendCardStyled,
    LegendHeader,
    LegendViewModeSelect,
    LegendMenuItemHeader,
    LegendCloseIcon,
    LegendUnits,
    LegendRangeWrapper,
} from './legendStyles'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { useAppSelector } from '../../app/hooks'
import { selectConfig } from '../core/coreSlice'
import {
    convertUnitsFromMetricToImperial,
    converterToImperialSystem,
    getUnitTypeFromUnit,
} from '../../helpers/unitsHelper'

const Legend: React.FC<ILegendProps> = ({
    showLegend,
    isLoading,
    viewModes,
    selectedViewMode,
    fetchingData,
    initiallyOpen,
    changeViewMode = () => {},
    widgetName,
    containerName,
}: ILegendProps) => {
    const { t } = useTranslation()
    const [collapse, setCollapse] = useState<boolean>(initiallyOpen)
    const [unit, setUnit] = useState<string>(selectedViewMode.units.main)
    const _moduleConfig: IModuleConfig = useAppSelector(selectConfig)
    const { units } = _moduleConfig

    const unitType = useMemo(() => getUnitTypeFromUnit(selectedViewMode.units.main), [selectedViewMode])

    useEffect(() => {
        if (units === 'metric') {
            setUnit(selectedViewMode.units.main)
        } else {
            setUnit(convertUnitsFromMetricToImperial(selectedViewMode.units.main))
        }
    }, [selectedViewMode, units])

    const toggleLegend = (): void => {
        setCollapse(!collapse)
    }

    const handleViewModeChange = (ev: SelectChangeEvent<unknown>): void => {
        const viewModeId: number = ev.target && Number(ev.target.value)
        if (widgetName) {
            changeViewMode({ widgetName, id: viewModeId })
        } else {
            changeViewMode(viewModeId)
        }
    }

    const getNameString = (range: IRange): string => {
        let range_ = {
            ...range,
        }
        if (units === 'imperial') {
            if (unitType) {
                range_.min = Math.round(converterToImperialSystem(unitType, range_.min))
                if (range_.max) {
                    range_.max = Math.round(converterToImperialSystem(unitType, range_.max))
                }
            }
        }
        if (!range_.max) {
            return `${range_.min} - inf`
        }
        return `${range_.min} - ${range_.max}`
    }

    const getLegendItems = (): { name: string; color: string }[] => {
        const items = selectedViewMode.styles.ranges.main.map((range: IRange) => {
            return {
                name: getNameString(range),
                color: range.color,
            }
        })

        if (selectedViewMode.variable === 'congestion_impact') {
            items.push({
                name: t('common.existingCongestion'),
                color: EXISTING_CONGESTION,
            })
        }

        items.push({
            name: t('common.noData'),
            color: NO_DATA,
        })

        return items
    }

    const legendItems: { name: string; color: string }[] = getLegendItems()

    return (
        <LegendCardStyled
            style={{
                display: showLegend ? 'block' : 'none',
                width: containerName === 'QM' ? 295 : 210,
            }}>
            {!isLoading && selectedViewMode !== null && (
                <LegendHeader>
                    <LegendViewModeSelect
                        id='variableSelector'
                        variant='standard'
                        disabled={fetchingData || containerName === 'QM'}
                        value={selectedViewMode?.id}
                        onChange={ev => handleViewModeChange(ev)}>
                        <LegendMenuItemHeader key='default' value=''>
                            <Typography>{t('mapbox.selectViewMode')}</Typography>
                        </LegendMenuItemHeader>
                        {viewModes.map(({ id, label }: IViewMode) => {
                            return (
                                <MenuItem key={id} value={id}>
                                    {i18n.exists(label) ? t(label) : label}
                                </MenuItem>
                            )
                        })}
                    </LegendViewModeSelect>
                    <LegendCloseIcon
                        id='legendVisibilityControl'
                        className={collapse ? '' : 'legend__close--collapsed'}
                        onClick={toggleLegend}
                    />
                    <LegendUnits variant='body2'>
                        {selectedViewMode && i18n.exists(`units.${unit}`) ? t(`units.${unit}`) : unit}
                    </LegendUnits>
                </LegendHeader>
            )}
            {collapse && !isLoading && (
                <CardContent id='legendContent' className='legend__content'>
                    <Grid container direction='column' spacing={2}>
                        {legendItems?.map((legendItem, index) => {
                            return (
                                <LegendRangeWrapper item key={index}>
                                    <Grid container direction='row' alignItems='center' spacing={1}>
                                        <Grid item>
                                            <LabelIcon htmlColor={legendItem.color}></LabelIcon>
                                        </Grid>
                                        <Grid item>
                                            <Typography>{legendItem.name}</Typography>
                                        </Grid>
                                    </Grid>
                                </LegendRangeWrapper>
                            )
                        })}
                    </Grid>
                </CardContent>
            )}
        </LegendCardStyled>
    )
}

export default Legend
