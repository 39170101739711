import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { selectAllMapData } from '../features/mapbox/mapboxSlice'

// Typing redux hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useFetchingData = () => {
    const _allMapData: IDataByMap[] = useSelector(selectAllMapData)
    return _allMapData.some((mapData) => mapData.fetchingData);
}
