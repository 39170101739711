import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'
import {
    getMainConfig,
    getModuleConfig,
    getNowTime,
    getPtLinesJson,
    getTimeZone,
    getVariableTypesWS,
    getPolicies,
    getStrategy,
} from '../../services/core/coreService'
import {
    initialSetup,
    storeConfig,
    storeTimeZone,
    storeMainConfig,
    storeNowTime,
    storeSystemServices,
    setRealDataUpdates,
    updateNowTime,
    selectNowTime,
    storeVariableTypesWS,
    getPtLines,
    storePtLines,
    storeStrategy,
    storePolicies,
    selectConfig,
} from './coreSlice'
import { getSystemServices } from '../../services/systemStatus/systemStatusService'

function* init(): Generator<unknown, any, unknown> {
    yield all([
        yield call(fetchMainConfig),
        yield call(fetchNowTime),
        yield call(fetchModuleConfig),
        yield call(fetchVariableTypes),
        yield call(fetchTimeZone),
        yield call(fetchSystemServices),
        yield call(fetchStrategy),
        yield call(fetchPolicies),
    ])
}

function* fetchModuleConfig(): Generator<unknown, any, IModuleConfig> {
    const moduleConfig: IModuleConfig = yield call(getModuleConfig)
    yield put(storeConfig(moduleConfig))
}

function* fetchVariableTypes(): Generator<unknown, any, IVariablesTypeWS> {
    const variablesTypeWS: IVariablesTypeWS = yield call(getVariableTypesWS)
    yield put(storeVariableTypesWS(variablesTypeWS))
}

function* fetchMainConfig(): Generator<unknown, any, IMainConfig> {
    const mainConfig: IMainConfig = yield call(getMainConfig)
    yield put(storeMainConfig(mainConfig))
}

function* fetchNowTime(): Generator<unknown, any, INowTime> {
    const nowTime: INowTime = yield call(getNowTime)
    yield put(storeNowTime(nowTime.time))
}

function* fetchTimeZone(): Generator<unknown, any, ITimeZone> {
    const tz: ITimeZone = yield call(getTimeZone)
    yield put(storeTimeZone(tz.name))
}

function* fetchSystemServices(): Generator<unknown, any, any> {
    const servicestxt = yield call(getSystemServices)
    const services = servicestxt.split(/\r?\n/)
    yield put(storeSystemServices(services))
}

function* fetchPtLinesServices(): Generator<unknown, any, any> {
    const ptLines = yield call(getPtLinesJson)

    yield put(storePtLines(ptLines))
}

function* fetchStrategy(): Generator<unknown, any, any> {
    const strategies = yield call(getStrategy)
    const sortedStrategies = strategies.sort((a: any, b: any) => {
        if (a.eid < b.eid) return -1
        else if (a.eid > b.eid) return 1
        return 0
    })
    yield put(storeStrategy(sortedStrategies))
}

function* fetchPolicies(): Generator<unknown, any, any> {
    const policies = yield call(getPolicies)
    yield put(storePolicies(policies))
}

export function* updateGetNowTime() {
    const config: IModuleConfig = yield select(selectConfig)
    const currentModule = config.modules.find(module => module.url === window.location.pathname.split('?')[0])
    if (currentModule?.usesWebsocket !== false) {
        const selectNT: number = yield select(selectNowTime)
        const nowTime: INowTime = yield* monitorNowTime(selectNT)
        yield put(storeNowTime(nowTime.time))
    } else {
        console.warn(`now_time not used for module ${currentModule?.name}`)
    }
}

function* monitorNowTime(previousValue: number, takePattern = '*') {
    while (true) {
        const nextValue: INowTime = yield call(getNowTime)
        if (nextValue && nextValue.time !== previousValue) {
            return nextValue
        }
        yield take(takePattern)
    }
}

function* coreSaga() {
    // @ts-ignore
    yield all([
        takeLatest(initialSetup, init),
        takeLatest(setRealDataUpdates, updateGetNowTime),
        takeLatest(updateNowTime, fetchNowTime),
        takeLatest(getPtLines, fetchPtLinesServices),
    ])
}

export default coreSaga
