import { all, AllEffect, call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { getDataByHorizon } from '../../../services/mapData/mapDataService'
import { loadAnalyticalData, setFetchingAnalyticalData, storeAnalyticalData } from './analyticalDataSlice'
import { getMarks } from '../../../helpers/SliderMarksHelper'
import { selectConfig } from '../coreSlice'

export function* initAnalyticalData(action: any) {
    yield call(fetchAnalyticalData, action.payload)
    yield put(setFetchingAnalyticalData(false))
}

function* fetchAnalyticalData({ epoch, variable }: { epoch: number; variable: string }): any {
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const step: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const marks = getMarks(step, numberOfHorizon, true, variable)

    const analyticalData: IPredictionByHorizonData[] = yield all(
        marks.map(horizon => call(getDataByHorizon, epoch, horizon.variable, 'analytical', horizon.value))
    )
    yield put(storeAnalyticalData({ data: analyticalData, variable }))
}

function* analyticalDataSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(loadAnalyticalData, initAnalyticalData)])
}

export default analyticalDataSaga
