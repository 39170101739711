import { getRequestAPI, postRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'
import { client } from '../apiClient/apiClient'

export function getEvaluations(): Promise<AxiosResponse<IEvaluationStatic[]>> {
    return getRequestAPI(`/api_dm/evaluations`, { cache: false })
}

export function getEvaluation(id: number): Promise<AxiosResponse<IEvaluationStatic>> {
    return getRequestAPI(`/api_dm/evaluations?id=${id}`)
}

export async function createManageDemoEvaluation(evaluation: IEvaluationStatic): Promise<AxiosResponse<any>> {
    return await postRequestAPI(`/api_dm/evaluations`, evaluation)
}

export async function deleteManageDemoEvaluation(pack_id: number): Promise<AxiosResponse<any, any>> {
    const config = {
        headers: {
            'X-HTTP-Method-Override': 'DELETE',
            Accept: 'application/json',
        },
    }
    return await client.post(`/api_dm/evaluations?pack_id=${pack_id}`, '', config).then(res => res)
}

export function getLandUseEvaluations(map: string): Promise<AxiosResponse<number>> {
    return getRequestAPI(`/api_landuse/evaluations?map=${map}`, { cache: false })
}

export async function createLandUseEvaluations(evaluation: IEvaluationStatic): Promise<AxiosResponse<any>> {
    return await postRequestAPI(`/api_landuse/evaluation`, evaluation)
}
