import React from 'react'
import { Grid, Typography } from '@mui/material'
import MapRounded from '@mui/icons-material/MapRounded'
import { ViewsToolbarWrapper } from './viewsStyles'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectViewOpen, toggleViews } from '../viewSettings/viewSettingsSlice'
import ViewSettings from '../viewSettings/viewSettings'
import { RED_LIVE } from '../../theme'
import { useTranslation } from 'react-i18next'

const Views: React.FC<IViewsProps> = () => {
    const { t } = useTranslation()
    const _viewsOpen = useAppSelector(selectViewOpen)
    const dispatch = useAppDispatch()
    return (
        <>
            <ViewsToolbarWrapper onClick={() => dispatch(toggleViews())} sx={{ '&:hover': { color: RED_LIVE } }}>
                <Grid item key='views-icon'>
                    <Typography color={_viewsOpen ? RED_LIVE : 'inherit'}>
                        <MapRounded />
                    </Typography>
                </Grid>
                <Grid item key='views-title'>
                    <Typography color={_viewsOpen ? RED_LIVE : 'inherit'}>{t('views.views')}</Typography>
                </Grid>
            </ViewsToolbarWrapper>
            <ViewSettings />
        </>
    )
}

export default Views
