import * as React from 'react'
import {
    Box,
    CardContent,
    Checkbox,
    Grid,
    Slide,
    Typography,
} from '@mui/material'
import {
    CardPtLinesModalStyled,
    CardHeaderPtModalStyled,
    PtLineModalTitle, PtLineInfoWrapper, PtLineInfoContent,
} from './ptLinesModalStyles'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getPtLines, selectPtLines } from '../core/coreSlice'
import { selectViewOpen, selectViewsLayout } from '../viewSettings/viewSettingsSlice'
import { useTranslation } from 'react-i18next'

export const PtLinesModal: React.FC<PtLinesModalProps> = ({ height, mapId, highlightPtLine, clearPtLine, checkedPtLine}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const _ptLines = useAppSelector(selectPtLines)
    const _openViews = useAppSelector(selectViewOpen)
    const _viewsLayout = useAppSelector(selectViewsLayout)


    useEffect(() => {
        dispatch(getPtLines())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const highlightPtLineRoute = (ptLine: any) => {
        highlightPtLine(ptLine)

    }

    const clearPtLineRoute = () => {
        clearPtLine()
    }

    const handlePtLineChecked = (event: any, ptLine: any) => {
        checkedPtLine(event.target.checked, ptLine)
    }

    const cardTitle = <PtLineModalTitle>{t('mapbox.publicTransportLine')}</PtLineModalTitle>
    const mapIdeToDown = _viewsLayout === 4 ? 1 : 0

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <CardPtLinesModalStyled style={{height: `${height * 0.6}px`, marginTop:`${_openViews && mapId === mapIdeToDown ?  160: 0}px` }}>
                <CardHeaderPtModalStyled
                    title={cardTitle}
                />
                <CardContent style={{height: '95%'}}>
                    <PtLineInfoWrapper>
                        <Grid item key='title' className='action-maker__title-container'>
                            <Typography className='action-maker__title' color='primary'>
                                {t('mapbox.selectPtLine')}
                            </Typography>
                        </Grid>
                        <PtLineInfoContent>
                            {_ptLines.map((ptLine: any, idx: any) => {

                                return (
                                    <Box key={ptLine.data.eid} style={{margin: '4px 0'}} display='flex' flexDirection='row' alignItems='center'>
                                        <Checkbox
                                            onMouseEnter={() => highlightPtLineRoute(ptLine)}
                                            onMouseLeave={() => clearPtLineRoute()}
                                            onChange={(event) => handlePtLineChecked(event, ptLine)}
                                            value={ptLine.data.id}
                                            tabIndex={idx}
                                            color='primary'
                                        />
                                        <Typography
                                            style={{
                                                marginLeft: '5px',
                                                marginRight: '10px',
                                                marginTop: '2px',
                                            }}>
                                            {ptLine.data.name}
                                        </Typography>

                                    </Box>
                                )
                            })}
                        </PtLineInfoContent>
                    </PtLineInfoWrapper>
                </CardContent>
            </CardPtLinesModalStyled>
        </Slide>
    )
}
