import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

const initialState: IHorizonState = {
    horizon: 0,
}

export const horizonSlice = createSlice({
    name: 'horizon',
    initialState,
    reducers: {
        changeHorizon: (state: IHorizonState, action: PayloadAction<number>): void => {},
        storeHorizon: (state: IHorizonState, action: PayloadAction<number>): void => {
            state.horizon = action.payload
        },
    },
})

export const { storeHorizon, changeHorizon } = horizonSlice.actions

export const selectHorizon = (state: RootState): number => state.horizon.horizon

export default horizonSlice.reducer
