import React from 'react'
import { TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { QmDialogTableTypographyHeader } from '../qualityManagerDialogStyles'
import { useTranslation } from 'react-i18next'

const QualityManagerDialogTableHead: React.FC<IQualityManagerDialogTableHeadProps> = ({
    order,
    orderBy,
    onRequestSort,
    horizons,
    isDelayTimeSubPath,
}: IQualityManagerDialogTableHeadProps) => {
    const { t } = useTranslation()
    const TypoValue = ({ sortValue }: { sortValue: string }) => {
        return (
            <QmDialogTableTypographyHeader>
                {sortValue === t('qualityManager.global') || sortValue === t('qualityManager.time') ?
                    sortValue : isDelayTimeSubPath ? sortValue : `t +${sortValue}`}
            </QmDialogTableTypographyHeader>
        )
    }

    const HeaderCell = ({
        id,
        align,
        rowSpan,
        colSpan
    }: {
        id: string
        align: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined
        rowSpan: number
        colSpan: number
    }) => {
        return (
            <TableCell
                align={align}
                rowSpan={rowSpan}
                colSpan={colSpan}
                sortDirection={orderBy === id ? order : false}>
                <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : 'asc'}
                    onClick={createSortHandler(id)}>
                    <TypoValue sortValue={id} />
                    {orderBy === id ? (
                        <Box component='span' sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                    ) : null}
                </TableSortLabel>
            </TableCell>
        )
    }

    const createSortHandler = (property: string) => (event: any) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead
            sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                "& .MuiTableCell-root": { backgroundColor: "background.paper" },
            }}
        >
            <TableRow>
                <HeaderCell id={t('qualityManager.time')} rowSpan={isDelayTimeSubPath ? 2 : 1} colSpan={1} align='left' />
                {horizons &&
                    horizons.map(({ value }: IQualityManagerHorizon, index: number) => (
                        <HeaderCell key={'header_cell' + index} id={value} rowSpan={1} colSpan={isDelayTimeSubPath ? 2 : 1}
                                    align='center' />
                    ))
                }
            </TableRow>
            <TableRow>
                {isDelayTimeSubPath && horizons &&
                    horizons.map(({ value }: IQualityManagerHorizon, index: number) => (
                        <React.Fragment key={'blufax_prediction' + index}>
                            <TableCell  id={value} rowSpan={1} colSpan={1} align='center' sortDirection={false}>
                                <QmDialogTableTypographyHeader>
                                    {t('qualityManager.blufaxDelay')}
                                </QmDialogTableTypographyHeader>
                            </TableCell>
                            <TableCell id={value} rowSpan={1} colSpan={1} align='center' sortDirection={false}>
                                <QmDialogTableTypographyHeader>
                                    {t('qualityManager.predictionDelay')}
                                </QmDialogTableTypographyHeader>
                            </TableCell>
                        </React.Fragment>
                    ))
                }
            </TableRow>
        </TableHead>
    )
}

export default QualityManagerDialogTableHead
