import * as React from 'react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Skeleton } from '@mui/material'

import { selectConfig, selectNowTime, selectTimeZone } from '../../core/coreSlice'
import { TABLE_ROW } from '../../../theme'
import QualityManagerDialogTableFixedHead from './qualityManagerDialogTableFixedHead'
import { selectAvailablePatterns } from '../../pattern/store/patternSlice'
import { getComparator, stableSort } from '../qualityManagerDialogHelper'
import { useTranslation } from 'react-i18next'

export const QualityManagerDialogTablePatternsAvailableData: React.FC<IQualityManagerDialogTableSwitchProps> = ({
    qmData,
}) => {
    const { t } = useTranslation()
    const _moduleConfig: IModuleConfig = useSelector(selectConfig)
    const _timeZone: string = useSelector(selectTimeZone)
    const _availablePatterns: IPatternJson[] = useSelector(selectAvailablePatterns)
    const _epochTime: number = useSelector(selectNowTime)

    const [order, setOrder] = useState<'desc' | 'asc' | undefined>('desc')
    const [orderBy, setOrderBy] = useState('time')
    const [loader, setLoader] = useState<boolean>(true)
    const [maxViewCount, setMaxViewCount] = useState<number>(50)
    const [qmDataToShow, setQmDataToShow] = useState<IQualityManagerPatternsAvailableRow[]>([])

    const { time: timeFormat, date: dateFormat } = _moduleConfig.date_format
    const tableContentHeight = window && window.innerHeight - 225

    const now: DateTime = DateTime.fromMillis(_epochTime, {
        zone: _timeZone,
    })

    useEffect(() => {
        if (qmData) {
            let res: IQualityManagerPatternsAvailableRow[] = dataToShow()
            res = stableSort(res, getComparator(order, orderBy))
            setQmDataToShow(res)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, qmData])

    const handleActionsScroll = ({ currentTarget }: { currentTarget: any }) => {
        if (currentTarget.offsetHeight + currentTarget.scrollTop + 0.5 >= currentTarget.scrollHeight) {
            loadMoreActions()
        }
    }

    const loadMoreActions = () => {
        const nextMax = maxViewCount + 50
        if (nextMax <= qmDataToShow.length) {
            setMaxViewCount(nextMax)
        } else {
            setMaxViewCount(qmDataToShow.length)
            setLoader(false)
        }
    }

    const handleRequestSort = (event: any, property: React.SetStateAction<string>) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const dataToShow = () => {
        let lastFrom = 0
        let row: IQualityManagerPatternsAvailableRow = {}
        return qmData
            .filter((data: IQualityManager) => {
                const from: DateTime = DateTime.fromMillis(data.from, { zone: _timeZone })
                return from <= now && (data.feature_id !== '0' || (data.feature_id === '0' && data.global === -1))
            })
            .reduce((acc: any, curr: IQualityManager) => {
                let data = [...acc]
                if (curr.from !== lastFrom) {
                    Object.keys(row).length > 0 && data.push(row)
                    row = {}
                    row['time'] = curr.from
                    if (curr.feature_id === '0' && curr.global === -1) {
                        _availablePatterns.forEach(pattern => {
                            row[pattern.pattern_id] = curr.global
                        })
                    } else {
                        row[curr.pattern] = curr.global
                    }
                } else {
                    row['time'] = curr.from
                    row[curr.pattern] = curr.global
                }
                lastFrom = curr.from
                return data
            }, [])
    }

    let subTittle: string[] = []
    _availablePatterns.forEach((pattern: IPatternJson) => {
        subTittle.push(t(pattern.pattern_name))
    })

    return (
        <Grid item>
            <TableContainer sx={{ height: `${tableContentHeight}px` }} onScroll={handleActionsScroll}>
                <Table
                    stickyHeader
                    aria-label='quality manager table view'
                    size='small'
                    sx={{
                        height: 'max-content',
                    }}>
                    <QualityManagerDialogTableFixedHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        fixedTittles={subTittle}
                        generalTittle={t('qualityManager.global')}
                    />
                    <TableBody>
                        {qmDataToShow &&
                            qmDataToShow
                                .filter((qmDataToShow, index: number) => {
                                    return index < maxViewCount
                                })
                                .map((data, index: number) => {
                                    const dateTime = DateTime.fromMillis(data.time, { zone: _timeZone })
                                    const fromTime = dateTime.toFormat(timeFormat)
                                    const fromDate = dateTime.toFormat(dateFormat)

                                    return (
                                        <TableRow
                                            sx={index % 2 === 0 ? { backgroundColor: TABLE_ROW } : {}}
                                            inlist={data || {}}
                                            key={index}>
                                            <TableCell align='left'>
                                                <Typography variant='caption'>{`${fromTime} ${fromDate}`}</Typography>
                                            </TableCell>
                                            {_availablePatterns.map(pattern => {
                                                return (
                                                    <TableCell
                                                        component='th'
                                                        scope='row'
                                                        align='center'
                                                        key={`${pattern.pattern_id}_${index}`}>
                                                        <Typography variant='caption'>
                                                            {data[pattern.pattern_id] === -1 ||
                                                            isNaN(data[pattern.pattern_id])
                                                                ? t('common.noData')
                                                                : Number(data[pattern.pattern_id]).toFixed(2)}
                                                        </Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                        {loader &&
                            [...Array(20)].map((row, index) => {
                                return (
                                    <TableRow sx={index % 2 === 0 ? { backgroundColor: TABLE_ROW } : {}} key={index}>
                                        {[...Array(_availablePatterns.length + 1)].map((row, index) => {
                                            return (
                                                <TableCell key={index} component='th' scope='row'>
                                                    <Skeleton variant='text' />
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default QualityManagerDialogTablePatternsAvailableData
