import React, { useState } from 'react'
import logo from '../../app/logo.svg'
import appVersionFile from '../../app/appVersion.json'
import appRevisionFile from '../../app/app_git_revision.json'
import { Dialog } from '@mui/material'
import { ILogoProps } from './logoTypes'
import {
    AboutDialogTextWrapper,
    AboutDialogWrapper,
    AboutText,
    AimsunLogo,
    DialogContentStyled,
    LogoContainer,
} from './logoStyles'
import { useTranslation } from 'react-i18next'

const Logo: React.FC<ILogoProps> = (props: ILogoProps) => {
    const { t } = useTranslation()
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const mode: string = props.mode
    const handleClick = () => {
        setOpenDialog(true)
    }

    return (
        <>
            <LogoContainer mode={mode}>
                <AimsunLogo src={logo} alt='live logo' onClick={handleClick} />
            </LogoContainer>
            <Dialog
                onKeyDown={() => setOpenDialog(false)}
                aria-labelledby='charts-dialog__title'
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth='md'>
                <DialogContentStyled>
                    <AboutDialogWrapper>
                        <img src={logo} style={{ width: 125 }} alt={''} />
                        <AboutDialogTextWrapper>
                            <AboutText variant='caption'>
                                {t('aboutDialog.version')} <strong>{appVersionFile.version}</strong>
                            </AboutText>
                            <AboutText variant='caption'>
                                {t('aboutDialog.revision')} <strong>{appRevisionFile.revision}</strong>
                            </AboutText>
                            <AboutText variant='caption'>
                                {t('aboutDialog.releaseDate')} <strong>{appRevisionFile.date}</strong>
                            </AboutText>
                            <AboutText variant='caption'>{t('aboutDialog.aimsumSLU')}</AboutText>
                            <AboutText variant='caption'>{t('aboutDialog.aimsun')}</AboutText>
                        </AboutDialogTextWrapper>
                    </AboutDialogWrapper>
                </DialogContentStyled>
            </Dialog>
        </>
    )
}

export default Logo
