import styled from '@emotion/styled'
import { Box, ButtonGroup } from '@mui/material'
import { RED_LIVE, WHITE } from '../../theme'

export const NavigatorCanvas = styled(Box)`
    height: 70px;
    padding: 15px;
    margin-right: auto;
`

export const NavigatorButtonGroup = styled(ButtonGroup)({
    '& .MuiButtonGroup-grouped:not(:last-of-type)': {
        borderColor: 'transparent',
        transition: '0.8s',
        ':hover': {
            backgroundColor: RED_LIVE,
            color: WHITE,
        },
    },
})
