import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface IEvaluationLiveState {
    evaluationLiveIsSelected: boolean
    openEvaluationLiveCreateDialog: boolean
    featureEvaluationLiveSelected: any
    evaluationLiveCreating: boolean
    evaluationLiveCreated: boolean
    fetchingEvaluationLive: boolean

}

const initialEvaluationLiveState: IEvaluationLiveState = {
    evaluationLiveIsSelected: false,
    openEvaluationLiveCreateDialog: false,
    fetchingEvaluationLive: false,
    evaluationLiveCreating: false,
    evaluationLiveCreated: false,
    featureEvaluationLiveSelected: {
        id: 0,
    }
}

const evaluationLiveSlice = createSlice({
    name: 'evaluationLive',
    initialState: initialEvaluationLiveState,
    reducers: {
        setEvaluationLiveSelected: (state, action: PayloadAction<boolean>): void => {
            state.evaluationLiveIsSelected = action.payload
        },
        setOpenEvaluationLiveCreateDialog: (state, action: PayloadAction<boolean>): void => {
            state.openEvaluationLiveCreateDialog = action.payload
        },
        storeFeatureEvaluationLiveSelected: (state, action: PayloadAction<any>): void => {
            state.featureEvaluationLiveSelected = action.payload
        },
        setEvaluationLiveCreated: (state, action: PayloadAction<any>): void => {
            state.evaluationLiveCreated = action.payload
        },
        setEvaluationLiveCreating: (state, action: PayloadAction<any>): void => {
            state.evaluationLiveCreating = action.payload
        },
        createNewEvaluationLive: (state, action: PayloadAction<any>): void => {
            state.evaluationLiveCreating = true
        },
        updateEvaluationLive: (state, action: PayloadAction<any>): void => {
            state.evaluationLiveCreating = true
        },
        deleteEvaluationLive: (state, action: PayloadAction<any>): void => {
        },
        setFetchNetworkEvaluationLive: (state, action: PayloadAction<any>): void => {
            state.fetchingEvaluationLive = action.payload
        },
        loadEvaluationLive: (state, action: PayloadAction<any>): void => {
            state.fetchingEvaluationLive = true
        }
    },
})

export const {
    setEvaluationLiveSelected,
    storeFeatureEvaluationLiveSelected,
    setOpenEvaluationLiveCreateDialog,
    createNewEvaluationLive,
    updateEvaluationLive,
    deleteEvaluationLive,
    setEvaluationLiveCreating,
    setEvaluationLiveCreated,
    setFetchNetworkEvaluationLive,
    loadEvaluationLive
} = evaluationLiveSlice.actions

export const selectEvaluationLiveSelected = (state: RootState) => state.evaluationLive.evaluationLiveIsSelected
export const selectOpenEvaluationLiveCreateDialog = (state: RootState) => state.evaluationLive.openEvaluationLiveCreateDialog
export const selectFeatureEvaluationLiveSelected = (state: RootState) => state.evaluationLive.featureEvaluationLiveSelected
export const selectEvaluationLiveCreating = (state: RootState) => state.evaluationLive.evaluationLiveCreating
export const selectEvaluationLiveCreated = (state: RootState) => state.evaluationLive.evaluationLiveCreated

export default evaluationLiveSlice.reducer
