import styled from '@emotion/styled'
import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import { NO_DATA } from '../../theme'

export const ResponsePlanDialogTitleStyled = styled(DialogTitle)`
  padding: 16px 24px 0 24px;
`

export const ResponsePlanDialogTitleInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ResponsePlanDialogTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`

export const ResponsePlanDialogIconTitleWrapper = styled.div`
  display: flex;
  height: 30px;
`

export const ResponsePlanDialogTitle = styled(Typography)`
  margin: auto 0 auto 8px;
  line-height: 8px;
`

export const ResponsePlanDialogSeparator = styled(Box)`
  height: 1px;
  background-color: ${NO_DATA};
  box-shadow: 0 0 1px ${NO_DATA};
  margin-bottom: 20px;
  margin-top: 20px;
  opacity: 0.5;
`

export const ResponsePlanDialogContentStyled = styled(DialogContent)`
  padding-top: 0;
  padding-bottom: 26px;
  margin-top: -6px;
  height: 60vh;
`

export const ResponsePlanDialogEventsActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
`

export const ResponsePlanSelectActionsEventsWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  &:last-of-type {
    padding-left: 30px;
  }
  
`

export const ResponsePlanSearchTermWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  >.MuiContainer-root {
    padding: 0;
    width: 100%;
    >.MuiFormControl-root {
      padding: 0;
      width: 100%;
    }
  }
`

export const ResponsePlanDialogListWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-height: 40vh;
`

export const ResponsePlanDialogName = styled(TextField)`
  .MuiFormHelperText-root {
    margin-top: 8px;
    font-size: 14px;
  }
`

export const ResponsePlanDialogButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 20px 0;
  padding: 0 24px;
`

export const ResponsePlanDialogButton = styled(Button)`
  padding: 9px 25px 6px;
  border-radius: 10px;
  margin-left: 20px;
  min-width: 110px;
  
  &:hover {
    opacity: 0.85;
  }
`

export const ResponsePlanDialogResetButton = styled(ResponsePlanDialogButton)`
  padding: 5px 15px 3px;
  border-radius: 10px;
`

export const ResponsePlanDialogSnackbarStyled = styled(Snackbar)`
  position: relative;
  margin: 24px auto;
  width: 70%;
  transform: translateX(0);
  bottom: initial;
  left: auto;
  right: auto;
  @media (min-width: 600px){
    margin: 24px auto;
    width: 70%;
    transform: translateX(0);
    bottom: initial;
    left: auto;
    right: auto;
  }
`
