import { all, AllEffect, call, ForkEffect, put, takeLatest } from 'redux-saga/effects'
import { createNewTrafficAction, setTrafficActionCreated, setTrafficActionCreating } from './trafficActionSlice'
import { createManageTrafficAction } from '../../../../../services/actions/actionsService'
import { getTrafficActions } from '../../../../../features/actionsToolbar/store/actionsSaga'

function* createNewManageTrafficAction(action: any): any {
    try {
        yield call(createManageTrafficAction, action.payload.trafficAction)
        yield call(getTrafficActions)
        yield put(setTrafficActionCreated(true))
        yield put(setTrafficActionCreating(false))
        yield put(setTrafficActionCreated(true))
    } catch (error) {
        console.error(error)
    }
}



function* trafficActionSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(createNewTrafficAction, createNewManageTrafficAction)
    ])
}

export default trafficActionSaga
