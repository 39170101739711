import React, { useState } from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { MapHorizonSelectorForm, MapHorizonSelectorInputLabel, MapHorizonSelectorWrapper } from './mapHorizonSelectorStyles'
import { setMapHorizonSelected } from './store/mapHorizonSelectedSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectMapHorizonSelector } from '../core/coreSlice'

const MapHorizonSelector: React.FC<IMapHorizonSelectorProps> = () => {
    const _mapHorizonSelectorOptions = useAppSelector(selectMapHorizonSelector)
    const [value, setValue] = useState<string>(_mapHorizonSelectorOptions[0].id)
    const dispatch = useAppDispatch()

    const handleDayChange = (event: SelectChangeEvent) => {
        const currentMapSelected = _mapHorizonSelectorOptions.filter(mapHorizon => mapHorizon.id === event.target.value)[0]
        setValue(event.target.value)
        dispatch(setMapHorizonSelected(currentMapSelected))
    }
    return (
        <React.Fragment>
            <MapHorizonSelectorWrapper>
                <MapHorizonSelectorForm key='landuse-map-selector-form' variant='standard'>
                    <MapHorizonSelectorInputLabel>Map</MapHorizonSelectorInputLabel>
                    <Select
                        id='land-use-map-selector'
                        value={value}
                        className='land-use-map-selector'
                        onChange={(event: SelectChangeEvent) => {
                            handleDayChange(event)
                        }}>
                        {_mapHorizonSelectorOptions.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </MapHorizonSelectorForm>
            </MapHorizonSelectorWrapper>
        </React.Fragment>
    )
}

export default MapHorizonSelector
