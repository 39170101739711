import React from 'react'
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material'
import { ZoomContainer, ZoomIcon } from './zoomStyles'

const Zoom: React.FC<IZoomProps> = ({ zoomIn, zoomOut }: IZoomProps) => {
    return (
        <ZoomContainer id='zoomControls' className='zoom__container'>
            <ZoomIcon onClick={zoomIn}>
                <AddIcon />
            </ZoomIcon>
            <ZoomIcon onClick={zoomOut}>
                <RemoveIcon />
            </ZoomIcon>
        </ZoomContainer>
    )
}

export default Zoom
