import { all, AllEffect, ForkEffect, put, takeLatest } from 'redux-saga/effects'
import {
    createNewEvaluationLive,
    setEvaluationLiveCreated,
    setEvaluationLiveCreating,
} from './evaluationLiveSlice'

function* createNewManageEvaluationLive(): any {
    try {
        yield put(setEvaluationLiveCreated(true))
        yield put(setEvaluationLiveCreating(false))
    } catch (error) {
        console.error(error)
    }
}



function* evaluationLiveSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(createNewEvaluationLive, createNewManageEvaluationLive)
    ])
}

export default evaluationLiveSaga
