import React from 'react'
import { AssessmentRounded as AssessmentRoundedIcon } from '@mui/icons-material'
import { KpiIcon, KpiLabel, KpiWrapper } from './kpiToolbarStyles'
import { IKpiToolbarProps } from './kpiToolbarTypes'
import { useTranslation } from 'react-i18next'

const KpiToolbar: React.FC<IKpiToolbarProps> = (
    {
        isDisabled,
        kpiToolbarOnClick,
        openDialog,
    }: IKpiToolbarProps
) => {
    const { t } = useTranslation()
    const handleKpiOnClick = () =>{
        kpiToolbarOnClick()
    }

    return (
        <KpiWrapper className={`${isDisabled ? 'disabled' : ''}`} onClick={handleKpiOnClick}>
            <KpiIcon className={`${isDisabled ? 'disabled' : ''} ${openDialog ? 'open-kpi-dialog' : ''}`}>
                <AssessmentRoundedIcon />
            </KpiIcon>
            <KpiLabel className={`${isDisabled ? 'disabled' : ''} ${openDialog ? 'open-kpi-dialog' : ''}`}>{t('kpi.kpis')}</KpiLabel>
        </KpiWrapper>
    )
}

export default KpiToolbar
