import React, { useEffect, useState } from 'react'
import CustomIcon from '../../components/customIcon/CustomIcon'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectEventsSelected, selectOpenEventsDialog, setEventsSelected } from './store/eventsSlice'
import { EventsIcon, EventsLabel, EventsWrapper } from './eventsToolbarStyles'
import { EventsDialog } from '../eventsDialog/eventsDialog'

const EventsToolbar: React.FC = () => {
    const _eventsIsSelected: boolean = useAppSelector(selectEventsSelected)
    const _openEventDialog: boolean = useAppSelector(selectOpenEventsDialog)
    const [eventsIsSelected, setEventsIsSelected] = useState<boolean>(_eventsIsSelected)
    const [openEventsDialog, setOpenEventsDialog] = useState<boolean>(_openEventDialog)

    const dispatch = useAppDispatch()

    useEffect(() => {
        setEventsIsSelected(_eventsIsSelected)
    }, [_eventsIsSelected])

    useEffect(() => {
        setOpenEventsDialog(_openEventDialog)
    }, [_openEventDialog])

    const handleEventsOnClick = () => {
        setEventsIsSelected(!eventsIsSelected)
        dispatch(setEventsSelected(!eventsIsSelected))
    }
    return (
        <>
            <EventsWrapper onClick={handleEventsOnClick}>
                <EventsIcon color={eventsIsSelected ? 'secondary' : 'primary'}>
                    <CustomIcon name={eventsIsSelected ? 'testEvent' : 'realEventActive'} />
                </EventsIcon>
                <EventsLabel className={`${eventsIsSelected ? 'open-events-dialog' : ''}`}>Events</EventsLabel>
            </EventsWrapper>
            {openEventsDialog && <EventsDialog />}
        </>
    )
}

export default EventsToolbar
