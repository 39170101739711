import { Box, Typography, Backdrop } from '@mui/material'
import { NO_DATA, WHITE } from '../../theme'
import { GridLoader } from 'react-spinners'
import { Popup } from 'react-map-gl'
import styled from '@emotion/styled'

export const FeatureTooltip = styled(Popup)`
    z-index: 1051;
    padding: 10px;
`

export const FeatureTooltipCentroidModified = styled(FeatureTooltip)`
    z-index: 1050;
    padding: 10px;
`

export const TooltipHeader = styled(Box)`
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 15px;
    flex-direction: row;
    padding: 10px 5px;
    display: flex;
`

export const TooltipFoundFeature = styled(Box)`
    flex-direction: row;
    padding: 10px 5px;
    display: flex;
`

export const HeaderText = styled(Typography)`
    padding: 3px 0 0 5px;
`

export const TooltipContent = styled(Box)`
    padding: 10px;
`

export const TooltipActionContent = styled(TooltipContent)`
    h4 {
      word-break:break-all;
    }
`

export const TooltipCircle = styled(Box)`
    box-shadow: inset 0 0 0 1px #00000050;
    border-radius: 20px;
    height: 20px;
    z-index: 11;
    width: 20px;
    margin-top: 3px;
`

export const TooltipCircleBackground = styled(Box)`
    border-radius: 20px;
    position: relative;
    left: -15px;
    width: 20px;
    height: 20px;
    z-index: 10;
    margin-top: 3px;
`

export const IncidentTooltip = styled(FeatureTooltip)`
    z-index: 1052;
`

export const LayerControlsContainer = styled(Box)`
    justify-content: flex-end;
    align-items: flex-end;
    pointer-events: none;
    position: absolute;
    display: flex;
    bottom: 30px;
    right: 6px;
`

export const IncidentIcon = styled('img')`
    margin-right: 15px;
    height: 26px;
    width: 26px;
`
export const TooltipText = styled(Typography)`
    font-size: 18px;
    font-family: 'AimsunMedium', sans-serif, serif;
    margin-bottom: 4px;
`

export const MapBoxLoader = styled(Backdrop)<{ width: number; height: number }>`
    color: ${WHITE};
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    position: absolute;
`

export const MapboxWrapper = styled.div`
    position: relative;
`

export const MapBoxLoaderWrapper = styled.div<{ width: number; height: number }>`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    position: absolute;
    background-color: ${WHITE};
    z-index: 1052;
    display: flex;
    border: solid 2px ${NO_DATA};
`
export const MapBoxLoaderMaterial = styled(GridLoader)`
    margin: auto;
`
export const SubPathListHeader = styled.ul`
  font-family: 'AimsunMedium', sans-serif, serif;
  font-size: 18px;
  line-height: 22px;
  color: #09254c;
  text-align: justify;
  margin: 0;
`
export const SubPathListList = styled.li`
  list-style-type: '- ';
  padding: 7px 0 0 25px;
  margin-top: 0;
  font-size: small;
`
