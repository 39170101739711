import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export const initialSetSelectedEvaluationState: IEvaluationStatic = {
    id: -1,
    eventId: null,
    epoch: 0,
    name: '',
}

export const newEvaluationSelectedState: IEvaluationStatic = {
    id: -2,
    eventId: null,
    epoch: 0,
    name: 'New Evaluation',
}

const initialEvaluationsState = {
    evaluations: [],
    selectedEvaluation: initialSetSelectedEvaluationState,
    fetching: false,
    message: ''
}

const evaluationsSlice = createSlice({
    name: 'evaluations',
    initialState: initialEvaluationsState,
    reducers: {
        storeEvaluations: (state, action: PayloadAction<any>): void => {
            state.evaluations = action.payload
        },
        setFetchingEvaluations: (state, action: PayloadAction<boolean>): void => {
            state.fetching = action.payload
        },
        storeEvaluationCreatedMessage: (state, action: PayloadAction<string>): void => {
            state.message = action.payload
        },
        loadEvaluations: (state): void => {
            state.fetching = true
        },
        setSelectedEvaluation: (state, action: PayloadAction<IEvaluationStatic>): void => {
            state.selectedEvaluation = action.payload
        },
        loadEvaluation: (state, action: PayloadAction<IEvaluationStatic>): void => {
            state.fetching = true
        },
        loadLandUseEvaluations: (state): void => {
            state.fetching = true
        },
    },
})

export const {
    storeEvaluations,
    setFetchingEvaluations,
    storeEvaluationCreatedMessage,
    loadEvaluations,
    setSelectedEvaluation,
    loadEvaluation,
    loadLandUseEvaluations,
} = evaluationsSlice.actions

export const selectEvaluations = (state: RootState) => state.evaluations.evaluations
export const selectFetchingEvaluations = (state: RootState) => state.evaluations.fetching
export const selectSelectedEvaluation = (state: RootState) => state.evaluations.selectedEvaluation
export const selectEvaluationCreatedMessage = (state: RootState) => state.evaluations.message

export default evaluationsSlice.reducer
