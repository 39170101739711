import { all, AllEffect, call, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import {
    storeCentroid,
    storeDetectors,
    storeLocal,
    storeMotorways,
    storeSuburban,
    storeUrban1,
    storeUrban2,
    enableNetworkAssets,
    storePublicTransport,
    storeNodes,
    storeSubpaths,
} from './mapboxSlice'
import { getTilesCatalog, getTilesInfo } from '../../services/core/coreService'
import { setStatus } from '../core/coreSlice'

function* getNetworkCatalog(action: any): Generator<unknown, any, INetwork> {
    let networkCatalog: INetwork = yield call(fetchNetworkCatalog, action.payload.mapHorizon)
    yield call(storeNetworkCatalog, action.payload, networkCatalog)
    yield put(setStatus('ready'))
}

function* fetchNetworkCatalog(mapHorizon: string): Generator<unknown, any, any> {
    const tilesCatalog = yield call(getTilesCatalog, mapHorizon)
    let networkCatalog: INetwork = {
        centroid: { available: false },
        detectors: { available: false },
        motorways: { available: false },
        suburban: { available: false },
        urban_1: { available: false },
        urban_2: { available: false },
        local: { available: false },
        public_transport: { available: false },
        subpaths: { available: false },
        nodes: { available: false },
    }

    if (tilesCatalog.tiles) {
        const tiles = Object.keys(tilesCatalog.tiles)
        networkCatalog.detectors.available = tiles.includes('geogkdetector')
        networkCatalog.centroid.available = tiles.includes('geogkcentroid')
        networkCatalog.motorways.available = tiles.includes('geogksection_1')
        networkCatalog.suburban.available = tiles.includes('geogksection_2')
        networkCatalog.urban_1.available = tiles.includes('geogksection_3')
        networkCatalog.urban_2.available = tiles.includes('geogksection_4')
        networkCatalog.local.available = tiles.includes('geogksection_5')
        networkCatalog.public_transport.available = tiles.includes('geoptlayer')
        networkCatalog.subpaths.available = tiles.includes('geogksubpath')
        networkCatalog.nodes.available = tiles.includes('geogknode')
    } else {
        console.error('**** Aimsun dev message => Martin service update is required. ****')
        tilesCatalog.forEach((tile: any) => {
            networkCatalog.detectors.available = tile.id === 'geogkdetector' || networkCatalog.detectors.available
            networkCatalog.centroid.available = tile.id === 'geogkcentroid' || networkCatalog.centroid.available
            networkCatalog.motorways.available = tile.id === 'geogksection_1' || networkCatalog.motorways.available
            networkCatalog.suburban.available = tile.id === 'geogksection_2' || networkCatalog.suburban.available
            networkCatalog.urban_1.available = tile.id === 'geogksection_3' || networkCatalog.urban_1.available
            networkCatalog.urban_2.available = tile.id === 'geogksection_4' || networkCatalog.urban_2.available
            networkCatalog.local.available = tile.id === 'geogksection_5' || networkCatalog.local.available
            networkCatalog.subpaths.available = tile.id === 'geogksubpath' || networkCatalog.subpaths.available
            networkCatalog.nodes.available = tile.id === 'geogknode' || networkCatalog.nodes.available
        })
    }

    return networkCatalog
}

function* fetchTileInfo(tileId: string, mapHorizon: string) {
    const tileInfo: ITileInfo = yield call(getTilesInfo, tileId, mapHorizon)
    const networkTile: INetworkTile = {
        available: true,
        maxzoom: tileInfo.maxzoom,
        minzoom: tileInfo.minzoom,
        bounds: tileInfo.bounds,
        mapHorizon: mapHorizon,
    }
    return networkTile
}

function* storeNetworkCatalog(payload: { networks: string; mapHorizon: string }, networkCatalog: INetwork) {
    if (payload.networks === 'sections' || payload.networks === 'centroids' || payload.networks === 'all') {
        if (networkCatalog.motorways.available) {
            const networkTile: INetworkTile = yield call(fetchTileInfo, 'geogksection_1', payload.mapHorizon)
            yield put(storeMotorways(networkTile))
        } else {
            console.error('motorway sections have been enabled but they are not available in the catalog')
        }
        if (networkCatalog.suburban.available) {
            const networkTile: INetworkTile = yield call(fetchTileInfo, 'geogksection_2', payload.mapHorizon)
            yield put(storeSuburban(networkTile))
        } else {
            console.error('suburban sections have been enabled but they are not available in the catalog')
        }
        if (networkCatalog.urban_1.available) {
            const networkTile: INetworkTile = yield call(fetchTileInfo, 'geogksection_3', payload.mapHorizon)
            yield put(storeUrban1(networkTile))
        } else {
            console.error('urban 1 sections have been enabled but they are not available in the catalog')
        }
        if (networkCatalog.urban_2.available) {
            const networkTile: INetworkTile = yield call(fetchTileInfo, 'geogksection_4', payload.mapHorizon)
            yield put(storeUrban2(networkTile))
        } else {
            console.error('urban 2 sections have been enabled but they are not available in the catalog')
        }
        if (networkCatalog.local.available) {
            const networkTile: INetworkTile = yield call(fetchTileInfo, 'geogksection_5', payload.mapHorizon)
            yield put(storeLocal(networkTile))
        } else {
            console.error('local sections have been enabled but they are not available in the catalog')
        }
        if (networkCatalog.public_transport.available) {
            const networkTile: INetworkTile = yield call(fetchTileInfo, 'geoptlayer', payload.mapHorizon)
            yield put(storePublicTransport(networkTile))
        } else {
            console.error('public transport sections have been enabled but they are not available in the catalog')
        }
        if (networkCatalog.subpaths.available) {
            const networkTile: INetworkTile = yield call(fetchTileInfo, 'geogksubpath', payload.mapHorizon)
            yield put(storeSubpaths(networkTile))
        } else {
            console.error('subpath sections have been enabled but they are not available in the catalog')
        }
        if (networkCatalog.nodes.available) {
            const networkTile: INetworkTile = yield call(fetchTileInfo, 'geogknode', payload.mapHorizon)
            yield put(storeNodes(networkTile))
        } else {
            console.error('signalized nodes have been enabled but they are not available in the catalog')
        }
    }
    if (payload.networks === 'centroids') {
        if (networkCatalog.centroid.available) {
            const networkTile: INetworkTile = yield call(fetchTileInfo, 'geogkcentroid', payload.mapHorizon)
            yield put(storeCentroid(networkTile))
        } else {
            console.error('centroids have been enabled but they are not available in the catalog')
        }
    }
    if (payload.networks === 'detectors' || payload.networks === 'all') {
        if (networkCatalog.detectors.available) {
            const networkTile: INetworkTile = yield call(fetchTileInfo, 'geogkdetector', payload.mapHorizon)
            yield put(storeDetectors(networkTile))
        } else {
            console.error('detectors have been enabled but they are not available in the catalog')
        }
    }
}

function* mapboxSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(enableNetworkAssets, getNetworkCatalog)])
}

export default mapboxSaga
