import styled from '@emotion/styled'
import { Button, Card, CardContent, CardHeader, TextField } from '@mui/material'
import { NO_DATA } from '../../../../theme'

export const CardTrafficActionsRendererStyled = styled(Card)`
  padding: 8px 16px;
  position: absolute;
  z-index: 99;
  margin: auto 0;
  right: 8px;
  top: 8px;
  min-width: 250px;
`

export const HeaderWrapperTrafficActions = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  .MuiTypography-root {
    margin: auto 10px;
  }
`

export const CardHeaderTrafficActionsStyled = styled(CardHeader)`
  border-bottom: 1px solid ${NO_DATA};
  span {
    font-size: 24px;
    font-weight: bold;
    opacity: 1;
  }
`

export const CardContentTrafficActionsStyled = styled(CardContent)`
  
`

export const TrafficActionsNameStyled = styled(TextField)`
  .MuiFormHelperText-root.Mui-error {
    font-size: 12px;
  }
`

export const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TrafficActionsButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 16px;
`

export const TrafficActionsButton = styled(Button)`
  padding: 9px 25px 6px;
  border-radius: 10px;
  margin-left: 20px;
  min-width: 110px;
  
  &:hover {
    opacity: 0.85;
  }
`