import styled from '@emotion/styled'
import { IconButton, Typography } from '@mui/material'
import { RED_LIVE_DARK } from '../../../../theme'

export const DialogTitleWrapper = styled.div`
    margin: 20px 20px 0;
`

export const Title = styled(Typography)`
    margin: 20px 20px 0;
    padding: 20px 0 15px;
`
export const CloseButton = styled(IconButton)`
    position: absolute;
    right: 10px;
    top: 10px;
    color: ${RED_LIVE_DARK};
`
