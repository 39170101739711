import React  from 'react'
import { CardContent, Grid } from '@mui/material'
import { ViewsContainer, ViewsSubtitle, ViewsTitle, IconContainer } from './viewSettingsStyles'
import singleMap from '../../app/icons/singleMap.svg'
import singleMapActive from '../../app/icons/singleMapActive.svg'
import twoMaps from '../../app/icons/twoMaps.svg'
import twoMapsActive from '../../app/icons/twoMapsActive.svg'
import threeMaps from '../../app/icons/threeMaps.svg'
import threeMapsActive from '../../app/icons/threeMapsActive.svg'
import fourMaps from '../../app/icons/fourMaps.svg'
import fourMapsActive from '../../app/icons/fourMapsActive.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { changeViewsLayout, selectViewOpen, selectViewsLayout } from './viewSettingsSlice'
import { useTranslation } from 'react-i18next'

const ViewSettings: React.FC<IViewSettingsProps> = () => {
    const { t } = useTranslation()
    const _open = useAppSelector(selectViewOpen)
    const _viewsLayout = useAppSelector(selectViewsLayout)
    const dispatch = useAppDispatch()

    return (
        <>
            {_open && (
                <ViewsContainer>
                    <CardContent>
                        <Grid container direction='column'>
                            <Grid item>
                                <ViewsTitle variant='h2'>{t('views.layout')}</ViewsTitle>
                            </Grid>
                            {/*     <Grid item>
                                        <Grid container direction='row' justifyContent='space-between'>
                                            <Grid item>
                                                <SwitchText>{t('views.position')}</SwitchText>
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={false}
                                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {}}
                                                            value='position'
                                                            size='small'
                                                        />
                                                    }
                                                    label=''
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction='row' justifyContent='space-between'>
                                            <Grid item>
                                                <SwitchText>{t('views.layers')}</SwitchText>
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={false}
                                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {}}
                                                            value='layers'
                                                            size='small'
                                                        />
                                                    }
                                                    label=''
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction='row' justifyContent='space-between'>
                                            <Grid item>
                                                <SwitchText>{t('views.viewMode')}</SwitchText>
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={false}
                                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {}}
                                                            value='viewMode'
                                                            size='small'
                                                        />
                                                    }
                                                    label=''
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                            <Grid item>
                                <ViewsSubtitle>{t('views.numberOfMaps')}</ViewsSubtitle>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction='row'
                                    alignContent='center'
                                    justifyContent='space-between'
                                    alignItems='center'>
                                    <Grid item>
                                        <IconContainer
                                            src={_viewsLayout === 1 ? singleMapActive : singleMap}
                                            onClick={() => {
                                                dispatch(changeViewsLayout(1))
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconContainer
                                            src={_viewsLayout === 2 ? twoMapsActive : twoMaps}
                                            onClick={() => {
                                                dispatch(changeViewsLayout(2))
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconContainer
                                            src={_viewsLayout === 3 ? threeMapsActive : threeMaps}
                                            onClick={() => {
                                                dispatch(changeViewsLayout(3))
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconContainer
                                            src={_viewsLayout === 4 ? fourMapsActive : fourMaps}
                                            onClick={() => {
                                                dispatch(changeViewsLayout(4))
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </ViewsContainer>
            )}
        </>
    )
}

export default ViewSettings
