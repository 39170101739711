import React from 'react'
import { useAppSelector } from '../../app/hooks'
import { selectConfig } from '../../features/core/coreSlice'
import ManageDemoContainer from './ManageDemoContainer'
import ManageLiveContainer from './ManageLiveContainer'

const ManagerManageContainer: React.FC<IContainerProps> = ({ options }: IContainerProps) => {
    const _moduleConfig = useAppSelector(selectConfig)

    const containerName = 'manage'
    const isDemoMode = _moduleConfig.modules.find(module => module.name === containerName)?.mode === 'demo'

    return (
        <>
            {isDemoMode ? (<ManageDemoContainer options={options} />) : (<ManageLiveContainer options={options} />)}
        </>
    )
}

export default ManagerManageContainer
