import { all, AllEffect, call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { getRiskPredictionByHorizon } from '../../../services/mapData/mapDataService'
import { loadRiskPredictionData, storeRiskPredictionData } from './riskPredictionDataSlice'
import { getMarks } from '../../../helpers/SliderMarksHelper'
import { selectConfig } from '../coreSlice'

export function* initRiskPredictionData(action: any) {
    yield call(fetchRiskPredictionData, action.payload)
}

function* fetchRiskPredictionData({ epoch }: { epoch: number; variable: string }): any {
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const step: number = _moduleConfig['horizon-step']
    const numberOfHorizon: number = _moduleConfig['horizon-count']
    const marks = getMarks(step, numberOfHorizon)

    const incidentsPrediction: IPredictionByHorizonData[] = yield all(
        marks.map(horizon =>
            call(getRiskPredictionByHorizon, epoch, horizon.value)
        )
    )

    yield put(storeRiskPredictionData({ data: incidentsPrediction }))
}

function* riskPredictionDataSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(loadRiskPredictionData, initRiskPredictionData)])
}

export default riskPredictionDataSaga
