import styled from '@emotion/styled'
import { NAVY_BLUE } from '../../theme'
import { FormControl, InputLabel } from '@mui/material'

export const ClockDropDownWrapper = styled.div`
  padding: 0 8px;
  min-width: 170px;
  position: relative;
  margin: auto;
`
export const ClockDropDownForm = styled(FormControl)`
  width: 100%;
  &.Mui-focused{
    color: inherit;
  }
  .land-use-clock-selector {
    &.selected {
      background-color: ${NAVY_BLUE};
      opacity: 0.6;
    }
    &:after {
      border-bottom-color: inherit;
    }
  }
`
export const ClockDropDownInputLabel = styled(InputLabel)`
  &.Mui-focused{
    color: inherit;
  }
`