import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { useAppSelector } from '../../app/hooks'
import { selectCurrentError } from '../../features/core/coreSlice'
import { useEffect, useMemo, useState } from 'react'
import { signOut } from '../../features/auth/auth'

const CurrentErrorDialog: React.FC = () => {
    const _currentError: ICurrentError = useAppSelector(selectCurrentError)

    const [alertOpen, setAlertOpen] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>('')

    useEffect(() => {
        if (_currentError.code !== '') {
            if (_currentError.code === 'ERR_NETWORK') {
                setAlertMessage('Network connection error')
            } else if (_currentError.code === 'ERR_BAD_RESPONSE') {
                setAlertMessage('Request data timeout')
            } else if (_currentError.code === 'ERR_BAD_REQUEST') {
                setAlertMessage('Not allowed to access resource')
            } else {
                setAlertMessage('Unexpected error')
            }
            setAlertOpen(true)
        }
    }, [_currentError])

    const unauthorized = useMemo(() => _currentError.code === 'ERR_BAD_REQUEST', [_currentError])

    const handleClose = (): void => {
        setAlertOpen(false)
    }

    const handleReload = (): void => {
        window.location.reload()
    }

    const handleAction = (): void => {
        if (unauthorized) {
            signOut()
        } else {
            handleReload()
        }
    }

    return (
        <Dialog
            open={alertOpen}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>{unauthorized ? 'Unauthorized' : 'Error'}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>{alertMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleAction} autoFocus>
                    {unauthorized ? 'Sign out' : 'Reload'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CurrentErrorDialog
