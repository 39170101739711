import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../app/store'

interface PrecodedResponsePlanState {
    precodedResponsePlans: IResponsePlan[]
}
const initialPrecodedResponsePlanState: PrecodedResponsePlanState = {
    precodedResponsePlans: [],
}

const precodedResponsePlanSlice = createSlice({
    name: 'precodedResponsePlan',
    initialState: initialPrecodedResponsePlanState,
    reducers: {
        setPrecodedResponsePlans: (state, action: PayloadAction<IResponsePlan[]>): void => {
            state.precodedResponsePlans = action.payload
        },
        loadPrecodedResponsePlan: (state, action: PayloadAction): void => {},
    },
})

export const {
    loadPrecodedResponsePlan,
    setPrecodedResponsePlans,
} = precodedResponsePlanSlice.actions

export const selectPrecodedResponsePlans = (state: RootState) => state.precodedResponsePlans.precodedResponsePlans

export default precodedResponsePlanSlice.reducer
