import { all, AllEffect, call, ForkEffect, put, takeLatest } from 'redux-saga/effects'
import { loadListActions, loadTrafficActions, storeListActions } from './actionsSlice'
import { getActionsService, getListActionsService } from '../../../services/actions/actionsService'
import { initialEmptyFeatureCollectionState, storeActions } from '../../mapbox/mapboxSlice'


export function* getTrafficActions(): any {
    try {
        const actions: GeoJSON.FeatureCollection = yield call(getActionsService)
        const nextActions = actions.features ? actions : initialEmptyFeatureCollectionState
        yield put(storeActions(nextActions))
    } catch (error) {
        console.error(error)
    }
}

export function* fetchListActions() {
    const actions: any[] = yield call(getListActionsService)
    yield put(storeListActions(actions))
}

function* actionsSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(loadTrafficActions, getTrafficActions),
        yield takeLatest(loadListActions, fetchListActions),
    ])
}

export default actionsSaga
