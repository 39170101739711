import { Button } from '@mui/material'
import styled from '@emotion/styled'
import { SearchTermWrapper } from '../../../containers/analyzeContainer/components/kpi/kpis/kpiDialog/KpiDialogStyles'

export const EvaluationsDialogButton = styled(Button)`
  padding: 9px 25px 6px;
  border-radius: 10px;
  margin-left: 20px;
  
  &:hover {
    opacity: 0.85;
  }
`

export const EvaluationsDialogButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 32px;
`

export const SearchTermWrapperEvaluationDialog = styled(SearchTermWrapper)`
  padding: 0 0 0 24px;
  margin: 20px auto;
  
  @media (min-width: 1200px){
    max-width: 1200px;
  }
`
