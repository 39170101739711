import React, { useEffect, useState } from 'react'
import KpiComparisonDialog from './kpiComparisonDialog/KpiComparisonDialog'
import { useSelector } from 'react-redux'
import { IKpiComparisonProps } from './kpiComparisonTypes'
import { selectFetchingRPKpis, selectRPKpis } from '../store/kpisResponsePlansSlice'
import KpiToolbar from '../../../../../features/kpiToolbar/kpiToolbar'

const KpisComparison: React.FC<IKpiComparisonProps> = (
    {
        isDisabled,
        selectedEvaluation,
        getRPKpis,
        apmHorizonStep,
        apmHorizonCount,
        setSelectedResponsePlans,
        responsePlanEvaluations
    }: IKpiComparisonProps
) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const _kpiList = useSelector(selectRPKpis)
    const _fetchingKpiList = useSelector(selectFetchingRPKpis)

    useEffect(() => {
        getRPKpis()
    }, [selectedEvaluation, getRPKpis])

    const handleKpiOnClick = () =>{
        setOpenDialog(true)
    }

    const handleKpiDialogClose = (responsePlans: string[]) =>{
        setOpenDialog(false)
        setSelectedResponsePlans(responsePlans)
    }

    return (
        <React.Fragment>
            <KpiToolbar
                kpiToolbarOnClick={handleKpiOnClick}
                openDialog={openDialog}
                isDisabled={isDisabled || _fetchingKpiList}
            />
            <KpiComparisonDialog
                open={openDialog}
                onClose={handleKpiDialogClose}
                selectedEvaluation={selectedEvaluation}
                kpiList={_kpiList}
                unitsSystem={'metric'}
                apmHorizonStep={apmHorizonStep}
                apmHorizonCount={apmHorizonCount}
                responsePlanEvaluations={responsePlanEvaluations}
            />
        </React.Fragment>
    )
}

export default KpisComparison
