import React, { useEffect, useState } from 'react'
import KpiDialog from './kpiDialog/KpiDialog'
import KpiToolbar from '../../../../../features/kpiToolbar/kpiToolbar'
import { getKpisConverted } from '../../../../../helpers/kpiHelper'
import { useSelector } from 'react-redux'
import { loadKpi, selectFetchingKpis, selectKpis } from '../store/kpisSlice'
import { selectEpoch, selectTimeZone } from '../../../../../features/core/coreSlice'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'

const Kpis: React.FC<IKpisProps> = ({ isDisabled, dateFormat, unitSystem }: IKpisProps) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const _kpis = useAppSelector(selectKpis)
    const _epoch: number = useAppSelector(selectEpoch)
    const _fetchingKpis = useSelector(selectFetchingKpis)
    const _timeZone: string = useSelector(selectTimeZone)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (_epoch !== 0) {
            dispatch(loadKpi(_epoch))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_epoch])
    const handleKpiOnClick = () => {
        setOpenDialog(true)
    }

    const handleKpiDialogClose = () => {
        setOpenDialog(false)
    }

    const kpisConverter = getKpisConverted(unitSystem, _kpis)

    return (
        <React.Fragment>
            <KpiToolbar
                kpiToolbarOnClick={handleKpiOnClick}
                openDialog={openDialog}
                isDisabled={isDisabled || _fetchingKpis}
            />
            <KpiDialog
                open={openDialog}
                onClose={handleKpiDialogClose}
                kpis={kpisConverter}
                dateFormat={dateFormat}
                timeZone={_timeZone}
            />
        </React.Fragment>
    )
}

export default Kpis
