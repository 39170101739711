import React, { useEffect, useMemo } from 'react'
import MapboxComponent from './mapboxComponent'
import { setSelectedMapbox } from './mapboxSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getMapboxViewport } from '../../helpers/MapboxHelper'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { selectViewsLayout } from '../viewSettings/viewSettingsSlice'

const MapboxContainer: React.FC<IMapboxContainerProps> = ({
    layers,
    sidebarOpen,
    containerName,
    changeViewMode,
}: IMapboxContainerProps) => {
    const _views_layout = useAppSelector(selectViewsLayout)
    const dispatch = useAppDispatch()
    const [dimensions, setDimensions] = React.useState<{ height: number; width: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    const getMapboxBundle = (views_layout: any, layers: any, dimensions: any, sidebarOpen: any) => {
        let nextMapboxBundle = []
        const windowHeight: number = dimensions.height - 70
        const windowWidth: number = dimensions.width - (sidebarOpen ? 144 : 66)
        for (let index = 0; index < views_layout; index++) {
            const nextViewport = getMapboxViewport(windowHeight, windowWidth, _views_layout, index)
            nextMapboxBundle.push({
                id: index,
                networkLayers: layers.network,
                incidentLayers: layers.incidents,
                actionLayers: layers.actions,
                viewport: nextViewport,
            })
        }
        return nextMapboxBundle
    }

    const mapboxBundle = useMemo(
        () => getMapboxBundle(_views_layout, layers, dimensions, sidebarOpen), // eslint-disable-next-line react-hooks/exhaustive-deps
        [_views_layout, layers, dimensions, sidebarOpen]
    )

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleMapboxSelected = (id: number) => {
        dispatch(setSelectedMapbox(id))
    }

    const GridContainer = styled(Grid)`
        height: ${window.innerHeight - 70}px;
        margin-left: ${sidebarOpen ? '144px' : '66px'};
    `

    return (
        <GridContainer container wrap='wrap' direction='row'>
            {mapboxBundle.map((mapbox: any, index: number) => {
                return (
                    <Grid item key={index}>
                        <MapboxComponent
                            networkLayers={mapbox.networkLayers}
                            actionLayers={mapbox.actionLayers}
                            incidentLayers={mapbox.incidentLayers}
                            mapId={mapbox.id}
                            viewport={mapbox.viewport}
                            setSelected={handleMapboxSelected}
                            containerName={containerName}
                            showLegend={true}
                            changeViewMode={changeViewMode}
                            isBaseline={false}
                        />
                    </Grid>
                )
            })}
        </GridContainer>
    )
}

const areEqual = (prevProps: any, nextProps: any) => {
    return true
}

export default React.memo(MapboxContainer, areEqual)
