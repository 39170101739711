import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const mapDataSlice = createSlice({
    name: 'mapData',
    initialState: null,
    reducers: {
        loadMapData: (state, action: PayloadAction<{ epoch: number; allViewMode: IViewMode[] }>): void => {},
        loadUpdateRealData: (state, action: PayloadAction<{ epoch: number; allViewMode: IViewMode[] }>): void => {},
        loadUpdateAnalyticalData: (
            state,
            action: PayloadAction<{ epoch: number; allViewMode: IViewMode[] }>
        ): void => {},
        loadUpdateSimulatedData: (
            state,
            action: PayloadAction<{ epoch: number; allViewMode: IViewMode[] }>
        ): void => {},
        loadMapDataByViewMode: (
            state,
            action: PayloadAction<{
                epoch: number
                allViewMode: IViewMode[]
                viewModeId: number
                mapId: number
                qm: boolean
            }>
        ): void => {},
    },
})

export const {
    loadMapData,
    loadUpdateRealData,
    loadUpdateAnalyticalData,
    loadUpdateSimulatedData,
    loadMapDataByViewMode,
} = mapDataSlice.actions

export default mapDataSlice.reducer
