import * as React from 'react'
import { Checkbox, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { LandUseViewsForm, LandUseViewsInputLabel, LandUseViewsSelectorWrapper } from './landUseViewsSelectorStyles'
import { useAppSelector } from '../../app/hooks'
import { isBaselineDataAvailable } from '../baselineLandUse/baselineLandUseSlice'

const LandUseViewsSelector: React.FC<LandUseViewsSelectorProps> = ({
    landUseViewsSelected,
    handleLandUseViewsChange,
    landUseViews,
    disabled,
}: LandUseViewsSelectorProps) => {
    const disabledBaseline: boolean = useAppSelector(isBaselineDataAvailable)

    const handleViewsChange = (event: SelectChangeEvent<number[]>) => {
        handleLandUseViewsChange(event.target.value)
    }

    const valuesToRender = (selected: number[]) => {
        const values = selected.map(id => landUseViews[id].name)
        return values.join(',')
    }

    return (
        <React.Fragment>
            <LandUseViewsSelectorWrapper>
                <LandUseViewsForm key='response-plan-form' variant='standard'>
                    <LandUseViewsInputLabel>Views</LandUseViewsInputLabel>
                    <Select
                        id='response-plan-selector'
                        multiple={true}
                        value={landUseViewsSelected}
                        className='response-plan-selector'
                        onChange={(event: SelectChangeEvent<number[]>) => {
                            handleViewsChange(event)
                        }}
                        disabled={disabled}
                        renderValue={selected => valuesToRender(selected)}>
                        {landUseViews.map((option: { id: number; name: string }) => {
                            return (
                                <MenuItem key={option.id} value={option.id}>
                                    <Checkbox
                                        disabled={disabledBaseline && option.name.toLowerCase().includes('baseline')}
                                        checked={landUseViewsSelected.indexOf(option.id) > -1}
                                    />
                                    {option.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </LandUseViewsForm>
            </LandUseViewsSelectorWrapper>
        </React.Fragment>
    )
}

export default LandUseViewsSelector
