import { IRawKpi } from '../../../../../analyzeContainer/components/kpi/store/kpisSlice'
import { IRPKpiList } from '../kpiComparisonTypes'

export const checkKpiDataAvailable = (kpiList: IRPKpiList): boolean => {
    let count = 0
    for (const rpId in kpiList) {
        if (Array.isArray(kpiList[rpId]) && kpiList[rpId].length > 0) {
            count = count +1
        }
    }

    return count > 0
}

export const getKpisSelected = (responsePlans: string[], kpiList: IRPKpiList): IRPKpiList =>
    responsePlans.reduce((acc: {[p: string]: IRawKpi[]}, rpId) => {
        acc[rpId] = [...kpiList[rpId]]
        return acc
    }, {})

export const getKpiListWithoutCongestion = (responsePlans: string[], kpiList: IRPKpiList): IRPKpiList =>
    responsePlans.reduce((acc:  {[p: string]: IRawKpi[]}, rpId) => {
        acc[rpId] = [...kpiList[rpId]].filter(kpi => kpi.horizon !== 0)
        return acc
    }, {})
