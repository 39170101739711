import { all, takeLatest } from 'redux-saga/effects'
import { storeAuthTokens } from './authSlice'
import { PayloadAction } from '@reduxjs/toolkit'

function* persistAuthTokens(action: PayloadAction<IAuthPayload>) {
    const { access_token, refresh_token } = action.payload
    yield localStorage.setItem('access_token', JSON.stringify(access_token))
    yield localStorage.setItem('refresh_token', JSON.stringify(refresh_token))

    if (access_token && refresh_token) {
        if (window.location.href.includes('?')) {
            window.location.href = window.location.href.split('?', 1)[0]
        } else {
            window.location.reload()
        }
    }
}

function* authSaga() {
    // @ts-ignore
    yield all([takeLatest(storeAuthTokens, persistAuthTokens)])
}

export default authSaga
