import styled from '@emotion/styled'
import { IconButton, List, ListItemIcon, ListItemText, Paper } from '@mui/material'
import { NAVY_BLUE, RED_LIVE, TITLE_TOOLBAR, WHITE } from '../../theme'

export const SearchIconButton = styled(IconButton)`
    margin: 0 7px;
    width: 44px;
    background-color: ${NAVY_BLUE};
    border-radius: 44px;
    color: ${WHITE};
    border: 1px solid ${NAVY_BLUE};
    z-index: 11;
    &:hover {
        background-color: ${NAVY_BLUE};
        color: ${WHITE};
    }
`

export const CloseIconButton = styled(IconButton)`
    margin-right: 7px;
    width: 44px;
    background-color: ${RED_LIVE};
    border-radius: 44px;
    color: ${WHITE};
    border: 1px solid ${RED_LIVE};
    z-index: 11;
    &:hover {
        background-color: ${RED_LIVE};
    }
`

export const SearchInputDiv = styled.div`
    display: flex;
    flex-direction: row;
    transition: all 0.5s;
`

export const SearchTermInputWrapper = styled(Paper)`
    border-radius: 4px;
    margin: auto 12px auto 0;
    z-index: 11;
    width: 240px;
`

export const SearchWrapper = styled.div`
    pointer-events: auto;
`

export const ListStyled = styled(List)`
    margin-bottom: 8px;
    background-color: ${WHITE};
    min-width: 240px;
    max-height: 220px;
    overflow: auto;
`

export const ListItemIconStyled = styled(ListItemIcon)`
    border-radius: 12px;
    background-color: ${WHITE};
    height: 22px;
    min-width: 22px;
    margin-right: 8px;
`

export const TypeNetwork = styled(ListItemText)`
    text-transform: capitalize;
`

export const WrapperSubHeader = styled.div`
    padding: 0;
    margin: 0;
    position: relative;
`

export const CloseListIconButton = styled(IconButton)`
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    top: 8px;
    background-color: ${WHITE};
    border-radius: 16px;
    color: ${TITLE_TOOLBAR};
    border: 1px solid ${TITLE_TOOLBAR};
    z-index: 11;
    &:hover {
        background-color: ${RED_LIVE};
        color: ${WHITE};
        border: 1px solid ${RED_LIVE};
    }
`
