import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { ClockInputRender } from './clockInputRender'
import { FirstPage, LastPage, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { addTimeToDate, subtractTimeToDate } from '../../helpers/DateTimeHelper'
import { TextFieldProps } from '@mui/material'
import { ClockWrapper, ClockStyledIconButton, StyledMobileDateTimePicker } from './clockStyles'
import { useAppSelector, useFetchingData } from '../../app/hooks'
import { selectConfig, selectEpoch, selectNowTime, selectTimeZone } from '../core/coreSlice'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

const ClockQM: React.FC<IClockQmProps> = ({
    minutesStep = 0,
    longTimeStep = { number: 0, unit: 'hours' },
    qmHorizon,
    updateQmTime,
}: IClockQmProps) => {
    const { t, i18n } = useTranslation()
    const _config: IModuleConfig = useAppSelector(selectConfig)
    const _timeZone: string = useAppSelector(selectTimeZone)
    const _nowTime = useAppSelector(selectNowTime)
    const _epoch = useAppSelector(selectEpoch)
    const [ready, setReady] = useState<boolean>(false)
    const [value, setValue] = useState(DateTime.fromMillis(-1, { zone: _timeZone }))
    const [maxDateTime, setMaxDateTime] = useState(DateTime.fromMillis(-1, { zone: _timeZone }))
    const _fetchingData = useFetchingData()

    const calculateQmTimeFromEpoch = (epoch: number) => {
        const now = DateTime.fromMillis(_epoch, { zone: _timeZone })
        const qmDate = subtractTimeToDate(now, 'minutes', qmHorizon)
        return qmDate
    }

    useEffect(() => {
        const initDate = () => {
            if (_epoch === 0 || ready) return
            const qmDate = calculateQmTimeFromEpoch(_epoch)
            setReady(true)
            saveDate(qmDate)
        }
        initDate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_epoch, _timeZone])

    useEffect(() => {
        const setMaxDate = () => {
            if (_nowTime === 0) return
            const qmDate = calculateQmTimeFromEpoch(_nowTime)
            setMaxDateTime(qmDate)
        }
        setMaxDate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_nowTime, _timeZone])

    const handleAccept = (date: DateTime) => {
        saveDate(date)
    }

    const handleChange = (date: DateTime) => {
        setValue(date)
    }

    const saveDate = (date: DateTime) => {
        updateQmTime(date.toMillis())
        setValue(date)
    }

    const goLongTimeBack = () => {
        const nextDate: DateTime = subtractTimeToDate(value, longTimeStep.unit, longTimeStep.number)
        saveDate(nextDate)
    }

    const goStepBack = () => {
        const nextDate: DateTime = subtractTimeToDate(value, 'minutes', minutesStep)
        saveDate(nextDate)
    }

    const goStepForward = () => {
        const nextDate: DateTime = addTimeToDate(value, 'minutes', minutesStep)
        saveDate(nextDate)
    }

    const goLongTimeForward = () => {
        const nextDate: DateTime = addTimeToDate(value, longTimeStep.unit, longTimeStep.number)
        saveDate(nextDate)
    }

    const disabledStepForward = maxDateTime && addTimeToDate(value, 'minutes', minutesStep) > maxDateTime
    const disabledLongTimeForward =
        maxDateTime && addTimeToDate(value, longTimeStep.unit, longTimeStep.number) > maxDateTime
    const disabledStepBack = false
    const disabledLongTimeBack = false

    const renderInput = (params: TextFieldProps) => {
        let timeParams = { ...params }
        let dateParams = { ...params }

        if (timeParams?.inputProps?.value) {
            timeParams.inputProps = {
                ...params.inputProps,
                value: value.setZone(_timeZone).toFormat(_config.date_format.time),
            }
        }
        if (dateParams?.inputProps?.value) {
            dateParams.inputProps = {
                ...params.inputProps,
                value: value.setZone(_timeZone).toFormat(_config.date_format.date),
            }
        }

        return <ClockInputRender timeParams={timeParams} dateParams={dateParams} />
    }

    const DatetimePicker = () => {
        return (
            <StyledMobileDateTimePicker
                value={value}
                ampm={false}
                maxDateTime={maxDateTime}
                maxDate={maxDateTime}
                maxTime={maxDateTime}
                showToolbar={false}
                onAccept={(date: any) => handleAccept(date)}
                onChange={(date: any) => handleChange(date)}
                disabled={_fetchingData}
                minutesStep={minutesStep}
                DialogProps={{ style: { color: 'magenta' } }}
                renderInput={(params: any) => renderInput(params)}
                disableIgnoringDatePartForTimeValidation={true}
                dayOfWeekFormatter={day => {
                    return i18n.resolvedLanguage?.includes('zh')
                        ? day.slice(1, 2).toUpperCase()
                        : day.slice(0, 1).toUpperCase()
                }}
            />
        )
    }

    return (
        <ClockWrapper>
            <ClockStyledIconButton disabled={disabledLongTimeBack || _fetchingData} onClick={goLongTimeBack}>
                <FirstPage />
            </ClockStyledIconButton>
            <ClockStyledIconButton disabled={disabledStepBack || _fetchingData} onClick={goStepBack}>
                <ChevronLeft />
            </ClockStyledIconButton>

            <LocalizationProvider
                localeText={{ cancelButtonLabel: t('clockButtons.cancel'), okButtonLabel: t('clockButtons.ok') }}
                dateAdapter={AdapterLuxon}
                adapterLocale={i18n.language}>
                {DatetimePicker()}
            </LocalizationProvider>

            <ClockStyledIconButton disabled={disabledStepForward || _fetchingData} onClick={goStepForward}>
                <ChevronRight />
            </ClockStyledIconButton>
            <ClockStyledIconButton disabled={disabledLongTimeForward || _fetchingData} onClick={goLongTimeForward}>
                <LastPage />
            </ClockStyledIconButton>
        </ClockWrapper>
    )
}

export default ClockQM
