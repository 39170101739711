import { all, AllEffect, call, delay, ForkEffect, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { storeEvaluations, setFetchingEvaluations, setSelectedEvaluation } from './evaluationsSlice'
import { createLandUseEvaluations, getLandUseEvaluations } from '../../../services/evaluations/evaluationsService'
import {
    createNewEvaluationLandUse,
    loadPastEvaluationData,
    loadBaseLineData,
    loadEvaluationDataByView,
    loadEvaluationDataByViewMode,
    setCreatingEvaluationsLandUse,
} from './evaluationsLandUseSlice'
import { storeFetchingDataByMap, storeMapboxData } from '../../mapbox/mapboxSlice'
import { getTasks } from '../../../services/tasks/tasksService'
import { selectConfig } from '../../core/coreSlice'
import { selectHorizon } from '../../horizonSlider/horizonSilderSlice'
import { initEvaluationSimulationData } from '../../core/simulationEvaluationData/evaluationSimulationDataSaga'
import { getAllViewMode, getAllViewModeBaseline } from '../../../helpers/ContainerHelper'
import { selectEvaluationLandUseSimulationData } from '../../core/simulationEvaluationData/evaluationSimulationDataSlice'
import { getBaselineDataByViewMode } from '../../baselineLandUse/baselineLandUseSlice'
import { selectMapHorizonSelected } from '../../mapHorizonSelector/store/mapHorizonSelectedSlice'

export const TASK_STATUS_TYPE = {
    pending: 0,
    running: 1,
    complete: 2,
    cancelled: 3,
    failed: 4,
}

function* getEvaluationData(action: any, allViewMode: IViewMode[], epoch: number) {
    yield all(
        allViewMode.map((viewMode: IViewMode) =>
            call(initEvaluationSimulationData, {
                ...action,
                payload: {
                    epoch: epoch,
                    variable: viewMode.variable,
                },
            })
        )
    )
}

function* watchTasksRequests(action: any): any {
    const time = action.pack_id
    let finish = false
    let newSimulationsTasks
    while (!finish) {
        const tasks: any[] = yield call(getTasks)
        newSimulationsTasks = tasks.filter(evaluation => Number(evaluation.pack_id) === time)
        finish = newSimulationsTasks.every(
            task =>
                task &&
                (task.status === TASK_STATUS_TYPE.complete ||
                    task.status === TASK_STATUS_TYPE.cancelled ||
                    task.status === TASK_STATUS_TYPE.failed)
        )
        yield delay(5000)
    }

    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const allViewMode = getAllViewMode('Land use', _moduleConfig.view_mode, _moduleConfig.modules)
    const horizon: number = yield select(selectHorizon)
    const viewMode = allViewMode[0]
    const allViewModesBaseline = getAllViewModeBaseline(
        'Land use',
        _moduleConfig.baseline_view_mode,
        _moduleConfig.modules
    )
    const baselineData = yield select(getBaselineDataByViewMode, allViewModesBaseline[0].variable)
    if (finish) {
        yield call(getEvaluationData, action, allViewMode, action.pack_id)
        const amPmData = yield select(selectEvaluationLandUseSimulationData)
        yield all([
            yield call(storeEvaluationMapData, 0, allViewModesBaseline[0].id, baselineData['am_baseline']),
            yield call(storeEvaluationMapData, 1, viewMode.id, amPmData[viewMode.variable][horizon].am),
        ])
    } else {
        yield all([
            yield call(storeEvaluationMapData, 0, allViewModesBaseline[0].id, baselineData['am_baseline']),
            yield call(storeEvaluationMapData, 1, viewMode.id, {}),
        ])
    }
}

function* storeEvaluationMapData(mapId: number, viewModeId: number, data: any): any {
    yield put(
        storeMapboxData({
            stationData: [],
            sectionData: data,
            mapId,
            viewModeId,
            stationNowData: [],
            stationFutureData: [],
            sectionNowData: data,
            sectionFutureData: [],
            riskPrediction: [],
            riskPredictionData: [],
            speedRecommendation: [],
            speedRecommendationData: [],
            qm: false,
        })
    )
}

function* evaluationLandUseCreation(action: any): any {
    try {
        yield call(createLandUseEvaluations, action.payload)
        yield put(storeFetchingDataByMap(0))
        yield put(storeFetchingDataByMap(1))
        yield put(setFetchingEvaluations(false))
        const mapHorizonSelected: IMapHorizon = yield select(selectMapHorizonSelected)
        let evaluations = yield call(getLandUseEvaluations, mapHorizonSelected.id)
        yield put(storeEvaluations(evaluations))
        yield put(setCreatingEvaluationsLandUse(false))
        const newEvaluationCreated = evaluations.find((evaluation: any) => evaluation.epoch === action.payload.when)
        if (newEvaluationCreated) {
            yield put(setSelectedEvaluation(newEvaluationCreated))
        }
        yield call(watchTasksRequests, { pack_id: action.payload.when })
    } catch (error) {
        console.error(error)
    }
}

function* getEvaluationDataByViewMode(action: any): any {
    const { epoch, allViewMode, viewModeId, mapId, option } = action.payload
    const selectedViewMode = allViewMode.find(({ id }: IViewMode) => id === viewModeId)
    const horizon: number = yield select(selectHorizon)
    yield put(storeFetchingDataByMap(mapId))
    yield call(getEvaluationData, action, allViewMode, epoch)
    const amPmData = yield select(selectEvaluationLandUseSimulationData)
    yield call(storeEvaluationMapData, mapId, viewModeId, amPmData[selectedViewMode.variable][horizon][option])
}

function* getEvaluationDataByView(action: any): any {
    const { allViewMode, viewModeId, mapId, option } = action.payload
    const selectedViewMode = allViewMode.find(({ id }: IViewMode) => id === viewModeId)
    const horizon: number = yield select(selectHorizon)
    const amPmData = yield select(selectEvaluationLandUseSimulationData)
    yield call(storeEvaluationMapData, mapId, viewModeId, amPmData[selectedViewMode.variable][horizon][option])
}

function* getBaselineData(action: any): any {
    const { allViewMode, viewModeId, mapId, option } = action.payload
    const selectedViewMode = allViewMode.find(({ id }: IViewMode) => id === viewModeId)
    const baselineData = yield select(getBaselineDataByViewMode, selectedViewMode.variable)
    yield call(storeEvaluationMapData, mapId, viewModeId, baselineData[option])
}

function* getPastEvaluationData(action: any): any {
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const allViewMode = getAllViewMode('Land use', _moduleConfig.view_mode, _moduleConfig.modules)
    const allViewModesBaseline = getAllViewModeBaseline(
        'Land use',
        _moduleConfig.baseline_view_mode,
        _moduleConfig.modules
    )

    const horizon: number = yield select(selectHorizon)
    const viewMode = allViewMode[0]

    yield put(storeFetchingDataByMap(0))
    yield put(storeFetchingDataByMap(1))
    yield call(getEvaluationData, action, allViewMode, action.payload.epoch)
    const baselineData = yield select(getBaselineDataByViewMode, allViewModesBaseline[0].variable)
    const amPmData = yield select(selectEvaluationLandUseSimulationData)
    yield all([
        yield call(storeEvaluationMapData, 0, allViewModesBaseline[0].id, baselineData['am_baseline']),
        yield call(storeEvaluationMapData, 1, viewMode.id, amPmData[viewMode.variable][horizon].am),
    ])
}

function* evaluationsLandUseSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(createNewEvaluationLandUse, evaluationLandUseCreation),
        yield takeEvery(loadPastEvaluationData, getPastEvaluationData),
        yield takeEvery(loadEvaluationDataByViewMode, getEvaluationDataByViewMode),
        yield takeEvery(loadEvaluationDataByView, getEvaluationDataByView),
        yield takeEvery(loadBaseLineData, getBaselineData),
    ])
}

export default evaluationsLandUseSaga
