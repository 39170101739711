import { MobileDateTimePicker } from '@mui/x-date-pickers'
import { IconButton } from '@mui/material'
import { NAVY_BLUE } from '../../theme'
import styled from '@emotion/styled'

export const ClockWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin: auto;
    justify-content: center;
`

export const ClockStyledIconButton = styled(IconButton)`
    color: ${NAVY_BLUE};
    padding: 5px;
    margin: 0 -4px;
`
export const StyledMobileDateTimePicker = styled(MobileDateTimePicker)<{ disabled: boolean }>`
    & * {
        cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    }
`
