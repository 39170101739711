import { GREY, MUSTARD, OLIVE_ISH, RED_LIVE } from '../../theme'

export const getPatternQualityColor = (patternQuality: number): string => { if (patternQuality >= 70) {
        return OLIVE_ISH
    } else if (patternQuality > 60 && patternQuality < 70) {
        return MUSTARD
    } else  if (patternQuality <= 60) {
        return RED_LIVE
    } else {
        return GREY
    }
}
