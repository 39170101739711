import * as React from 'react'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useSelector } from 'react-redux'
import { Grid, TextFieldProps } from '@mui/material'
import {
    QmDialogClockDateInput,
    QmDialogDatePickerContainerWrapper,
    QmDialogDatePickerGeneralWrapper,
    QmDialogDatePickerLabel,
    QmDialogDatePickerWrapper,
    QmDialogLatestData,
    QmDialogNextDay,
    QmDialogNextWeek,
    QmDialogPreviousDay,
    QmDialogPreviousWeek,
} from './qualityManagerDialogStyles'
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { selectConfig } from '../core/coreSlice'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

export const QualityManagerOneDayDatePicker: React.FC<IQualityManagerOneDayDatePickerProps> = ({
    disabled,
    disableFutureNavigation,
    fromDate,
    onLoadPreviousWeek,
    onLoadPreviousDay,
    onLoadNextWeek,
    onLoadNextDay,
    onLoadLastPage,
    handleFromDatePickerChange,
}: IQualityManagerOneDayDatePickerProps) => {
    const { t, i18n } = useTranslation()
    const _moduleConfig: IModuleConfig = useSelector(selectConfig)
    const { date: dateFormat } = _moduleConfig.date_format

    const renderInput = (date: DateTime, params: TextFieldProps) => {
        let dateParams = { ...params }
        if (dateParams.inputProps && dateParams.inputProps.value) {
            let formattedValue = date.toFormat(dateFormat)
            dateParams.inputProps = Object.assign({}, params.inputProps, { value: formattedValue })
        }
        return <QmDialogClockDateInput {...dateParams} variant='standard' InputProps={{ disableUnderline: true }} />
    }

    return (
        <QmDialogDatePickerGeneralWrapper item>
            <QmDialogDatePickerContainerWrapper
                container
                direction='row'
                alignContent='center'
                alignItems='center'
                justifyContent='space-around'
                spacing={0}>
                <Grid item>
                    <QmDialogPreviousWeek
                        onClick={onLoadPreviousWeek}
                        color={disabled ? 'disabled' : 'inherit'}
                        disabled={disabled}
                        titleAccess={t('qualityManager.previousPeriod')}
                    />
                </Grid>
                <Grid item>
                    <QmDialogPreviousDay
                        onClick={onLoadPreviousDay}
                        color={disabled ? 'disabled' : 'inherit'}
                        disabled={disabled}
                        titleAccess={t('qualityManager.previousDay')}
                    />
                </Grid>
                <QmDialogDatePickerWrapper item>
                    <QmDialogDatePickerLabel>{t('qualityManager.currentDate')}</QmDialogDatePickerLabel>
                    <LocalizationProvider
                        localeText={{cancelButtonLabel: t('clockButtons.cancel'), okButtonLabel: t('clockButtons.ok')}}
                        dateAdapter={AdapterLuxon}
                        adapterLocale={i18n.language}
                    >
                        <MobileDatePicker
                            showToolbar={false}
                            disableFuture={true}
                            disabled={disabled}
                            value={fromDate}
                            onChange={handleFromDatePickerChange}
                            renderInput={(params: any) => renderInput(fromDate, params)}
                        />
                    </LocalizationProvider>
                </QmDialogDatePickerWrapper>
                <Grid item>
                    <QmDialogNextDay
                        onClick={onLoadNextDay}
                        color={disableFutureNavigation || disabled ? 'disabled' : 'inherit'}
                        disabled={disableFutureNavigation || disabled}
                        titleAccess={t('qualityManager.nextDay')}
                    />
                </Grid>
                <Grid item>
                    <QmDialogNextWeek
                        onClick={onLoadNextWeek}
                        color={disableFutureNavigation || disabled ? 'disabled' : 'inherit'}
                        disabled={disableFutureNavigation || disabled}
                        titleAccess={t('qualityManager.nextPeriod')}
                    />
                </Grid>
                <Grid item>
                    <QmDialogLatestData
                        onClick={onLoadLastPage}
                        color={disableFutureNavigation || disabled ? 'disabled' : 'inherit'}
                        disabled={disableFutureNavigation || disabled}
                        titleAccess={t('qualityManager.lastDay')}
                    />
                </Grid>
            </QmDialogDatePickerContainerWrapper>
        </QmDialogDatePickerGeneralWrapper>
    )
}
