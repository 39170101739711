import { Button, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { RED_LIVE } from '../../../../../../theme'

export const StepDialogButton = styled(Button)`
  padding: 9px 25px 6px;
  border-radius: 10px;
  margin-left: 20px;
  
  &:hover {
    opacity: 0.85;
  }
`

export const ActionsButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 32px;
`

export const NoDataMessage = styled(Typography)`
  color: ${RED_LIVE};
  font-size: 20px;
`
