import { Card, CardHeader} from '@mui/material'
import { NO_DATA } from '../../theme'
import styled from '@emotion/styled'

export const CardTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const CardHeaderStyled = styled(CardHeader)`
  border-bottom: 1px solid ${NO_DATA};
`

export const CardStationStyled = styled(Card)<{ width: string, height: string, marginbottom: string }>`
  width: ${props => (props.width ? props.width : '50%')};
  height: ${props => (props.height ? props.height : '50%')};
  padding: 8px 16px;
  margin: 0 auto;
  position: absolute;
  bottom: ${props => (props.marginbottom ? props.marginbottom : '56px')};
  left: 20%;
  z-index: 99;
`
