import { Box, CircularProgress, Typography } from '@mui/material'
import { statusTypes } from './TaskDialogHelper'

export const ProgressField: React.FC<IProgressFieldProps> = ({ progress, status }) => {
    if (progress < 100 && status !== 2) {
        return (
            <Box position='relative' display='inline-flex'>
                <CircularProgress size='40px' variant='determinate' thickness={5} value={progress} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position='absolute'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'>
                    <Typography variant='caption' component='div' color='primary'>{`${Math.round(
                        progress
                    )}%`}</Typography>
                </Box>
            </Box>
        )
    } else {
        return <Typography variant='body2'>{statusTypes.get(status)}</Typography>
    }
}
