import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface ISpeedRecommendationDataState {
    speedRecommendationData: IPredictionByHorizonData[]
}

const initialSpeedRecommendationDataState: ISpeedRecommendationDataState = {
    speedRecommendationData: [],
}

export const speedRecommendationDataSlice = createSlice({
    name: 'speedRecommendationData',
    initialState: initialSpeedRecommendationDataState,
    reducers: {
        storeSpeedRecommendationData: (state, action: PayloadAction<{ data: IPredictionByHorizonData[]}>): void => {
            state.speedRecommendationData = action.payload.data
        },
        loadSpeedRecommendationData: (state, action: PayloadAction<{ epoch: number}>): void => {}
    },
})

export const { loadSpeedRecommendationData, storeSpeedRecommendationData } = speedRecommendationDataSlice.actions

export const selectSpeedRecommendationData = (state: RootState): IPredictionByHorizonData[] => state.speedRecommendationData.speedRecommendationData

export default speedRecommendationDataSlice.reducer
