import {
    all,
    AllEffect,
    call, CallEffect,
    ForkEffect,
    put,
    PutEffect,
    takeEvery,
} from 'redux-saga/effects'
import { loadSearchFeature, setFetchingFeature, storeFeatureList } from './searchFeatureSlice'
import { searchFeature } from '../../../services/search/searchFeatureService'
import { AxiosResponse } from 'axios'

function* fetchFeature(action: any): Generator<CallEffect<AxiosResponse<number, any>> |
    PutEffect<{payload: any, type: "searchFeature/storeFeatureList"}> |
    PutEffect<{payload: any, type: "searchFeature/setFetchingFeature"}>, void, unknown> {
    const foundListCall = yield call(searchFeature, action.payload.criteria, action.payload.searchIdentifier)
    yield put(storeFeatureList({ foundList: foundListCall, mapId: action.payload.mapId, criteria: action.payload.criteria }))
    yield put(setFetchingFeature(action.payload.mapId))
}

function* searchFeatureSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield all([yield takeEvery(loadSearchFeature, fetchFeature)])])
}

export default searchFeatureSaga
