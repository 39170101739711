import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { RED_LIVE } from '../../theme'

export const EvaluationsToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 0 8px;
  margin: auto;
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
`

export const EvaluationsToolbarIcon = styled(Typography)`
  margin-top: 5px;
  margin-right: 14px;
  &.disabled {
    .MuiSvgIcon-root {
      color: black;
      opacity: 0.87;
    }
  }
  &.open-evaluationsToolbar-dialog {
    color: ${RED_LIVE};
  }
`

export const EvaluationsToolbarLabel = styled(Typography)`
  margin: auto 0;
  &.open-evaluationsToolbar-dialog {
    color: ${RED_LIVE};
    font-weight: bold;
  }
`