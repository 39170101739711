import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

const initialState: IBaselineLandUseTypes[] = [
    {
        am_baseline: [],
        pm_baseline: [],
        variable: 'baseline_flow',
    },
]

export const baselineLandUseSlice = createSlice({
    name: 'baselineLandUse',
    initialState,
    reducers: {
        storeLandUseBaseline: (state, action: PayloadAction<any>): void => {
            const baselineIndexByViewMode = state.findIndex(baseline => baseline.variable === action.payload.variable)
            const nextLandUseBaseline = {
                am_baseline: action.payload.am_baseline,
                pm_baseline: action.payload.pm_baseline,
                variable: action.payload.variable,
            }
            if (baselineIndexByViewMode !== -1) {
                state[baselineIndexByViewMode] = nextLandUseBaseline
            } else {
                state.push(nextLandUseBaseline)
            }
        },
        loadBaseLineLandUse: (state, action: PayloadAction<any>): void => {},
    },
})

export const { loadBaseLineLandUse, storeLandUseBaseline } = baselineLandUseSlice.actions

export const selectBaselineData = (state: RootState): IBaselineLandUseTypes[] => state.baselineLandUse

export const getBaselineDataByViewMode = (state: RootState, variable: string) => {
    const baseline = state.baselineLandUse.find(baseline => baseline.variable === variable)
    return baseline ? baseline : state.baselineLandUse[0]
}

export const isBaselineDataAvailable = (state: RootState) => {
    const baseline = state.baselineLandUse.find(baseline => baseline.am_baseline.length > 0)
    return baseline !== undefined
}

export default baselineLandUseSlice.reducer
