import { SpeedDial } from '@mui/material'
import styled from '@emotion/styled'
import { NAVY_BLUE, WHITE } from '../../theme'

export const CustomSpeedDialStyled = styled(SpeedDial)`
    button {
        color: ${WHITE};
        background-color: ${NAVY_BLUE};
        &:hover {
            background-color: ${NAVY_BLUE};
        }
        &.MuiSpeedDial-fab {
            width: 42px;
            height: 42px;
        }
    }

    .MuiSpeedDialAction-fab {
        width: 42px;
        height: 42px;
    }

    .MuiSpeedDialAction-staticTooltipLabel {
        white-space: nowrap;
        max-width: none;
    }

    .MuiSpeedDialAction-tooltipPlacementTop {
        margin: 0 4px;
        .MuiSpeedDialAction-staticTooltipLabel {
            top: -50px;
            max-width: 100%;
            white-space: inherit;
            padding: 4px;
            font-size: 12px;
        }
    }
`

export const sxActionSelected = {
    '.MuiSpeedDialAction-staticTooltipLabel ': {
        backgroundColor: NAVY_BLUE,
        color: WHITE,
        whiteSpace: 'nowrap',
        maxWidth: 'none',
    },
    '.MuiSpeedDialAction-fab': {
        backgroundColor: NAVY_BLUE,
        '&:hover': {
            backgroundColor: NAVY_BLUE,
        },
    },
}

export const sxActionNoSelected = {
    '.MuiSpeedDialAction-staticTooltipLabel ': {
        backgroundColor: WHITE,
        color: NAVY_BLUE,
        whiteSpace: 'nowrap',
        maxWidth: 'none',
    },
    '& .MuiSpeedDialAction-fab': {
        backgroundColor: WHITE,
        '&:hover': {
            backgroundColor: WHITE,
        },
    },
}
