import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { setupCache, CacheOptions, buildMemoryStorage, HeaderInterpreter } from 'axios-cache-interceptor'
import { handleRefreshToken, signOut } from '../../features/auth/auth'
import store from '../../app/store'

export const client: AxiosInstance = axios.create({
    timeout: 14400000, //4h
})

export const headerInterpreter: HeaderInterpreter = () => {
    const hours: number = 12
    const seconds: number = 1000 * 60 * 60 * hours

    if (seconds < 1) {
        return 'dont cache'
    }

    return seconds
}

const needsAuth = (): boolean | undefined => {
    const auth = store.getState().core.main_config.auth
    if (store.getState().core.main_config.auth) {
        if (auth['use-login'] === undefined) {
            return undefined
        }
        let useLogin: boolean = !!auth['use-login']
        if (auth['use-login'] === 'false') {
            useLogin = false
        }
        if (useLogin) {
            return true
        }
    } else {
        return true
    }
    return false
}

const addAuthorization = (config: InternalAxiosRequestConfig) => {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken && accessToken !== 'undefined') {
        config.headers['Authorization'] = `Bearer ${JSON.parse(accessToken)}`
    } else {
        const searchParams: URLSearchParams = new URL(window.location.href).searchParams
        if (searchParams.get('token') || searchParams.get('refresh_token')) {
            localStorage.setItem('access_token', JSON.stringify(searchParams.get('token')))
            localStorage.setItem('refresh_token', JSON.stringify(searchParams.get('refresh_token')))
            window.location.href = window.location.href.split('?', 1)[0]
        } else if (needsAuth() === true) {
            signOut()
        }
    }
    return config
}

const cacheOptions: CacheOptions = {
    headerInterpreter,
    storage: buildMemoryStorage(true, false, false),
}

setupCache(client, cacheOptions)

client.interceptors.request.use(
    config => {
        config = addAuthorization(config)
        config.headers['Accept'] = 'application/json'
        return config
    },
    error => {
        console.error('Request Error')
        console.error(error)
        return Promise.reject(error)
    }
)

client.interceptors.response.use(
    response => {
        return response.data
    },
    async error => {
        if (error.response.status === 401 && !error.config._retry) {
            error.config._retry = true
            let accessToken: string = ''
            try {
                const refreshTokenResp: IAuthResponse = await handleRefreshToken()
                accessToken = refreshTokenResp.access_token
                localStorage.setItem('access_token', JSON.stringify(refreshTokenResp.access_token))
                localStorage.setItem('refresh_token', JSON.stringify(refreshTokenResp.refresh_token))
            } catch (error) {
                signOut()
            }

            error.config.headers['Authorization'] = `Bearer ${accessToken}`
            return await client(error.config)
        }

        console.error('Response Error')
        console.error(error)
        return Promise.reject(error)
    }
)
