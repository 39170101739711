import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface IEventsState {
    eventsIsSelected: boolean
    openEventsDialog: boolean
    featureEventSelected: any
    eventCreating: boolean
    fetchingEvents: boolean
    listEvents: any[]
}

const initialEventsState: IEventsState = {
    eventsIsSelected: false,
    openEventsDialog: false,
    fetchingEvents: false,
    eventCreating: false,
    featureEventSelected: {
        id: 0,
    },
    listEvents: []
}

const eventsSlice = createSlice({
    name: 'events',
    initialState: initialEventsState,
    reducers: {
        setEventsSelected: (state, action: PayloadAction<boolean>): void => {
            state.eventsIsSelected = action.payload
        },
        setOpenEventsDialog: (state, action: PayloadAction<boolean>): void => {
            state.openEventsDialog = action.payload
        },
        storeFeatureEventsSelected: (state, action: PayloadAction<any>): void => {
            state.featureEventSelected = action.payload
        },
        storeListEvents: (state, action: PayloadAction<any>): void => {
            state.listEvents = action.payload
        },
        setEventCreating: (state, action: PayloadAction<any>): void => {
            state.eventCreating = action.payload
        },
        createNewEvent: (state, action: PayloadAction<any>): void => {
            state.eventCreating = true
        },
        updateEvent: (state, action: PayloadAction<any>): void => {
            state.eventCreating = true
        },
        deleteEvent: (state, action: PayloadAction<any>): void => {
        },
        setFetchNetworkEvents: (state, action: PayloadAction<any>): void => {
            state.fetchingEvents = action.payload
        },
        loadEvents: (state, action: PayloadAction<any>): void => {
            state.fetchingEvents = true
        },
        loadListEvents: (state): void => {
            state.fetchingEvents = true
        }
    },
})

export const {
    setEventsSelected,
    storeFeatureEventsSelected,
    storeListEvents,
    setOpenEventsDialog,
    createNewEvent,
    updateEvent,
    deleteEvent,
    setEventCreating,
    setFetchNetworkEvents,
    loadEvents,
    loadListEvents
} = eventsSlice.actions

export const selectEventsSelected = (state: RootState) => state.events.eventsIsSelected
export const selectOpenEventsDialog = (state: RootState) => state.events.openEventsDialog
export const selectFeatureEventSelected = (state: RootState) => state.events.featureEventSelected
export const selectListEvents = (state: RootState) => state.events.listEvents

export default eventsSlice.reducer
