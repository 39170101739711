import { all, AllEffect, call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { changeViewsLayout, storeViewsLayout, changeDataByMap } from './viewSettingsSlice'
import { selectAllMapData, selectVisibility, storeDataByMap, storeLayerVisibility } from '../mapbox/mapboxSlice'
import { selectConfig } from '../core/coreSlice'
import { getDataFromStore, getRiskPredictionFrommStore } from '../core/mapData/mapDataSaga'
import { selectHorizon } from '../horizonSlider/horizonSilderSlice'
import { selectSpeedRecommendationData } from '../core/speedRecommendationData/speedRecommendationDataSlice'

function* addMaps(action: any): any {
    const allMapboxData = yield select(selectAllMapData)
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const layersVisibility: IMapContainerLayers = yield select(selectVisibility)
    const step: number = _moduleConfig['horizon-step']
    const horizon = yield select(selectHorizon)
    const lastViewModeSelected = _moduleConfig.view_mode.find(
        ({ id }: IViewMode) => id === allMapboxData[allMapboxData.length - 1].viewModeId
    )
    let nextDataByMap = [...allMapboxData]
    let nextLayersVisibility = {...layersVisibility}
    if (action.payload > allMapboxData.length) {
        const stationNowData = lastViewModeSelected
            ? yield call(
                  getDataFromStore,
                  lastViewModeSelected.sources.stations.now,
                  lastViewModeSelected.variable,
                  'now'
              )
            : []
        const stationFutureData = lastViewModeSelected
            ? yield call(
                  getDataFromStore,
                  lastViewModeSelected.sources.stations.future,
                  lastViewModeSelected.variable,
                  'future'
              )
            : []
        const sectionNowData = lastViewModeSelected
            ? yield call(
                  getDataFromStore,
                  lastViewModeSelected.sources.sections.now,
                  lastViewModeSelected.variable,
                  'now'
              )
            : []
        const sectionFutureData = lastViewModeSelected
            ? yield call(
                  getDataFromStore,
                  lastViewModeSelected.sources.sections.future,
                  lastViewModeSelected.variable,
                  'future'
              )
            : []
        const riskPredictionData = lastViewModeSelected
            ? yield call(getRiskPredictionFrommStore, lastViewModeSelected.sources.sections.future)
            : []

        const speedRecommendationData = lastViewModeSelected ? yield select(selectSpeedRecommendationData) : []

        let newDataByMap = new Array(action.payload - allMapboxData.length).fill(
            allMapboxData[allMapboxData.length - 1]
        )
        newDataByMap.forEach((dataByMap, index) => {
            newDataByMap[index] = {
                stationData: horizon > 0 ? stationFutureData[horizon / step] || [] : stationNowData,
                sectionData: horizon > 0 ? sectionFutureData[horizon / step] || [] : sectionNowData,
                mapId: allMapboxData.length + index,
                viewModeId: lastViewModeSelected?.id,
                stationNowData,
                stationFutureData,
                sectionNowData,
                sectionFutureData,
                riskPrediction: riskPredictionData[horizon / step],
                riskPredictionData: lastViewModeSelected ? riskPredictionData : [],
                speedRecommendationData: speedRecommendationData,
                speedRecommendation: speedRecommendationData[0],
                fetchingData: false,
            }
        })
        nextDataByMap = nextDataByMap.concat(newDataByMap)
        for (let i = allMapboxData.length; i < action.payload; i++) {
            nextLayersVisibility = {
                ...nextLayersVisibility,
                [i]:  layersVisibility[allMapboxData.length - 1]
            }
        }
    } else {
        nextDataByMap.splice(action.payload)
    }

    yield put(storeLayerVisibility(nextLayersVisibility))
    yield put(storeDataByMap(nextDataByMap))
}

function* changeViews(action: any): any {
    yield put(changeDataByMap(action.payload))
    yield put(storeViewsLayout(action.payload))
}

function* viewSettingsSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeLatest(changeViewsLayout, changeViews), yield takeLatest(changeDataByMap, addMaps)])
}

export default viewSettingsSaga
