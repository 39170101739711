import React, { useState } from 'react'
import {
    ArrowBackIosRounded as ArrowBack,
    ArrowForwardIosRounded as ArrowForward,
    PlaylistAddCheckRounded,
    Settings as SettingsIcon,
    FormatListBulleted,
} from '@mui/icons-material'

import { Grid } from '@mui/material'
import {
    ClosedSidebarToggle,
    IconWrapper,
    OpenSidebarToggle,
    SidebarActions,
    SidebarItem,
    TextWrapper,
    SidebarContainer,
} from './sidebarStyles'
import { QualityManagerDialog } from '../qualityManagerDialog/qualityManagerDialog'
import { SystemStatusDialog } from '../systemStatus/SystemStatusDialog'
import { useTranslation } from 'react-i18next'
import { TasksDialog } from '../../containers/manageContainer/components/tasks/TaskDialog'

const Sidebar: React.FC<ISidebarProps> = ({ toggleSidebar, open, epoch, options }: ISidebarProps) => {
    const { t } = useTranslation()
    const [systemStatusDialogOpen, setSystemStatusDialogOpen] = useState<boolean>(false)
    const [qmDialogOpen, setQmDialogOpen] = useState<boolean>(false)
    const [tasksDialogOpen, setTasksDialogOpen] = useState<boolean>(false)

    const toggleQmDialog = (open: boolean): void => {
        setQmDialogOpen(!open)
    }

    const toggleSystemStatusDialog = (open: boolean): void => {
        setSystemStatusDialogOpen(!open)
    }

    const toggleTaskDialog = (open: boolean): void => {
        setTasksDialogOpen(!open)
    }

    return (
        <>
            <SidebarContainer container direction='column' justifyContent='flex-start' alignItems='center' open={open}>
                <SidebarActions>
                    {open ? (
                        <OpenSidebarToggle onClick={() => toggleSidebar(open)}>
                            <IconWrapper>
                                <ArrowBack fontSize='large' />
                            </IconWrapper>
                            <TextWrapper>{t('sideBar.showLess')}</TextWrapper>
                        </OpenSidebarToggle>
                    ) : (
                        <ClosedSidebarToggle onClick={() => toggleSidebar(open)}>
                            <IconWrapper>
                                <ArrowForward fontSize='large' />
                            </IconWrapper>
                        </ClosedSidebarToggle>
                    )}
                </SidebarActions>
                {options?.quality_manager && options.quality_manager.active && (
                    <SidebarItem
                        container
                        onClick={() => toggleQmDialog(qmDialogOpen)}
                        key='quality manager'
                        direction='row'
                        alignItems='center'
                        wrap='nowrap'
                        spacing={1}>
                        <Grid item>
                            <IconWrapper>
                                <PlaylistAddCheckRounded key='QM' fontSize='large' />
                            </IconWrapper>
                        </Grid>
                        {open && (
                            <Grid item>
                                <TextWrapper>{t('qualityManager.qualityManager')}</TextWrapper>
                            </Grid>
                        )}
                    </SidebarItem>
                )}
                {options?.system_status && options.system_status.active && (
                    <SidebarItem
                        container
                        onClick={() => toggleSystemStatusDialog(systemStatusDialogOpen)}
                        key='system status'
                        direction='row'
                        alignItems='center'
                        wrap='nowrap'
                        spacing={1}>
                        <Grid item>
                            <IconWrapper>
                                <SettingsIcon key='SystemStatus' fontSize='large' />
                            </IconWrapper>
                        </Grid>
                        {open && (
                            <Grid item>
                                <TextWrapper>{t('systemStatus.systemStatus')}</TextWrapper>
                            </Grid>
                        )}
                    </SidebarItem>
                )}
                {options?.tasks && (
                    <SidebarItem
                        container
                        onClick={() => toggleTaskDialog(systemStatusDialogOpen)}
                        key='system status'
                        direction='row'
                        alignItems='center'
                        wrap='nowrap'
                        spacing={1}>
                        <Grid item>
                            <IconWrapper>
                                <FormatListBulleted key='Tasks' fontSize='large' />
                            </IconWrapper>
                        </Grid>
                        {open && (
                            <Grid item>
                                <TextWrapper>{t('tasks.tasks')}</TextWrapper>
                            </Grid>
                        )}
                    </SidebarItem>
                )}
            </SidebarContainer>
            {qmDialogOpen && (
                <QualityManagerDialog
                    open={qmDialogOpen}
                    epoch={epoch}
                    onClose={toggleQmDialog}
                    options={options.quality_manager}
                />
            )}
            {systemStatusDialogOpen && (
                <SystemStatusDialog open={systemStatusDialogOpen} onClose={toggleSystemStatusDialog} epoch={epoch} />
            )}
            {tasksDialogOpen && <TasksDialog open={tasksDialogOpen} onClose={toggleTaskDialog} epoch={epoch} />}
        </>
    )
}

export default Sidebar
