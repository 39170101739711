import ApexCharts from 'apexcharts'
import { MUSTARD, NAVY_BLUE, NEXT_BLUE, OLIVE_ISH, PALE_PINK, PURPLE_ISH, RED_LIVE, SMOKE, WYLD_PINK } from '../../../../../theme'
import { IRawKpi } from '../../../../analyzeContainer/components/kpi/store/kpisSlice'

interface MinMaxKpi { [key: string]: number }

interface KpiChartData { kpiName: string, kpiAvg: number}

export const getChartOptions = (categories: number[]): ApexCharts.ApexOptions => {
    const COLORS = [NEXT_BLUE, RED_LIVE, OLIVE_ISH, NAVY_BLUE, MUSTARD, PURPLE_ISH, WYLD_PINK, PALE_PINK, SMOKE]

    return {
        chart: {
            toolbar: {
                show: false,
            },
            type: 'radar',
            dropShadow: {
                enabled: true,
                blur: 1,
                left: 1,
                top: 1,
            },
        },
        colors: COLORS,
        dataLabels: {
            enabled: false,
            background: {
                enabled: true,
                borderRadius: 2,
                dropShadow: {},
            },
            formatter: function(val) {
                return (val as Number).toFixed(0)
            },
        },
        tooltip: {
            enabled: true,
        },
        stroke: {
            width: 2,
        },
        fill: {
            opacity: 0.1,
        },
        markers: {
            size: 0,
        },
        xaxis: {
            categories: categories,
            labels: {
                show: true,
                offsetX: 5,
                formatter: function(val) {
                    const parsedVal = Number.parseInt(val)
                    return parsedVal.toFixed(0)
                },
                style: {
                    colors: categories.map(() => NAVY_BLUE),
                    fontSize: '16px',
                    fontFamily: 'AimsunMedium',
                },
            },
        },
        yaxis: {
            min: -20,
            max: 100,
            tickAmount: 6,
        },
        legend: {
            show: true,
            position: 'left',
            showForSingleSeries: true,
        },
    }
}



const normalizeChartData = (chartData: { name: string, data: KpiChartData[], minKpis: MinMaxKpi,maxKpis: MinMaxKpi }[]) => {
    return chartData.map(rpData => ({
        name: rpData.name,
        data: rpData.data.map((kpi) => {
            const min = rpData.minKpis[kpi.kpiName]
            const max = rpData.maxKpis[kpi.kpiName]
            const baseReference = max - min
            const valueReference = kpi.kpiAvg - min
            let result = baseReference === 0 ? 50 : (100 * valueReference) / baseReference //for now is max === min, normalize to 50%

            return Number.isInteger(result) ? result : Number.parseFloat(result.toFixed(2))
        }),
    }))
}

export const getChartData = (kpiList: {[p: string]: IRawKpi[]}) => {
    let minKpis: MinMaxKpi = {}
    let maxKpis: MinMaxKpi = {}
    let chartDataAux: { name: string, data: KpiChartData[], minKpis: MinMaxKpi,maxKpis: MinMaxKpi }[]  = []
    let kpiNamesAux: string[] = []

    for (let rpId in kpiList) {
        const kpis = kpiList[rpId]
        const kpiNames = kpis
            .map((kpi) => kpi.name)
            .filter((item, pos, self) => self.indexOf(item) === pos)

        kpiNamesAux = [...kpiNames]

        let seriesData: { kpiName: string, kpiAvg: number}[] = []

        kpiNames.forEach(kpiName => {
            let kpiSum = 0
            let horizonCount = 0
            kpis.filter(kpi => kpi.name === kpiName).forEach((kpi, i, self) => {
                horizonCount = self.length
                kpiSum += kpi.value
            })
            const kpiAvg = kpiSum / horizonCount
            if (!minKpis[kpiName]) {
                minKpis[kpiName] = kpiAvg
            } else if (minKpis[kpiName] > kpiAvg) {
                minKpis[kpiName] = kpiAvg
            }
            if (!maxKpis[kpiName]) {
                maxKpis[kpiName] = kpiAvg
            } else if (maxKpis[kpiName] < kpiAvg) {
                maxKpis[kpiName] = kpiAvg
            }
            seriesData.push({
                kpiName: kpiName,
                kpiAvg: kpiAvg
            })
        })

        const label = rpId === '0' ? 'DN' : `RP${rpId}`

        chartDataAux.push({
            name: label,
            data: seriesData,
            minKpis,
            maxKpis

        })
    }
    return {
        normalizedData:  normalizeChartData(chartDataAux),
        kpiNames: kpiNamesAux,
        categories: [...kpiNamesAux.map((arr, index) => index + 1)]
    }
}
