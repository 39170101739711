import React  from 'react'
import {
    Radio,
    RadioGroup,
} from '@mui/material'
import { TrafficActionsRPFormControlStyled, TrafficActionsRPWrapper } from './trafficActionsResponsePlanStyles'

const TrafficActionsResponsePlan: React.FC<TrafficActionsResponsePlanProps> = ({handleControlPlanChange, controlPlanIDSelected, controlPlanList}): any => {

    return (
        <RadioGroup
            aria-label='control-plans-list'
            name='control-plans'
            value={controlPlanIDSelected}
            onChange={handleControlPlanChange}
            style={{
                marginBottom: '20px',
                maxHeight: '60vh',
                overflow: 'auto',
            }}>
            <TrafficActionsRPWrapper>
                {controlPlanList.map((controlPlan: ControlPlan, index: number) => (
                    <TrafficActionsRPFormControlStyled
                        value={controlPlan.control_plan_id}
                        control={<Radio color='primary' />}
                        label={controlPlan.control_plan_name}
                        labelPlacement='end'
                        key={controlPlan.id + controlPlan.control_plan_name}
                        className={index % 2 === 0 ? 'list__row--even' : 'list__row--odd'}
                    />
                ))}
            </TrafficActionsRPWrapper>
        </RadioGroup>
    )
}

export default TrafficActionsResponsePlan

