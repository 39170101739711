import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface IRealDataState {
    realData: {
        [key: string]: IMapDataByHorizon[]
    }
    lastVariable: string
    fetching: boolean
}

const initialRealDataState: IRealDataState = {
    realData: {},
    lastVariable: 'flow',
    fetching: false,
}

export const realDataSlice = createSlice({
    name: 'realData',
    initialState: initialRealDataState,
    reducers: {
        storeRealData: (state, action: PayloadAction<{ data: IMapDataByHorizon; variable: string }>): void => {
            state.realData = { ...state.realData, [action.payload.variable]: [action.payload.data] }
            state.lastVariable = action.payload.variable
        },
        setFetchingRealData: (state, action: PayloadAction<boolean>): void => {
            state.fetching = action.payload
        },
        loadRealData: (state, action: PayloadAction<{ epoch: number; variable: string }>): void => {
            state.fetching = true
        },
    },
})

export const { loadRealData, setFetchingRealData, storeRealData } = realDataSlice.actions

export const selectRealData = (state: RootState): { [key: string]: IMapDataByHorizon[] } => state.realData.realData
export const selectFetchingRealData = (state: RootState): boolean => state.realData.fetching

export default realDataSlice.reducer
