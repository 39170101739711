import { all, AllEffect, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'
import { loadBaseLineLandUse, storeLandUseBaseline } from './baselineLandUseSlice'
import { DateTime } from 'luxon'
import { addTimeToDate } from '../../helpers/DateTimeHelper'
import { getLandUseBaseline } from '../../services/baselineLandUse/baselineLandUseService'
import { selectNowTime, selectTimeZone } from '../core/coreSlice'
import { selectMapHorizonSelected } from '../mapHorizonSelector/store/mapHorizonSelectedSlice'

function* fetchBaselineLanUse(action: any): any {
    const _nowTime: number = yield select(selectNowTime)
    const _timeZone: string = yield select(selectTimeZone)
    const _mapHorizonSelected: IMapHorizon = yield select(selectMapHorizonSelected)
    const startNowTimeDate = DateTime.fromMillis(_nowTime).setZone(_timeZone).startOf('day')
    const amTime = addTimeToDate(startNowTimeDate, 'hours', 10).valueOf()
    const pmTime = addTimeToDate(startNowTimeDate, 'hours', 19).valueOf()
    const pattern_id = _mapHorizonSelected.pattern_id
    const variable = action.payload

    try {
        const [am_baseline, pm_baseline] = yield all([
            call(getLandUseBaseline, amTime, pattern_id, variable),
            call(getLandUseBaseline, pmTime, pattern_id, variable),
        ])

        yield put(storeLandUseBaseline({ variable, am_baseline, pm_baseline }))
    } catch (error) {
        console.error(error)
    }
}

function* baselineLandUseSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([yield takeEvery(loadBaseLineLandUse, fetchBaselineLanUse)])
}

export default baselineLandUseSaga
