import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

const searchFeatureInitialState: ISearchFeature = {
    fetchingFeature: [
        {
            fetching: false,
            mapId: 0,
            criteria: '',
            features: [],
            openListFoundFeatures: false
        },

    ],
}

const searchFeatureSlice = createSlice({
    name: 'searchFeature',
    initialState: searchFeatureInitialState,
    reducers: {
        storeFeatureList: (state, action: PayloadAction<any>): void => {
            state.fetchingFeature[action.payload.mapId] = {
                fetching: false,
                mapId: action.payload.mapId,
                criteria: action.payload.criteria,
                features: action.payload.foundList,
                openListFoundFeatures: true
            }
        },
        restoreInitialFeatureList: (state, action: PayloadAction<any>): void => {
            state.fetchingFeature[action.payload.mapId] = {
                fetching: false,
                mapId: action.payload.mapId,
                criteria: '',
                features: [],
                openListFoundFeatures: false
            }
        },
        setFetchingFeature: (state, action: PayloadAction<any>): void => {
            state.fetchingFeature[action.payload].fetching = false
        },
        loadSearchFeature: (state, action: PayloadAction<{ mapId: number, criteria: string, searchIdentifier: string }>): void => {
            const fetchingFeatureIndexByMapId = state.fetchingFeature.findIndex(fetchingFeature => fetchingFeature.mapId === action.payload.mapId)
            const resetSearchFeature: ISearchFeatureByMap = {
                fetching: true,
                mapId: action.payload.mapId,
                criteria: action.payload.criteria,
                features: [],
                openListFoundFeatures: false
            }

            if (fetchingFeatureIndexByMapId !== -1) {
                state.fetchingFeature[fetchingFeatureIndexByMapId] = resetSearchFeature
            } else {
                state.fetchingFeature.push(resetSearchFeature)
            }
        },
    },
})

export const { storeFeatureList, restoreInitialFeatureList, setFetchingFeature, loadSearchFeature } = searchFeatureSlice.actions

export const selectFetchingFeatureByMapFetching = (state: RootState, mapId: number) => {
    const fetchingFeatureByMap = state.searchFeature.fetchingFeature.find(fetchingFeature => fetchingFeature.mapId === mapId)
    return fetchingFeatureByMap ? fetchingFeatureByMap.fetching : false
}

export const selectFoundFeatureList = (state: RootState, mapId: number) => {
    const fetchingFeatureByMap = state.searchFeature.fetchingFeature.find(fetchingFeature => fetchingFeature.mapId === mapId)
    return fetchingFeatureByMap ? fetchingFeatureByMap.features : []
}

export const selectOpenFoundFeatureList = (state: RootState, mapId: number) => {
    const fetchingFeatureByMap = state.searchFeature.fetchingFeature.find(fetchingFeature => fetchingFeature.mapId === mapId)
    return fetchingFeatureByMap ? fetchingFeatureByMap.openListFoundFeatures : false
}

export const selectSearchCriteria = (state: RootState, mapId: number) => {
    const fetchingFeatureByMap = state.searchFeature.fetchingFeature.find(fetchingFeature => fetchingFeature.mapId === mapId)
    return fetchingFeatureByMap ? fetchingFeatureByMap.criteria : ''
}

export default searchFeatureSlice.reducer
