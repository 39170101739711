import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { NAVY_BLUE, OLIVE_ISH } from '../../theme'

export const WidgetTitle = styled(Typography)`
    margin-left: 12px;
    margin-top: 12px;
`

export const DetailsContainer = styled(Box)`
    padding: 15px 30px;
`

export const HeatmapContainer = styled(Box)`
    padding: 15px 30px;
`

export const TimeseriesContainer = styled(Box)`
    padding: 15px 30px;
`

export const SidebarContainer = styled(Box)`
    background-color: ${NAVY_BLUE};
`

export const MapboxWidgetContainer = styled(Box)`
    background: ${OLIVE_ISH};
    borderright: solid 10px ${NAVY_BLUE};
`

export const WidgetClockWrapper = styled(Box)`
    display: flex;
    align-items: center;
    padding: 10px 0;
    justify-content: flex-start;
    background-color: #f3f3f3;
    padding: 0 20px;
`

export const WidgetTitleFlex = styled(WidgetTitle)`
    display: flex;
    align-items: center;
`

export const DetectorCircle = styled(Box)`
    width: 18px;
    height: 18px;
    border-radius: 100%;
    margin-right: 6px;
    margin-bottom: 3px;
    background: #f3f3f3;
`

export const CorridorLine = styled(DetectorCircle)`
    width: 48px;
    height: 3px;
    border-radius: 6px;
`
