import { Card, Typography, styled, Box } from '@mui/material'

export const Views = styled(Box)`
    height: 70px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
`

export const ViewsContainer = styled(Card)`
    position: fixed;
    right: 10px;
    top: 80px;
    width: 260px;
    z-index: 12;
`

export const ViewsTitle = styled(Typography)`
    font-size: 17px;
    font-family: 'AimsunBold', sans-serif, serif;
    font-weight: bold;
    margin-bottom: 12px;
`

export const ViewsSubtitle = styled(Typography)`
    font-size: smaller;
    font-family: 'AimsunMedium', sans-serif, serif;
    margin-top: 20px;
    margin-bottom: 10px;
`

export const SwitchText = styled(Typography)`
    font-family: 'AimsunMedium', sans-serif, serif;
    padding-left: 8px;
    margin-bottom: 10px;
    font-size: 16px;
`

export const IconContainer = styled('img')`
    height: 25px;
    cursor: pointer;
`

export const MapsText = styled(Typography)`
    font-size: smaller;
    font-family: 'AimsunBold', sans-serif, serif;
`
