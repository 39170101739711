import { AllEffect, ForkEffect, call, put, takeLatest, all, select } from 'redux-saga/effects'
import {
    loadPatternSelectedInTime,
    storeClock,
    storeDetails,
    storeHeatmap,
    storeTimeseries,
    storeMapbox,
    updateClock,
    updateHeatmap,
    updateTimeseries,
    updateDetails,
    changeMapboxViewMode,
    changeFeatureSelected,
    storeMapboxWidgetData,
    initCalendar,
    storeAvailablePatterns,
    fetchWidgetRealData,
    initPatternsAvailable,
    selectDetailsState,
    selectClockState,
    storeTimesWithData,
    loadMonthAvailableData,
    selectViewMode,
    selectTimeseriesState,
    selectAvailablePatterns,
    reloadGlobalTimeseries,
    selectMapboxState,
    storeMaxAndMinTimesWithData,
    loadDataRange,
    selectMaxAndMinTimesWithData,
} from './DashboardContainerSlice'
import { getAveragePattern24hours, getQMDMDataRequest } from '../../services/qualityManager/qualityManagerService'
import { selectConfig, selectTimeZone } from '../../features/core/coreSlice'
import { getCleanDataRequest, getPatternDataRequest } from './DashboardContainerService'
import { getNextPatternHeatmapSeries } from '../../features/qualityManagerDialog/qualityManagerDialogHelper'
import { NAVY_BLUE, RED_LIVE, WHITE } from '../../theme'
import { getAvailablePatterns, getPattern } from '../../services/pattern/patternService'
import { getDataRange, getTimesWithData } from '../../services/dashboard/dashboardService'
import { getRealData } from '../../services/mapData/mapDataService'
import { DateTime } from 'luxon'
import { generateExtendedPalette } from '../../helpers/colorsHelper'

function* fetchPatternAverages(action: any): Generator<unknown, any, any> {
    try {
        const { date, widgetName, variable } = action
        const _moduleConfig = yield select(selectConfig)
        const availablePatterns: IPatternJson[] = yield call(getAvailablePatterns)
        const startOfDay: number = date.endOf('day').plus({ minutes: _moduleConfig['horizon-step'] }).toMillis()

        let fetch: any = {}
        availablePatterns.forEach(pattern => {
            fetch[`Pattern ${pattern.pattern_id}`] = call(getAveragePattern24hours, pattern.pattern_id, variable)
        })
        const dataFetch = yield all(fetch)
        const currentSeries = []
        for (const [key, value] of Object.entries(dataFetch)) {
            const items = value as IQMAverage[]
            const seriesData = items
                .filter((item: { avg: number; time: number }) => {
                    return DateTime.fromMillis(item.time).minute % _moduleConfig['horizon-step'] === 0
                })
                .map((item: { avg: number; time: number }) => {
                    return {
                        x: item.time,
                        y: item.avg?.toFixed(),
                    }
                })
            const nextSeries = {
                name: key,
                data: seriesData,
            }
            currentSeries.push(nextSeries)
        }

        // this is needed to fix a crash on apexcharts when no data
        if (!currentSeries.every(serie => serie.data.length)) {
            currentSeries.splice(0)
        }

        currentSeries.sort((value, nextValue) =>
            Number(value.name.slice(8)) > Number(nextValue.name.slice(8)) ? 1 : -1
        )

        const pattern = yield getPattern(date.toMillis())

        yield put(
            storeTimeseries({
                widgetName,
                series: currentSeries,
                date: startOfDay,
                pattern,
            })
        )
    } catch (error) {
        console.error('fetchQMDMData error:', error)
    }
}

function* loadGlobalTimeseries(action: { payload: string }): Generator<unknown, any, any> {
    const _mapboxState = yield select(selectMapboxState)
    const mapboxWidgetName: string = action.payload
    const state = _mapboxState[mapboxWidgetName]
    const viewMode = state.viewMode
    yield call(fetchPatternAverages, {
        date: state.date,
        widgetName: 'patternAverage',
        variable: viewMode.variable,
    })
}

function* fetchPatternSelectedInTime(action: any): Generator<unknown, any, any> {
    try {
        const _moduleConfig: IModuleConfig = yield select(selectConfig)
        const _timeZone = yield select(selectTimeZone)
        const { date, widgetName } = action
        const DASHBOARD_HEATMAP_DAYS = 7
        const size: number = 86400 / (_moduleConfig['horizon-step'] * 60)
        const { time: timeFormat, date: dateFormat } = _moduleConfig.date_format
        if (date) {
            // Clear data to show loader because the request might take some time
            yield put(storeHeatmap({ widgetName, data: undefined, time: date.toMillis() }))
            const daysBefore = date.minus({ days: 3 }).startOf('day')
            const daysAfter = date.plus({ days: 3 }).endOf('day')
            const dmParams = {
                type: 'pattern',
                variable: 'flowqmstations',
                realm: 'clean',
                interval: 'date',
                from: daysBefore.toMillis(),
                to: daysAfter.toMillis(),
                filter: 1000,
                feature_id: 0,
            }
            const qmData = yield call(getQMDMDataRequest, dmParams)

            let series = qmData?.reduce((acc: any, qmData: any) => {
                const dateTime = DateTime.fromMillis(qmData.from, { zone: _timeZone })
                let day = dateTime.toFormat(dateFormat)
                const seriesData = { ...acc }

                let nextSeries:
                    | {
                          x: any
                          y: number
                      }
                    | {
                          x: any
                          y: string
                      }
                    | undefined

                nextSeries = getNextPatternHeatmapSeries(qmData, 'pattern', _timeZone, timeFormat, 'flowQmStations')

                if (nextSeries) {
                    if (nextSeries.x === '00:00') {
                        const _datetime = dateTime.minus({ days: 1 })
                        day = _datetime.toFormat(dateFormat)
                        nextSeries.x = '24:00'
                    }
                    if (!seriesData[day]) {
                        seriesData[day] = {
                            name: day,
                            data: [nextSeries],
                        }
                    } else if (seriesData[day].name === day) {
                        seriesData[day].data.push(nextSeries)
                    }
                }

                return seriesData
            }, [])

            if (series) {
                Object.keys(series).forEach(key => {
                    if (series[key].data.length !== size) {
                        const fixedData = []
                        const data = series[key].data
                        let dataIndex = 0
                        let hour = 0
                        let minute = 0

                        for (let i = 0; i < size; i++) {
                            if (minute === 60 - _moduleConfig['horizon-step']) {
                                hour += 1
                                minute = 0
                            } else {
                                minute += _moduleConfig['horizon-step']
                            }
                            let [dHour, dMinute] = data[dataIndex].x.split(':').map(Number)

                            if (dHour < hour || (dHour === hour && dMinute < minute)) {
                                for (let i = dataIndex; i < data.length; i++) {
                                    const [fHour, fMinute] = data[i].x.split(':').map(Number)
                                    if (fHour === hour && fMinute === minute) {
                                        dataIndex = i
                                        dHour = fHour
                                        dMinute = fMinute
                                        break
                                    }
                                }
                            }
                            if (dHour === hour && dMinute === minute) {
                                fixedData.push(data[dataIndex])
                                if (dataIndex < data.length - 1) {
                                    dataIndex += 1
                                }
                            } else {
                                const time = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
                                fixedData.push({ x: time, y: -1 })
                            }
                        }
                        series[key].data = fixedData
                    }
                })

                const _heatmapSeries = Object.keys(series).map(day => {
                    if (series[day].data && series[day].data.length < size) {
                        series[day].data.shift()
                    }
                    series[day].data.sort((value: any, nextValue: any) => {
                        const hour: number = Number.parseInt(value.x.split(':')[0])
                        const nextHour: number = Number.parseInt(nextValue.x.split(':')[0])
                        return hour - nextHour
                    })
                    return series[day]
                })

                const visibleHeatmapDays: any[] =
                    _heatmapSeries.length - DASHBOARD_HEATMAP_DAYS > 0
                        ? _heatmapSeries.slice(_heatmapSeries.length - DASHBOARD_HEATMAP_DAYS)
                        : _heatmapSeries

                yield put(storeHeatmap({ widgetName, data: visibleHeatmapDays, time: date.toMillis() }))
            }
        }
    } catch (error) {
        console.error('fetchPatternSelectedInTime error:', error)
    }
}

function* fetchStationDetails(action: any): Generator<unknown, any, any> {
    try {
        const _stationDetailsState = yield select(selectDetailsState)
        const _clockState = yield select(selectClockState)
        const _moduleConfig = yield select(selectConfig)
        const { featureSelected, variable, viewMode, date, widgetName } = action
        const currentFeatureSelected = featureSelected || _stationDetailsState.stationDetails?.featureSelected

        let featureId = currentFeatureSelected?.properties?.eid
        let time = date?.toMillis()

        const dashboardConfig: IModule | undefined = _moduleConfig.modules.find(
            (module: any) => module.name === 'dashboard'
        )
        const widgets: IWidget[] = dashboardConfig?.options.widgets

        if (!featureId) {
            widgets
                .filter(({ type }) => type === 'details')
                .forEach((widget: IWidget) => {
                    const currentWidgetState = _stationDetailsState[widget.name]
                    featureId = currentWidgetState?.featureSelected?.properties?.eid
                })
        }

        if (!time) {
            widgets
                .filter(({ type }) => type === 'clock')
                .forEach((widget: IWidget) => {
                    const currentWidgetState = _clockState[widget.name]
                    time = currentWidgetState.date.toMillis()
                })
        }

        const nHours = 6
        const halfNHoursInMillis = (nHours / 2) * 60 * 60 * 1000
        const timeToRequest = time + halfNHoursInMillis

        const detailsParams = {
            feature_id: featureId,
            variable: variable || viewMode.variable,
            t: isNaN(timeToRequest) ? time : timeToRequest,
            n_hours: nHours,
        }
        const patternData = yield call(getPatternDataRequest, detailsParams)
        const cleanData = yield call(getCleanDataRequest, detailsParams)
        const series: ApexAxisChartSeries = []

        if (patternData.length) {
            series.push(
                {
                    type: 'area',
                    name: 'Pattern Max',
                    data: cleanData.map((item: dataReliabilityType) => {
                        const matchingItem = patternData.find((e: dataPatternType) => item.time === e.time)
                        return {
                            x: DateTime.fromMillis(item.time).toMillis(),
                            y: matchingItem?.max_value,
                        }
                    }),
                    color: RED_LIVE,
                },
                {
                    type: 'area',
                    name: 'Pattern Min',
                    data: cleanData.map((item: dataReliabilityType) => {
                        const matchingItem = patternData.find((e: dataPatternType) => item.time === e.time)
                        return {
                            x: DateTime.fromMillis(item.time).toMillis(),
                            y: matchingItem?.min_value,
                        }
                    }),
                    color: WHITE,
                },
                {
                    type: 'line',
                    name: 'Real',
                    data: cleanData.map((item: dataReliabilityType) => {
                        return { x: DateTime.fromMillis(item.time).toMillis(), y: item.value }
                    }),
                    color: NAVY_BLUE,
                },
                {
                    type: 'scatter',
                    name: 'Reliability',
                    data: cleanData.map((item: dataReliabilityType) => {
                        return {
                            x: DateTime.fromMillis(item.time).toMillis(),
                            y: item.reliability,
                        }
                    }),
                    color: NAVY_BLUE,
                }
            )
        }
        yield put(
            storeDetails({
                widgetName: widgetName,
                data: series,
                featureSelected: currentFeatureSelected,
                time: time,
            })
        )
    } catch (error) {
        console.error('fetchPatternSelectedInTime error:', error)
    }
}

function* fetchStationPatterns(action: any): Generator<unknown, any, any> {
    try {
        const _stationDetailsState = yield select(selectDetailsState)
        const _clockState = yield select(selectClockState)
        const _moduleConfig = yield select(selectConfig)
        const _viewMode = yield select(selectViewMode)
        const { featureSelected, date } = action
        const currentFeatureSelected = featureSelected || _stationDetailsState.stationDetails.featureSelected
        let featureId = currentFeatureSelected?.properties?.eid
        const currentPattern = yield getPattern(date.toMillis())
        const startOfDay: number = date.endOf('day').plus({ minutes: _moduleConfig['horizon-step'] }).toMillis()

        const dashboardConfig: IModule | undefined = _moduleConfig.modules.find(
            (module: any) => module.name === 'dashboard'
        )
        const widgets: IWidget[] = dashboardConfig?.options.widgets

        if (!date) {
            widgets
                .filter(({ type }) => type === 'clock')
                .forEach((widget: IWidget) => {
                    const currentWidgetState = _clockState[widget.name]
                    currentWidgetState.date.toMillis()
                })
        }

        if (!featureId) {
            widgets
                .filter(({ type }) => type === 'details')
                .forEach((widget: IWidget) => {
                    const currentWidgetState = _stationDetailsState[widget.name]
                    featureId = currentWidgetState?.featureSelected?.properties?.eid
                })
        }

        const _patterns = yield select(selectAvailablePatterns)
        const patternData = yield all(
            _patterns.map((pattern: any) => {
                const patternCallParams = {
                    feature_id: featureId,
                    variable: _viewMode.variable,
                    t: startOfDay,
                    n_hours: 24,
                    pattern_id: pattern.pattern_id,
                }
                return call(getPatternDataRequest, patternCallParams)
            })
        )

        const cleanCallParams = {
            feature_id: featureId,
            variable: _viewMode.variable,
            t: startOfDay,
            n_hours: 24,
            pattern_id: currentPattern.pattern_id,
        }

        const cleanData = yield call(getCleanDataRequest, cleanCallParams)
        const series: ApexAxisChartSeries = []
        if (patternData.length) {
            const PATTERNS_EXTENDED_PALETTE = generateExtendedPalette(_patterns.length)
            yield _patterns.forEach((pattern: { pattern_id: number; pattern_name: string }, $i: number) => {
                series.push({
                    type: 'line',
                    name: `Pattern ${pattern.pattern_id}`,
                    data: cleanData
                        .filter(
                            (item: dataReliabilityType) =>
                                DateTime.fromMillis(item.time).minute % _moduleConfig['horizon-step'] === 0
                        )
                        .map((item: dataReliabilityType) => {
                            const matchingItem = patternData[$i].find((e: dataPatternType) => item.time === e.time)
                            return { x: item.time, y: matchingItem?.mean?.toFixed() }
                        }),
                    color: PATTERNS_EXTENDED_PALETTE[$i],
                })
            })
        }

        yield put(
            storeTimeseries({
                widgetName: 'patternAverage',
                series: series,
                featureSelected: currentFeatureSelected,
                date: date,
                pattern: currentPattern,
            })
        )
    } catch (error) {
        console.error('fetchPatternSelectedInTime error:', error)
    }
}

function* propagateClockChanges(action: any): Generator<unknown, any, any> {
    const _moduleConfig = yield select(selectConfig)
    const dashboardModule = _moduleConfig.modules.find((module: IModule) => module.name === 'dashboard')
    const currentConnections = dashboardModule?.options.connections.filter((conn: any) => conn.from.type === 'clock')
    for (let conn in currentConnections) {
        const currentConn = currentConnections[conn]
        yield put(
            updateClock({
                connection: currentConn,
                data: action.payload.date,
            })
        )

        if (currentConn.to.type === 'heatmap') {
            yield call(fetchPatternSelectedInTime, {
                date: action.payload.date,
                widgetName: currentConn.to.name,
            })
        }

        if (currentConn.to.type === 'timeseries') {
            const timeSeriesState = yield select(selectTimeseriesState)
            const currentTimeSeriesState = timeSeriesState[currentConn.to.name]
            const currentFeature = currentTimeSeriesState['featureSelected']
            if (currentFeature) {
                yield call(fetchStationPatterns, {
                    widgetName: currentConn.to.name,
                    featureSelected: currentFeature,
                    variable: action.payload.variable,
                    date: action.payload.date,
                })
            } else {
                yield call(fetchPatternAverages, {
                    date: action.payload.date,
                    widgetName: currentConn.to.name,
                    variable: action.payload.variable,
                })
            }
        }

        if (currentConn.to.type === 'details') {
            yield call(fetchStationDetails, {
                date: action.payload.date,
                widgetName: currentConn.to.name,
                variable: action.payload.variable,
            })
        }
    }
}

function* propagateHeatmapChanges(action: any): Generator<unknown, any, any> {
    const _moduleConfig = yield select(selectConfig)
    const dashboardModule = _moduleConfig.modules.find((module: IModule) => module.name === 'dashboard')
    const heatmapConnections = dashboardModule?.options.connections.filter((conn: any) => conn.from.type === 'heatmap')
    for (let conn in heatmapConnections) {
        const currentConn = heatmapConnections[conn]
        yield put(
            updateHeatmap({
                connection: currentConn,
                data: action.payload[currentConn.to.target],
            })
        )
    }
}

function* propagateTimeseriesChanges(action: any): Generator<unknown, any, any> {
    const _moduleConfig = yield select(selectConfig)
    const dashboardModule = _moduleConfig.modules.find((module: IModule) => module.name === 'dashboard')
    const timeseriesConnections = dashboardModule?.options.connections.filter(
        (conn: any) => conn.from.type === 'timeseries'
    )
    for (let conn in timeseriesConnections) {
        const currentConn = timeseriesConnections[conn]
        yield put(
            updateTimeseries({
                connection: currentConn,
                data: action.payload[currentConn.to.target],
            })
        )
    }
}

function* propagateMapboxChanges(action: any): Generator<unknown, any, any> {
    const clockState = yield select(selectClockState)
    const viewModeSelector = yield select(selectViewMode)
    const _moduleConfig = yield select(selectConfig)
    const dashboardModule = _moduleConfig.modules.find((module: IModule) => module.name === 'dashboard')
    const mapboxConnections = dashboardModule?.options.connections.filter((conn: any) => {
        return conn.from.type === 'mapbox' || conn.from.type === 'clock'
    })

    for (let conn in mapboxConnections) {
        const currentConn = mapboxConnections[conn]
        const currentClockState = clockState[currentConn.from.name]

        if (currentConn.to.type === 'mapbox' && viewModeSelector?.variable) {
            const payload = action.payload
            const realData: any = yield call(getRealData, currentClockState.date.toMillis(), viewModeSelector.variable)
            yield put(storeMapboxWidgetData({ widgetName: payload.widgetName, data: realData }))
        }

        if (currentConn.to.type === 'details') {
            if (currentConn.to.target === 'featureSelected') {
                yield call(fetchStationDetails, {
                    widgetName: currentConn.to.name,
                    featureSelected: action.payload[currentConn.to.target],
                    viewMode: viewModeSelector,
                })
            }
        }

        if (currentConn.to.type === 'timeseries') {
            if (currentClockState) {
                const currentFeature = action.payload['featureSelected']
                if (currentFeature) {
                    yield call(fetchStationPatterns, {
                        widgetName: currentConn.to.name,
                        featureSelected: currentFeature,
                        viewMode: viewModeSelector,
                        date: currentClockState.date,
                    })
                } else {
                    yield call(fetchPatternAverages, {
                        date: currentClockState.date,
                        widgetName: currentConn.to.name,
                        variable: viewModeSelector.variable,
                    })
                }
            }
        }
    }
}

function* propagateDetailsChanges(action: any): Generator<unknown, any, any> {
    const _moduleConfig = yield select(selectConfig)
    const dashboardModule = _moduleConfig.modules.find((module: IModule) => module.name === 'dashboard')
    const detailsConnections = dashboardModule?.options.connections.filter((conn: any) => conn.from.type === 'details')
    for (let conn in detailsConnections) {
        const currentConn = detailsConnections[conn]
        yield put(updateDetails({ connection: currentConn, data: action.payload[currentConn.to.target] }))
    }
}

function* loadRealData(action: {
    payload: { date: DateTime; viewMode: IViewMode; widget: IWidget }
}): Generator<unknown, any, any> {
    const { date, viewMode, widget } = action.payload
    const realData: any = yield call(getRealData, date.toMillis(), viewMode.variable)
    yield put(storeMapboxWidgetData({ widgetName: widget.name, data: realData }))
}

function* loadAvailablePatterns(): Generator<unknown, any, any> {
    const patterns = yield call(getAvailablePatterns)
    yield put(storeAvailablePatterns({ patterns }))
}

function* fetchDataRange(): Generator<unknown, any, any> {
    const dataRange: IDataRange = yield call(getDataRange)
    yield put(storeMaxAndMinTimesWithData(dataRange))
}

function* fetchTimesWithData(): Generator<unknown, any, any> {
    const _moduleConfig: IModuleConfig = yield select(selectConfig)
    const _timeZone: string = yield select(selectTimeZone)
    const _dataRange: IDataRange = yield select(selectMaxAndMinTimesWithData)
    const to: DateTime = DateTime.fromMillis(_dataRange.to)
    const dataAvailableInMonth = yield call(getTimesWithData, to.month.toString(), to.year.toString())
    yield put(storeTimesWithData({ timestamps: dataAvailableInMonth }))

    const prevMonth = to.minus({ month: 1 })
    const dataAvailableInPreviousMonth = yield call(
        getTimesWithData,
        prevMonth.month.toString(),
        prevMonth.year.toString()
    )

    const nextMonth = to.plus({ month: 1 })
    const dataAvailableInNextMonth = yield call(getTimesWithData, nextMonth.month.toString(), nextMonth.year.toString())

    yield put(
        storeTimesWithData({
            timestamps: [...dataAvailableInPreviousMonth, ...dataAvailableInMonth, ...dataAvailableInNextMonth],
        })
    )

    const initTime = DateTime.fromMillis(_dataRange.to, { zone: _timeZone })
    const dashboardConfig: IModule | undefined = _moduleConfig.modules.find(
        (module: any) => module.name === 'dashboard'
    )
    const widgets: IWidget[] = dashboardConfig?.options.widgets
    const clockWidget = widgets.find(({ type }) => type === 'clock')
    if (clockWidget) {
        yield put(storeClock({ widgetName: clockWidget.name, date: initTime, variable: 'flow' }))
    }
}

function* fetchAvailableData(action: { payload: { month: number; year: number } }): Generator<unknown, any, any> {
    const dataAvailableInMonth = yield call(
        getTimesWithData,
        action.payload.month.toString(),
        action.payload.year.toString()
    )
    let prevMonth = action.payload.month - 1
    let prevYear = action.payload.year
    let nextMonth = action.payload.month + 1
    let nextYear = action.payload.year
    if (action.payload.month === 1) {
        prevMonth = 12
        prevYear = action.payload.year - 1
    } else if (action.payload.month === 12) {
        nextMonth = 1
        nextYear = action.payload.year + 1
    }
    const dataAvailableInPrevMonth = yield call(getTimesWithData, prevMonth.toString(), prevYear.toString())
    const dataAvailableInNextMonth = yield call(getTimesWithData, nextMonth.toString(), nextYear.toString())
    const dataAvailable = [...dataAvailableInPrevMonth, ...dataAvailableInMonth, ...dataAvailableInNextMonth]
    if (dataAvailable.length > 0) {
        yield put(storeTimesWithData({ timestamps: dataAvailable }))
    }
}

function* dashboardSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(loadPatternSelectedInTime, fetchPatternSelectedInTime),
        yield takeLatest(storeClock, propagateClockChanges),
        yield takeLatest(storeHeatmap, propagateHeatmapChanges),
        yield takeLatest(storeTimeseries, propagateTimeseriesChanges),
        yield takeLatest(storeDetails, propagateDetailsChanges),
        yield takeLatest(storeMapbox, propagateMapboxChanges),
        yield takeLatest(changeMapboxViewMode, propagateMapboxChanges),
        yield takeLatest(changeFeatureSelected, propagateMapboxChanges),
        yield takeLatest(initCalendar, fetchTimesWithData),
        yield takeLatest(fetchWidgetRealData, loadRealData),
        yield takeLatest(initPatternsAvailable, loadAvailablePatterns),
        yield takeLatest(loadMonthAvailableData, fetchAvailableData),
        yield takeLatest(reloadGlobalTimeseries, loadGlobalTimeseries),
        yield takeLatest(loadDataRange, fetchDataRange),
    ])
}

export default dashboardSaga
