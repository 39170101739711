import * as React from 'react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Skeleton } from '@mui/material'

import { selectConfig, selectTimeZone, selectEpoch } from '../../core/coreSlice'
import { TABLE_ROW } from '../../../theme'
import QualityManagerDialogTableFixedHead from './qualityManagerDialogTableFixedHead'
import { getComparator, stableSort } from '../qualityManagerDialogHelper'
import { useTranslation } from 'react-i18next'

export const QualityManagerDialogTablePatternsSelectedData: React.FC<IQualityManagerDialogTableSwitchProps> = ({
    qmData,
}) => {
    const { t } = useTranslation()
    const _moduleConfig: IModuleConfig = useSelector(selectConfig)
    const _timeZone: string = useSelector(selectTimeZone)
    const _epochTime: number = useSelector(selectEpoch)

    const [order, setOrder] = useState<'desc' | 'asc' | undefined>('desc')
    const [orderBy, setOrderBy] = useState('time')
    const [loader, setLoader] = useState<boolean>(true)
    const [maxViewCount, setMaxViewCount] = useState<number>(50)
    const [qmDataToShow, setQmDataToShow] = useState<any[]>([])

    const { time: timeFormat, date: dateFormat } = _moduleConfig.date_format
    const tableContentHeight = window && window.innerHeight - 225

    const now: DateTime = DateTime.fromMillis(_epochTime, {
        zone: _timeZone,
    })

    useEffect(() => {
        if (qmData) {
            let res = qmData.filter((qmData: { feature_id: string; from: number }) => {
                const from: DateTime = DateTime.fromMillis(qmData.from, { zone: _timeZone })
                return qmData.feature_id === '0' && from < now
            })

            res = stableSort(res, getComparator(order, orderBy === 'time' ? 'from' : orderBy))
            setQmDataToShow(res)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, qmData])

    const handleActionsScroll = ({ currentTarget }: { currentTarget: any }) => {
        if (currentTarget.offsetHeight + currentTarget.scrollTop + 0.5 >= currentTarget.scrollHeight) {
            loadMoreActions()
        }
    }

    const loadMoreActions = () => {
        const nextMax = maxViewCount + 50
        if (nextMax <= qmDataToShow.length) {
            setMaxViewCount(nextMax)
        } else {
            setMaxViewCount(qmDataToShow.length)
            setLoader(false)
        }
    }

    const handleRequestSort = (event: any, property: React.SetStateAction<string>) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <Grid item>
            <TableContainer sx={{ height: `${tableContentHeight}px` }} onScroll={handleActionsScroll}>
                <Table
                    stickyHeader
                    aria-label='quality manager table view'
                    size='small'
                    sx={{
                        height: 'max-content',
                    }}>
                    <QualityManagerDialogTableFixedHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        fixedTittles={[t('qualityManager.pattern'), t('qualityManager.global')]}
                    />
                    <TableBody>
                        {qmDataToShow &&
                            qmDataToShow
                                .filter((qmDataToShow, index: number) => {
                                    return index < maxViewCount
                                })
                                .map((qmData: { from: number; global: number; pattern: number }, index: number) => {
                                    const dateTime = DateTime.fromMillis(qmData.from, { zone: _timeZone })
                                    const fromTime = dateTime.toFormat(timeFormat)
                                    const fromDate = dateTime.toFormat(dateFormat)

                                    return (
                                        <TableRow
                                            sx={index % 2 === 0 ? { backgroundColor: TABLE_ROW } : {}}
                                            inlist={qmData || {}}
                                            key={index}>
                                            <TableCell align='left'>
                                                <Typography variant='caption'>{`${fromTime} ${fromDate}`}</Typography>
                                            </TableCell>
                                            <TableCell component='th' scope='row' align='center'>
                                                <Typography variant='caption'>
                                                    {qmData.pattern === -1 || isNaN(qmData.pattern)
                                                        ? t('common.noData')
                                                        : qmData.pattern}
                                                </Typography>
                                            </TableCell>
                                            <TableCell component='th' scope='row' align='center'>
                                                <Typography variant='caption'>
                                                    {qmData.global === -1 || isNaN(qmData.global)
                                                        ? t('common.noData')
                                                        : Number(qmData.global).toFixed(2)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                        {loader &&
                            [...Array(20)].map((row, index) => {
                                return (
                                    <TableRow sx={index % 2 === 0 ? { backgroundColor: TABLE_ROW } : {}} key={index}>
                                        {[...Array(3)].map((row, index) => {
                                            return (
                                                <TableCell key={index} component='th' scope='row'>
                                                    <Skeleton variant='text' />
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default QualityManagerDialogTablePatternsSelectedData
