import {
    GREEN,
    MUSTARD,
    NAVY_BLUE,
    NEXT_BLUE,
    OLIVE_ISH,
    PALE_PINK,
    PURPLE_ISH,
    RED_LIVE,
    SMOKE,
    WYLD_PINK,
} from '../../../../../theme'
import { isRpSelected } from '../kpiHelper'
import { IRPKpiList } from '../kpisComparison/kpiComparisonTypes'
import { LineChartComparison } from './comparisonTimeSeriesChartTypes'

export const COLORS = [NEXT_BLUE, RED_LIVE, OLIVE_ISH, NAVY_BLUE, MUSTARD, PURPLE_ISH, WYLD_PINK, PALE_PINK, SMOKE, GREEN]

export const getComparisonTimeSeriesOptions = (title: string, categories: string[]): ApexCharts.ApexOptions => {

    return {
        chart: {
            type: 'line',
            dropShadow: {
                enabled: true,
                blur: 1,
                left: 1,
                top: 1,
            },
            toolbar: {
                show: false,
            },
            width: 250,
        },
        colors: COLORS,
        legend: {
            show: false,
        },
        stroke: {
            width: 2,
        },
        fill: {
            opacity: 1,
        },
        markers: {
            size: 0,
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return value ? value.toFixed(1).toString() : ''
                },
            },
        },
        title: {
            text: title,
            style: {
                fontSize: '20px',
                fontFamily: 'AimsunMedium',
                color: '#09254c',
            },
        },
    }
}

export const getXCategoriesComparisonTimeChart = (horizonCount: number, horizonStep: number): string[] => {
    let categories: string[] = []
    for (let i = 1; i <= horizonCount; i++) {
        const nextHorizon = horizonStep * i
        categories.push(`${nextHorizon.toString()}'`)
    }
    return categories
}

export const getTimeLineSeries = (
    rpsNames: string[],
    kpiList: IRPKpiList,
    selectedResponsePlans: string[]
): LineChartComparison[] => {
    const timeSeriesAux: {name: string, data: number[], kpiName: string}[][] = []
    let kpiNames: string[] = []
    rpsNames.forEach((responsePlan: string) => {
        if (kpiNames.length === 0) {
            kpiNames = (kpiList[responsePlan]
                .map(kpi => kpi.name)
                .filter((item, pos, self) => self.indexOf(item) === pos))
        }


        if (isRpSelected(responsePlan, selectedResponsePlans)) {
            const timeSeriesByResponsePlan = kpiNames.map((kpiName: string) => {
                let seriesData: number[] = []
                kpiList[responsePlan]
                    .filter(kpi => kpi.name === kpiName)
                    .forEach(kpi => {
                        seriesData.push(kpi.value)
                    })
                const seriesName = responsePlan === '0' ? 'DN' : `RP${responsePlan}`
                return {
                    name: seriesName,
                    data: seriesData,
                    kpiName,
                }
            })
            timeSeriesAux.push(timeSeriesByResponsePlan)
        }
    })

    let lineChartBundle: LineChartComparison[] = []
    kpiNames.forEach((kpiName: string) => {
        const lineChartData: {name:string, data: number[]}[] = []
        timeSeriesAux.forEach((timeSerie) => {
            timeSerie
                .filter(tsData => tsData.kpiName === kpiName)
                .forEach(timeSeries => {
                    lineChartData.push({
                        name: timeSeries.name,
                        data: timeSeries.data,
                    })
                })
        })
        lineChartBundle.push({ title: kpiName, timeSeries: lineChartData })
    })

    return lineChartBundle
}
