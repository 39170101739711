import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../app/store'

export interface ITrafficActionState {
    trafficActionIsSelected: boolean
    openTrafficActionCreateDialog: boolean
    featureTrafficActionSelected: any
    trafficActionCreating: boolean
    trafficActionCreated: boolean
    fetchingTrafficAction: boolean

}

const initialTrafficActionState: ITrafficActionState = {
    trafficActionIsSelected: false,
    openTrafficActionCreateDialog: false,
    fetchingTrafficAction: false,
    trafficActionCreating: false,
    trafficActionCreated: false,
    featureTrafficActionSelected: {
        id: 0,
    }
}

const trafficActionSlice = createSlice({
    name: 'trafficAction',
    initialState: initialTrafficActionState,
    reducers: {
        setTrafficActionSelected: (state, action: PayloadAction<boolean>): void => {
            state.trafficActionIsSelected = action.payload
        },
        setOpenTrafficActionCreateDialog: (state, action: PayloadAction<boolean>): void => {
            state.openTrafficActionCreateDialog = action.payload
        },
        storeFeatureTrafficActionSelected: (state, action: PayloadAction<any>): void => {
            state.featureTrafficActionSelected = action.payload
        },
        setTrafficActionCreated: (state, action: PayloadAction<any>): void => {
            state.trafficActionCreated = action.payload
        },
        setTrafficActionCreating: (state, action: PayloadAction<any>): void => {
            state.trafficActionCreating = action.payload
        },
        createNewTrafficAction: (state, action: PayloadAction<any>): void => {
            state.trafficActionCreating = true
        },
        updateTrafficAction: (state, action: PayloadAction<any>): void => {
            state.trafficActionCreating = true
        },
        deleteTrafficAction: (state, action: PayloadAction<any>): void => {
        },
        setFetchNetworkTrafficAction: (state, action: PayloadAction<any>): void => {
            state.fetchingTrafficAction = action.payload
        },
        loadTrafficAction: (state, action: PayloadAction<any>): void => {
            state.fetchingTrafficAction = true
        }
    },
})

export const {
    setTrafficActionSelected,
    storeFeatureTrafficActionSelected,
    setOpenTrafficActionCreateDialog,
    createNewTrafficAction,
    updateTrafficAction,
    deleteTrafficAction,
    setTrafficActionCreating,
    setTrafficActionCreated,
    setFetchNetworkTrafficAction,
    loadTrafficAction
} = trafficActionSlice.actions

export const selectTrafficActionSelected = (state: RootState) => state.trafficAction.trafficActionIsSelected
export const selectOpenTrafficActionCreateDialog = (state: RootState) => state.trafficAction.openTrafficActionCreateDialog
export const selectFeatureTrafficActionSelected = (state: RootState) => state.trafficAction.featureTrafficActionSelected
export const selectTrafficActionCreating = (state: RootState) => state.trafficAction.trafficActionCreating
export const selectTrafficActionCreated = (state: RootState) => state.trafficAction.trafficActionCreated

export default trafficActionSlice.reducer
