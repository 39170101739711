import { NAVY_BLUE, OLIVE_ISH, RED_LIVE } from '../../theme'
import { TFunction } from 'i18next'

export const getStationDialogLegend = (translate: TFunction): string[] => {
    return [
        `<div  style='display: flex; flex-direction: row; align-items: center'>
            <div  style='display: inline'>
                <svg style='height: 20px; width: 20px'>
                    <circle cx='10' cy='10' r='6' stroke=${NAVY_BLUE} stroke-width='2' fill='white' />
                </svg>
            </div>
            <span style='margin: auto 0'>
                ${translate('stationDialog.real')}
            </span>
        </div>`,
        `<div  style='display: flex; flex-direction: row; align-items: center'>
            <div  style='display: inline'>
                <svg style='height: 20px; width: 20px'>
                    <circle cx='10' cy='10' r='6' stroke=${OLIVE_ISH} stroke-width='2' fill='white' />
                </svg>
            </div>
            <span style='margin: auto 0'>
                ${translate('stationDialog.prediction')}
            </span>
        </div>`,
        `<div  style='display: flex; flex-direction: row; align-items: center'>
            <div  style='display: inline'>
                <svg style='height: 20px; width: 20px'>
                    <line x1='5' y1='5' x2='15' y2='5' stroke=${RED_LIVE} stroke-width='2px' />
                    <line
                        x1='5'
                        y1='10'
                        x2='15'
                        y2='10'
                        stroke=${RED_LIVE}
                        stroke-width='10px'
                        opacity='0.2'
                    />
                    <line x1='5' y1='15' x2='15' y2='15' stroke=${RED_LIVE} stroke-width='2px' />
                </svg>
            </div>
            <span style='margin: auto 0'>
                ${translate('stationDialog.pattern')}
            </span>
        </div>`,
    ]
}

export const getStationDetailsLegend = (translate: TFunction): string[] => {
    return [getStationDialogLegend(translate)[0], getStationDialogLegend(translate)[2]]
}
