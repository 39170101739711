import { Box, Grid, styled, Typography } from '@mui/material'
import { NO_DATA, NAVY_BLUE, WHITE } from '../../theme'

export const OpenSidebarToggle = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    margin: 0 0 0 10px;
`

export const ClosedSidebarToggle = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0 0 0 10px;
`

export const IconWrapper = styled(Typography)`
    color: inherit;
    font-size: 16px;
`

export const TextWrapper = styled(Typography)`
    color: inherit;
    font-size: 1.1em;
    font-family: AimsunLight, sans-serif, serif;
    max-width: 80px;
`

export const SidebarActions = styled(Box)`
    position: absolute;
    bottom: 0;
    left: 0;
    color: ${WHITE};
`

export const SidebarItem = styled(Grid)`
    margin: 0 0 0 10px;
    color: ${WHITE};
    &:hover {
        cursor: pointer;
        color: ${NO_DATA};
    }
`
export const SidebarContainer = styled(Grid)<{ open: boolean }>`
    background-color: ${NAVY_BLUE};
    width: ${props => (props.open ? '144px' : '66px')};
    height: calc(100vh - 70px);
    position: absolute;
    left: 0;
    bottom: 0;
`
