import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import ApexCharts from 'apexcharts'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { getChartData, getChartOptions } from './comparisonRadarChartHelper'
import { IRawKpi } from '../../../../analyzeContainer/components/kpi/store/kpisSlice'


export const ComparisonRadarChart: React.FC<{
    kpiList: {[p: string]: IRawKpi[]}
    setResponsePlansSelected: (responsePlansSelected: string[]) => void
    responsePlansSelected: string[]
}> = ({ kpiList, setResponsePlansSelected, responsePlansSelected }) => {

    const [responsePlanSelectedKpis, setResponsePlanSelectedKpis] = useState<string[]>(responsePlansSelected)
    const { normalizedData, kpiNames, categories } = getChartData(kpiList)
    const options = getChartOptions(categories)

    const toggle = (rp: string[]) => {
        const rpSelectedNames  = rp.map((rpId) =>  rpId === '0' ? 'DN' : `RP${rpId}`)
        const rpNames  = normalizedData.map(({name}) => name)

        const rpNotSelected: string[] = []
        for (let name of rpNames) {
            if (!rpSelectedNames.includes(name)) {
                rpNotSelected.push(name)
            }
        }

        for (let rpName of rpNotSelected) {
            ApexCharts.exec('comparisonRadarChart', 'hideSeries', [rpName])
        }
        ApexCharts.exec('comparisonRadarChart', 'updateOptions', {
            ...options,
            chart: {
                ...options.chart,
                events: {
                    legendClick: (event:any, chartContext: number) => {

                        handleLegendClick(chartContext)
                    },
                },
                id: 'comparisonRadarChart'
            }
        })
    }

    useEffect(() => {
        toggle(responsePlanSelectedKpis)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        setResponsePlanSelectedKpis(responsePlansSelected)
        toggle(responsePlansSelected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responsePlansSelected])

    const handleLegendClick = (rpClicked: number | undefined, ) => {
        if (rpClicked !== undefined) {
            const rpClickedString = rpClicked.toString()
            if (responsePlanSelectedKpis.includes(rpClickedString)) {
                const filteredRp = responsePlanSelectedKpis.filter(rpId => rpId !== rpClickedString)
                setResponsePlansSelected(filteredRp)
                setResponsePlanSelectedKpis(filteredRp)
            } else {
                const filteredRp = [...responsePlanSelectedKpis, rpClickedString]
                setResponsePlansSelected(filteredRp)
                setResponsePlanSelectedKpis(filteredRp)
            }
        }
    }



    return (
        <Grid container direction='row'>
            <Grid item xs={4}>
                <Grid container direction='column' alignContent='center' justifyContent='center'>
                    <Card>
                        <CardContent>
                            {kpiNames.map((kpiName: string, idx: number) => {
                                const kpiListItem = `${idx + 1} - ${kpiName}`
                                return (
                                    <Grid item style={{ padding: '10px' }} key={idx}>
                                        <Typography variant='body1'>{kpiListItem}</Typography>
                                    </Grid>
                                )
                            })}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid item xs={8}>
                <Chart
                    options={{
                        ...options,
                        chart: {
                            ...options.chart,
                            events: {
                                legendClick: (event, chartContext) => {
                                    handleLegendClick(chartContext)
                                },
                            },
                            id: 'comparisonRadarChart'
                        }
                    }}
                    series={normalizedData} type='radar'
                />
            </Grid>
        </Grid>
    )
}
