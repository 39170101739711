import * as React from 'react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Skeleton } from '@mui/material'

import { selectConfig, selectTimeZone, selectEpoch } from '../../core/coreSlice'
import QualityManagerDialogTableHead from './qualityManagerDialogTableHead'
import { TABLE_ROW } from '../../../theme'
import { stableSort, getComparator } from '../qualityManagerDialogHelper'
import { useTranslation } from 'react-i18next'

export const QualityManagerDialogTableStandard: React.FC<IQualityManagerDialogTableSwitchProps> = ({
    horizons,
    qmData,
    predictionHorizon,
}) => {
    const { t } = useTranslation()
    const _moduleConfig: IModuleConfig = useSelector(selectConfig)
    const _epochTime: number = useSelector(selectEpoch)
    const _timeZone: string = useSelector(selectTimeZone)

    const [order, setOrder] = useState<'desc' | 'asc' | undefined>('desc')
    const [orderBy, setOrderBy] = useState('time')
    const [loader, setLoader] = useState<boolean>(true)
    const [maxViewCount, setMaxViewCount] = useState<number>(50)
    const [qmDataToShow, setQmDataToShow] = useState<IQualityManagerPatternsAvailableRow[]>([])
    const { time: timeFormat, date: dateFormat } = _moduleConfig.date_format
    const tableContentHeight = window && window.innerHeight - 225

    const minusNow = horizons && horizons.length > 1 && predictionHorizon ? predictionHorizon : 0

    const now: DateTime = DateTime.fromMillis(_epochTime - minusNow, {
        zone: _timeZone,
    })

    useEffect(() => {
        if (qmData) {
            let res: IQualityManagerPatternsAvailableRow[] = dataToShow()
            res = stableSort(res, getComparator(order, orderBy))
            setQmDataToShow(res)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, qmData])

    const dataToShow = () => {
        let lastFrom = 0
        let row: IQualityManagerPatternsAvailableRow = {}
        return qmData
            .filter((qmData: { from: any }) => {
                const from: DateTime = DateTime.fromMillis(qmData.from, { zone: _timeZone })
                return from < now
            })
            .reduce((acc: any, curr: any) => {
                let data = [...acc]
                if (curr.from !== lastFrom) {
                    row = {}
                    row['time'] = curr.from
                    row['Global'] = curr.global
                    if (curr.partial) {
                        row['15'] = curr.partial[0]
                        row['30'] = curr.partial[1]
                        row['45'] = curr.partial[2]
                        row['60'] = curr.partial[3]
                    }
                    Object.keys(row).length > 0 && data.push(row)
                }
                lastFrom = curr.from
                return data
            }, [])
    }

    const handleActionsScroll = ({ currentTarget }: { currentTarget: any }) => {
        if ((currentTarget.offsetHeight * 3) / 2 + currentTarget.scrollTop >= currentTarget.scrollHeight) {
            loadMoreActions()
        }
    }

    const loadMoreActions = () => {
        const nextMax = maxViewCount + 30
        if (nextMax <= qmDataToShow.length) {
            setMaxViewCount(nextMax)
        } else {
            setMaxViewCount(qmDataToShow.length)
            setLoader(false)
        }
    }

    const handleRequestSort = (event: any, property: React.SetStateAction<string>) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <Grid item>
            {horizons && (
                <TableContainer sx={{ height: `${tableContentHeight}px` }} onScroll={handleActionsScroll}>
                    <Table
                        stickyHeader
                        aria-label='quality manager table view'
                        size='small'
                        sx={{
                            height: 'max-content',
                        }}>
                        <QualityManagerDialogTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            horizons={horizons}
                        />
                        <TableBody>
                            {qmDataToShow &&
                                qmDataToShow
                                    .filter((qmDataToShow, index: number) => {
                                        return index < maxViewCount
                                    })
                                    .map((data, index: number) => {
                                        const dateTime = DateTime.fromMillis(Number(data.time), { zone: _timeZone })
                                        const fromTime = dateTime.toFormat(timeFormat)
                                        const fromDate = dateTime.toFormat(dateFormat)

                                        return (
                                            <TableRow
                                                sx={index % 2 === 0 ? { backgroundColor: TABLE_ROW } : {}}
                                                inlist={qmData || {}}
                                                key={index}>
                                                <TableCell align='left'>
                                                    <Typography variant='caption'>{`${fromTime} ${fromDate}`}</Typography>
                                                </TableCell>
                                                {horizons.map((horizon, index) => {
                                                    return (
                                                        <TableCell
                                                            component='th'
                                                            scope='row'
                                                            align='center'
                                                            key={`${horizon.value}_${index}`}>
                                                            <Typography variant='caption'>
                                                                {data[horizon.value] === -1 ||
                                                                isNaN(data[horizon.value])
                                                                    ? t('common.noData')
                                                                    : Number(data[horizon.value]).toFixed(2)}
                                                            </Typography>
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                            {loader &&
                                [...Array(20)].map((row, index) => {
                                    return (
                                        <TableRow
                                            sx={index % 2 === 0 ? { backgroundColor: TABLE_ROW } : {}}
                                            key={index}>
                                            {[...Array(horizons.length + 1)].map((row, index) => {
                                                return (
                                                    <TableCell key={index} component='th' scope='row'>
                                                        <Skeleton variant='text' />
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Grid>
    )
}

export default QualityManagerDialogTableStandard
