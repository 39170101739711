import * as React from 'react'
import { Close as CloseIcon } from '@mui/icons-material'
import { CardContent, IconButton, Typography } from '@mui/material'
import Chart from 'react-apexcharts'
import { StationDialogTooltip } from './stationDialogTooltip'
import { TooltipCircle, TooltipCircleBackground } from '../mapbox/mapboxStyles'
import { stationDialogChartOptions } from './stationDialogChartOption'
import { CardHeaderStyled, CardStationStyled, CardTitleWrapper } from './stationDialogStyles'
import { getStationDialogLegend } from './stationDialogLegend'
import { useSelector } from 'react-redux'
import {
    selectStationPatternData,
    selectStationPredictionData,
    selectStationRealData,
    selectStationReliabilityData,
} from './store/stationSelectedSlice'
import {
    preparePatternMaxDataToChart,
    preparePatternMinDataToChart,
    prepareRealDataToChart,
    preparePredictionDataToShowOnChart,
    preparePredictionDataToHideChart,
} from './stationDialogHelper'
import { getSeriesData } from './stationDialogChartSeries'
import { RootState } from '../../app/store'
import { selectConfig, selectMainConfig, selectTimeZone } from '../core/coreSlice'
import { NO_DATA } from '../../theme'
import { useTranslation } from 'react-i18next'
import { getUnitTypeFromUnit } from '../../helpers/unitsHelper'

const GRAPH_ASPECT_RATIO = 1.37
const MARGIN_BOTTOM_GRAPH = 20

export const StationDialog: React.FC<ChartsDialogProps> = ({
    valueColor = NO_DATA,
    reliabilityColor = NO_DATA,
    stationName = 'Unknown',
    epoch,
    onClose,
    mapId,
    height,
    width,
    viewMode,
}) => {
    const { t } = useTranslation()
    const _timeZone: string = useSelector(selectTimeZone)
    const _mainConfig: IMainConfig = useSelector(selectMainConfig)
    const _moduleConfig: IModuleConfig = useSelector(selectConfig)
    const realData: realDataType[] = useSelector((state: RootState) => selectStationRealData(state, mapId))
    const predictionData = useSelector((state: RootState) => selectStationPredictionData(state, mapId))
    const patternData = useSelector((state: RootState) => selectStationPatternData(state, mapId))

    const cleanerInterval: number = Number.parseInt(_mainConfig.defaults['clock-interval']) / 60
    const unitType = _moduleConfig.units === 'imperial' ? getUnitTypeFromUnit(viewMode.units.main) : undefined

    const reliabilityData: dataReliabilityType[] = useSelector((state: RootState) =>
        selectStationReliabilityData(state, mapId)
    )

    const realDataSeries: lineDataChart[] = prepareRealDataToChart(
        realData,
        epoch,
        cleanerInterval,
        _timeZone,
        unitType
    )
    const predictionDataSeries: lineDataChart[] = preparePredictionDataToShowOnChart(
        predictionData,
        realData,
        epoch,
        cleanerInterval,
        _timeZone
    )
    const predictionDataHideSeries: lineDataChart[] = preparePredictionDataToHideChart(
        predictionData,
        realData,
        epoch,
        cleanerInterval,
        _timeZone
    )
    const patternMinDataSeries: lineDataChart[] = preparePatternMinDataToChart(
        patternData,
        epoch,
        cleanerInterval,
        _timeZone
    )
    const patternMaxDataSeries: lineDataChart[] = preparePatternMaxDataToChart(
        patternData,
        epoch,
        cleanerInterval,
        _timeZone
    )

    const toolTip = ({ dataPointIndex }: any) => {
        return StationDialogTooltip({
            timeZone: _timeZone,
            hour: realDataSeries[dataPointIndex] ? realDataSeries[dataPointIndex].x : null,
            real: realDataSeries[dataPointIndex] ? realDataSeries[dataPointIndex].y : null,
            dataReliability: realData[dataPointIndex] ? realData[dataPointIndex].reliability : null,
            patterMin: patternMinDataSeries[dataPointIndex] ? patternMinDataSeries[dataPointIndex].y : null,
            patterMax: patternMaxDataSeries[dataPointIndex] ? patternMaxDataSeries[dataPointIndex].y : null,
            sensorReliability: reliabilityData[dataPointIndex] ? reliabilityData[dataPointIndex].value : null,
            prediction:
                predictionDataHideSeries[dataPointIndex] && predictionDataHideSeries[dataPointIndex].x !== epoch
                    ? predictionDataHideSeries[dataPointIndex].y
                    : null,
            viewMode,
        })
    }

    const series = getSeriesData(
        realDataSeries,
        patternMaxDataSeries,
        patternMinDataSeries,
        predictionDataSeries,
        predictionDataHideSeries
    )

    const handleClose = () => {
        onClose()
    }

    const heightGraphInitial = (width * 0.65) / GRAPH_ASPECT_RATIO

    const widthGraph =
        heightGraphInitial > height + MARGIN_BOTTOM_GRAPH
            ? GRAPH_ASPECT_RATIO * (height - MARGIN_BOTTOM_GRAPH - 8)
            : width * 0.65

    const heightGraph = widthGraph / GRAPH_ASPECT_RATIO

    const cardTitle = (
        <CardTitleWrapper>
            <TooltipCircle
                style={{
                    background: valueColor,
                }}
            />
            <TooltipCircleBackground
                style={{
                    backgroundColor: reliabilityColor,
                }}
            />
            <Typography variant='h1'>{stationName}</Typography>
        </CardTitleWrapper>
    )

    return (
        <CardStationStyled
            width={`${widthGraph}px`}
            height={`${heightGraph}px`}
            marginbottom={`${MARGIN_BOTTOM_GRAPH}px`}>
            <CardHeaderStyled
                action={
                    <IconButton aria-label='settings' onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                }
                title={cardTitle}
            />
            <CardContent>
                {series && (
                    <Chart
                        options={stationDialogChartOptions(
                            epoch,
                            _timeZone,
                            toolTip,
                            viewMode.name,
                            getStationDialogLegend(t)
                        )}
                        series={series}
                        type={'line'}
                    />
                )}
            </CardContent>
        </CardStationStyled>
    )
}
