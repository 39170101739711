import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../../app/store'
import { IRPKpiList } from '../kpisComparison/kpiComparisonTypes'

export interface IKpisState {
    listKpis: IRPKpiList
    fetching: boolean
}

const initialKpisState: IKpisState = {
    listKpis: {},
    fetching: false,
}

const RPKpisSlice = createSlice({
    name: 'RPKpis',
    initialState: initialKpisState,
    reducers: {
        storeRPKpis: (state, action: PayloadAction<any>): void => {
            state.listKpis = action.payload
        },
        setFetchingRPKpis: (state, action: PayloadAction<boolean>): void => {
            state.fetching = action.payload
        },
        loadRPKpi: (state, action: PayloadAction<{ selectedEvaluation: IEvaluationStatic, isStaticMode: boolean}>): void => {
            state.fetching = true
        },
    },
})

export const { storeRPKpis, setFetchingRPKpis, loadRPKpi } = RPKpisSlice.actions

export const selectRPKpis = (state: RootState) => state.manageContainer.RPKpis.listKpis
export const selectFetchingRPKpis = (state: RootState) => state.manageContainer.RPKpis.fetching

export default RPKpisSlice.reducer
