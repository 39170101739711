import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: ISystemStatusState = {
    analyticalQuality: 0,
    simulatedQuality: 0,
    patternQuality: 0,
    raMissing: 0,
    storeNoApm: 0,
    computerInfo: {
        address: '',
        cores: 0,
        name: '',
        os: '',
        ram: 0,
        version: '',
    },
}

export const systemStatusSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        storeAnalyticalQuality: (state, action: PayloadAction<any>): void => {
            state.analyticalQuality = action.payload
        },
        storeSimulatedQuality: (state, action: PayloadAction<any>): void => {
            state.simulatedQuality = action.payload
        },
        storePatternQuality: (state, action: PayloadAction<any>): void => {
            state.patternQuality = action.payload
        },
        storeMissingData: (state, action: PayloadAction<any>): void => {
            state.raMissing = action.payload
        },
        storeNoApm: (state, action: PayloadAction<any>): void => {
            state.storeNoApm = action.payload
        },
    },
})

export const { storeAnalyticalQuality, storeSimulatedQuality, storePatternQuality, storeMissingData, storeNoApm } =
    systemStatusSlice.actions

// export const selectAnaQuality = (state: RootState) => state.system.analyticalQuality

export default systemStatusSlice.reducer
