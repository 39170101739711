import { MUSTARD, OLIVE_ISH, RED_LIVE, GREY } from '../../src/theme'

export const getSimulatedQualityColor = (simulatedQuality: number): string => {
    if (simulatedQuality === -1) return GREY
    if (simulatedQuality >= 70) return OLIVE_ISH
    if (simulatedQuality > 60 && simulatedQuality < 70) return MUSTARD
    if (simulatedQuality <= 60) return RED_LIVE
    return GREY
}

export const getAnalyticalQualityColor = (analyticalQuality: number): string => {
    if (analyticalQuality === -1) return GREY
    if (analyticalQuality >= 85) return OLIVE_ISH
    if (analyticalQuality > 80 && analyticalQuality < 85) return MUSTARD
    if (analyticalQuality <= 80) return RED_LIVE
    return GREY
}

export const getRAMissingColor = (realityAcquirerMissing: number): string => {
    if (realityAcquirerMissing === -1) return GREY
    if (realityAcquirerMissing <= 25) return OLIVE_ISH
    if (realityAcquirerMissing > 25 && realityAcquirerMissing < 50) return MUSTARD
    if (realityAcquirerMissing >= 50) return RED_LIVE
    return GREY
}

export const getPatternQualityColor = (patternQuality: number): string => {
    if (patternQuality === -1) return GREY
    if (patternQuality >= 70) return OLIVE_ISH
    if (patternQuality > 60 && patternQuality < 70) return MUSTARD
    if (patternQuality <= 60) return RED_LIVE
    return GREY
}

export const getSimRuntimeColor = (simRuntime?: number): string => {
    if (simRuntime === -1) return GREY
    if (simRuntime && simRuntime > 300) return MUSTARD
    if (simRuntime && simRuntime <= 300) return OLIVE_ISH
    if (!simRuntime) return RED_LIVE
    return GREY
}
