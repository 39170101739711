import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface IActionsState {
    actionIsSelected: boolean
    fetchingTrafficActions: boolean
    fetchingListActions: boolean
    fetchingRpActions: boolean
    listActions: any[]

}

const initialActionsState: IActionsState = {
    actionIsSelected: false,
    fetchingRpActions: false,
    fetchingListActions: false,
    fetchingTrafficActions: false,
    listActions: []
}

const actionsSlice = createSlice({
    name: 'actions',
    initialState: initialActionsState,
    reducers: {
        setActionSelected: (state, action: PayloadAction<boolean>): void => {
            state.actionIsSelected = action.payload
        },
        setFetchingTrafficActions: (state, action: PayloadAction<boolean>): void => {
            state.fetchingTrafficActions = action.payload
        },
        storeListActions: (state, action: PayloadAction<any[]>): void => {
            state.listActions = action.payload
        },
        setFetchingRPActions: (state, action: PayloadAction<boolean>): void => {
            state.fetchingRpActions = action.payload
        },
        loadTrafficActions: (state): void => {
            state.fetchingTrafficActions = true
        },
        loadListActions: (state): void => {
            state.fetchingListActions = true
        }
    },
})

export const {
    storeListActions,
    loadListActions,
    loadTrafficActions,
    setActionSelected
} = actionsSlice.actions

export const selectFetchingTrafficActions = (state: RootState) => state.actions.fetchingTrafficActions
export const selectFetchingRpActions = (state: RootState) => state.actions.fetchingRpActions
export const selectListActions = (state: RootState) => state.actions.listActions
export const selectActionIsSelected = (state: RootState) => state.actions.actionIsSelected


export default actionsSlice.reducer
