import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { Box, Checkbox, Container, Grid, Typography } from '@mui/material'
import { isRpSelected, sortRp } from '../kpiHelper'
import {
    COLORS,
    getComparisonTimeSeriesOptions, getTimeLineSeries,
    getXCategoriesComparisonTimeChart,
} from './comparisonTimeSeriesChartHelper'
import { ComparisonTimeSeriesChartprops, LineChartComparison } from './comparisonTimeSeriesChartTypes'

export const ComparisonTimeSeriesChart: React.FC<ComparisonTimeSeriesChartprops> = (
    {
        kpiList,
        responsePlansSelected,
        apmHorizonCount,
        apmHorizonStep,
        setResponsePlansSelected
    }: ComparisonTimeSeriesChartprops
) => {
    const [selectedResponsePlans, setSelectedResponsePlans] = useState<string[]>(responsePlansSelected)
    const [lineChartBundle, setLineChartBundle] = useState<LineChartComparison[]>([])

    useEffect(() => {
        const lineChartData = getTimeLineSeries(Object.keys(kpiList), kpiList, selectedResponsePlans)
        setLineChartBundle(lineChartData)
    }, [selectedResponsePlans, kpiList])


    const handleResponsePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rpId = event.target.value
        if (isRpSelected(rpId, selectedResponsePlans)) {
            setSelectedResponsePlans(selectedResponsePlans.filter(id => id !== rpId))
            setResponsePlansSelected(selectedResponsePlans.filter(id => id !== rpId))
        } else{
            setSelectedResponsePlans([...selectedResponsePlans, rpId])
            setResponsePlansSelected([...selectedResponsePlans, rpId])
        }
    }

    const xAxisCategories = getXCategoriesComparisonTimeChart(apmHorizonCount, apmHorizonStep)

    const RPsNames = Object.keys(kpiList)

    return (
        <Grid container>
            <Grid item xs={3}>
                <Grid container direction='column'>
                    {RPsNames.sort((a, b) => {
                        return sortRp(a, b, selectedResponsePlans)
                    }).map((responsePlan, idx) => {
                        const label = responsePlan === '0' ? 'DN' : `RP${responsePlan}`
                        return (
                            <Grid item key={responsePlan}>
                                <Container
                                    style={{
                                        marginLeft: '20px',
                                    }}>
                                    <Box display='flex' flexDirection='row' alignItems='center'>
                                        <Checkbox
                                            checked={isRpSelected(responsePlan, selectedResponsePlans)}
                                            onChange={handleResponsePlanChange}
                                            value={responsePlan}
                                            color='primary'
                                        />
                                        <Typography
                                            style={{ marginLeft: '5px', marginRight: '10px', marginTop: '2px', minWidth: '40px', textAlign: 'center' }}>
                                            {label}
                                        </Typography>
                                        <Box
                                            style={{
                                                width: '30px',
                                                height: '3px',
                                                backgroundColor: COLORS[idx],
                                                marginBottom: '3px',
                                            }}
                                        />
                                    </Box>
                                </Container>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid item xs={9}>
                <Grid id='timeSeriesChart' container spacing={3}>
                    {lineChartBundle &&
                        lineChartBundle.map((lineChart, index) => {
                            return (
                                <Grid item key={index} xs={6}>
                                    <Chart
                                        options={getComparisonTimeSeriesOptions(lineChart.title, xAxisCategories)}
                                        series={lineChart.timeSeries} />
                                </Grid>
                            )
                        })}
                </Grid>
            </Grid>
        </Grid>
    )
}
