import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface IResponsePlanState {
    responsePlanIsSelected: boolean
    openResponsePlanCreateDialog: boolean
    featureResponsePlanSelected: any
    responsePlanCreating: boolean
    responsePlanCreated: boolean
    fetchingResponsePlan: boolean

}

const initialResponsePlanState: IResponsePlanState = {
    responsePlanIsSelected: false,
    openResponsePlanCreateDialog: false,
    fetchingResponsePlan: false,
    responsePlanCreating: false,
    responsePlanCreated: false,
    featureResponsePlanSelected: {
        id: 0,
    }
}

const responsePlanSlice = createSlice({
    name: 'responsePlan',
    initialState: initialResponsePlanState,
    reducers: {
        setResponsePlanSelected: (state, action: PayloadAction<boolean>): void => {
            state.responsePlanIsSelected = action.payload
        },
        setOpenResponsePlanCreateDialog: (state, action: PayloadAction<boolean>): void => {
            state.openResponsePlanCreateDialog = action.payload
        },
        storeFeatureResponsePlanSelected: (state, action: PayloadAction<any>): void => {
            state.featureResponsePlanSelected = action.payload
        },
        setResponsePlanCreated: (state, action: PayloadAction<any>): void => {
            state.responsePlanCreated = action.payload
        },
        setResponsePlanCreating: (state, action: PayloadAction<any>): void => {
            state.responsePlanCreating = action.payload
        },
        createNewResponsePlan: (state, action: PayloadAction<any>): void => {
            state.responsePlanCreating = true
        },
        updateResponsePlan: (state, action: PayloadAction<any>): void => {
            state.responsePlanCreating = true
        },
        deleteResponsePlan: (state, action: PayloadAction<any>): void => {
        },
        setFetchNetworkResponsePlan: (state, action: PayloadAction<any>): void => {
            state.fetchingResponsePlan = action.payload
        },
        loadResponsePlan: (state, action: PayloadAction<any>): void => {
            state.fetchingResponsePlan = true
        }
    },
})

export const {
    setResponsePlanSelected,
    storeFeatureResponsePlanSelected,
    setOpenResponsePlanCreateDialog,
    createNewResponsePlan,
    updateResponsePlan,
    deleteResponsePlan,
    setResponsePlanCreating,
    setResponsePlanCreated,
    setFetchNetworkResponsePlan,
    loadResponsePlan
} = responsePlanSlice.actions

export const selectResponsePlanSelected = (state: RootState) => state.responsePlan.responsePlanIsSelected
export const selectOpenResponsePlanCreateDialog = (state: RootState) => state.responsePlan.openResponsePlanCreateDialog
export const selectFeatureResponsePlanSelected = (state: RootState) => state.responsePlan.featureResponsePlanSelected
export const selectResponsePlanCreating = (state: RootState) => state.responsePlan.responsePlanCreating
export const selectResponsePlanCreated = (state: RootState) => state.responsePlan.responsePlanCreated

export default responsePlanSlice.reducer
