import { getRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'

export function getTimesWithData(month: string, year: string): Promise<AxiosResponse<any[]>> {
    return getRequestAPI(`/api_dm/rpc/epochs_with_data?month=${month}&year=${year}&realm=1`)
}

export function getDataRange(): Promise<AxiosResponse<any[]>> {
    return getRequestAPI(`/api_dm/rpc/data_range?realm=1`)
}

export function getMonthsWithData(year: string): Promise<AxiosResponse<any[]>> {
    return getRequestAPI(`/api_dm/rpc/months_with_data?year=${year}&realm=1`)
}

export function getLatestEpochWithData(): Promise<AxiosResponse<any[]>> {
    return getRequestAPI(`/api_dm/rpc/latest_data_available`)
}
