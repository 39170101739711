import { Card, CardHeader, Typography } from '@mui/material'
import {  NAVY_BLUE, NO_DATA } from '../../theme'
import styled from '@emotion/styled'

export const CardHeaderPtModalStyled = styled(CardHeader)`
  border-bottom: 1px solid ${NO_DATA};
  span {
    font-size: 24px;
    font-weight: bold;
  }
`
export const PtLineModalTitle = styled(Typography)`
  font-family: AimsunMedium,Roboto,Sans-serif,serif;
  font-size: 24px;
  font-weight: bold;
  color: ${NAVY_BLUE};
`

export const CardPtLinesModalStyled = styled(Card)`
  padding: 8px 16px;
  position: absolute;
  z-index: 99;
  margin: auto 0;
  right: 8px;
  top: 8px;
`

export const PtLineInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const PtLineInfoContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

