export const prepareTableModifiedCentroidToShow = (originalData: any, modifiedTripsData: any, optionLandUseView: string) => {
    const landUseValuesToShow = [
        {
            name: 'residential',
            translatableLabel: 'mapbox.residential',
            baseline: Math.ceil(originalData?.originalData?.residential),
            modification: originalData?.modifiedData?.newResidential ? originalData?.modifiedData?.newResidential : 0,
            units: 'units.m2'
        },
        {
            name: 'office',
            translatableLabel: 'mapbox.office',
            baseline: Math.ceil(originalData?.originalData?.office),
            modification: originalData?.modifiedData?.newOffice ? originalData?.modifiedData?.newOffice : 0,
            units: 'units.m2'
        },
        {
            name: 'retail',
            translatableLabel: 'mapbox.retail',
            baseline: Math.ceil(originalData?.originalData?.retail),
            modification: originalData?.modifiedData?.newRetail ? originalData?.modifiedData?.newRetail : 0,
            units: 'units.m2'
        },
        {
            name: 'industrial',
            translatableLabel: 'mapbox.industrial',
            baseline: Math.ceil(originalData?.originalData?.industrial),
            modification: originalData?.modifiedData?.newIndustrial ? originalData?.modifiedData?.newIndustrial : 0,
            units: 'units.m2'
        },
        {
            name: 'school',
            translatableLabel: 'mapbox.school',
            baseline: Math.ceil(originalData?.originalData?.school),
            modification: originalData?.modifiedData?.newSchool ? originalData?.modifiedData?.newSchool : 0,
            units: 'units.m2'
        },
        {
            name: 'medical',
            translatableLabel: 'mapbox.medical',
            baseline: Math.ceil(originalData?.originalData?.medical),
            modification: originalData?.modifiedData?.newMedical ? originalData?.modifiedData?.newMedical : 0,
            units: 'units.m2'
        },
        {
            name: 'hotelRooms',
            translatableLabel: 'mapbox.hotelRooms',
            baseline: Math.ceil(originalData?.originalData?.hotelrooms),
            modification: originalData?.modifiedData?.newHotelrooms ? originalData?.modifiedData?.newHotelrooms : 0,
            units: 'units.rooms'
        }
    ]

    let tripsValueToShow: any = []

    if (optionLandUseView !== 'pm') {
        tripsValueToShow = [
            {
                name: 'generatedAm',
                translatableLabel: 'mapbox.generatedAm',
                baseline: Math.ceil(originalData?.originalData?.amGeneration),
                modification: Math.ceil(modifiedTripsData.amGeneration),
                units: 'units.trips'
            },
            {
                name: 'attractedAm',
                translatableLabel: 'mapbox.attractedAm',
                baseline: Math.ceil(originalData?.originalData?.amAttraction),
                modification: Math.ceil(modifiedTripsData.amAttraction),
                units: 'units.trips'
            }
        ]
    }

    if (optionLandUseView !== 'am') {
        tripsValueToShow = [
            {
                name: 'generatedPm',
                translatableLabel: 'mapbox.generatedPm',
                baseline: Math.ceil(originalData?.originalData?.pmGeneration),
                modification: Math.ceil(modifiedTripsData.pmGeneration),
                units: 'units.trips'
            },
            {
                name: 'attractedPm',
                translatableLabel: 'mapbox.attractedPm',
                baseline: Math.ceil(originalData?.originalData?.pmAttraction),
                modification: Math.ceil(modifiedTripsData.pmAttraction),
                units: 'units.trips'
            }
        ]
    }

    return landUseValuesToShow.concat(tripsValueToShow)
}