import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects'
import { getPrecodedResponsePlan } from '../../../../services/responsePlans/precodedResponsePlansService'
import {
    loadManageLiveResponsePlan,
    setPrecodedResponsePlanManageLive,
    setResponsePlanLives,
} from './responsePlanManageLiveSlice'
import { getManageResponsePlan } from '../../../../services/responsePlans/responsePlansService'

function* fetchManageLiveResponsePlan(action: any): any {
    const { epoch } = action.payload
    const responsePlanLives: IResponsePlan[] = yield call(getManageResponsePlan, epoch)
    const precodedResponsePlanLives: IResponsePlan[] = yield call(getPrecodedResponsePlan)

    yield put(setResponsePlanLives(responsePlanLives))
    yield put(setPrecodedResponsePlanManageLive(precodedResponsePlanLives))

}


function* responsePlanManageLiveSaga(): any {
    yield takeLatest(loadManageLiveResponsePlan, fetchManageLiveResponsePlan)
}

export default responsePlanManageLiveSaga
