import { all, AllEffect, call, CallEffectDescriptor, ForkEffect, put,
    PutEffectDescriptor, select, SelectEffectDescriptor, SimpleEffect, takeLatest } from 'redux-saga/effects'
import { selectEpoch } from '../../core/coreSlice'
import {
    createNewEvent,
    deleteEvent,
    loadEvents,
    loadListEvents,
    setEventCreating,
    storeListEvents,
    updateEvent,
} from './eventsSlice'
import {
    createEvent,
    deleteEventService,
    getActiveEvents, getEvents,
    updateEventService,
} from '../../../services/events/eventsService'
import { AxiosResponse } from 'axios'
import { restoreEvents, storeRealEvents, storeTestEvents } from '../../mapbox/mapboxSlice'

function* eventCreation(action: { type: string; payload: any }): Generator<
    SimpleEffect<"PUT", PutEffectDescriptor<any>> |
    SimpleEffect<"PUT",
        PutEffectDescriptor<{ payload: { duration: number; type: string; message: string };
    type: "SHOW_TOOLBAR_FEEDBACK" }>> | SimpleEffect<"SELECT", SelectEffectDescriptor> |
    SimpleEffect<"CALL", CallEffectDescriptor<AxiosResponse<number>>>, void, unknown
    > {
    try {
        yield call(createEvent, action.payload)

    } catch (error) {
        console.error(error)
    }
    yield put(setEventCreating(false))
    yield put(restoreEvents())
    const epochTime = yield select(selectEpoch)
    yield put(loadEvents({ epoch: epochTime, type: 'all' }))
}

function* eventUpdating(action: { type: string; payload: any }): Generator<
    SimpleEffect<"PUT", PutEffectDescriptor<any>> |
    SimpleEffect<"PUT",
        PutEffectDescriptor<{ payload: { duration: number; type: string; message: string };
    type: "SHOW_TOOLBAR_FEEDBACK" }>> | SimpleEffect<"SELECT", SelectEffectDescriptor> |
    SimpleEffect<"CALL", CallEffectDescriptor<AxiosResponse<number>>>, void, unknown
    > {
    try {
        yield call(updateEventService, action.payload)

    } catch (error) {
        console.error(error)
    }
    yield put(setEventCreating(false))
    yield put(restoreEvents())
    const epochTime = yield select(selectEpoch)
    yield put(loadEvents({ epoch: epochTime, type: 'all' }))
}

function* eventDelete(action: { type: string; payload: any }): Generator<
    SimpleEffect<"PUT", PutEffectDescriptor<any>> |
    SimpleEffect<"PUT",
        PutEffectDescriptor<{ payload: { duration: number; type: string; message: string };
    type: "SHOW_TOOLBAR_FEEDBACK" }>> | SimpleEffect<"SELECT", SelectEffectDescriptor> |
    SimpleEffect<"CALL", CallEffectDescriptor<AxiosResponse<number>>>, void, unknown
    > {
    try {
        yield call(deleteEventService, action.payload)

    } catch (error) {
        console.error(error)
    }
    yield put(setEventCreating(false))
    yield put(restoreEvents())
    const epochTime = yield select(selectEpoch)
    yield put(loadEvents({ epoch: epochTime, type: 'all' }))
}

function* fetchEvents(action: any): Generator<unknown, any, unknown> {
    const { type, epoch } = action.payload
    if (type === 'all') {
        yield call(getAllEvents, epoch)
    } else {
        yield call(getEventsByType, epoch, type)
    }
}

function* getAllEvents(epoch: number) {
    const incidentsBundle: any[] = yield all([
        getActiveEvents(epoch, 'real'),
        getActiveEvents(epoch, 'test'),
    ])

    yield put(storeRealEvents(incidentsBundle[0]))
    yield put(storeTestEvents(incidentsBundle[1]))
}

export function* getEventsByType(epoch: number, type: IIncidentType) {
    let nextIncidents: GeoJSON.FeatureCollection
    switch (type) {
        case 'real':
            nextIncidents = yield call(getActiveEvents, epoch, 'real')
            yield put(storeRealEvents(nextIncidents))
            break
        case 'test':
            nextIncidents = yield call(getActiveEvents, epoch, 'test')
            yield put(storeTestEvents(nextIncidents))
            break
        default:
            console.log('Event type not found')
    }
}

export function* fetchListEvents() {
    const events: any[] = yield call(getEvents)
    yield put(storeListEvents(events))

}

function* eventsSaga(): Generator<ForkEffect<never> | AllEffect<any>, void, any> {
    yield all([
        yield takeLatest(createNewEvent, eventCreation),
        yield takeLatest(updateEvent, eventUpdating),
        yield takeLatest(deleteEvent, eventDelete),
        yield takeLatest(loadEvents, fetchEvents),
        yield takeLatest(loadListEvents, fetchListEvents)
    ])
}

export default eventsSaga
