import styled from '@emotion/styled'
import { IconButton, Paper, Typography } from '@mui/material'
import { NAVY_BLUE, RED_LIVE_DARK } from '../../theme'

export const DialogTitleWrapper = styled.div`
    margin: 20px 20px 0;
`

export const Title = styled(Typography)`
    margin: 20px 20px 0;
    padding: 20px 0 15px;
`

export const PaperTitle = styled(Typography)`
    padding-bottom: 15px;
    padding-top: 0px;
    margin: 0;
`

export const CloseButton = styled(IconButton)`
    position: absolute;
    right: 10px;
    top: 10px;
    color: ${RED_LIVE_DARK};
`
export const PaperContent = styled(Paper)`
    padding: 40px;
`

export const DatetimeText = styled(Typography)`
    font-size: 39px;
    color: ${NAVY_BLUE};
`

export const PaperText = styled(Typography)`
    font-size: 20x;
    color: ${NAVY_BLUE};
    line-height: 34px;
`
