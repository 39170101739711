import { getRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'

const reporterServer: string = '/reporter'
const clockServer: string = '/api_clock'
const noApmEndpoint: string = '/no_apm'
const computerInfoEndpoint: string = '/computer_info'
const simulationHealthEndpoint: string = '/simulation_health'
const simRuntimeEndpoint: string = '/sim_runtime'
const missingDataEntpoint: string = '/missing'
const patternQualityEndpoint: string = '/pattern_match_geh'
const analyticalQualityEndpoint: string = '/ana_geh'
const simulatedQualityEndpoint: string = '/sim_geh'

export const noApmRequest = (): Promise<AxiosResponse<INoApmResponse>> =>
    getRequestAPI(`${reporterServer}${noApmEndpoint}`)
export const computerInfoRequest = (): Promise<AxiosResponse<IComputerInfoResponse>> =>
    getRequestAPI(`${reporterServer}${computerInfoEndpoint}`)
export const missingDataRequest = (): Promise<AxiosResponse<IMissingDataResponse>> =>
    getRequestAPI(`${reporterServer}${missingDataEntpoint}`)
export const patternQualityRequest = (): Promise<AxiosResponse<IPatternQualityResponse>> =>
    getRequestAPI(`${reporterServer}${patternQualityEndpoint}`)
export const analyticalQualityRequest = (): Promise<AxiosResponse<IAnaQualityResponse>> =>
    getRequestAPI(`${reporterServer}${analyticalQualityEndpoint}`)
export const simulatedQualityRequest = (): Promise<AxiosResponse<ISimQualityResponse>> =>
    getRequestAPI(`${reporterServer}${simulatedQualityEndpoint}`)
export const simulatedRuntimeRequest = (): Promise<AxiosResponse<ISimRuntimeResponse>> =>
    getRequestAPI(`${reporterServer}${simRuntimeEndpoint}`)
export const simulationHealthRequest = (): Promise<AxiosResponse<ISimulationHealthResponse>> =>
    getRequestAPI(`${clockServer}${simulationHealthEndpoint}`)
export const getSystemStatusRequest = (): Promise<AxiosResponse<ISystemStatusResponse>> =>
    getRequestAPI('/consul/v1/agent/services')
export const getSystemServices = (): Promise<AxiosResponse<any>> => getRequestAPI('/config/services.txt')
