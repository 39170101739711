import { getRequestAPI, postRequestAPI } from '../apiClient/apiClientHelpers'
import { AxiosResponse } from 'axios'

export async function getManageResponsePlan(epoch: number): Promise<AxiosResponse<IResponsePlan>> {
    return getRequestAPI(`/api_dm/response_plans?epoch=${epoch}`, {cache: false})
}

export async function createManageResponsePlan(responsePlan: ICreateResponsePlan): Promise<AxiosResponse<any>> {
    return await postRequestAPI(`api_dm/response_plans`, responsePlan)
}
